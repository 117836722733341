<template>
  <div>
    <sm-card header-title="Props">
      <div class="">
        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add the name attribute to the input<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> name<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">name="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-select
              label="Select with name prop"
              name="name" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add the id attribute to the select<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> id<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">id="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-select
              id="id"
              label="Select with id prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Set the label key to a different key from 'label'<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> customLabel<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">custom-label="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-select
              id="id"
              custom-label="anything"
              label="Select with customLabel prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add error text and state to the select<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> error<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean, String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">error="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-select
              id="id"
              error="Some error text"
              label="Select with error prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add disabled state to select<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> disabled<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:disabled="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-select
              disabled
              label="Select with disabled prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add required state to select<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> required<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:required="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-select
              required
              label="Select with required prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Allow to remove the selected option<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> clearable<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:clearable="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-select
              :model-value="selectValue"
              :options="options"
              clearable
              label="Select with clearable prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Allow the select multiple options<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> multiple<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:multiple="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-select
              :model-value="selectValue"
              :options="options"
              multiple
              label="Select with multiple prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Allow the select multiple options with tags (it remains open when choose one)<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> tagSelect<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:tag-select="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-select
              :model-value="selectValue"
              :options="options"
              tag-select
              label="Select with tagSelect prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Append the select option to the body<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> appendToBody<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:append-to-body="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-select
              :model-value="selectValue"
              :options="options"
              append-to-body
              label="Select with appendToBody prop" />
          </div>
        </div>
        <hr>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Render the label inline before the select<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> inline<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:inline="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-select
              inline
              label="Select with inline prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Render the select with half width<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> half<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:half="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-select
              half
              label="Select with half prop" />
          </div>
        </div>
        <hr>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add default options to select<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> preset<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Available values:</strong> gender<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">preset="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-select
              preset="gender"
              label="Select with preset gender" />
          </div>
        </div>
      </div>
    </sm-card>
  </div>
</template>

<script setup>
const selectValue = ref({
  id: 1,
  label: "First something"
});
const options = ref([
  {
    id: 1,
    label: "First something"
  },
  {
    id: 2,
    label: "Second something"
  },
  {
    id: 3,
    label: "Third and extremly long something"
  }
]);
</script>
