<template>
  <sm-card header-title="Emits">
    <div class="sm-row">
      <div class="w-1/2 sm-col">
        <p class="mb-2">
          Emits the <code>update:modelValue</code> event when the value of the checkbox has changed (in vue2 its the input event)<br>
          <strong class="inline-block pl-2 w-36">Emit name:</strong> @update:modelValue<br>
          <code class="inline-block pl-2"><pre class="text-xs text-bo-yellow-600">&lt;sm-checkbox @update:modelValue="customEvent" /&gt;</pre></code>
        </p>
      </div>
      <div class="w-1/2 sm-col">
        <sm-checkbox
          label="Emit the update:modelValue event (check the console)"
          @update:modelValue="e => console.log(e)" />
      </div>
    </div>
  </sm-card>
</template>
