<template>
  <settings-layout>
    <in-head :title="$t('ntak.label')" />

    <sm-alert
      v-if="settings.certificate_uploaded_at && settings.tax_number !== ''"
      color="success">
      {{ $t('ntak.settings.alerts.upload_success') }}<br>
      {{ $t('ntak.settings.alerts.certificate_uploaded_at', {date: useDateFormat(settings.certificate_uploaded_at, 'long')}) }}
    </sm-alert>

    <h4 class="flex items-center">
      <vue-feather
        type="check-circle"
        class="w-4 h-4 mr-2 text-bo-green" />
      {{ $t('ntak.settings.steps.first') }}
    </h4>

    <sm-card
      class="mb-8"
      :form="form"
      @onSubmit="onSubmit">
      <sm-input
        v-model="form.registration_number"
        name="registration_number"
        :label="$t('ntak.settings.registration_number.label')"
      />
      <sm-input
        v-model="form.tax_number"
        name="tax_number"
        :label="$t('ntak.settings.tax_number.label')"
        :help="$t('ntak.settings.tax_number.help')"
      />
    </sm-card>

    <template v-if="settings.registration_number">
      <h4 class="flex items-center">
        <vue-feather
          type="check-circle"
          class="w-4 h-4 mr-2 text-bo-green" />
        {{ $t('ntak.settings.steps.second') }}
      </h4>

      <sm-card class="mb-8">
        <sm-alert
          v-if="settings.certificate_uploaded_at"
          color="warning">
          {{ $t('ntak.settings.generate_csr.warning') }}
        </sm-alert>
        <form
          method="post"
          :action="page.props.csrGeneratorEndpoint">
          <input
            type="hidden"
            name="_token"
            :value="page.props.csrfToken">
          <sm-button
            type="submit"
            color="primary"
            :title="$t('ntak.settings.generate_csr.button')"
          />
        </form>
      </sm-card>

      <h4 class="flex items-center">
        <vue-feather
          type="check-circle"
          class="w-4 h-4 mr-2 text-bo-green" />
        {{ $t('ntak.settings.steps.third') }}
      </h4>

      <sm-card
        class="mb-8"
        :form="form"
        @onSubmit="onSubmit">
        <sm-alert
          v-if="settings.certificate_uploaded_at"
          color="info">
          {{ $t('ntak.settings.alerts.certificate_uploaded_at', {date: useDateFormat(settings.certificate_uploaded_at, 'long')}) }}
        </sm-alert>
        <sm-alert
          v-else
          color="warning">
          {{ $t('ntak.settings.alerts.must_upload') }}
        </sm-alert>
        <sm-file
          v-model="form.certificate"
          name="certificate"
          :label="$t('ntak.settings.certificate.label')"
        >
          <template #help>
            <span v-html="$t('ntak.settings.certificate.help')" />
          </template>
        </sm-file>
      </sm-card>
    </template>
  </settings-layout>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";
import { useForm, usePage } from "@inertiajs/vue3";
import { provide } from "vue";

const props = defineProps({
  settings: { type: Object, required: true, default:  null },
});

// default variables
const page = usePage();

const form = useForm({
  registration_number: props.settings.registration_number,
  tax_number: props.settings.tax_number,
  certificate: null
});

const onSubmit = () => {
  form
    .transform(data => {
      return {
        ...data
      };
    })
    .post(page.url);
};

provide("form", form);
</script>
