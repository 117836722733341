<template>
  <settings-layout>
    <in-head :title="$t('system_settings.settings.title')" />
    <sm-card
      :form="form"
      :header-title="$t('system_settings.settings.title')"
      @onSubmit="onSubmit">
      <sm-checkbox
        id="send_system_news_emails"
        v-model="form.send_system_news_emails"
        name="send_system_news_emails"
        :text="$t('generic.yes')"
        :label="$t('system_settings.settings.send_system_news_emails.label')"
        :help="$t('system_settings.settings.send_system_news_emails.help')"
      />
      <template #footer>
        <sm-button
          preset="save"
          :loading="form.processing"
          @click="onSubmit" />
      </template>
    </sm-card>
  </settings-layout>
</template>

<script setup>
import { useForm, usePage } from "@inertiajs/vue3";
import { provide } from "vue";

const props = defineProps({
  settings: { type: Object, required: true, default:  null },
});

// default variables
const page = usePage();

const form = useForm({
  send_system_news_emails: props.settings.send_system_news_emails,
});

const onSubmit = () => {
  form
    .post(page.url);
};

provide("form", form);
</script>
