<template>
  <sm-modal
    :modal-is-visible="modalVisibility"
    :modal-title="$t('customers.tickets.modals.move.title')"
    @toggleModalVisibility="emit('toggleModalVisibility')">
    <form @submit.prevent="onSubmit">
      <sm-alert color="secondary">
        <span>{{ $t('customers.tickets.modals.move.help') }}</span>
      </sm-alert>
      <sm-async-select
        v-model="form.user"
        :label="$t('customers.fields.name_long')"
        name="user_id"
        :route="modalData.routes.searchCustomer" />
    </form>
    <template #footer>
      <sm-button
        :title="$t('generic.cancel')"
        @click="emit('toggleModalVisibility')" />
      <sm-button
        color="primary"
        :title="$t('generic.save')"
        :disabled="!form.isDirty"
        :loading="form.processing"
        @click="onSubmit" />
    </template>
  </sm-modal>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";

const props = defineProps({
  modalVisibility: { type: Boolean, required: true, default: false },
  modalData: { type: Object, required: false, default: null },
});

const emit = defineEmits(["toggleModalVisibility"]);

const form = useForm({
  user: null,
});

provide("form", form);

const onSubmit = () => {
  form
    .transform((data) => ({
      user_id: data.user.id,
    }))
    .post(props.modalData.routes.moveTicket, {
      only: ["table", "customer", "errors", "flash"],
      onSuccess: () => {
        emit("toggleModalVisibility");
        form.reset("user");
      }
    });
};
</script>
