<template>
  <div>
    <div
      class="flex-1 hidden"
      :class="{'!flex': filteredAbilities.length}"
    >
      <div
        class="px-0 bg-white sticky sm-col w-3/12 flex-[0_0_25%] min-w-[200px] border-x left-0 z-[199]"
      >
        <div class="cursor-pointer select-none">
          <div
            class="flex items-center h-12 px-2 border-b bg-bo-gray-100/60"
            @click="collapsed = !collapsed"
          >
            <div
              v-if="collapsed"
              class="mr-1"
            >
              <slot name="icon-down" />
            </div>
            <div
              v-cloak
              v-if="!collapsed"
              class="mr-1"
            >
              <slot name="icon-up" />
            </div>
            <span class="flex items-center justify-between flex-1 overflow-hidden font-medium">
              <span class="mr-1 overflow-hidden truncate">
                {{ $t(`permissions.abilities.${group}.title`) }}
              </span>
              <span class="badge badge-light badge-number">
                {{ filteredAbilities.length }}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div
        v-for="role in roles"
        :key="role.id"
        class="px-0 bg-white sm-col w-2/12 flex-[0_0_16.666667%] min-w-[100px] border-r">
        <div class="flex items-center justify-center h-12 border-b bg-bo-gray-100/60">
          <label
            class="sm-toggleable sm-checkbox"
            :for="`groupCheckbox-${group}${role.id}`">
            <input
              :id="`groupCheckbox-${group}${role.id}`"
              :ref="`groupCheckbox-${role.id}`"
              type="checkbox"
              :disabled="isAdminRole(role)"
              :readonly="isAdminRole(role)"
              :checked="isAdminRole(role)"
              class="outline-none custom-checkbox group-checkbox"
              @change="onGroupSelected($event, group, role)"
            >
            <span>
              <check-icon class="text-white" />
            </span>
          </label>
          <!-- <input
            :ref="`groupCheckbox-${role.id}`"
            type="checkbox"
            class="w-full outline-none custom-checkbox ht-15 grouped"
            :disabled="isAdminRole(role)"
            :readonly="isAdminRole(role)"
            :checked="isAdminRole(role)"
            @change="onGroupSelected($event, group, role)"
          > -->
        </div>
      </div>
    </div>
    <div
      v-for="ability in abilities"
      :key="ability.name"
      class="flex-1 hidden"
      :class="{'hidden': !shouldShowAbilityRow(ability), '!flex': shouldShowAbilityRow(ability)}"
    >
      <div
        class="px-0 bg-white sticky sm-col w-3/12 flex-[0_0_25%] min-w-[200px] border-r left-0 z-[199]"
      >
        <div class="flex items-center h-10 pl-2 border-b border-l">
          {{ $t(`permissions.abilities.${ability.name}`) }}
        </div>
      </div>
      <div
        v-for="role in roles"
        :key="role.id"
        class="px-0 mb-0 bg-white sm-col w-2/12 flex-[0_0_16.666667%] min-w-[100px] border-r flex items-center justify-center h-10 border-b cursor-pointer"
        :name="`abilities[${role.id}][${ability.name}]`"
      >
        <label
          class="sm-toggleable sm-checkbox"
          :for="`abilities[${role.id}][${ability.name}]`">
          <input
            :id="`abilities[${role.id}][${ability.name}]`"
            :name="`abilities[${role.id}][${ability.name}]`"
            type="checkbox"
            class="outline-none custom-checkbox group-checkbox"
            value="1"
            :checked="roleHasAbility(role, ability)"
            :disabled="isAdminRole(role)"
            :readonly="isAdminRole(role)"
            @change="onItemSelected(role, ability)"
          >
          <span>
            <check-icon class="text-white" />
          </span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import difference from "lodash/difference";
import union from "lodash/union";
import xor from "lodash/xor";
import { CheckIcon } from "vue-feather-icons";

export default {
  components: {
    CheckIcon
  },
  props: {
    group: { type: String, default: null, },
    abilities: { type: Array, default: () => []},
    roles: { type: Array, default: () => []},
    searchString: { type: String, default: null }
  },
  emits: ["role-changed"],
  data() {
    return {
      collapsed: true,
    };
  },
  computed: {
    filteredAbilities() {
      if (!this.searchString) {
        return this.abilities;
      }

      return this.abilities.filter(ability => {
        return this.$t(`permissions.abilities.${ability.name}`)
          .toLowerCase()
          .includes(this.searchString.toLowerCase());
      });
    }
  },
  mounted() {
    this.roles.forEach(role => this.updateGroupState(role));
  },
  methods: {
    isAdminRole(role) {
      return role.name === "club-admin";
    },
    getAbilityGroupName(ability) {
      const lastDot = ability.name.lastIndexOf(".");

      if (lastDot === -1) {
        return ability.name;
      }

      return ability.name.substr(0, lastDot);
    },
    translatedAbilityName(ability) {
      return this.$t(`permissions.abilities.${ability.name}`);
    },
    shouldShowAbilityRow(ability) {
      if (this.collapsed) {
        return false;
      }

      if (!this.searchString) {
        return true;
      }

      return this.translatedAbilityName(ability)
        .toLowerCase()
        .includes(this.searchString.toLowerCase());
    },
    onGroupSelected(event, group, role) {
      if (event.target.checked) {
        // add all abilities that are filtered
        role.abilities = union(role.abilities, this.filteredAbilities.map(a => a.name));
      } else {
        // remove all abilities that are filtered
        role.abilities = difference(role.abilities, this.filteredAbilities.map(a => a.name));
      }

      this.$emit("role-changed", role);

      event.target.indeterminate = this.filteredAbilities.length !== this.abilities.length;
    },
    onItemSelected(role, ability) {
      role.abilities = xor(role.abilities, [ability.name]);

      this.$emit("role-changed", role);

      this.updateGroupState(role);
    },
    roleHasAbility(role, ability) {
      return role.abilities.includes(ability.name);
    },
    updateGroupState(role) {
      const abilityCount = this.abilities.length;
      const selectedAbilityCount = this.abilities.filter(ability => {
        return this.roleHasAbility(role, ability);
      }).length;

      const groupCheckbox = this.$refs[`groupCheckbox-${role.id}`][0];

      if (abilityCount === selectedAbilityCount) {
        // checked
        groupCheckbox.indeterminate = false;
        groupCheckbox.checked = true;
      } else {
        if (selectedAbilityCount > 0) {
          // indeterminate
          groupCheckbox.indeterminate = true;
          groupCheckbox.checked = true;
        } else {
          // not checked
          groupCheckbox.checked = false;
          groupCheckbox.indeterminate = false;
        }
      }
    }
  },
};
</script>
