<template>
  <sm-card header-title="Emits">
    <div class="sm-row">
      <div class="w-1/2 sm-col">
        <p class="mb-2">
          Emits the <code>update:modelValue</code> event when the value of the input has changed (in vue2 its the input event)<br>
          <strong class="inline-block pl-2 w-36">Emit name:</strong> @update:modelValue<br>
          <code class="inline-block pl-2"><pre class="text-xs text-bo-yellow-600">&lt;sm-input @update:modelValue="customEvent" /&gt;</pre></code>
        </p>
      </div>
      <div class="w-1/2 sm-col">
        <sm-input
          label="Emit the update:modelValue event (check the console)"
          @update:modelValue="console.log('Emitted')" />
      </div>
    </div>
    <hr>

    <div class="sm-row">
      <div class="w-1/2 sm-col">
        <p class="mb-2">
          Emits the onEnter event when the Enter button has pressed<br>
          <strong class="inline-block pl-2 w-36">Emit name:</strong> @onEnter<br>
          <code class="inline-block pl-2"><pre class="text-xs text-bo-yellow-600">&lt;sm-input @onEnter="customEvent" /&gt;</pre></code>
        </p>
      </div>
      <div class="w-1/2 sm-col">
        <sm-input
          label="Emit the onEnter event (check the console)"
          @onEnter="console.log('Emitted the onEnter event')" />
      </div>
    </div>
    <hr>
  </sm-card>
</template>
