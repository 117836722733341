<script>
import $ from "jquery";

export default {
  mounted(el, binding, vNode) {
    $(el).datepicker({
      autoclose: true,
      format: "yyyy-mm-dd"
    }).on("changeDate", function (e) {
      vNode.elm.dispatchEvent(new Event("input", { bubbles: true, value: e.format() }));
    });
  },
  updated(el) {
    if (el.value === "") {
      // Force reset datepicker's value
      $(el).datepicker("update", "");
    }
  }
};
</script>
