<script>
import ProductRow from "./ProductRow.vue";
import debounce from "lodash/debounce";
import axios from "axios";

export default {
  name: "StockRegistrationForm",
  components: {ProductRow},
  props: {
    productSearchEndpoint: { type: String, default: "" },
    selectedProducts: { type: Array, default: () => [] },
    productLastRegistrationPriceEndpoint: { type: String, default: "" },
  },
  data() {
    return {
      productToAdd: {
        product: null,
        acquisitionPrice: null,
        amount: null,
      },
    };
  },
  computed: {
    products() {
      return this.$store.state.cafeteria.stockRegistration.selectedProducts;
    },
    availableProducts() {
      return this.$store.state.cafeteria.stockRegistration.availableProducts;
    },
    netSum() {
      return this.$store.getters.productsNetSum;
    },
    sum() {
      return this.$store.getters.productsSum;
    },
    canAddProduct() {
      return this.productToAdd.product !== null &&
        this.productToAdd.amount > 0 &&
        this.productToAdd.acquisitionPrice > 0;
    }
  },
  watch: {
    "productToAdd.product"(product) {
      if (product) {
        this.loadLastRegistrationPrice(product.id);
      } else {
        this.productToAdd.acquisitionPrice = null;
      }
    }
  },
  mounted() {
    this.fetchProducts = debounce(this.fetchProducts, 350);

    if (this.selectedProducts && this.selectedProducts.length > 0) {
      this.$store.commit("setSelectedProducts", this.selectedProducts);
    }
  },
  methods: {
    addProduct() {
      if (this.canAddProduct) {
        this.$store.commit("selectProduct", this.productToAdd);
        this.clearProductToAdd();
      }
    },
    removeProduct(product) {
      this.$store.commit("removeProduct", product);
    },
    clearProductToAdd() {
      this.productToAdd = {
        acquisitionPrice: null,
        amount: null,
        product: null,
      };
    },
    searchProduct(term, loading) {
      this.fetchProducts(term, loading);
    },
    async fetchProducts(term, loading) {
      loading && loading(true);
      try {
        let params = term && term.length > 0 ? `search=${encodeURIComponent(term)}` : "";
        const response = await axios.get( `${this.productSearchEndpoint}?${params}`);
        this.$store.commit("setAvailableProducts", response.data.data.map(product => ({
          value: product.id,
          label: product.title,
          product,
        })));
      } catch (e) {
        console.error(e);
      } finally {
        loading && loading(false);
      }
    },
    reduceSelectedProduct(product) {
      return {
        ...product.product,
        label: product.label,
      };
    },
    async loadLastRegistrationPrice(productId) {
      try {
        const response = await axios.get(
          "/" + this.productLastRegistrationPriceEndpoint.replace("{product}", productId)
        );

        this.productToAdd.acquisitionPrice = response.data || 0;
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>
