(function () {
  const token = document.head.querySelector("meta[name=\"csrf-token\"]");

  const addTokenToForm = form => {
    const existingHiddenToken = form.querySelector("input[name=_token]");
    if (existingHiddenToken) {
      return;
    }

    const csrfInput = document.createElement("input");
    csrfInput.setAttribute("type", "hidden");
    csrfInput.setAttribute("name", "_token");
    csrfInput.setAttribute("value", token.content);
    form.appendChild(csrfInput);
  };

  document.querySelectorAll("button[formaction]")
    .forEach(node => {
      addTokenToForm(node.form);

      node.form.addEventListener("submit", () => {
        addTokenToForm(node.form);
      });
    });
})();
