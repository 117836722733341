<template>
  <styleguide-layout>
    <sm-anchor-point id="defaults" />
    <input-defaults />

    <sm-anchor-point id="props" />
    <input-props />

    <sm-anchor-point id="slots" />
    <input-slots />

    <sm-anchor-point id="emits" />
    <input-emits />
  </styleguide-layout>
</template>

<script setup>
import InputDefaults from "@/inertia/components/styleguide/Input/InputDefaults.vue";
import InputEmits from "@/inertia/components/styleguide/Input/InputEmits.vue";
import InputProps from "@/inertia/components/styleguide/Input/InputProps.vue";
import InputSlots from "@/inertia/components/styleguide/Input/InputSlots.vue";
import StyleguideLayout from "@/inertia/components/styleguide/StyleguideLayout.vue";
import { useForm } from "@inertiajs/vue3";

const form = useForm({});
provide("form", form);
</script>
