<template>
  <div class="pb-4 mb-4 border-bottom">
    <div class="flex items-center">
      <div class="space-y-4 sm-row lg:space-y-0">
        <div class="w-1/2 sm-col lg:w-2/12">
          <label class="sm-label">Időtartam</label>
          <timepicker
            v-model="innerData.from"
            :value="innerData.from" />
        </div>

        <div class="sm-col w-1/2 lg:w-2/12 !mt-0">
          <label class="sm-label">Vég</label>
          <timepicker
            v-model="innerData.to"
            :value="innerData.to" />
        </div>

        <div class="w-full sm-col lg:w-2/12">
          <label class="sm-label">Vendég ár</label>
          <div class="sm-input-group">
            <input
              v-model="innerData.price"
              type="number"
              min="0"
              class="sm-input-text"
            >
            <div class="sm-input-group-append">
              Ft
            </div>
          </div>
        </div>

        <div class="w-full sm-col lg:w-2/12">
          <label class="sm-label">Tag ár</label>
          <div class="sm-input-group">
            <input
              v-model="innerData.member_price"
              type="number"
              min="0"
              class="sm-input-text"
            >
            <div class="sm-input-group-append">
              Ft
            </div>
          </div>
        </div>

        <div class="w-full sm-col lg:w-2/12">
          <label class="sm-label">Edző ár</label>
          <div class="sm-input-group">
            <input
              v-model="innerData.coach_price"
              type="number"
              min="0"
              class="sm-input-text"
            >
            <div class="sm-input-group-append">
              Ft
            </div>
          </div>
        </div>

        <div class="flex items-end w-auto mt-4 sm-col">
          <div
            class="sm-btn sm-btn-danger-outline sm-btn-block lg:mt-label"
            @click="onDelete"
          >
            <i class="far fa-trash-alt" />
          </div>
        </div>
      </div>
    </div>

    <input
      type="hidden"
      :value="JSON.stringify(transferableEntry)"
      :name="`prices[${innerData.day}][]`"
    >
  </div>
</template>

<script>
import Timepicker from "./Timepicker.vue";

export default {
  components: { Timepicker },
  props: {
    entry: { type: Object, default: () => {} },
  },
  emits: ["deleted"],
  data() {
    return {
      innerData: this.entry,
    };
  },
  computed: {
    transferableEntry: function () {
      return {
        day: this.innerData.day,
        from: this.innerData.from,
        to: this.innerData.to,
        price: this.innerData.price,
        member_price: this.innerData.member_price,
        coach_price: this.innerData.coach_price,
      };
    },
  },
  methods: {
    onDelete: function () {
      this.$emit("deleted");
    },
  },
};
</script>
