<template>
  <tr>
    <td>
      {{ dataItem?.club_product?.name }}
    </td>
    <td>
      {{ dataItem?.club_product?.barcode ?? '-' }}
    </td>
    <td
      :class="{'text-bo-blue transition-all font-bold': refreshed}">
      <div class="flex items-center">
        <span>{{ dataItem?.formatted }}</span>
        <transition name="fade">
          <span
            v-if="loading"
            class="inline-flex">
            <vue-feather
              type="rotate-cw"
              class="ml-1 animate-spin"
              size="16"
            />
          </span>
        </transition>
      </div>
    </td>
    <td>
      <div class="flex items-center space-x-2">
        <div v-auto-animate>
          <span
            v-if="saveLoading"
            class="inline-flex">
            <vue-feather
              type="rotate-cw"
              class="animate-spin"
              size="16"
            />
          </span>
          <span
            v-else
            class="min-w-[13px]"
          >
            <div v-auto-animate>
              <vue-feather
                v-if="saved"
                class="text-bo-green"
                type="check-circle"
                size="16"
              />
              <vue-feather
                v-if="failed"
                v-tippy="actualAmountError"
                class="opacity-100 text-bo-red"
                type="alert-triangle"
                size="16"
              />
            </div>
          </span>
        </div>
        <sm-input
          v-model="data"
          class="m-0"
          :placeholder="dataItem?.formatted"
          @onChange="onUpdate"
          @onFocus="onFocus"
        />
      </div>
    </td>
  </tr>
</template>

<script setup>
import axios from "axios";

const props = defineProps({
  item: { type: Object, required: true }
});

const dataItem = ref(props.item);
const data = ref(dataItem.value.actual_amount);
const loading = ref(false);
const saveLoading = ref(false);
const refreshed = ref(false);
const actualAmountError = ref("");

const saved = ref(data.value !== null);
const failed = ref(false);

const onUpdate = () => {
  saveLoading.value = true;
  saved.value = false;
  failed.value = false;

  axios.post(props.item.routes.update, {actual_amount: data.value})
    .then((response) => {
      saved.value = true;
    })
    .catch((e) => {
      if (e.response) {
        actualAmountError.value = e.response.data?.errors?.actual_amount[0];
      }
      failed.value = true;
    })
    .finally(() => {
      saveLoading.value = false;
    });
};

const onFocus = () => {
  loading.value = true;
  axios.get(props.item.routes.show)
    .then((response) => {
      if (parseInt(dataItem.value.expected_amount) !== parseInt(response.data.expected_amount)) {
        refreshed.value = true;
        setTimeout(() => {
          refreshed.value = false;
        }, 500);
      }
      dataItem.value = response.data;
    }).finally(() => {
      loading.value = false;
    });
};
</script>
