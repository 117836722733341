<template>
  <Form @onSubmit="onSubmit" />
</template>

<script setup>
import { useForm, usePage } from "@inertiajs/vue3";
import Form from "../Form.vue";

const page = usePage();

const validityRanges = ref(
  page.props.ticketValidityRanges.map(id => ({ id, label: window.trans(`ticket_validity_range.${id}`) }))
);

const form = useForm(page.props.ticket);

const onSubmit = () => {
  form
    .transform(data => {
      const { limitless_usage, available_uses, ...rest } = data;
      return {
        ...rest,
        validity_range: data.validity_range?.id,
        continuous_discount_type: data.continuous_discount_type?.id,
        available_uses: limitless_usage ? -1 : Math.max(-1, available_uses),
      };
    })
    .put(page.props.routes.submit);
};

provide("form", form);
provide("validityRanges", validityRanges);
</script>
