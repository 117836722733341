<template>
  <input
    class="sm-input-text"
    :value="defaultValue"
    placeholder="__:__"
    @change="changed($event)"
  >
</template>

<script>
import Cleave from "cleave.js";

export default {
  props: {
    value: { type: [String, Number], default: null }
  },
  emits: ["update:modelValue"],
  data() {
    return {
      defaultValue: null
    };
  },
  mounted: function () {
    this.defaultValue = this.value;
    new Cleave(this.$el, {
      time: true,
      timePattern: ["h", "m"],
    });
  },
  methods: {
    changed(e) {
      this.$emit("update:modelValue", e.target.value);
      this.defaultValue = e.target.value;
    },
  },
};
</script>
