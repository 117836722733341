<template>
  <div v-if="hasPages">
    <ul class="sm-pagination">
      <li
        class="sm-page-item"
        :class="{disabled: isFirstPage}"
        @click="goToFirstPage"
      >
        <span class="sm-page-link">
          <i class="fas fa-angle-double-left" />
        </span>
      </li>
      <li
        class="sm-page-item"
        :class="{disabled: !hasPrevPage}"
        @click="goToPrevPage"
      >
        <span class="sm-page-link">
          <i class="fas fa-angle-left" />
        </span>
      </li>
      <li
        v-for="page in pages"
        :key="page"
        class="sm-page-item"
        :class="{active: pagination.current_page === page}"
      >
        <span
          class="sm-page-link"
          @click="goToPage(page)"
        >{{ page }}</span>
      </li>
      <li
        class="sm-page-item"
        :class="{disabled: !hasNextPage}"
        @click="goToNextPage"
      >
        <span class="sm-page-link">
          <i class="fas fa-angle-right" />
        </span>
      </li>
      <li
        class="sm-page-item"
        :class="{disabled: isLastPage}"
        @click="goToLastPage"
      >
        <span class="sm-page-link">
          <i class="fas fa-angle-double-right" />
        </span>
      </li>
    </ul>
  </div>
</template>
<script>
import range from "lodash/range";

export default {
  props: {
    pagination: {
      type: Object, default: () => {
      }
    },
    surroundingPages: {
      type: Number,
      default: 2
    }
  },
  emits: ["page-selected"],
  computed: {
    pages() {
      let start = this.pagination.current_page - this.surroundingPages;
      let end = this.pagination.current_page + this.surroundingPages;

      if (start < 1) {
        start = 1;
        end = start + (2 * this.surroundingPages);
      }

      if (end > this.pagination.last_page) {
        end = this.pagination.last_page;

        let possiblyNewStart = end - (2 * this.surroundingPages);
        if (possiblyNewStart < 1) {
          possiblyNewStart = 1;
        }
        start = possiblyNewStart;
      }

      return range(start, end + 1);
    },
    hasPages() {
      return this.pagination.last_page > 1;
    },
    hasNextPage() {
      return !this.isLastPage && this.pagination.current_page < this.pagination.last_page;
    },
    hasPrevPage() {
      return !this.isFirstPage;
    },
    isLastPage() {
      return this.pagination.current_page === this.pagination.last_page;
    },
    isFirstPage() {
      return this.pagination.current_page === 1;
    },
    nextPage() {
      return this.pagination.current_page + 1;
    },
    prevPage() {
      return this.pagination.current_page - 1;
    },
  },
  methods: {
    goToFirstPage() {
      if (this.isFirstPage) {
        return;
      }

      this.goToPage(1);
    },
    goToPrevPage() {
      if (!this.hasPrevPage) {
        return;
      }

      this.goToPage(this.prevPage);
    },
    goToPage(page) {
      if (this.pagination.current_page === page) {
        return;
      }

      this.$emit("page-selected", page);
    },
    goToNextPage() {
      if (!this.hasNextPage) {
        return;
      }

      this.goToPage(this.nextPage);
    },
    goToLastPage() {
      if (this.isLastPage) {
        return;
      }

      this.goToPage(this.pagination.last_page);
    }
  }
};
</script>
