<template>
  <div>
    <sm-card header-title="Default usage">
      <sm-async-select
        v-model="selectModel"
        label="Default async select"
        route="/api-int/customers" />
      <code class="block !-mt-4"><pre class="text-xs">&lt;sm-async-select label="..." route="..." /&gt;</pre></code>
    </sm-card>
  </div>
</template>

<script setup>
const selectModel = ref("");
</script>
