<template>
  <div>
    <sm-button
      icon="copy"
      :title="$t('tickets.season_tickets.copy_from_another_ticket')"
      @click="modalOpen = true"
    />
    <sm-modal
      :modal-is-visible="modalOpen"
      size="lg"
      :modal-title="$t('tickets.season_tickets.copy_services_modal.title')"
      @toggleModalVisibility="modalOpen = false"
    >
      <div>
        <sm-async-select
          v-model="selectedTicket"
          :label="$t('tickets.season_tickets.fields.ticket')"
          :route="$page.props.routes.searchTicket"
        />
      </div>
      <template #footer>
        <sm-button
          :title="$t('generic.cancel')"
          @click="modalOpen = false" />
        <sm-button
          color="primary"
          :title="$t('tickets.season_tickets.copy_services_modal.action')"
          @click="copyTickets" />
      </template>
    </sm-modal>
  </div>
</template>

<script setup>
import axios from "axios";

const { ticketServiceConstraintsEndpoint } = defineProps({
  ticketServiceConstraintsEndpoint: { type: String, required: true },
});

const emit = defineEmits(["ticketSelected"]);

const modalOpen = ref(false);

const selectedTicket = ref(null);

const copyTickets = async () => {
  console.log(selectedTicket.value);

  try {
    const response = await axios.get(`${ticketServiceConstraintsEndpoint}?ticket=${selectedTicket.value.id}`);
    emit("ticketSelected", response.data.data);
  } catch (e) {
    //
  }

  modalOpen.value = false;
};
</script>
