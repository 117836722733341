<template>
  <div class="flex flex-col items-center justify-center h-full">
    <h1 class="text-2xl color-black sm:text-2xl lg:text-3xl xl:mb-4">
      {{ status }} - {{ title }}
    </h1>
    <h5
      v-if="lead"
      class="mb-5 text-base font-normal sm:text-lg lg:text-xl">
      {{ lead }}
    </h5>
    <p
      v-if="description"
      class="max-w-xl mx-auto mb-4 text-center text-bo-gray-400">
      {{ description }}
    </p>
    <a
      class="sm-btn sm-btn-white"
      :href="backRoute">
      {{ $t('Vissza a kezdőlapra') }}
    </a>
  </div>
</template>

<script setup>
const props = defineProps({
  status: { type: Number, required: true, default: 404 },
  backRoute: { type: String, required: true },
});

const title = computed(() => {
  return {
    401: window.trans("errors.401.title"),
    403: window.trans("errors.403.title"),
    404: window.trans("errors.404.title"),
    419: window.trans("errors.419.title"),
    429: window.trans("errors.429.title"),
    500: window.trans("errors.500.title"),
    503: window.trans("errors.503.title"),
  }[props.status];
});

const lead = computed(() => {
  return {
    404: window.trans("errors.404.lead"),
    500: window.trans("errors.500.lead"),
  }[props.status];
});

const description = computed(() => {
  return {
    404: window.trans("errors.404.description"),
    500: window.trans("errors.500.description"),
  }[props.status];
});
</script>
