<template>
  <settings-layout>
    <in-head :title="$t('aycm.settings.title')" />
    <sm-card
      :form="form"
      :header-title="$t('aycm.settings.title')"
      @onSubmit="onSubmit">
      <div class="w-1/2">
        <sm-checkbox
          v-model="form.validate_qr"
          name="validate_qr"
          :label="$t('aycm.settings.validate_qr.label')"
        />
        <sm-checkbox
          v-model="form.validate_qr_on_gate"
          name="validate_qr_on_gate"
          :label="$t('aycm.settings.validate_qr_on_gate.label')"
        />
        <sm-input
          v-model="form.api_key"
          name="api_key"
          inline
          :label="$t('aycm.settings.api_key.label')"
        />
        <sm-input
          v-model="form.username"
          name="username"
          inline
          :label="$t('aycm.settings.username.label')"
        />
        <sm-input
          v-model="form.password"
          name="password"
          inline
          :label="$t('aycm.settings.password.label')"
        />
        <sm-input
          v-model="form.service_id"
          name="service_id"
          inline
          :label="$t('aycm.settings.service_id.label')"
        />
      </div>
      <template #footer>
        <sm-button
          preset="save"
          :loading="form.processing"
          @click="onSubmit" />
      </template>
    </sm-card>
  </settings-layout>
</template>

<script setup>
import { useForm, usePage } from "@inertiajs/vue3";
import { provide } from "vue";

const props = defineProps({
  settings: { type: Object, required: true, default:  null },
});

// default variables
const page = usePage();

const form = useForm({
  validate_qr: props.settings.validate_qr,
  validate_qr_on_gate: props.settings.validate_qr_on_gate,
  api_key: props.settings.api_key,
  username: props.settings.username,
  password: props.settings.password,
  service_id: props.settings.service_id,
});

const onSubmit = () => {
  form
    .post(page.url);
};

provide("form", form);
</script>
