<script>
import axios from "axios";
import { format } from "date-fns";
import hotkeys from "hotkeys-js";
import debounce from "lodash/debounce";
import { useToast } from "vue-toastification";
import Product from "./SellProductForm/Product";

export default {
  props: {
    searchProductRoute: { type: String, default: "" },
    priceCalculatorRoute: { type: String, default: "" },
    warehouses: { type: String, default: "" },
    oldProducts: { type: String, default: "" },
    activeWarehouse: { type: Object, default: () => { } },
    removeProductConfirmationMessage: { type: String, default: "" },
    openCashRegisters: { type: Array, default: () => [] },
    userCanSelectCashRegister: { type: Boolean, default: false },
    customer: { type: [Number, String], default: null },
  },
  data() {
    const warehouseList = JSON.parse(this.warehouses).map(w => ({ ...w, value: w.id, label: w.name }));

    return {
      products: JSON.parse(this.oldProducts).map(p => Product.fromOld(JSON.parse(p))),
      productList: [],
      selectedUser: null,
      warehouseList,
      cashRegisterList: this.openCashRegisters,
      product: new Product(null, 1),
      warehouse: this.activeWarehouse ? warehouseList.find(w => w.id === this.activeWarehouse.id) : null,
      cashRegister: null,
      amount: 1,
      serviceFee: 0,
      paymentType: null,

      abortController: null,
    };
  },
  computed: {
    totalAmount() {
      let total = this.products.reduce((sum, p) => {
        return sum + (p.amount * (p.price ?? 0));
      }, 0);

      const serviceFeeAmount = Math.round(total * this.serviceFee) / 100;

      total += serviceFeeAmount;

      if (this.paymentType === "cash" || this.paymentType === "cash_eur") {
        total = Math.ceil(total) % 5 === 0 ? Math.ceil(total) : Math.round(total / 5) * 5;
      }

      return Math.round(total);
    },
    customerId() {
      return this.customer || (this.selectedUser ? this.selectedUser.value : null);
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (document.querySelector("#sales-sell-product-modal")) {
        document.querySelector("#sales-sell-product-modal").addEventListener("bs-modal-opened", () => {
          hotkeys.setScope("sell-product");
          this.$refs.productInput ? this.$refs.productInput.searchEl.focus() : null;
        });
        document.querySelector("#sales-sell-product-modal").addEventListener("bs-modal-closed", () => {
          hotkeys.setScope("global");
        });
      }
      if (document.querySelector("#sales-sell-product-modal-global")) {
        document.querySelector("#sales-sell-product-modal-global").addEventListener("bs-modal-opened", () => {
          hotkeys.setScope("sell-product");
          this.$refs.customerInput ? this.$refs.customerInput.$refs.select.$refs.search.focus() : null;
        });
        document.querySelector("#sales-sell-product-modal-global").addEventListener("bs-modal-closed", () => {
          hotkeys.setScope("global");
        });
      }
    });
  },
  methods: {
    onUserPickerClose() {
      const globalSalesModal = document.getElementById("sales-sell-product-modal-global");
      if (globalSalesModal && globalSalesModal.classList.contains("sm-show")) {
        hotkeys("esc", "sell-product", () => {
          window.toggleSmModal("#sales-sell-product-modal-global", "close");
        });
      }
    },
    onClosed() {
      hotkeys("esc", "sell-product", () => {
        window.toggleSmModal("#sales-sell-product-modal", "close");
      });
    },
    async searchProduct(term, loading) {
      if (term.length >= 2) {
        loading && loading(true);
        this.doProductSearch(term, loading, this);
      }
    },
    doProductSearch: debounce(async (term, loading, vm) => {
      vm.abortController?.abort();
      vm.abortController = new AbortController();

      try {
        const res = await axios.get(vm.searchProductRoute, {
          params: {
            customer: vm.customerId,
            search: term
          },
          signal: vm.abortController.signal,
        });
        vm.productList = res.data.data;
      } catch (e) {
        if (!axios.isCancel(e)) {
          throw e;
        }
      } finally {
        loading && loading(false);
      }
    }, 350),
    addProduct() {
      const isTicket = this.product.isTicket();

      // If the product has not been selected, exit
      if (!this.product.hasProduct()) {
        console.warn("Product is missing");
        return false;
      }

      // If the selected amount is less then 1, exit
      if (this.product.amount < 1) {
        console.warn("No amount is set.");
        return false;
      }

      // If the form requires a warehouse and one hasnt been selected, exit
      if (this.formRequiresWarehouse() && !this.product.hasWarehouse()) {
        console.warn("Form requires a warehouse, but none has been selected.");
        return false;
      }

      // If the selected product is a ticket check the valid from value
      const dateRegex = /[0-9]{4}-[0-9]{2}-[0-9]{2}/;
      const dateIsCorrect = dateRegex.test(this.product.validFrom);
      if (isTicket && (!this.product.hasValidFrom() || !dateIsCorrect)) {
        if (this.$refs.validFromInput) {
          this.$refs.validFromInput.blur();
          this.$refs.validFromInput.focus();
        }

        const toast = useToast();
        toast.error("A bérlet érvényessége hibásan van megadva!");
        return false;
      }

      // Check if the product was already added to the list
      let product = this.products.find(p => p.product.product_type === this.product.product_type && p.product.value === this.product.value);

      // Tickets can not be purchased in bulk
      if (product && !isTicket) {
        product.increaseAmount(this.product.amount);
      } else {
        product = this.product;
        this.products.push(product);
      }

      this.loadPriceForProduct(product);

      this.resetData();

      return true;
    },
    removeProduct(product) {
      if (confirm(this.$t("Biztosan el akarod távolítani ezt a terméket?"))) {
        this.products = this.products.filter(p => p !== product);
      }
    },
    formRequiresWarehouse() {
      return !this.activeWarehouse && this.product.isStockProduct();
    },
    formRequiresValidFrom() {
      return this.product.isTicket();
    },
    formRequiresAmount() {
      if (!this.product.hasProduct()) {
        return false;
      }
      return !this.product.isTicket();
    },
    focusInputAfterProduct() {
      this.$nextTick(() => {
        let el = null;

        if (this.formRequiresWarehouse() && !this.product.hasWarehouse()) {
          el = this.$refs.warehouseInput.searchEl;
        } else if (this.formRequiresValidFrom()) {
          el = this.$refs.validFromInput;
          if (!this.product.validFrom) {
            this.product.setValidFrom(format(new Date(), "yyyy-MM-dd"));
          }
        } else {
          el = this.$refs.amountInput;
        }

        el && el.focus();
      });
    },
    resetData() {
      this.productList = [];
      this.product = new Product(null, 1);
    },
    onValidFromChange(event) {
      if (!isNaN(Date.parse(event.target.value))) {
        this.product.setValidFrom(event.target.value);
        this.focusInputAfterProduct();
      }
    },
    async loadPriceForProduct(product) {
      product.markAsLoading();

      try {
        const response = await axios.get(this.priceCalculatorRoute, {
          params: {
            customer: this.customerId,
            product_id: product.product.value,
            product_type: product.product.product_type,
          }
        });
        const data = response.data.data;
        product.setPrice(data.price ?? 0);
        product.setPriceAttributes(
          data.price ?? 0,
          data.original_price ?? 0,
          data.discount_value ?? 0,
          data.discount_percentage ?? 0
        );
      } catch (e) {
        const toast = useToast();
        toast.error(e.response ? e.response.data.message : e.message);
      } finally {
        product.markAsLoading(false);
      }
    },
  },
};
</script>

<style lang="postcss">
.sell-product-select {
  .vs__dropdown-menu {
    @apply !max-h-[190px];
  }
}
</style>
