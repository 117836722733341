/* global trans */
import $ from "jquery";
import sharedCore from "../shared/Core";

const asyncModules = import.meta.glob("./chunks/**/*.js");

export default {
  ...sharedCore,
  async loadPageScript(scriptName) {
    const module = asyncModules[`./chunks/${scriptName}`];
    if (module) {
      await module();
    } else {
      console.warn(`Could not load ${scriptName}. It could not be found.`);
    }
  },
  toast(message, title = "Értesítés") {
    const $toastTemplate = $("#toast-template");
    const $toast = $($toastTemplate.html());
    $toast.appendTo("#toast-container");
    $toast.find(".toast-body").text(trans(message));
    $toast.find(".toast-header > h6").text(trans(title));
    $toast.toast("show");

    setTimeout(() => $toast.remove(), 5000);
  },
};
