<template>
  <settings-layout>
    <in-head :title="$t('customers.settings.title')" />

    <form @submit.prevent="onSubmit()">
      <sm-card
        :header-title="$t('customers.settings.title')">
        <sm-radio-group
          v-model="form.has_registration_form"
          name="has_registration_form"
          :items="has_registration_form_options"
          :label="$t('customers.settings.has_registration_form.label')"
          :help="$t('customers.settings.has_registration_form.help')"
          inline
        />
        <sm-input
          v-model="form.credit_limit"
          name="credit_limit"
          :label="$t('customers.settings.credit_limit.label')"
          :help="$t('customers.settings.credit_limit.help')"
          inline
          type="number"
          required
        >
          <template #append>
            Ft
          </template>
        </sm-input>
      </sm-card>
      <sm-card :header-title="$t('customers.settings.notifications.title')">
        <sm-input
          v-model="form.merge_request_emails"
          name="merge_request_emails"
          :label="$t('customers.settings.merge_request_emails.label')"
          :help="$t('customers.settings.merge_request_emails.help')"
          inline
        />
      </sm-card>
      <sm-card>
        <sm-button
          preset="save"
          :loading="form.processing"
          @click="onSubmit" />
      </sm-card>
    </form>
  </settings-layout>
</template>

<script setup>
import { useFormatArrayToTrueFalseObjects } from "@/inertia/composables/global";
import { useForm, usePage } from "@inertiajs/vue3";

// default variables
const page = usePage();

const props = defineProps({
  settings: { type: Object, required: true, default: null },
  notificationSettings: { type: Object, required: true, default: null },
});

const has_registration_form_options = useFormatArrayToTrueFalseObjects(["has_registration_form", "doesnt_have_registration_form"]);

const form = useForm({
  has_registration_form: props.settings.has_registration_form,
  credit_limit: props.settings.credit_limit,
  merge_request_emails: props.notificationSettings.profile_merge_notification_email,
});

const onSubmit = () => {
  form
    .post(page.url);
};

provide("form", form);
</script>
