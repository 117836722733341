<template>
  <settings-layout>
    <in-head :title="$t('payment.physical.page_title')" />

    <component :is="physicalPaymentComponent" />
  </settings-layout>
</template>

<script setup>
import CashDro from "./components/CashDro.vue";

const props = defineProps({
  physicalPaymentService: { type: String, required: false, default: null },
});

const physicalPaymentComponent = computed(() => {
  if (props.physicalPaymentService === "cashdro") {
    return CashDro;
  }
});
</script>
