<template>
  <div>
    <transition
      name="fade"
      mode="out-in">
      <onboarding-step-2-a
        v-if="activeSubStep === 1"
        @increaseSubStep="increaseSubStep"
        @updateStepA="updateStepA" />
      <onboarding-step-2-b
        v-else-if="activeSubStep === 2"
        :step-a-finished="stepAFinished"
        @increaseStep="$emit('increaseStep')" />
    </transition>
  </div>
</template>

<script>
import OnboardingStep2A from "./Step2/OnboardingStep2A.vue";
import OnboardingStep2B from "./Step2/OnboardingStep2B.vue";

export default {
  components: {
    OnboardingStep2A,
    OnboardingStep2B,
  },
  emits: ["increaseStep"],
  data() {
    return {
      subSteps: 3,
      activeSubStep: 1,
      stepAFinished: false
    };
  },
  methods: {
    increaseSubStep() {
      this.activeSubStep++;
    },
    updateStepA() {
      this.stepAFinished = !this.stepAFinished;
    }
  }
};
</script>
