<template>
  <div class="space-y-4">
    <div v-if="apiToken !== null && apiToken !== ''">
      <sm-alert color="success">
        {{ $t('integrations.tokens.created') }}
      </sm-alert>
      <sm-copy-code :code="apiToken" />
    </div>
    <sm-button
      color="primary"
      :title="$t('integrations.tokens.new')"
      @click="requestToken" />
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
  name: "GetApiToken",
  props: {
    route: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      apiToken: null,
    };
  },
  methods: {
    async requestToken() {
      try {
        const response = await axios.post(this.route);
        this.apiToken = response.data.token.plainTextToken;
      } catch (e) {
        const toast = useToast();
        toast.error(e.response ? e.response.data.message : e.message);
      }
    }
  },
};
</script>
