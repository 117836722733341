<template>
  <div>
    <button
      type="button"
      class="flex items-center w-full p-3 mb-4 transition-colors border border-transparent rounded cursor-pointer hover:!border-bo-gray-200"
      :class="{ '!border-bo-gray-100' : user.id === selectedUser?.id, '!border-red-400': error}"
      @click="updateSelectedUser(user)">
      <div class="flex items-center justify-between">
        <div class="w-[4.5rem] h-[4.5rem] rounded-full overflow-hidden relative mr-3">
          <img
            :src="user.profilePicture"
            class="absolute top-0 left-0 object-cover object-center">
        </div>
        <div class="text-left">
          <p
            v-tippy="`${user.fullName} - ${ user.id }`"
            class="font-bold mb-1.5 truncate max-w-[175px]">
            {{ user.fullName }} - {{ user.id }}
          </p>
          <a
            v-tippy="user.email"
            :href="`mailto:${user.email}`"
            class="text-bo-blue inline-block truncate max-w-[175px]">{{ user.email }}</a>
        </div>
      </div>
      <div class="flex items-center ml-auto min-w-fit">
        <div class="w-px h-[4.5rem] mx-3 bg-bo-gray-100" />
        <div class="flex items-center p-0">
          <sm-radio
            :value="user.id"
            class="mb-0 pointer-events-none"
            name="profile_merge_user"
            :checked="user.id === selectedUser?.id"
            :text="$t('customers.generic.info.profile_merge_modal.default_profile')"
          />
          <button
            class="flex items-center justify-center w-6 h-6 transition-colors border rounded border-bo-gray-100 hover:border-bo-red hover:text-bo-red"
            :class="{'opacity-0 pointer-events-none': !canBeDeleted}"
            @click="removeSelectedUser(user)">
            <vue-feather
              type="x"
              class="w-4 h-4" />
          </button>
        </div>
      </div>
    </button>
    <div v-auto-animate>
      <span
        v-if="error"
        class="block mb-4 -mt-3 text-bo-red">
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script setup>
defineProps({
  user: { type: Object, required: true, default: null },
  selectedUser: { type: Object, required: true, default: null },
  canBeDeleted: { type: Boolean, required: false, default: false },
  error: { type: String, required: true },
});

const emit = defineEmits(["updateSelectedUser", "onUserRemoved"]);

const updateSelectedUser = (user) => {
  emit("updateSelectedUser", user);
};

const removeSelectedUser = (user) => {
  emit("onUserRemoved", user);
};

</script>
