<template>
  <div class="relative flex items-center justify-start w-full h-full md:justify-center sm:flex">
    <span @click="updateSearchIsvisible">
      <i class="fas fa-search mr-2.5 text-bo-gray-400 text-xl" />
    </span>
    <transition name="fade-from-left">
      <div
        v-if="searchIsvisible"
        class="absolute lg:relative -top-2 lg:top-auto w-[calc(100vw-7.5rem)] lg:w-full left-full lg:left-0 z-50 flex">
        <template v-if="searchIsQrCode">
          <div>
            <span
              class="flex flex-row items-center px-2 space-x-2 text-sm font-medium whitespace-pre badge badge-light"
              :title="searchTerm"
            >
              <i class="fas fa-qrcode" />
              <span>{{ $t('QR Kód') }}</span>
              <span
                class="cursor-pointer"
                @click="clearQrSearch">×</span>
            </span>
          </div>
        </template>
        <input
          ref="searchInput"
          type="search"
          class="text-ellipsis py-4 border-0 sm-input-text placeholder:text-brand-gray-300/75 lg:py-0 lg:w-full w-[calc(100%-7.5rem)]"
          :placeholder="placeholderText"
          :value="searchTerm"
          @input="onInput"
          @keydown.stop=""
          @keydown.enter.stop="goToCurrent"
          @keydown.esc.stop="onInputBlur"
          @focus="onInputFocus"
          @keydown.down.prevent="selectItem(1)"
          @keydown.up.prevent="selectItem(-1)"
          @keydown.backspace="clearQrSearch">
        <div
          v-if="shouldPresentResultList || noResultsFound"
          v-cloak
          class="sm-card absolute w-full top-full max-h-[400px] overflow-y-auto t-[9999]">
          <div class="sm-card-body">
            <ul class="flex flex-col flex-wrap p-0 mb-0 text-xs whitespace-nowrap">
              <template v-for="group in formattedResults">
                <li
                  v-if="group.typeLabel"
                  :key="group.typeLabel"
                  class="block w-full mt-6 mb-1 font-bold uppercase first-of-type:mt-0 text-xxs">
                  {{ group.typeLabel }}
                </li>
                <li
                  v-for="item in group.items"
                  :key="item.index"
                  class="relative p-0 text-xs leading-normal">
                  <a
                    :href="item.url ? item.url : '#'"
                    class="flex px-2 py-1 text-brand-gray-800 rounded mb-0.5 transition-colors hover:bg-bo-blue hover:!text-white group"
                    :class="{ 'bg-bo-blue !text-white': highlightedItemIndex === item.index }"
                    @click.prevent="openUrl(item.url ? item.url : '#')">
                    <div class="inline-block sm-avatar">
                      <img :src="item.data.avatar">
                    </div>
                    <div class="ml-2">
                      <span class="block">
                        {{ item.title }}
                        <template v-if="item.data.date_of_birth">
                          <i class="ml-1 text-xs font-semibold">{{ item.data.date_of_birth }}</i>
                        </template>
                      </span>
                      <span
                        v-if="item.subtitle && item.type === 'customer'"
                        class="block text-xs">
                        <span>E-mail: </span> {{ item.subtitle }}
                      </span>
                    </div>
                  </a>
                </li>
              </template>
              <template v-if="noResultsFound">
                <p class="mb-0">
                  {{ $t('Nincs találat.') }}
                </p>
              </template>
            </ul>
          </div>
          <div
            v-if="hasMoreItems"
            class="sm-card-footer sticky bottom-0 bg-white py-2">
            <div class="relative p-0 text-xs leading-normal">
              <a
                :href="`${customersListEndpoint}?search=${searchTerm}`"
                class="flex px-2 py-1 text-brand-gray-800 rounded transition-colors hover:bg-bo-blue hover:!text-white group"
              >
                <div>
                  {{ $t('Még több találat elérhető IDE kattintva.') }}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { router } from "@inertiajs/vue3";
import axios from "axios";
import feather from "feather-icons";
import hotkeys from "hotkeys-js";
import debounce from "lodash/debounce";
import filter from "lodash/filter";
import map from "lodash/map";
import uniqBy from "lodash/uniqBy";

export default {
  props: {
    searchEndpoint: { type: String, default: "" },
    customersListEndpoint: { type: String, default: "" },
    qrCodePrefix: { type: String, default: "" },
  },
  data() {
    return {
      searchTerm: "",
      results: null,
      inputFocused: false,
      loading: false,
      documentClickListener: e => {
        if (this.$el.contains(e.target)) {
          return;
        }
        this.searchTerm = "";
        this.results = null;
      },
      highlightedItemIndex: 0,
      abortController: null,
      searchIsvisible: true,
      searchIsQrCode: false,
      hasMoreResults: false,
    };
  },
  computed: {
    placeholderText() {
      if (window.width > 768) {
        return window.trans("Keresés... (Az F1 megnyomásával azonnal kereshetsz)");
      } else {
        return window.trans("Keresés...");
      }
    },
    icon() {
      return feather.icons.search.toSvg({ width: 18, height: 18 });
    },
    shouldPresentResultList() {
      return this.searchTerm?.length > 0 && this.results?.length > 0;
    },
    indexedResults() {
      return map(this.results, (item, index) => ({ index, ...item }));
    },
    formattedResults() {
      return map(this.formattedGroups, group => {
        let items = filter(this.indexedResults, item => item.type === group.type);

        return {
          type: group.type,
          typeLabel: group.typeLabel,
          items
        };
      });
    },
    formattedGroups() {
      let results = map(this.results, item => ({
        type: item.type,
        typeLabel: item.type_label,
      }));
      return uniqBy(results, "type");
    },
    noResultsFound() {
      return this.searchTerm?.length > 0 && this.results && this.results.length === 0;
    }
  },
  watch: {
    searchTerm() {
      this.doSearch();
    },
  },
  mounted() {
    hotkeys("f1", "global", e => {
      e.preventDefault();
      if (e.repeat) {
        return;
      } else {
        this.$refs.searchInput && this.$refs.searchInput.focus();
      }
    });

    document.addEventListener("click", this.documentClickListener);
    if (window.innerWidth < 1024) {
      // this.searchIsvisible = false;
    }
  },
  beforeUnmount() {
    document.removeEventListener("click", this.documentClickListener);
  },
  methods: {
    updateSearchIsvisible() {
      if (window.innerWidth < 1024) {
        this.searchIsvisible = !this.searchIsvisible;
      }
    },
    onInputFocus() {
      this.inputFocused = true;
    },
    onInputBlur() {
      this.inputFocused = false;
    },
    parseAndCheckQrCode: debounce(function (searchTerm) {
      let isQrCode = false;

      if (this.qrCodePrefix.length) {
        if (searchTerm.startsWith(this.qrCodePrefix)) {
          const after = searchTerm.substring(this.qrCodePrefix.length);
          if (after.length) {
            isQrCode = true;
          }
        }
      }

      this.searchIsQrCode = isQrCode;

      if (isQrCode) {
        // If the input value is a QR code, clear it out.
        this.searchTerm = "";
      }
    }, 100),
    onInput(event) {
      const searchTerm = event.target.value;

      if (searchTerm.length < 1) {
        this.searchTerm = searchTerm;
      }

      this.searchTerm = searchTerm;

      // this.parseAndCheckQrCode(searchTerm);

    },
    doSearch: debounce(async function () {
      if (!this.searchTerm || this.searchTerm.length === 0) {
        return;
      }

      this.abortController?.abort();

      this.abortController = new AbortController();

      this.loading = true;

      this.hasMoreItems = false;

      try {
        const response = await axios.get(
          `${this.searchEndpoint}?q=${encodeURIComponent(this.searchTerm)}`,
          { signal: this.abortController.signal }
        );
        this.results = response.data.data ?? [];
        this.hasMoreItems = response.data.has_more;
      } catch (e) { // eslint-disable-line
        throw e;
      } finally {
        this.loading = false;
      }
    }, 250),
    goToCurrent() {
      const selectedElem = this.indexedResults.find(item => item.index === this.highlightedItemIndex);
      if (selectedElem) {
        this.openUrl(selectedElem.url);
      }
    },
    openUrl(url) {
      router.visit(url);
      this.searchTerm = "";

      this.$refs.searchInput?.blur();
    },
    selectItem(direction) {
      if (this.indexedResults.length <= 0) {
        return;
      }

      const newIndex = this.highlightedItemIndex + direction;

      if (newIndex < 0) {
        this.highlightedItemIndex = this.indexedResults.length - 1;
      } else if (newIndex > this.indexedResults.length - 1) {
        this.highlightedItemIndex = 0;
      } else if (newIndex >= 0 && newIndex < this.indexedResults.length) {
        this.highlightedItemIndex = newIndex;
      }
    },
    clearQrSearch() {
      if (this.searchIsQrCode) {
        this.searchIsQrCode = false;
        this.searchTerm = "";
      }
    }
  },
};
</script>
