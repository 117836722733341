<template>
  <div>
    <In-Head :title="customer.fullName" />

    <sub-header :title="customer.fullName">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sell-product-modal
          v-if="pageSaleModal && $page.props.permissions.canSellProducts"
          :search-product-route="$page.props.routes.searchProducts"
          :warehouses="pageSaleModal.warehouses"
          :active-warehouse="pageSaleModal.activeWarehouse"
          :customer="$page.props.customer.userId"
          :price-calculator-route="$page.props.routes.calculatePrice"
          :open-cash-registers="pageSaleModal.openCashRegisters"
          :user-can-select-cash-register="pageSaleModal.permissions.userCanSelectCashRegister"
          :trigger-modal="triggerSellProductModal"
          :page-parking="pageParking"
          :page-sale-modal="pageSaleModal"
          :page-permissions="$page.props.permissions"
        />

        <assign-accessories-modal
          v-if="pageAccessories?.permissions?.hasPermissionToAssign"
          @updateAccessoriesRefreshed="updateAccessoriesRefreshed" />

        <template v-if="$page.props.clubOpen">
          <checkout-modal v-if="$page.props.customerCheckedIn" />
          <template v-else>
            <checkin-modal
              :show-checkin="showCheckin"
              :checkinable-appointment="checkinableAppointment"
              @updateShowCheckin="updateShowCheckin"
            />
            <AYCM-checkin v-if="$page.props.hasAycmQuickCheckin" />
          </template>
        </template>
        <sm-button
          v-else
          color="secondary"
          disabled
          :title="$t('exceptions.clubs.club_is_closed')" />

        <customer-club-data-modal v-if="$page.props.permissions.canEditCustomer" />

        <resend-email-verification v-if="$page.props.customer.hasUnverifiedEmail" />
      </template>
    </sub-header>

    <sm-alert
      v-if="customer.birthdayIsToday"
      color="info">
      {{ $t('customers.has_birthday') }}
    </sm-alert>

    <sm-alert
      v-if="customer.pendingDelete"
      color="warning">
      {{ $t('customers.has_pending_delete', { on: customer.scheduledToDeleteAt }) }}
    </sm-alert>

    <customers-nav-tabs />

    <div class="sm-row">
      <div class="w-full sm-col lg:w-1/2">
        <sm-card>
          <div class="sm-row">
            <customer-info />
            <div class="w-full pt-2 border-t sm-col lg:w-4/12 lg:border-t-0 lg:border-l border-bo-gray-100 lg:pt-0">
              <customer-balance
                :page-deposit="pageDeposit"
                :page-permissions="$page.props.permissions" />
              <customer-cards :page-customer-cards="pageCustomerCards" />
            </div>
          </div>

          <hr>

          <div>
            <p class="mb-4 font-semibold">
              {{ $t('generic.note') }}:
            </p>
            <p
              v-if="$page.props.customerCheckedIn && $page.props.overUsedTicketPenalty && $page.props.latestCheckinValidUntil"
              class="text-bo-red"
            >
              {{ $t('customers.profile.checkin_valid_until_warning', { date: $page.props.latestCheckinValidUntil }) }}
            </p>
            <sm-alert color="secondary">
              <span v-if="customer.note">
                {{ customer.note }}
              </span>
              <span v-else>Nincs megjegyzés ehhez a vendéghez!</span>
            </sm-alert>
          </div>

          <customer-tags
            v-if="pageTags?.roles?.length !== 0 || pageTags?.groups?.length !== 0 || pageTags?.contacts?.length !== 0"
            :tags="pageTags" />

          <customer-accessories
            v-if="pageAccessories?.accessories?.length !== 0"
            :accessories-refreshed="accessoriesRefreshed"
            :page-accessories="pageAccessories"
            @updateAccessoriesRefreshed="updateAccessoriesRefreshed"
          />

          <hr>

          <div class="sm-row">
            <div class="w-full sm-col lg:w-1/2">
              <customer-billing-data
                v-if="pageBilling"
                :page-billing="pageBilling" />
            </div>
            <div class="w-full pt-2 border-t sm-col lg:w-1/2 lg:border-t-0 lg:border-l border-bo-gray-100 lg:pt-0">
              <customer-discounts
                v-if="pageDiscounts"
                :page-discounts="pageDiscounts" />
            </div>
          </div>
        </sm-card>
      </div>
      <div class="w-full sm-col lg:w-1/2">
        <customer-tickets
          v-if="$page.props.permissions.canListTickets"
          :page-tickets="pageTickets"
          @openSellProductModal="openSellProductModal" />
        <customer-appointments
          :page-appointments="pageAppointments"
          @updateShowCheckin="updateShowCheckin" />
        <customer-stats
          v-if="pageStats"
          :page-stats="pageStats" />
      </div>
    </div>
  </div>
</template>

<script setup>
import CheckinModal from "@/inertia/pages/customers/show/_modals/CheckinModal.vue";
import CheckoutModal from "@/inertia/pages/customers/show/_modals/CheckoutModal.vue";
import CustomerClubDataModal from "@/inertia/pages/customers/show/_modals/CustomerClubDataModal.vue";
import SellProductModal from "@/inertia/pages/customers/show/_modals/SellProductModal.vue";
import AYCMCheckin from "@/inertia/pages/customers/show/_partials/AYCMCheckin.vue";
import CustomerAccessories from "@/inertia/pages/customers/show/_partials/CustomerAccessories.vue";
import CustomerAppointments from "@/inertia/pages/customers/show/_partials/CustomerAppointments.vue";
import CustomerBalance from "@/inertia/pages/customers/show/_partials/CustomerBalance.vue";
import CustomerBillingData from "@/inertia/pages/customers/show/_partials/CustomerBillingData.vue";
import CustomerCards from "@/inertia/pages/customers/show/_partials/CustomerCards.vue";
import CustomerDiscounts from "@/inertia/pages/customers/show/_partials/CustomerDiscounts.vue";
import CustomerInfo from "@/inertia/pages/customers/show/_partials/CustomerInfo.vue";
import CustomerStats from "@/inertia/pages/customers/show/_partials/CustomerStats.vue";
import CustomerTags from "@/inertia/pages/customers/show/_partials/CustomerTags.vue";
import CustomerTickets from "@/inertia/pages/customers/show/_partials/CustomerTickets.vue";
import CustomersNavTabs from "@/inertia/pages/customers/show/_partials/CustomersNavTabs.vue";
import ResendEmailVerification from "@/inertia/pages/customers/show/_partials/ResendEmailVerification.vue";
import AssignAccessoriesModal from "@/inertia/pages/customers/show/_partials/accessories/AssignAccessoriesModal.vue";

import { router, usePage } from "@inertiajs/vue3";
import { provide, watch } from "vue";

const page = usePage();

const accessoriesRefreshed = ref(false);
const updateAccessoriesRefreshed = () => {
  accessoriesRefreshed.value = !accessoriesRefreshed.value;
};

const checkinableAppointment = ref(null);
const showCheckin = ref(false);
const updateShowCheckin = (e) => {
  showCheckin.value = e.visible;
  checkinableAppointment.value = e.appointmentId;
};
const props = defineProps({
  customer: { type: Object, required: false, default: null },
});

const pageIsLoading = ref(false);

const pageTags = ref(null);
const pageBilling = ref(null);
const pageAppointments = ref(null);
const pageTickets = ref(null);
const pageStats = ref(null);
const pageDiscounts = ref(null);
const pageParking = ref(null);
const pageAccessories = ref(null);
const pageDeposit = ref(null);
const pageSaleModal = ref(null);
const pageCustomerCards = ref(null);

watch(() => page.props.tickets, (tickets) => {
  pageTickets.value = tickets;
});

watch(() => page.props.appointments, (appointments) => {
  pageAppointments.value = appointments;
});

watch(() => page.props.accessories, (accessories) => {
  pageAccessories.value = accessories;
});

watch(() => page.props.tags, (tags) => {
  pageTags.value = tags;
});

onMounted(() => {
  pageIsLoading.value = true;

  pageTags.value = null;
  pageBilling.value = null;
  pageAppointments.value = null;
  pageTickets.value = null;
  pageStats.value = null;
  pageDiscounts.value = null;
  pageParking.value = null;
  pageAccessories.value = null;
  pageDeposit.value = null;
  pageSaleModal.value = null;
  pageCustomerCards.value = null;

  router.reload({
    only: ["tags", "billing", "appointments", "tickets", "stats", "discounts", "parking", "accessories", "deposit", "saleModal", "customerCards"],
    onSuccess: (page) => {
      pageBilling.value = page.props.billing;
      pageStats.value = page.props.stats;
      pageDiscounts.value = page.props.discounts;
      pageParking.value = page.props.parking;
      pageDeposit.value = page.props.deposit;
      pageSaleModal.value = page.props.saleModal;
      pageCustomerCards.value = page.props.customerCards;
    },
    onFinish: () => {
      pageIsLoading.value = false;
    }
  }, {
    preserveState: true,
  });
});

const triggerSellProductModal = ref(false);

const openSellProductModal = () => {
  triggerSellProductModal.value = !triggerSellProductModal.value;
};

provide("props", props);
provide("pageIsLoading", pageIsLoading);
</script>
