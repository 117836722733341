<template>
  <div
    class="p-2 mb-2 border rounded evaporate-uploaded-file border-bo-gray-100"
  >
    <div
      v-if="showName"
      class="flex"
    >
      <span class="flex-1">{{ file.file.name }}</span>
      <span
        v-if="file.status === 'completed'"
        class="cursor-pointer text-bo-red"
        @click="$emit('deleted')"
      >
        {{ $t("Törlés") }}
      </span>
    </div>
    <div
      v-else
      class="mt-2"
    />
    <div
      v-if="file.status !== 'completed'"
      class="flex items-center"
    >
      <div class="progress h-2.5 w-full mr-2">
        <div
          class="progress-bar"
          role="progressbar"
          :style="{ width: progressBarWidth }"
          :aria-valuenow="file.progress"
          aria-valuemin="0"
          aria-valuemax="100"
        />
      </div>
      <div class="flex">
        <slot
          name="buttons"
          :pausable="pausable"
          :resumable="resumable"
          :cancellable="cancellable"
          :pause="pause"
          :resume="resume"
          :cancel="cancel"
        />
      </div>
    </div>
    <div v-if="file.status !== 'completed'">
      <span v-if="file.status !== 'errored'">
        {{ file.progress.toFixed(1) }}%
        <span v-if="file.remainingTime > -1 && file.status !== 'completed'">- {{ file.remainingTime }} másodperc
          <span class="text-small">({{ formatSize(file.speed) }}/s)</span></span>
      </span>
      <span
        v-if="file.status === 'errored'"
        class="text-bo-red"
      >
        {{ file.error }}
      </span>
    </div>
  </div>
</template>
<script>
import Evaporate from "../../utils/Evaporate";

export default {
  props: {
    file: { type: Object, default: () => {} },
    showName: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["deleted"],
  data() {
    return {};
  },
  computed: {
    progressBarWidth() {
      return `${this.file.progress}%`;
    },
  },
  methods: {
    formatSize(size) {
      if (size < 1000) {
        return `${size} B`;
      }

      size = size / 1000;
      if (size < 1000) {
        return `${size.toFixed(1)} Kb`;
      }

      return `${(size / 1000).toFixed(1)} Mb`;
    },
    pausable() {
      return this.file.status === "uploading";
    },
    resumable() {
      return this.file.status === "paused";
    },
    cancellable() {
      return (
        this.file.status !== "uploaded" &&
        this.file.status !== "completed" &&
        this.file.status !== "prepared"
      );
    },
    async pause() {
      await Evaporate.instance().pause(this.file.fileKey, { force: true });
    },
    async resume() {
      await Evaporate.instance().resume(this.file.fileKey);
    },
    async cancel() {
      await Evaporate.instance().cancel(this.file.fileKey);
    },
  },
};
</script>
