<template>
  <sm-modal
    :modal-is-visible="modalVisibility"
    :modal-title="$t('customers.tickets.modals.change_validty_start.title')"
    @toggleModalVisibility="emit('toggleModalVisibility')">
    <form @submit.prevent="onSubmit">
      <sm-date-picker-date
        v-model="form.valid_from"
        name="valid_from"
        :label="$t('customers.tickets.modals.change_validty_start.fields.valid_from.label')"
        :placeholder="$t('customers.tickets.modals.change_validty_start.fields.valid_from.placeholder', {date: useDateFormat(new Date())})"
      />
    </form>
    <sm-alert color="secondary">
      <small>
        {{ $t('customers.tickets.modals.change_validty_start.info') }}
      </small>
    </sm-alert>
    <template #footer>
      <sm-button
        :title="$t('generic.cancel')"
        @click="emit('toggleModalVisibility')" />
      <sm-button
        color="primary"
        :title="$t('generic.save')"
        :disabled="!form.isDirty"
        :loading="form.processing"
        @click="onSubmit" />
    </template>
  </sm-modal>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";
import { useForm } from "@inertiajs/vue3";

const props = defineProps({
  modalVisibility: { type: Boolean, required: true, default: false },
  modalData: { type: Object, required: false, default: null },
});

const emit = defineEmits(["toggleModalVisibility"]);

const form = useForm({
  valid_from: null
});

provide("form", form);

const onSubmit = () => {
  form
    .transform((data) => ({
      valid_from: useDateFormat(data.valid_from, "form"),
    }))
    .post(props.modalData.routes.changeValidityStart, {
      only: ["table", "customer", "errors", "flash"],
      onSuccess: () => {
        emit("toggleModalVisibility");
        form.reset("valid_from");
      }
    });
};
</script>
