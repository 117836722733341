<template>
  <div class="text-left">
    <sm-button
      color="secondary"
      :title="$t('customers.checkout_modal.open_button')"
      hotkey="F3"
      @click="toggleModalVisibility" />
    <sm-modal
      :modal-is-visible="modal"
      size="lg"
      :modal-title="$t('customers.checkout_modal.title')"
      @toggleModalVisibility="toggleModalVisibility">
      <sm-alert color="warning">
        {{ $t('customers.checkins.checkout_confirm') }}
      </sm-alert>
      <template #footer>
        <sm-button
          :title="$t('generic.cancel')"
          @click="toggleModalVisibility" />
        <sm-button
          id="checkoutSubmitButton"
          color="primary"
          :title="$t('customers.actions.logging_out')"
          @click="onSubmit" />
      </template>
    </sm-modal>
  </div>
</template>

<script setup>
import { useForm, usePage } from "@inertiajs/vue3";
import hotkeys from "hotkeys-js";
import { nextTick } from "vue";


const page = usePage();

const modal = ref(false);

const toggleModalVisibility = () => {
  modal.value = !modal.value;
};

const form = useForm({});

onMounted(() => {
  hotkeys("f3", "global", (e) => {
    e.preventDefault();
    if (e.repeat) {
      return;
    } else {
      toggleModalVisibility();
    }
  });
});

watch(modal, (e) => {
  if (e === true) {
    nextTick(() => {
      document.querySelector("#checkoutSubmitButton").focus();
    });
  }
});

const onSubmit = () => {
  form
    .post(page.props.routes.checkOut, {
      only: ["appointments", "tickets", "clubOpen", "customerCheckedIn", "hasAycmQuickCheckin", "checkinModal", "customer", "errors", "flash", "accessories"],
      onSuccess: () => {
        toggleModalVisibility();
      }
    });
};
</script>
