<template>
  <div>
    <div>
      <header class=" mb-11">
        <h2 class="text-2xl font-open-sans-bold text-brand-blue-450 mb-7">
          Vannak már vendégeid?
        </h2>
        <p class="leading-7 ">
          Hogy hozzáférj a teljes funkcionalitáshoz, hívd meg vendégeidet a SportMate alkalmazásba az alábbi link* segítségével:
        </p>
      </header>
      <div>
        <div class="relative">
          <input
            v-model="inviteLink"
            type="text"
            class="sm-input-text-app"
            readonly
          >
          <button
            type="button"
            class="absolute top-0 right-0 flex items-center h-full px-4 transition-colors text-black/60 bg-brand-blue-10 hover:text-black"
            @click="copyToClipboard">
            <copy-icon class="w-4 mr-1 text-black" />
            <span class="hidden lg:inline-block">link másolása</span>
          </button>
        </div>
        <div class="my-3 text-center">
          <p>vagy</p>
        </div>
        <button
          type="button"
          class="w-full mb-10 sm-btn-app sm-btn-app-secondary"
          @click="nextStep">
          <span class="inline-flex items-center">Vendég manuális felvitele <user-plus-icon class="inline-block w-5 ml-4" /></span>
        </button>
        <p class="flex items-center justify-center mb-8 italic text-black/50">
          <users-icon class="inline-block w-5 mr-4" />
          *a meghívó linket később a “Vendégeim” pontnál bármikor elérheted
        </p>
      </div>
    </div>
    <div class="text-center">
      <button
        type="button"
        class="w-full sm-btn-app sm-btn-app-primary lg:w-auto"
        @click="skipStep">
        <span>Következő lépés</span>
      </button>
    </div>
  </div>
</template>

<script>
import { CopyIcon, UserPlusIcon, UsersIcon } from "vue-feather-icons";
import { useToast } from "vue-toastification";

export default {
  components: {
    CopyIcon,
    UserPlusIcon,
    UsersIcon
  },
  inject: ["inviteLink"],
  emits: ["increaseSubStep"],
  methods: {
    async copyToClipboard() {
      const toast = useToast();
      if (! window.isSecureContext) {
        toast.error("A másolás csak HTTPS módban működik.");
        return;
      }

      try {
        await navigator.clipboard.writeText(this.inviteLink);
        toast.success("Link a vágólapra másolva", {
          position: "bottom-center"
        });
      } catch ($e) {
        console.log($e);
      }
    },
    nextStep() {
      this.$emit("increaseSubStep");
    },
    skipStep() {
      this.$emit("increaseSubStep", 3);
    }
  }
};
</script>
