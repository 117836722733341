<template>
  <div>
    <div>
      <div
        class="mx-n1"
        :class="{
          wrapperClass,
          border: error,
          'border-danger': error,
          rounded: error,
        }"
        style="height: inherit"
      >
        <button
          v-for="weekday in weekdays"
          :key="weekday"
          type="button"
          class="px-5 py-0 m-1 rounded shadow-none sm-btn sm-btn-xs"
          :class="
            selectedDays.includes(weekday.dayOfWeek)
              ? 'sm-btn-primary'
              : 'sm-btn-white'
          "
          @click="onDayClick(weekday.dayOfWeek)"
        >
          {{ weekday.label }}
        </button>
        <input
          type="hidden"
          :value="JSON.stringify(selectedDays)"
          :name="hiddenInputName"
        >
      </div>

      <small
        v-if="error"
        class="text-bo-red"
      >{{ error }}</small>
    </div>
    <small
      v-if="error"
      class="text-bo-red"
    >{{ error }}</small>
  </div>
</template>

<script>
export default {
  name: "WeekdayPicker",
  props: {
    value: {
      type: Array,
      default: null,
    },
    hiddenInputName: {
      type: String,
      default: "",
    },
    wrapperClass: {
      type: String,
      default: "",
    },
    weekdays: {
      type: Array,
      default: () => [
        {
          dayOfWeek: 1,
          label: "Hétfő",
        },
        {
          dayOfWeek: 2,
          label: "Kedd",
        },
        {
          dayOfWeek: 3,
          label: "Szerda",
        },
        {
          dayOfWeek: 4,
          label: "Csütörtök",
        },
        {
          dayOfWeek: 5,
          label: "Péntek",
        },
        {
          dayOfWeek: 6,
          label: "Szombat",
        },
        {
          dayOfWeek: 7,
          label: "Vasárnap",
        },
      ],
    },
    error: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedDays:
        this.value ?? this.weekdays.map((weekday) => weekday.dayOfWeek),
    };
  },
  methods: {
    onDayClick(day) {
      this.selectedDays.includes(day) ? this.removeDay(day) : this.addDay(day);
    },
    addDay(day) {
      this.selectedDays = this.selectedDays.concat(day);
    },
    removeDay(day) {
      this.selectedDays = this.selectedDays.filter((item) => item !== day);
    },
  },
};
</script>

<style>
.has-error {
}
</style>
