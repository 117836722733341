<template>
  <div class="space-y-4">
    <sm-card>
      <template v-if="$page.props.vivawallet.accountId">
        <sm-alert color="info">
          <span v-html="$t('payment.vivawallet.connect.in_progress', {link: $page.props.vivawallet.invitationUrl})" />
        </sm-alert>
        <p>
          {{ $t('vivawallet.labels.verified') }}: {{ $page.props.vivawallet.activated ? $t('generic.yes') : $t('generic.no') }}
        </p>
      </template>
      <template v-else>
        <sm-alert color="info">
          {{ $t('payment.vivawallet.connect.no_account') }}
        </sm-alert>
        <sm-button @click="connectAccount">
          {{ $t('vivawallet.labels.connect') }}
        </sm-button>
      </template>
    </sm-card>
    <sm-card
      :form="form"
      :header-title="$t('payment.vivawallet.settings.title')"
      @onSubmit="onSubmit">
      <sm-radio-group
        v-model="form.online_payment"
        name="online_payment"
        :items="online_payment_options"
        :label="$t('payment.vivawallet.settings.online_payment.label')"
        :help="$t('payment.vivawallet.settings.online_payment.help')"
        inline
      />
      <sm-radio-group
        v-model="form.escrow_payment"
        class="hidden"
        name="escrow_payment"
        :items="escrow_payment_options"
        :label="$t('payment.vivawallet.settings.escrow_payment.label')"
        :help="$t('payment.vivawallet.settings.escrow_payment.help')"
        inline
      />
      <sm-radio-group
        v-model="form.balance_payment"
        name="balance_payment"
        :items="balance_payment_options"
        :label="$t('payment.vivawallet.settings.balance_payment.label')"
        :help="$t('payment.vivawallet.settings.balance_payment.help')"
        inline
      />
      <div v-auto-animate>
        <sm-radio-group
          v-if="form.balance_payment"
          v-model="form.balance_payment_use_credit_limit"
          name="balance_payment_use_credit_limit"
          :items="balance_payment_use_credit_limit_options"
          :label="$t('payment.vivawallet.settings.balance_payment_use_credit_limit.label')"
          :help="$t('payment.vivawallet.settings.balance_payment_use_credit_limit.help')"
          inline
        />
      </div>
      <template #footer>
        <sm-button
          preset="save"
          :loading="form.processing"
          @click="onSubmit" />
      </template>
    </sm-card>
  </div>
</template>

<script setup>
import { useFormatArrayToTrueFalseObjects } from "@/inertia/composables/global";
import { router, useForm, usePage } from "@inertiajs/vue3";
import { onMounted } from "vue";

const page = usePage();

const online_payment_options = useFormatArrayToTrueFalseObjects(["online_payment_enabled", "online_payment_disabled"]);
const escrow_payment_options = useFormatArrayToTrueFalseObjects(["has_escrow_payment", "doesnt_have_escrow_payment"]);
const balance_payment_options = useFormatArrayToTrueFalseObjects(["has_balance_payment", "doesnt_have_balance_payment"]);
const balance_payment_use_credit_limit_options = useFormatArrayToTrueFalseObjects(["balance_payment_use_credit_limit_yes", "balance_payment_use_credit_limit_no"]);

// build the form
const form = useForm({
  online_payment: page.props.settings.online_payment,
  escrow_payment: page.props.settings.escrow_payment,
  balance_payment: page.props.settings.balance_payment,
  balance_payment_use_credit_limit: page.props.settings.balance_payment_use_credit_limit,
});

const onSubmit = () => {
  form.post(page.url);
};

const connectAccount = async () => {
  form.post(page.props.routes.vivawallet.connect, {
    onSuccess: (page) => {
      if (page.props.vivawallet.invitationUrl && page.props.vivawallet.invitationUrl !== "") {
        window.open(page.props.vivawallet.invitationUrl, "_blank");
      }
    },
  });
};

onMounted(() => {
  router.reload({ only: ["vivawallet", "vivawalletRefresh", "errors", "flash"] }, { preserveState: true });
});

provide("form", form);
</script>
