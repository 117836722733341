<template>
  <sm-card header-title="Slots">
    <div class="">
      <div class="sm-row">
        <div class="w-1/2 sm-col">
          <p class="mb-2">
            Add slot after the radio group<br>
            <strong class="inline-block pl-2 w-36">Slot name:</strong> #afterLabel<br>
            <code class="inline-block pl-2"><pre class="text-xs text-bo-green">&lt;template #afterLabel&gt;After label&lt;/template&gt;</pre></code>
          </p>
        </div>
        <div class="w-1/2 sm-col">
          <sm-radio-group
            name="slots_01"
            :items="radioGroupItems"
            label="After label slot">
            <template #afterLabel>
              After label
            </template>
          </sm-radio-group>
        </div>
      </div>
      <hr>

      <div class="sm-row">
        <div class="w-1/2 sm-col">
          <p class="mb-2">
            Add slot after the radio group<br>
            <strong class="inline-block pl-2 w-36">Slot name:</strong> #afterInput<br>
            <code class="inline-block pl-2"><pre class="text-xs text-bo-green">&lt;template #afterInput&gt;After input&lt;/template&gt;</pre></code>
          </p>
        </div>
        <div class="w-1/2 sm-col">
          <sm-radio-group
            name="slots_02"
            :items="radioGroupItems"
            label="After checkbox slot">
            <template #afterInput>
              <div class="block pl-2">
                After input
              </div>
            </template>
          </sm-radio-group>
        </div>
      </div>
    </div>
  </sm-card>
</template>

<script setup>
const radioGroupItems = ref([
  {
    id: "radio_group_01",
    text: "First element"
  },{
    id: "radio_group_02",
    text: "Second element"
  },{
    id: "radio_group_03",
    text: "Third element"
  }
]);
</script>
