<template>
  <div>
    <my-dialog
      v-for="dialog in dialogs"
      :key="dialog.id"
      :dialog-config="dialog"
    />
  </div>
</template>

<script>
import MyDialog from "./MyDialog.vue";

export default {
  components: { MyDialog },
  computed: {
    dialogs() {
      return this.$store.state.dialog.dialogs;
    },
  }
};
</script>
