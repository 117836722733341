<script>
import AbstractCrudFilterType from "../utility/AbstractCrudFilterType";

export default {
  mixins: [AbstractCrudFilterType],
  data() {
    return {
      defaults: {},
      options: this.data.filter?.options?.options || [],
    };
  },
  computed: {
    filterData() {
      return {
        term: this.data.data.term,
      };
    },
  },
  render() {
    let options = [];
    let usingGroups = false;

    if (this.data.filter?.options?.optionGroups) {
      options = this.data.filter?.options?.optionGroups;
      usingGroups = true;
    } else {
      options = Array.isArray(this.options)
        ? this.options
        : Object.entries(this.options).map(([value, label]) => ({ value, label }));
    }


    return this.renderAbstract([
      this.createDiv("sm-col w-8/12", [
        this.createSelect([this.data.data, "term"], options, "", [], usingGroups),
      ]),
    ]);
  },
};
</script>
