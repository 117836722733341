<template>
  <sm-modal
    :modal-is-visible="modalVisibility"
    :modal-title="$t('customers.tickets.modals.delete.title')"
    @toggleModalVisibility="emit('toggleModalVisibility')">
    <form @submit.prevent="onSubmit">
      <template v-if="modalData.hasTransaction && !modalData.ownedByCustomerGroup">
        <sm-checkbox
          v-model="form.refund"
          name="refund"
          :label="$t('customers.tickets.modals.delete.fields.refund.label')"
        />
        <sm-input
          v-model="form.note"
          type="textarea"
          name="note"
          :label="$t('customers.tickets.modals.delete.fields.note.label')"
          :placeholder="$t('customers.tickets.modals.delete.fields.note.placeholder')"
        />
      </template>
      <span v-else>{{ $t('generic.are_you_sure') }}</span>
    </form>
    <template #footer>
      <sm-button
        :title="$t('generic.cancel')"
        @click="emit('toggleModalVisibility')" />
      <sm-button
        color="danger"
        :title="$t('generic.delete')"
        :loading="form.processing"
        @click="onSubmit" />
    </template>
  </sm-modal>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";

const props = defineProps({
  modalVisibility: { type: Boolean, required: true, default: false },
  modalData: { type: Object, required: false, default: null },
});

const emit = defineEmits(["toggleModalVisibility"]);

const form = useForm({
  refund: false,
  note: ""
});

provide("form", form);

const onSubmit = () => {
  form
    .transform((data) => {
      if (props.modalData.hasTransaction && !props.modalData.ownedByCustomerGroup) {
        return {...data};
      } else {
        return {};
      }
    })
    .delete(props.modalData.routes.deleteTicket, {
      only: ["table", "customer", "errors", "flash"],
      onSuccess: () => {
        emit("toggleModalVisibility");
        form.reset("refund");
        form.reset("note");
      }
    });
};
</script>
