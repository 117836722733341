import.meta.glob([
  "../img/**",
  "../webfonts/**",
]);

import Core from "./backoffice/Core";
import Evaporate from "./backoffice/utils/Evaporate";
import "./backoffice/utils/LivewireVueFix";

import { configureCompat } from "vue";
import "./backoffice/bootstrap";
import "./backoffice/utils/native_bootstrap/native_bootstrap";
import "./inertia";
import "./shared/Translation";
import "./shared/Sentry";

configureCompat({
  COMPONENT_V_MODEL: false,
  WATCH_ARRAY: false,
  INSTANCE_ATTRS_CLASS_STYLE: false,
  // RENDER_FUNCTION: false
});

Evaporate.init();

Core.initVue();
Core.initCleave();
Core.initSpectrum();
Core.guardFormDoubleSubmissions();
window.Core = Core;

// Reinit the dropdown on livewire reloads
Livewire.hook("message.processed", (message, component) => {
  const table = document.querySelector("#livewire-table");

  if (table === component.el) {
    window.removeSmDropdownEventListeners();
    window.loadSmDropdown();
  }
});
