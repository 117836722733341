import axios from "axios";
import { useToast } from "vue-toastification";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.withCredentials = true;
axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  const toast = useToast();

  if (error.name === "AxiosError" && error.message === "Network Error") {
    toast.error("Hálózati hiba, kérjük ellenőrizd az internetkapcsolatod.");
    return Promise.reject(error);
  }

  return Promise.reject(error);
});

let token = document.head.querySelector("meta[name=\"csrf-token\"]");
if (token) {
  axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
} else {
  console.error("CSRF token not found");
}
