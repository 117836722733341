<template>
  <sm-input-wrapper>
    <template
      v-if="$slots.afterLabel"
      #afterLabel>
      <slot name="afterLabel" />
    </template>
    <template
      v-if="$slots.prepend"
      #prepend>
      <slot name="prepend" />
    </template>
    <template
      v-if="$slots.afterInput"
      #afterInput>
      <slot name="afterInput" />
    </template>
    <template
      v-if="$slots.append"
      #append>
      <slot name="append" />
    </template>
    <div
      :id="id"
      :class="buttonGroup ? 'sm-radio-buttons' : !breakLines ? 'flex space-x-2' : ''">
      <label
        v-for="radio in items"
        :key="radio.id"
        disabled
        :class="{'block sm-toggleable sm-radio': !buttonGroup, 'disabled': disabled && (typeof disabled === 'boolean' || disabled === radio.id), 'mb-2': breakLines}">
        <input
          :id="radio.id"
          ref="input"
          :value="radio.value || radio.id"
          type="radio"
          :name="name"
          :checked="radioIsChecked(radio)"
          @input="updateInput($event)">
        <span v-html="radio.text || radio.label" />
      </label>
    </div>
  </sm-input-wrapper>
</template>

<script setup>
import SmInputWrapper from "@/inertia/components/forms/_layouts/SmInputWrapper.vue";
const props = defineProps({
  name: { type: String, required: false, default: "" },
  id: { type: String, required: false, default: "" },
  label: { type: String, required: false, default: "" },
  modelValue: { type: [Boolean, String], required: false, default: false },
  items: { type: Array, required: false, default: () => [] },
  tooltip: { type: String, required: false, default: "" },
  help: { type: String, required: false, default: "" },
  error: { type: [Boolean, String], required: false, default: false },
  errors: { type: Array, required: false, default: () => [] },
  breakLines: { type: Boolean, required: false, default: false },
  inline: { type: Boolean, required: false, default: false },
  half: { type: Boolean, required: false, default: false },
  disabled: { type: [Boolean, String], required: false, default: false },
  required: { type: Boolean, required: false, default: false },
  optional: { type: Boolean, required: false, default: false },
  buttonGroup: { type: Boolean, required: false, default: false },
});
provide("props", props);

const radioIsChecked = (radio) => {
  let isChecked = false;
  if (radio.value === "true" || radio.value === "false") {
    isChecked = props.modelValue === (radio.value === "true" ? true : false);
  } else {
    isChecked = props.modelValue === radio.id;
  }
  return isChecked;
};

const emit = defineEmits(["update:modelValue"]);

function updateInput(e) {
  if (e.target.value === "true" || e.target.value === "false") {
    emit("update:modelValue", e.target.value === "true" ? true : false);
  } else {
    emit("update:modelValue", e.target.value);
  }
}
</script>
