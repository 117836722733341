<template>
  <table class="sm-table sm-table-borderless-top">
    <thead>
      <tr>
        <th>{{ $t('Megnevezés') }}</th>
        <th>{{ $t('Létrehozva') }}</th>
        <th />
      </tr>
    </thead>
    <tbody>
      <tr v-if="!tokens">
        <td colspan="3">
          <sm-alert color="light">
            {{ $t('Betöltés...') }}
          </sm-alert>
        </td>
      </tr>
      <tr v-else-if="!tokens.length">
        <td colspan="3">
          <sm-alert
            color="warning"
            class="-mb-4">
            {{ $t('Nincs API kulcs létrehozva') }}
          </sm-alert>
        </td>
      </tr>
      <tr
        v-for="token in tokens"
        v-else
        :key="token.id"
      >
        <td>{{ token.name }}</td>
        <td>{{ token.created_at }}</td>
        <td class="text-right">
          <sm-button
            preset="delete"
            @click="deleteToken(token)" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
  props: {
    route: {
      type: String,
      required: true,
    },
    ability: {
      type: String,
      required: false,
      default: "*",
    },
  },
  data() {
    return {
      tokens: null,
    };
  },
  async mounted() {
    this.loadTokens();
  },
  methods: {
    async loadTokens() {
      try {
        const response = await axios.get(`${this.route}?ability=${this.ability}`);
        this.tokens = response.data.data;
      } catch (e) {
        const toast = useToast();
        toast.error(e.response ? e.response.data.message : e.message);
      }
    },
    async deleteToken(token) {
      const shouldDelete = await this.$confirm(
        this.$t("Biztosan törölni szeretnéd ezt az API kulcsot?"),
        this.$t("Ha még használatban lévő kulcsot törölsz, akkor az aktív integrációk működésében hibák léphetnek fel!"),
        [ { title: this.$t("Nem"), value: false }, { title: this.$t("Igen, törlöm"), value: true }]
      );

      if (!shouldDelete.cancelled && shouldDelete.value) {
        await this.callDeleteAction(token);
        this.loadTokens();
      }
    },
    async callDeleteAction(token) {
      try {
        await axios.delete(token.links.destroy);
      } catch (e) {
        const toast = useToast();
        toast.error(e.response ? e.response.data.message : e.message);
      }
    }
  },
};
</script>
