<template>
  <div :class="defaultClasses">
    <v-select
      ref="select"
      v-model="memberInput"
      :placeholder="placeholder"
      :options="users"
      :filterable="false"
      :disabled="readonly"
      :class="selectClass"
      :append-to-body="appendToBody"
      :calculate-position="appendToBody ? withPopper : undefined"
      @search="onSearch"
      @update:modelValue="$emit('update:modelValue', $event)"
      @search:focus="onFocus"
      @close="onClose"
      @option:selected="inputEvent"
    >
      <template #no-options>
        {{ $t('Nincs találat.') }}
      </template>

      <template #option="option">
        <user-picker-row :option="option" />
      </template>
    </v-select>

    <input
      v-if="!noHidden"
      type="hidden"
      :name="name"
      :value="selected"
    >
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import axios from "axios";
import debounce from "lodash/debounce";
import UserPickerRow from "../../inertia/components/forms/_partials/UserPickerRow.vue";

export default {
  components: {
    UserPickerRow
  },
  props: {
    name: { type: String, default: "" },
    value: {type:[String, Object, Number],default: null},
    placeholder: { type: String, default: "" },
    searchFn: { type: Function, default: null },
    endpoint: { type: String, default: "" },
    extraParams: {
      type: Object,
      default: () => {
      },
    },
    readonly: {
      type: Boolean,
      default: false
    },
    selectClass: { type: String, default: "" },
    noHidden: {
      type: Boolean,
      default: false,
    },
    input: { type: Function, default: null },
    noDefaultClass: {
      type: Boolean,
      default: false,
    },
    error: { type: String, default: "" },
    appendToBody: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue", "on-user-picker-close", "input"],
  data() {
    return {
      memberInput: (this.value && this.value.value) ? this.value : null,
      users: [],
    };
  },
  computed: {
    selected: function () {
      return this.memberInput && this.memberInput.value;
    },
    defaultClasses() {
      if (this.noDefaultClass) {
        return [];
      }

      let classes = [];
      if (this.error) {
        classes.push("is-invalid");
      }

      return classes;
    },
  },
  methods: {
    onClose() {
      this.$emit("on-user-picker-close");
    },
    withPopper(dropdownList, component, {width}) {
      dropdownList.style.width = width;
      dropdownList.style.zIndex = 2000;

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: "bottom",
        strategy: "fixed",
        modifiers: [
          {name: "offset", options: {offset: [0, 3]}},
        ],
      });

      window.addEventListener("scroll", () => popper.update());

      return () => popper.destroy();
    },
    search: debounce((search, vm) => {
      const params = new URLSearchParams({search, ...vm.extraParams}).toString();
      let requestPromise = null;
      if (vm.searchFn) {
        requestPromise = vm.searchFn(search);
      } else if (vm.endpoint) {
        requestPromise = axios.get(vm.endpoint + "?" + params);
      } else {
        requestPromise = axios.get(`/api-int/customers?${params}`);
      }

      requestPromise.then(response => response.data)
        .then(response => {
          const users = response.data ?? [];
          vm.users = users.map(user => ({
            label: user.name || user.title,
            value: user.id,
            subtitle: user.subtitle,
            avatar: user.avatar,
            data: user,
          }));
        });
    }, 350),
    onSearch: function (search) {
      this.search(search, this);
    },
    clear() {
      this.memberInput = null;
      this.users = [];
    },
    onFocus() {
      this.onSearch("");
    },
    inputEvent(e) {
      this.$emit("input", e);
    }
  },
};
</script>
