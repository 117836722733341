<template>
  <div>
    <sm-card header-title="Props">
      <div class="">
        <sm-alert
          no-flex
          color="info">
          All props are the same as the normal sm-select, but the async select have some of his own, these are:
        </sm-alert>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Hide specific ID-s from the result<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> disabledIds<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Array<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">disabled-ids="[0, 1, 2, ...]"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-async-select
              :disabled-ids="[0, 1]"
              label="Async select with disabled id-s"
              route="/api-int/customers" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add filterable prop to async select<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> filterable<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:filterable="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-async-select
              filterable
              label="Filterable async select"
              route="/api-int/customers" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Allow to manipulate the search behavior on focus<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> searchOnFocus<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> true<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:search-on-focus="false"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-async-select
              :search-on-focus="false"
              label="Async select with disabled searchOnFocus state"
              route="/api-int/customers" />
          </div>
        </div>
        <hr>
      </div>
    </sm-card>
  </div>
</template>
