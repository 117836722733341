<template>
  <div>
    <header class="mb-11">
      <h2 class="text-2xl font-open-sans-bold text-brand-blue-450 mb-7">
        Regisztráld első vendégedet*
      </h2>
    </header>
    <form @submit.prevent="saveGuest">
      <div class="grid grid-cols-2 gap-5 mb-4">
        <div class="col-span-2 -mb-4">
          <label class="sm-label-app">Név</label>
        </div>
        <div>
          <input
            v-model="form.firstname"
            name="firstname"
            placeholder="Vezetéknév"
            type="text"
            required
            class="sm-input-text-app"
          >
        </div>
        <div>
          <input
            v-model="form.lastname"
            name="lastname"
            placeholder="Keresztnév"
            type="text"
            required
            class="sm-input-text-app"
          >
        </div>
        <div>
          <label class="sm-label-app">E-mail</label>
          <input
            v-model="form.email"
            name="email"
            type="text"
            required
            class="sm-input-text-app">
        </div>
        <div>
          <label class="sm-label-app">Nem</label>
          <v-select
            v-model="form.gender"
            name="gender"
            :options="genders"
            :clearable="false"
            class="sm-input-text-app">
            <template #search="{attributes, events}">
              <input
                v-bind="attributes"
                class="vs__search"
                :required="!form.gender"
                v-on="events">
            </template>
          </v-select>
        </div>
        <div>
          <label class="sm-label-app">Telefonszám</label>
          <input
            v-model="form.phone"
            name="phone"
            type="text"
            required
            class="sm-input-text-app">
        </div>
        <div>
          <label class="sm-label-app">Születési dátum</label>
          <vc-date-picker
            v-model="form.birth_date"
            name="birth_date"
            locale="hu"
            :model-config="{ type: 'string', mask: 'YYYY-MM-DD' }"
            :masks="{ input: 'YYYY-MM-DD'}"
            is-required
            mode="date">
            <template #default="{ inputValue, inputEvents }">
              <input
                class="sm-input-text-app"
                field="from"
                required
                :value="inputValue"
                v-on="inputEvents">
            </template>
          </vc-date-picker>
        </div>
      </div>
      <p class="mb-8 italic text-black/50">
        *vendégedet email-ben értesítjük a regisztrációról
      </p>
      <div class="flex flex-wrap items-center justify-between mb-4 lg:flex-nowrap lg:space-x-5">
        <button
          type="button"
          class="order-2 w-full sm-btn-app sm-btn-app-secondary lg:order-1 lg:w-1/2"
          @click="skipStep">
          <span>Mégse</span>
        </button>
        <button
          type="submit"
          class="order-1 w-full mb-5 sm-btn-app sm-btn-app-primary lg:order-2 lg:w-1/2 lg:mb-0">
          <span>Vendég rögzítése</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import format from "date-fns/format";
import { useToast } from "vue-toastification";

export default {
  inject: ["customerApi"],
  emits: ["updateStepB", "increaseSubStep"],
  data() {
    return {
      form: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        birth_date: "",
        gender: "",
        language: "hu",
        zip_code: "1111"
      },
      genders: [
        {
          id: "not_given",
          label: "Nem adom meg"
        },
        {
          id: "male",
          label: "Férfi"
        },
        {
          id: "female",
          label: "Nő"
        }
      ],
    };
  },
  methods: {
    async saveGuest() {
      try {
        await axios.post(this.customerApi, {
          ...this.form,
          gender: this.form.gender.id,
          birth_date: format(this.form.birth_date, "yyyy-MM-dd"),
        });
        this.$emit("updateStepB");
        this.$emit("increaseSubStep");
      } catch (e) {
        for (const value of Object.values(e.response.data.errors)) {
          for (const data of value) {
            const toast = useToast();
            toast.error(data);
          }
        }
      }
    },
    skipStep() {
      this.$emit("increaseSubStep");
    }
  }
};
</script>
