<script>
export default {
  mounted(el, binding) {
    const defaultConfig = {
      default: 0,
      min: 0,
    };

    const config = {
      ...defaultConfig,
      ...(binding.value || {})
    };

    el.numeric = config;

    el.addEventListener("blur", () => {
      if (el.value === undefined || el.value === "") {
        el.value = config.default;
        el.dispatchEvent(new Event("input", {bubbles: true}));
      }
    });
  },
  updated(el) {
    const config = el.numeric;

    if (isNaN(el.value)) {
      el.value = config.default;
    }

    if (el.value < config.min) {
      el.value = config.min;
    }

    if (config.max && el.value > config.max) {
      el.value = config.max;
    }

    el.dispatchEvent(new Event("input", {bubbles: true}));
  },
};
</script>
