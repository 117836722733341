<template>
  <sm-card
    :form="form"
    :header-title="$t('payment.barion.settings.title')"
    @onSubmit="onSubmit">
    <sm-radio-group
      v-model="form.online_payment"
      name="online_payment"
      :items="online_payment_options"
      :label="$t('payment.barion.settings.online_payment.label')"
      inline
    />
    <sm-input
      v-model="form.payment_provider_identifier"
      name="payment_provider_identifier"
      :label="$t('payment.barion.settings.payment_provider_identifier.label')"
      :help="$t('payment.barion.settings.payment_provider_identifier.help')"
      inline
    />
    <sm-radio-group
      v-model="form.escrow_payment"
      name="escrow_payment"
      :items="escrow_payment_options"
      :label="$t('payment.barion.settings.escrow_payment.label')"
      :help="$t('payment.barion.settings.escrow_payment.help')"
      inline
    />
    <template #footer>
      <sm-button
        preset="save"
        :loading="form.processing"
        @click="onSubmit" />
    </template>
  </sm-card>
</template>

<script setup>
import { useFormatArrayToTrueFalseObjects } from "@/inertia/composables/global";
import { useForm, usePage } from "@inertiajs/vue3";

const page = usePage();

const online_payment_options = useFormatArrayToTrueFalseObjects(["online_payment_enabled", "online_payment_disabled"]);
const escrow_payment_options = useFormatArrayToTrueFalseObjects(["has_escrow_payment", "doesnt_have_escrow_payment"]);

// build the form
const form = useForm({
  online_payment: page.props.settings.online_payment,
  escrow_payment: page.props.settings.escrow_payment,
  payment_provider_identifier: page.props.settings.payment_provider_identifier,
});

const onSubmit = () => {
  form
    .post(page.url);
};

provide("form", form);
</script>
