import Core from "../Core";

Core.listen("change", "#club-selector", e => {
  const $form = e.target.closest("form");

  if (!$form) {
    return;
  }

  $form.submit();
});
