<template>
  <sm-card
    :form="form"
    @onSubmit="onSubmit()">
    <div class="grid grid-cols-2 gap-4">
      <div>
        <sm-select
          v-model="form.special_card_id"
          name="special_card_id"
          :label="$t('customers.special_cards.fields.special_card_id.label')"
          :options="specialCardsChoices"
        />
      </div>
      <div>
        <sm-input
          v-model="form.card_identifier"
          name="card_identifier"
          :label="$t('customers.special_cards.fields.card_identifier.label')"
          :help="$t('customers.special_cards.fields.card_identifier.help')"
          pattern="[a-zA-Z0-9öÖ]+"
        />
      </div>
      <div v-if="QRCodeIsVisible">
        <sm-input
          v-model="form.qr_code"
          name="qr_code"
          :label="$t('customers.special_cards.fields.qr_code.label')"
          :help="$t('customers.special_cards.fields.qr_code.help')"
        />
      </div>
      <div v-if="expirationDateIsVisible">
        <sm-date-picker
          v-model="form.expiration_date"
          name="expiration_date"
          :label="$t('customers.special_cards.fields.expiration_date.label')"
        />
      </div>
    </div>
    <template #footer>
      <sm-button
        :title="$t('customers.club_data.delete_modal.button')"
        color="danger-outline"
        icon="trash-2"
        class="mr-auto"
        @click="confirmDeleteProfile" />
      <sm-button
        :title="$t('generic.cancel')"
        @click="toggleModalVisibility" />
      <sm-button
        color="primary"
        :loading="form.processing"
        :title="$t('generic.save')"
        @click="onSubmit" />
    </template>
  </sm-card>
</template>

<script setup>
import { useDateFormat, useFormatSelect } from "@/inertia/composables/global";
import { useForm, usePage } from "@inertiajs/vue3";
import { provide, watch } from "vue";

const props = defineProps({
  method: { type: String, required: true, default: "post" },
  formRoute: { type: String, required: true, default: "post" }
});

const page = usePage();

const specialCardsChoices = useFormatSelect(page.props.specialCardChoices);
const specialCardExpirations = useFormatSelect(page.props.specialCardExpirations);

const form = useForm({
  special_card_id: page.props.customerSpecialCard ? specialCardsChoices.find(data => data.id == page.props.customerSpecialCard.special_card_id) : specialCardsChoices[0],
  card_identifier: page.props.customerSpecialCard ? page.props.customerSpecialCard.card_identifier : null,
  qr_code: page.props.customerSpecialCard ? page.props.customerSpecialCard.qr_code : null,
  expiration_date: page.props.customerSpecialCard ? page.props.customerSpecialCard.expiration_date : null,
});

const QRCodeIsVisible = ref(page.props.entryCards.find(data => parseInt(data) === parseInt(form.special_card_id?.id)));
const expirationDateIsVisible = ref(page.props.systemSpecialCardIds.find(data => parseInt(data) !== parseInt(form.special_card_id?.id)));

watch(() => form.special_card_id, (specialCard) => {
  const expDate = specialCardExpirations.find(data => data.id === specialCard.id).label;
  if (expDate) {
    form.defaults("expiration_date", new Date(expDate));
    form.reset("expiration_date");
  } else {
    form.defaults("expiration_date", "");
    form.reset("expiration_date");
  }

  if (page.props.entryCards.find(data => parseInt(data) === parseInt(specialCard.id))) {
    QRCodeIsVisible.value = true;
  } else {
    QRCodeIsVisible.value = false;
    form.defaults("qr_code", "");
    form.reset("qr_code");
  }

  if (page.props.systemSpecialCardIds.find(data => parseInt(data) !== parseInt(specialCard.id))) {
    expirationDateIsVisible.value = true;
  } else {
    expirationDateIsVisible.value = false;
  }
});

provide("form", form);

const onSubmit = () => {

  const formData = (data)  => {
    return {
      ...data,
      special_card_id: data.special_card_id.id,
      expiration_date: data.expiration_date? useDateFormat(data.expiration_date, "form") : "",
      qr_code: data.qr_code? data.qr_code : null,
    };
  };

  if (props.method === "post") {
    form
      .transform((data) => formData(data))
      .post(props.formRoute, {
        onSuccess: () => {
          form.reset();
        }
      });
  } else if (props.method === "patch") {
    form
      .transform((data) => {
        return {
          ...formData(data),
          _method: "patch",
        };
      })
      .patch(props.formRoute, {
        onSuccess: () => {
          form.reset();
        }
      });
  }

};
</script>
