<template>
  <settings-layout>
    <in-head :title="$t('invoicing.settings.invoicing-data.page_title')" />

    <form @submit.prevent="onSubmit()">
      <sm-card
        :header-title="$t('invoicing.settings.invoicing-data.page_title')">
        <h5 class="pt-4 font-bold">
          {{ $t('invoicing.settings.invoicing-data.type') }}
        </h5>
        <div class="pl-4 sm-row">
          <sm-select
            v-model="form.type"
            :options="invoicingDataTypes"
            half
          />
        </div>

        <hr>

        <h5 class="pt-4 font-bold">
          {{ $t('invoicing.settings.invoicing-data.partner') }}
        </h5>
        <div class="pl-4 sm-row">
          <sm-input
            v-model="form.name"
            name="name"
            :label="$t('invoicing.settings.invoicing-data.name.label')"
            half
          />
          <div class="sm-col sm-col-half" />
          <template v-if="form.type.id === 'company'">
            <sm-input
              v-model="form.tax_number"
              name="tax_number"
              :label="$t('invoicing.settings.invoicing-data.tax_number.label')"
              half
            />
            <sm-input
              v-model="form.community_tax_number"
              name="community_tax_number"
              :label="$t('invoicing.settings.invoicing-data.community_tax_number.label')"
              :help="$t('invoicing.settings.invoicing-data.community_tax_number.help')"
              half
            />
          </template>
        </div>

        <hr>

        <h5 class="pt-4 font-bold">
          {{ $t('invoicing.settings.invoicing-data.billing_address') }}
        </h5>
        <div class="pl-4 sm-row">
          <div class="w-4/12 px-2 lg:w-2/12">
            <sm-input
              v-model="form.postal_code"
              name="postal_code"
              :label="$t('invoicing.settings.invoicing-data.postal_code.label')"
            />
          </div>
          <div class="w-8/12 px-2 lg:w-4/12">
            <sm-input
              v-model="form.city"
              name="city"
              :label="$t('invoicing.settings.invoicing-data.city.label')"
            />
          </div>
          <div class="w-full px-2 lg:w-6/12">
            <sm-input
              v-model="form.street"
              name="street"
              :label="$t('invoicing.settings.invoicing-data.street.label')"
            />
          </div>
        </div>

        <hr>

        <h5 class="pt-4 font-bold">
          {{ $t('invoicing.settings.invoicing-data.contact') }}
        </h5>
        <div class="pl-4 sm-row">
          <sm-input
            v-model="form.email"
            name="email"
            :label="$t('invoicing.settings.invoicing-data.email.label')"
            half
          />
        </div>

        <hr>

        <h5 class="pt-4 font-bold">
          {{ $t('invoicing.settings.invoicing-data.other') }}
        </h5>
        <div class="pl-4 sm-row">
          <sm-input
            v-model="form.note"
            type="textarea"
            name="note"
            :label="$t('invoicing.settings.invoicing-data.note.label')"
            half
          />
          <sm-checkbox
            v-if="showIsDefault"
            v-model="form.is_default"
            name="is_default"
            :label="$t('invoicing.settings.invoicing-data.is_default.label')"
            :text="$t('invoicing.settings.invoicing-data.is_default.help')"
            half
          />
        </div>
      </sm-card>
      <sm-card>
        <sm-button
          preset="save"
          :loading="form.processing"
          @click="onSubmit" />
      </sm-card>
    </form>
  </settings-layout>
</template>

<script setup>
import { useFormatSelect } from "@/inertia/composables/global";
import { useForm, usePage } from "@inertiajs/vue3";
import { provide } from "vue";

// default variables
const page = usePage();

const props = defineProps({
  settings: { type: Object, required: true, default:  null },
  invoicingDataTypes: { type: Object, required: true, default: () => {} },
  showIsDefault: { type: Boolean, required: true, default:  null }
});

const invoicingDataTypes = useFormatSelect(props.invoicingDataTypes);

const form = useForm({
  type: props.settings.type ? invoicingDataTypes.find(data => data.id === props.settings.type) : invoicingDataTypes[0],
  name: props.settings.name,
  tax_number: props.settings.tax_number,
  community_tax_number: props.settings.community_tax_number,
  postal_code: props.settings.postal_code,
  city: props.settings.city,
  street: props.settings.street,
  email: props.settings.email,
  note: props.settings.note,
  is_default: props.settings.is_default,
});

const onSubmit = () => {
  form
    .transform((data) => ({
      ...data,
      type: data.type?.id
    }))
    .post(page.url);
};
provide("form", form);
</script>
