import {v4 as uuidv4} from "uuid";
import DialogRoot from "./ConfirmDialog/DialogRoot.vue";

const ConfirmDialog = {
  install(app) {
    /**
     * @typedef Button
     * @property {string} title
     * @property {string} color
     * @property value
     */

    /**
     * @typedef ConfirmResult
     * @property {boolean} cancelled
     * @property value
     */

    /**
     * @param {string} title
     * @param {string} message
     * @param {array<Button>} buttons
     * @return {Promise<ConfirmResult>}
     */
    app.config.globalProperties.$confirm = (title, message, buttons = []) => {
      return new Promise((resolve) => {
        const id = uuidv4();

        const callback = (value) => {
          app.config.globalProperties.$store.commit("closeDialog", id);
          resolve(value);
        };

        const config = { id, title, message, buttons, callback };
        app.config.globalProperties.$store.commit("openDialog", config);
      });
    };

    app.component("DialogRoot", DialogRoot);
  },
};

const dialogStore = {
  state: {
    dialogs: [],
  },
  mutations: {
    openDialog(state, config) {
      state.dialogs = state.dialogs.concat(config);
    },
    closeDialog(state, id) {
      state.dialogs = state.dialogs.filter(d => d.id !== id);
    }
  },
  getters: {},
  actions: {},
};

export { ConfirmDialog, dialogStore };
