import axios from "axios";

export default (route) => ({
  unreadCount: 0,
  async init() {
    try {
      const response = await axios.get(route);
      this.unreadCount = response.data.data.count;
    } catch (e) {
      console.error(e.message);
    }
  }
});
