import { debounce } from "lodash";

import "./dropdown";
import "./modal";
import "./alert";
import "./tabs";

const loadSmScripts = debounce(() => {
  window.removeSmDropdownEventListeners();
  window.loadSmDropdown();

  window.removeSmModalEventListeners();
  window.loadSmModal();

  window.loadSmAlert();

  window.loadSmTabs();
}, 1000);

window.loadSmScripts = loadSmScripts;
