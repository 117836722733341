<script>
import head from "lodash/head";
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
  props: {
    warehousesEndpoint: { type: String, default: "" },
    baseStockSearchEndpoint: { type: String, default: "" },
  },
  data() {
    return {
      availableWarehouses: [],
      selectedSourceWarehouse: {id: null},
      selectedTargetWarehouse: {id: null},
      note: null,
      itemsToMove: [],
      selectedProduct: null,
      movedAmount: 1,
    };
  },
  computed: {
    sourceWarehouses() {
      return this.availableWarehouses;
    },
    targetWarehouses() {
      return this.availableWarehouses.filter(warehouse => {
        return warehouse.id !== this.selectedSourceWarehouse?.id;
      });
    },
    stockSearchEndpoint() {
      if (this.selectedSourceWarehouse) {
        return `${this.baseStockSearchEndpoint}?warehouse=${this.selectedSourceWarehouse.id}`;
      }

      return `${this.baseStockSearchEndpoint}`;
    },
  },
  watch: {
    selectedSourceWarehouse() {
      this.selectedTargetWarehouse = head(this.targetWarehouses);
    },
  },
  async mounted() {
    try {
      const response = await axios.get(this.warehousesEndpoint);
      this.availableWarehouses = response.data.data;
      this.selectedSourceWarehouse = head(this.availableWarehouses);
    } catch (e) {
      const toast = useToast();
      toast.error(e.response ? e.response.data.message : e.message);
    }
  },
  methods: {
    addProduct() {
      if (!this.selectedProduct || !this.selectedProduct.item) {
        return;
      }

      this.itemsToMove = this.itemsToMove.concat({
        itemId: this.selectedProduct.item.id,
        itemName: this.selectedProduct.item.title,
        availableAmount: this.selectedProduct.item.data.onStock,
        movedAmount: this.movedAmount,
        unit: this.selectedProduct.item.data.unit,
      });
      this.selectedProduct = null;
      this.$refs.productSearch.reset();
      this.movedAmount = 1;
      this.$nextTick(() => {
        setTimeout(() => {
          document.querySelector("#productSearch .vs__search").focus();
        });
      });
    },
    removeItem(itemToRemove) {
      this.itemsToMove = this.itemsToMove.filter(
        item => item.itemId !== itemToRemove.itemId
      );
    },
    setSelectedProduct(value) {
      this.selectedProduct = value;
      this.$nextTick(() => {
        this.$refs.movedAmount.focus();
      });
    },
    onMovedAmountEnter(e) {
      e.preventDefault();
      this.addProduct();
    }
  },
};
</script>
