/*global unlayer */
import axios from "axios";

export default ({ uploadUrl, options, replacers, ...params }) => ({
  html: params.html ?? "",
  json: params.json ?? {},

  init() {
    unlayer.init(options);

    unlayer.loadDesign(JSON.parse(JSON.stringify(this.json).replaceAll("[[[", "&#91;&#91;&#91;")));

    if (! this.json) {
      unlayer.loadBlank({
        backgroundColor: "#ffffff"
      });
    }

    unlayer.registerCallback("image", (file, done) => {
      let data = new FormData();
      data.append("file", file.attachments[0]);

      axios.post(uploadUrl, data)
        .then(response => {
          // Make sure the response was valid
          if (response.status >= 200 && response.status < 300) {
            return response.data;
          }

          let error = new Error(response.statusText);
          error.response = response;
          throw error;
        }).then(data => done({ progress: 100, url: data.file.url }));
    });

    const mergeTags = [];

    for(const replacerName in replacers) {
      mergeTags.push({
        name: replacerName,
        value: `::${replacerName}::`
      });
    }

    unlayer.setMergeTags(mergeTags);

    const component = this;
    unlayer.addEventListener("design:updated", () => {
      unlayer.exportHtml(function(data) {
        component.html = data.html;
        component.json = JSON.parse(JSON.stringify(data.design));
        document.getElementById("editor").dirty = true;
      });
    });

    unlayer.addEventListener("design:loaded", function() {
      unlayer.exportHtml(function(data) {
        component.html = data.html;
        component.json = data.design;
      });
    });
  },
});
