<template>
  <sm-input-wrapper ref="asyncSelectRoot">
    <template
      v-if="$slots.afterLabel"
      #afterLabel>
      <slot name="afterLabel" />
    </template>
    <template
      v-if="$slots.prepend"
      #prepend>
      <slot name="prepend" />
    </template>
    <template
      v-if="$slots.afterInput"
      #afterInput>
      <slot name="afterInput" />
    </template>
    <template
      v-if="$slots.append"
      #append>
      <slot name="append" />
    </template>
    <v-select
      ref="smSelect"
      v-model="selectModelValue"
      :input-id="id"
      :options="selectOptions"
      :value="modelValue"
      :taggable="tagSelect"
      :clearable="tagSelect ? true : clearable"
      :multiple="tagSelect ? true : multiple"
      :disabled="disabled"
      :placeholder="placeholder ? placeholder : $t('generic.start_search')"
      :filterable="filterable"
      class="w-full"
      :no-drop="searchString === ''"
      :loading="loading"
      @input="input"
      @search="onSearch"
      @search:focus="onFocus"
      @option:selecting="selected"
      @option:selected="onSelected"
      @update:modelValue="onUpdate"
    >
      <template
        v-if="$slots['selected-option']"
        #selected-option="{id: innerId, label: innerLabel, data}">
        <slot
          :id="innerId"
          name="selected-option"
          :label="innerLabel"
          :data="data"
        />
      </template>
      <template
        v-if="$slots.option"
        #option="{id: innerId, label: innerLabel ,data}">
        <slot
          :id="innerId"
          name="option"
          :label="innerLabel"
          :data="data"
        />
      </template>
      <template #no-options>
        <p
          class="my-0">
          {{ $t("generic.no_result") }}
        </p>
      </template>
    </v-select>
  </sm-input-wrapper>
</template>

<script setup>
import SmInputWrapper from "@/inertia/components/forms/_layouts/SmInputWrapper.vue";
import { useDebounceFn } from "@vueuse/core";
import axios from "axios";
import { onMounted } from "vue";

// props
const props = defineProps({
  name: { type: String, required: false, default: "" },
  id: { type: String, required: false, default: "" },
  label: { type: String, required: false, default: "" },
  placeholder: { type: String, required: false, default: "" },
  modelValue: { type: [Array, String, Object], required: false, default: "" },
  options: { type: Array, required: false, default: () => [] },
  tooltip: { type: String, required: false, default: "" },
  help: { type: String, required: false, default: "" },
  error: { type: [Boolean, String], required: false, default: false },
  inline: { type: Boolean, required: false, default: false },
  disabled: { type: Boolean, required: false, default: false },
  required: { type: Boolean, required: false, default: false },
  clearable: { type: Boolean, required: false, default: false },
  multiple: { type: Boolean, required: false, default: false },
  tagSelect: { type: Boolean, required: false, default: false },
  half: { type: Boolean, required: false, default: false },
  route: { type: String, required: false, default: "" },
  // selectedOptions: { type: String, required: false, default: "" },
  // params: { type: Object, required: false, default: null },
  disabledIds: { type: Array, required: false, default: () => [] },
  filterable: { type: Boolean, required: false, default: false },
  searchOnFocus: { type: Boolean, required: false, default: true },
  searchOnMount: { type: Boolean, required: false, default: false },
});

provide("props", props);

// emits
const emit = defineEmits(["update:modelValue", "input", "onSelected"]);

// data
const asyncSelectRoot = ref(); // component root
const smSelect = ref(); // v-select
const selectModelValue = ref(props.multiple ? [] : "");
const selectOptions = ref([]);
const loading = ref(false);
const searchString = ref("");

// search items
const searchItems = (search = "", initial = false) => {
  loading.value = true;
  let url = props.route;
  searchString.value = search;

  const params = new URLSearchParams();

  if (search?.length > 0) {
    params.set("search", search);
  }

  if (initial && props.modelValue) {
    if (Array.isArray(props.modelValue)) {
      for (const val of props.modelValue) {
        params.append("ids[]", val);
      }
    } else {
      params.set("ids[]", [props.modelValue]);
    }
  }

  const divider = url.includes("?") ? "&" : "?";
  url += `${divider}${params.toString()}`;

  axios.get(url)
    .then((response) => {
      // format options to proper array
      selectOptions.value = [];
      let resp = response.data.data;

      if (props.disabledIds) {
        for (const id of props.disabledIds) {
          resp = resp.filter(data => data.id != id);
        }
      }

      selectOptions.value = resp.map(data => ({
        id: data.id,
        label: data.title || data.name,
        data,
      }));

      // load default data
      if (initial && props.searchOnMount && props.modelValue) {
        if (typeof props.modelValue === "object") {
          selectModelValue.value = props.modelValue;
        } else {
          selectModelValue.value = selectOptions.value.find(data => data.id === props.modelValue);
        }
      }

      loading.value = false;
    });
};

watch(() => props.modelValue, (e) => {
  setTimeout(() => {
    selectModelValue.value = e;
  }, 300);
});

// search items in focus
const onFocus = () => {
  if (selectOptions.value.length === 0 && props.searchOnFocus) {
    searchItems();
  }
};

const onUpdate = () => {
  emit("update:modelValue", selectModelValue.value);
};

const selected = () => {
  nextTick(() => {
    // set focus after selecting a tag
    if (props.tagSelect) {
      smSelect.value.$refs.search.focus();
    }

    // pass data to parent component
    // emit("update:modelValue", selectModelValue.value);
  });
};

const onSearch = (search) => {
  debouncedSearch(search);
};

const debouncedSearch = useDebounceFn((search) => {
  searchItems(search);
}, 300);

const input = (e) => {
  emit("input", e);
};

watch(() => props.route, () => {
  searchItems();
});

onMounted(() => {
  if (props.searchOnMount) {
    searchItems("", true);
  }
});

const onSelected = () => {
  emit("onSelected");
};
</script>
