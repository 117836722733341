<template>
  <sm-card header-title="Emits">
    <div class="sm-row">
      <div class="w-1/2 sm-col">
        <p class="mb-2">
          Emits the <code>click</code> event when the button is clicked<br>
          <strong class="inline-block pl-2 w-36">Emit name:</strong> @click<br>
          <code class="inline-block pl-2"><pre class="text-xs text-bo-yellow-600">&lt;sm-input @click="customEvent" /&gt;</pre></code>
        </p>
      </div>
      <div class="w-1/2 sm-col">
        <sm-button
          title="Button with click event (check the console)"
          @click="console.log('Clicked')" />
      </div>
    </div>
  </sm-card>
</template>
