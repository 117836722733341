<template>
  <styleguide-layout>
    <sm-anchor-point id="defaults" />
    <sm-card header-title="Default usage">
      <sm-label label="Default label" />
      <highlightjs
        code="<sm-label label='...' />"
        class="text-sm" />
    </sm-card>

    <sm-anchor-point id="props" />
    <sm-card header-title="Props">
      <div class="">
        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add text to the label<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> label<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">label="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-label label="Label with label prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add tooltip to the label<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> tooltip<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">tooltip="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-label
              label="Label with tooltip prop"
              tooltip="Hello i'm a tooltip" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add required star to label<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> required<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:required="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-label
              label="Label with required prop"
              required />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add optional text to label<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> optional<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:optional="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-label
              label="Label with optional prop"
              optional />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add for prop to label<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> forId<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String, Number<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:for-id="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-label
              for-id="id"
              label="Label with forId prop" />
          </div>
        </div>
        <hr>
      </div>
    </sm-card>

    <sm-anchor-point id="slots" />
    <sm-card header-title="Slots">
      <div class="">
        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add slot after the label<br>
              <strong class="inline-block pl-2 w-36">Slot name:</strong> #afterLabel<br>
              <code class="inline-block pl-2"><pre class="text-xs text-bo-green">&lt;template #afterLabel&gt;After label&lt;/template&gt;</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-label
              label="Prepend input slot">
              <template #afterLabel>
                After label slot
              </template>
            </sm-label>
          </div>
        </div>
        <hr>
      </div>
    </sm-card>
  </styleguide-layout>
</template>

<script setup>
import StyleguideLayout from "@/inertia/components/styleguide/StyleguideLayout.vue";
import { useForm } from "@inertiajs/vue3";

const form = useForm({});
provide("form", form);
</script>
