<template>
  <div>
    <onboarding-step-3-a />
  </div>
</template>

<script>
import OnboardingStep3A from "./Step3/OnboardingStep3A.vue";

export default {
  components: {
    OnboardingStep3A,
  },
};
</script>
