<template>
  <settings-layout>
    <in-head :title="$t('payment.page_title')" />

    <component :is="onlinePaymentComponent" />
  </settings-layout>
</template>

<script setup>
import SimplePay from "./components/SimplePay.vue";
import Barion from "./components/Barion.vue";
import VivaWallet from "./components/VivaWallet.vue";

const props = defineProps({
  onlinePaymentService: { type: String, required: false, default: "vivawallet" },
});

const onlinePaymentComponent = computed(() => {
  if (props.onlinePaymentService === "barion") {
    return Barion;
  }

  if (props.onlinePaymentService === "simplepay") {
    return SimplePay;
  }

  if (props.onlinePaymentService === "vivawallet") {
    return VivaWallet;
  }
});
</script>
