<template>
  <div>
    <h4>{{ $t('tickets.season_tickets.sales') }}</h4>
    <div class="space-y-4">
      <sm-card>
        <div class="grid grid-cols-2 gap-x-4">
          <div>
            <sm-input
              v-model="form.tax"
              name="tax"
              :label="$t('tickets.season_tickets.fields.tax.label')"
              :help="$t('tickets.season_tickets.fields.tax.help', {tax: $page.props.productTax})"
              :placeholder="$page.props.productTax"
              type="number"
              :min="0"
              :step="1"
            >
              <template #append>
                %
              </template>
            </sm-input>
          </div>
          <div />
          <div>
            <sm-checkbox
              v-model="form.purchasable"
              name="purchasable"
              inline
              :label="$t('tickets.season_tickets.fields.purchasable.label')"
              :help="$t('tickets.season_tickets.fields.purchasable.help')"
            />
          </div>
          <div>
            <sm-checkbox
              v-model="form.online_purchasable"
              name="online_purchasable"
              inline
              :label="$t('tickets.season_tickets.fields.online_purchasable.label')"
              :help="$t('tickets.season_tickets.fields.online_purchasable.help')"
            />
          </div>
          <div>
            <sm-checkbox
              v-model="form.one_time_purchase"
              name="one_time_purchase"
              inline
              :label="$t('tickets.season_tickets.fields.one_time_purchase')"
            />
          </div>
          <div>
            <sm-checkbox
              v-model="form.cannot_be_discounted"
              name="cannot_be_discounted"
              inline
              :label="$t('tickets.season_tickets.fields.cannot_be_discounted.label')"
              :help="$t('tickets.season_tickets.fields.cannot_be_discounted.help')"
            />
          </div>
        </div>
      </sm-card>
      <sm-card>
        <div class="grid grid-cols-2 gap-x-4">
          <div>
            <sm-checkbox
              v-model="form.has_continuous_discount"
              name="has_continuous_discount"
              :label="$t('tickets.season_tickets.fields.has_continuous_discount.label')"
              :help="$t('tickets.season_tickets.fields.has_continuous_discount.help')"
            />
          </div>
          <div />
          <template v-if="form.has_continuous_discount">
            <div>
              <sm-select
                v-model="form.continuous_discount_type"
                class="flex-1"
                name="continuous_discount_type"
                :label="$t('tickets.season_tickets.fields.continuous_discount_type')"
                :options="$page.props.continuousDiscountTypes"
              />
            </div>
            <div />
            <div>
              <sm-input
                v-model="form.continuous_discount_amount"
                name="continuous_discount_amount"
                :label="$t('tickets.season_tickets.fields.continuous_discount_amount')"
                type="number"
                :min="0"
                :step="1"
              />
            </div>
            <div />
            <div>
              <sm-input
                v-model="form.continuous_discount_required_count"
                name="continuous_discount_required_count"
                :label="$t('tickets.season_tickets.fields.continuous_discount_required_count.label')"
                :help="$t('tickets.season_tickets.fields.continuous_discount_required_count.help')"
                type="number"
                :min="1"
                :step="1"
              />
            </div>
            <div />
            <div>
              <sm-date-picker
                v-model="form.continuous_discount_valid_from"
                name="continuous_discount_valid_from"
                :label="$t('tickets.season_tickets.fields.continuous_discount_valid_from.label')"
              />
            </div>
            <div />
          </template>
        </div>
      </sm-card>
    </div>
  </div>
</template>

<script setup>
const form = inject("form");
</script>
