<template>
  <div
    class="relative "
    :class="{'pointer-events-none': pageIsLoading}">
    <div
      v-if="pageIsLoading"
      v-auto-animate
      class="absolute z-10 flex items-center justify-center w-full h-full">
      <vue-feather
        type="loader"
        class="ml-1 text-black animate-spin" />
    </div>
    <sm-card
      :header-title="$t('appointments.customer_appointments.title')"
      no-padding
      class="transition-opacity"
      :class="{'opacity-50': pageIsLoading}"
      :header-info="$t('customers.profile.tooltips.appointments')">
      <template #headerActions>
        <sm-button
          :href="$page.props.routes.customerAppointments">
          {{ $t('appointments.customer_appointments.all_button') }}
        </sm-button>
      </template>

      <table class="mb-0 sm-table sm-table-borderless-top">
        <template v-if="pageAppointments?.length !== 0">
          <thead>
            <tr>
              <th>{{ $t('appointments.customer_appointments.columns.date') }}</th>
              <th>{{ $t('appointments.customer_appointments.columns.place') }}</th>
              <th>{{ $t('appointments.customer_appointments.columns.payment_type') }}</th>
              <th>{{ $t('appointments.customer_appointments.columns.occasions') }}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(appointment, i) in pageAppointments"
              :key="i"
              :class="{'!text-bo-yellow-800 !bg-bo-yellow/25': isSameDay(new Date(appointment.from), new Date()) }">
              <td>
                {{ appointment.date }} {{ appointment.from }} - {{ appointment.to }}
              </td>
              <td>
                <a :href="appointment.routes.calendar">
                  {{ appointment.location.name }}
                </a>
                <span
                  v-if="appointment.location.trashed"
                  class="text-bo-red">({{ $t('appointments.customer_appointments.deleted') }})</span>
              </td>
              <td>
                <span v-if="appointment.hasUsedTickets">
                  {{ appointment.usedTickets }}
                </span>
                <span v-else-if="appointment.paymentType">
                  {{ $t(`payment_types.${appointment.paymentType}`) }}
                </span>
              </td>
              <td>
                <div class="flex items-center">
                  <span>{{ appointment.usageCounts }}</span>
                  <vue-feather
                    v-if="appointment.usageCountTooltip"
                    v-tippy="appointment.usageCountTooltip"
                    type="info"
                    class="w-2.5 ml-1 -mt-0.5" />
                </div>
              </td>
              <td class="text-right">
                <template v-if="appointment.ticketIdForCheckin">
                  <form
                    v-if="appointment.canShowCheckinButton"
                    @submit.prevent="login(appointment)">
                    <sm-button
                      type="submit"
                      :title="$t('customers.actions.logging_in')"
                    />
                  </form>
                  <sm-button
                    v-else-if="appointment.allMembersOfUserCheckedIn"
                    :title="$t('appointments.customer_appointments.logged_in')"
                    disabled
                  />
                </template>
                <sm-button
                  v-else
                  :title="$t('customers.actions.logging_in')"
                  @click="updateShowCheckin(appointment)"
                />
              </td>
            </tr>
          </tbody>
        </template>
        <template v-else>
          <tbody
            class="p-2 bg-bo-gray-50">
            <tr>
              <td colspan="4">
                <a
                  :href="$page.props.routes.calendar"
                  class="flex items-center justify-center w-full p-4 rounded-md bg-bo-gray-100 !text-bo-gray-400 group">
                  <div class="text-center">
                    <vue-feather
                      type="calendar"
                      class="w-10 h-10 mb-2 transition-colors group-hover:text-bo-blue" />
                    <br>
                    <p class="mb-0 transition-colors group-hover:text-bo-blue">
                      {{ $t('appointments.customer_appointments.empty') }}
                    </p>
                  </div>
                </a>
              </td>
            </tr>
          </tbody>
        </template>
      </table>
    </sm-card>
  </div>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";
import { isSameDay } from "date-fns";

defineProps({
  pageAppointments: { type: Object, required: false, default: null }
});

const pageIsLoading = inject("pageIsLoading");

const emit = defineEmits(["updateShowCheckin"]);

const updateShowCheckin = (appointment) => {
  emit("updateShowCheckin", {
    visible: true,
    appointmentId: appointment.id,
  });
};

const form = useForm({
  seasonTicketId: null,
});

const login = (appointment) => {
  form.transform(() => ({
    seasonTicketId: appointment.ticketIdForCheckin,
  }))
    .post(appointment.routes.checkIn, {
      only: ["appointments", "tickets", "clubOpen", "customerCheckedIn", "hasAycmQuickCheckin", "checkinModal", "customer", "errors", "flash"],
    });
};
</script>
