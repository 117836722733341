<template>
  <div>
    <template
      v-for="(item, index) of data"
      :key="item.id">
      <a
        v-if="!item.deleted"
        v-tippy="item.email"
        :href="item.routes.show"
        class="text-bo-blue"
        target="_blank">
        <span>{{ getName(item, index) }}</span>
      </a>
      <span v-else>
        {{ getName(item, index) }}
      </span>
    </template>
  </div>
</template>
<script setup>
const props = defineProps({
  data: {
    type: Array,
    required: true
  }
});

const getName = (item, index) => {
  let name = item.name;

  if (item.is_main) {
    name += " (Fő profil)";
  }

  if (item.deleted) {
    name += " (Törölt profil)";
  }

  if (index < props.data.length - 1) {
    name += ", ";
  }

  return name;
};
</script>
