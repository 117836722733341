<script>
export default {
  props: {
    from: { type: String, default: "" },
    to: { type: String, default: "" }
  },
  data() {
    return {
      fromModel: this.from,
      toModel: this.to,
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD HH:mm"
      }
    };
  }
};
</script>
