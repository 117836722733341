<template>
  <div class="mb-4">
    <h4 class="mb-4">
      {{ $t('sales.solarium.title') }}
    </h4>
    <sm-card
      :form="form"
      @onSubmit="onSubmit">
      <div class="mb-4">
        <sm-label :label="$t('clubs.settings.sales.solarium_product')" />

        <searchable-select
          v-model="computedForm.solarium_product"
          field="solarium_product"
          :search-endpoint="page.props.searchProductRoute"
          label-key="title"
        />
      </div>
      <sm-input
        v-model="computedForm.solarium_min_value"
        name="solarium_min_value"
        :label="$t('clubs.settings.sales.solarium_min_value')"
      />
      <sm-input
        v-model="computedForm.solarium_max_value"
        name="solarium_max_value"
        :label="$t('clubs.settings.sales.solarium_max_value')"
      />
      <template #footer>
        <sm-button
          preset="save"
          :loading="form.processing"
          @click="onSubmit" />
      </template>
    </sm-card>
  </div>
</template>

<script setup>
import { usePage } from "@inertiajs/vue3";

const page = usePage();

const props = defineProps({
  form: { type: Object, required: true, default: null }
});

const emit = defineEmits(["onSubmit"]);

const computedForm = computed(() => {
  return props.form;
});

const onSubmit = () => {
  emit("onSubmit");
};
</script>
