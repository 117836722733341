<template>
  <div>
    <h4>{{ $t('tickets.season_tickets.basic_info') }}</h4>
    <sm-card>
      <div class="grid grid-cols-2 gap-x-4">
        <div>
          <sm-input
            v-model="form.name"
            name="name"
            :label="$t('tickets.season_tickets.fields.name')"
          />
        </div>
        <div>
          <sm-input
            v-model="form.price"
            name="price"
            :label="$t('tickets.season_tickets.fields.price')"
            type="number"
            :min="0"
          >
            <template #append>
              Ft
            </template>
          </sm-input>
        </div>
        <div>
          <sm-input
            v-model="form.note"
            name="note"
            :label="$t('tickets.season_tickets.fields.note.label')"
            :help="$t('tickets.season_tickets.fields.note.help')"
            type="textarea"
          />
        </div>
      </div>
    </sm-card>
  </div>
</template>

<script setup>
const form = inject("form");
</script>
