<template>
  <div
    id="inertia-root"
    class="layout">
    <div v-auto-animate>
      <div
        v-for="flash of page.props.flash"
        :key="flash">
        <sm-alert
          :color="flash?.type"
          auto-close
          closeable>
          {{ flash?.message }}
        </sm-alert>
      </div>
    </div>
    <slot />
  </div>
</template>

<script setup>
import { usePage } from "@inertiajs/vue3";
const page = usePage();
</script>
