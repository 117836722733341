<template>
  <sm-card header-title="Slots">
    <div class="">
      <div class="sm-row">
        <div class="w-1/2 sm-col">
          <p class="mb-2">
            Default button slot to add text when you dont need the button text styles<br>
            <strong class="inline-block pl-2 w-36">Slot name:</strong> #default<br>
            <code class="inline-block pl-2"><pre class="text-xs text-bo-green">&lt;template #default&gt;Default slot&lt;/template&gt;</pre></code>
          </p>
        </div>
        <div class="w-1/2 sm-col">
          <sm-button
            label="Default button slot">
            <template #default>
              Default slot
            </template>
          </sm-button>
        </div>
      </div>
    </div>
  </sm-card>
</template>
