<template>
  <div>
    <ul
      class="sm-nav-tabs-line"
      role="tablist"
    >
      <li
        v-for="(day, i) in days"
        :key="i"
        class="sm-nav-item"
      >
        <a
          :class="`sm-nav-link ${i === 0 ? 'active' : ''}`"
          data-toggle="tab"
          :href="`#day-${i}`"
          role="tab"
        >
          <span class="hidden lg:block">{{ day }}</span>
          <span class="block lg:hidden">{{ mobileDays[i] }}</span>
        </a>
      </li>
    </ul>

    <div
      id="prices-list"
      class="mt-4 sm-tab-content"
    >
      <div
        v-for="(day, i) in days"
        :id="`day-${i}`"
        :key="i"
        :class="`sm-tab-pane ${i === 0 ? 'block' : ''}`"
        role="tabpanel"
      >
        <price-row
          v-for="(entry, index) in innerLists[i]"
          :key="index"
          :entry="entry"
          @deleted="onEntryDeleted(i, entry)"
        />

        <button
          class="mt-4 sm-btn sm-btn-primary-outline sm-btn-xs"
          type="button"
          @click.prevent="onEntryAdded(i)"
        >
          <i class="mr-1 fas fa-plus-circle" />
          {{ $t("Hozzáadás") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import PriceRow from "./PriceRow.vue";

export default {
  components: { PriceRow },
  props: {
    lists: { type: [Array, Object], default: () => [] },
  },
  data: function () {
    return {
      days: [
        "Hétfő",
        "Kedd",
        "Szerda",
        "Csütörtök",
        "Péntek",
        "Szombat",
        "Vasárnap",
      ],
      mobileDays: [
        "H",
        "K",
        "Sz",
        "Cs",
        "P",
        "Sz",
        "V",
      ],
      innerLists: new Array(7).fill("").map((_, i) => {
        return this.lists[i + 1] || [];
      }),
    };
  },
  methods: {
    onEntryDeleted: function (day, entry) {
      Vue.set(
        this.innerLists,
        day,
        this.innerLists[day].filter((e) => e !== entry)
      );
    },
    onEntryAdded: function (day) {
      this.innerLists[day].push({
        from: "",
        to: "",
        price: 0,
        coach_price: null,
        member_price: null,
        day: day + 1,
      });
    },
  },
};
</script>
