<template>
  <div>
    <In-Head :title="$page.props.title" />
    <sub-header :title="$page.props.title">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          v-if="$page.props.permissions.create"
          :href="$page.props.routes.create"
          preset="add" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        :data="table"
        :sorts="sorts"
        paginator>
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`franchises.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              {{ slotProps.data?.[col] }}
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div
                v-if="slotProps.data"
                class="flex justify-end space-x-1">
                <sm-button
                  v-if="slotProps.data.permissions.show"
                  size="icon"
                  icon="eye"
                  :href="slotProps.data.routes.show"
                />
                <sm-button
                  v-if="slotProps.data.permissions.edit"
                  preset="edit-icon"
                  :href="slotProps.data.routes.edit"
                />
                <sm-button
                  v-if="slotProps.data.permissions.destroy"
                  preset="delete-icon"
                  @click="confirmDelete(slotProps.data)"
                />
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
    <SmConfirmDialog group="confirmDelete" />
  </div>
</template>
<script setup>
import { useConfirm } from "primevue/useconfirm";
import axios from "axios";
import { router } from "@inertiajs/vue3";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableFields = ref([
  "name",
  "club_count",
  "owner",
]);

const confirm = useConfirm();

const confirmDelete = async (franchise) => {
  confirm.require({
    group: "confirmDelete",
    header: window.trans("franchises.delete_modal_title"),
    message: `${window.trans("generic.are_you_sure")}?`,
    accept: async () => {
      await axios.delete(franchise.routes.destroy);
      router.reload({ only: ["table", "flash"] }, { preserveState: true });
    }
  });
};
</script>
