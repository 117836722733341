import { throttle } from "lodash";

function calculateFixedPosition(toggler, menu) {
  const togglerBounds = toggler.getBoundingClientRect();
  const alignment = menu.classList.contains("sm-dropdown-menu-right") ? `left: ${togglerBounds.left + togglerBounds.width - 240}px;` : `left: ${togglerBounds.left}px;`;
  menu.setAttribute("style", `position:fixed; top: ${togglerBounds.top + togglerBounds.height}px; ${alignment}`);
}

const loadSmDropdown = throttle(() => {
  document.querySelectorAll(".sm-dropdown-toggle").forEach(toggler => {
    toggler.addEventListener("click", () => {
      const menu = toggler.parentElement.querySelector(".sm-dropdown-menu") || toggler.parentElement.parentElement.querySelector(".sm-dropdown-menu");
      menu.classList.toggle("block");

      if (toggler.classList.contains("sm-dropdown-fixed")) {
        calculateFixedPosition(toggler, menu);

        window.addEventListener("scroll", () => {
          const activeMenu = document.querySelector(".sm-dropdown-menu.block");
          if (activeMenu) {
            calculateFixedPosition(toggler, activeMenu);
          }
        });
      }

      const availableMenus = document.querySelectorAll(".sm-dropdown-menu.block");
      if (availableMenus.length > 1) {
        availableMenus.forEach(menu => {
          if (menu !== toggler.nextElementSibling) {
            menu.classList.remove("block");
          }
        });
      }
    });
  });

  // outside click
  document.addEventListener("click", function (event) {
    if (document.querySelectorAll(".sm-dropdown-menu.block").length > 0 && !event.target.closest(".sm-dropdown-toggle") && !event.target.closest(".sm-dropdown-menu")) {
      for (const elem of document.querySelectorAll(".sm-dropdown-menu")) {
        elem.classList.remove("block");
      }
    }
  }, false);
}, 300);

window.loadSmDropdown = loadSmDropdown;

const removeSmDropdownEventListeners = () => {
  document.querySelectorAll(".sm-dropdown-toggle").forEach(toggler => {
    toggler.replaceWith(toggler.cloneNode(true));
  });
};

window.removeSmDropdownEventListeners = removeSmDropdownEventListeners;
