<script>
import AbstractCrudFilterType from "../utility/AbstractCrudFilterType";

export default {
  mixins: [AbstractCrudFilterType],
  data() {
    return {
      defaults: {
        term: null,
      },
    };
  },
  computed: {
    filterData() {
      return {
        term: this.data.data.term,
      };
    },
  },
  render() {
    return this.renderAbstract([
      this.createDiv("sm-col w-8/12", [
        this.createSearchableSelect([this.data.data, "term"], "test", {
          route: this.data.filter.options.route,
        }),
      ]),
    ]);
  },
};
</script>
