<template>
  <div>
    <In-Head :title="$t('customers.discounts_page.title', {name: $page.props.customer.name})" />

    <sub-header :title="$t('customers.discounts_page.title', {name: $page.props.customer.name})">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          :href="$page.props.routes.customer"
          preset="back"
          icon="arrow-left" />
      </template>
    </sub-header>

    <form @submit.prevent="onSubmit()">
      <add-grouped-discounts @updateForm="updateForm" />
      <add-unique-discounts @updateForm="updateForm" />
      <sm-card :header-title="$t('customers.discounts_page.active_discounts.title')">
        <table
          class="sm-table">
          <thead>
            <tr>
              <th>{{ $t('customers.discounts_page.active_discounts.table.category') }}</th>
              <th>{{ $t('customers.discounts_page.active_discounts.table.name') }}</th>
              <th>{{ $t('customers.discounts_page.active_discounts.table.percentage') }}</th>
              <th>{{ $t('customers.discounts_page.active_discounts.table.customer_group') }}</th>
              <th>{{ $t('customers.discounts_page.active_discounts.table.created_at') }}</th>
            </tr>
          </thead>
          <tbody v-if="$page.props.activeDiscounts.length !== 0">
            <tr
              v-for="discount in $page.props.activeDiscounts"
              :key="discount.id">
              <td>
                {{ discountCategories.find(data => data.id === discount.discountableType).label }}
              </td>
              <td>
                {{ discount.name }}
              </td>
              <td>
                {{ discount.percentage }}%
              </td>
              <td>
                <template v-if="discount.customerGroup">
                  <a :href="discount.customerGroup.routes.show">
                    {{ discount.customerGroup.name }}
                  </a>
                </template>
              </td>
              <td>
                {{ discount.createdAt ?? '-' }}
              </td>
            </tr>
          </tbody>
          <empty-table
            v-else
            colspan="4" />
        </table>
      </sm-card>

      <sm-card>
        <sm-button
          preset="save"
          :loading="form.processing"
          @click="onSubmit" />
      </sm-card>
    </form>
  </div>
</template>

<script setup>
import { useDateFormat, useFormatSelect } from "@/inertia/composables/global";
import { useForm, usePage } from "@inertiajs/vue3";
import AddGroupedDiscounts from "./_partials/AddGroupedDiscounts.vue";
import AddUniqueDiscounts from "./_partials/AddUniqueDiscounts.vue";

const page = usePage();

const discountCategories = ref(useFormatSelect(page.props.discountCategories));

const form = useForm({
  discountGroups: [],
  discounts: [],
});

const updateForm = (value, data) => {
  form[value] = data;
};

const onSubmit = () => {
  form
    .transform((data) => ({
      discountGroups: data.discountGroups.map(d => ({
        ...d,
        end_date: d.end_date ? useDateFormat(d.end_date, "form") : null,
      })),
      discounts: data.discounts.map(d => ({
        ...d,
        end_date: d.end_date ? useDateFormat(d.end_date, "form") : null,
      })),
    }))
    .post(page.url, {
      only: ["activeDiscounts", "errors", "flash"],
    });
};
</script>
