<script>
import debounce from "lodash/debounce";
import axios from "axios";
import Group from "../components/PermissionEditor/Group.vue";
import { useToast } from "vue-toastification";

export default {
  components: {"permission-group": Group},
  props: {
    endpoint: { type: String, default: "" },
    updateRoleEndpoint: { type: String, default: "" },
  },
  data() {
    return {
      roles: [],
      abilities: [],
      searchString: null,
      changedAbilities: [],
    };
  },
  async mounted() {
    const toast = useToast();
    try {
      const response = await axios.get(this.endpoint);
      this.abilities = response.data.data.abilities;
      this.roles = response.data.data.roles;
    } catch (e) {
      toast.error(e.response ? e.response.data.message : e.message);
    }

    this.dispatchSearchStringUpdate = debounce(event => {
      this.searchString = event.target.value;
    }, 200);

    this.persistRoleAbilities = debounce(async role => {
      try {
        await axios.post(this.updateRoleEndpoint, {
          role: role.id,
          abilities: role.abilities
        });

        toast.success(this.$t(`Sikeresen frissítettük ${role.name} jogosultságait.`));
      } catch (e) {
        toast.error(e.response ? e.response.data.message : e.message);
      }
    }, 500);
  },
  methods: {
    updateSearchString(event) {
      this.dispatchSearchStringUpdate(event);
    },
    roleChanged(role) {
      this.persistRoleAbilities(role);
    }
  }
};
</script>
