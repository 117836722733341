<script>
import debounce from "lodash/debounce";
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
  props: {
    endpoint: { type: String, default: "" },
  },
  data() {
    return {
      customers: [],
      currentPage: 1,
      pagination: {},
      search: null,
      searchCallback: debounce(() => {
        this.loadData();
      }, 250),
      loading: false,
    };
  },
  watch: {
    search() {
      this.searchCallback();
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData(page) {
      this.loading = true;

      page = page || 1;
      try {
        let url = `${this.endpoint}?page=${page}`;
        if (this.search && this.search.length > 0) {
          url += `&search=${encodeURIComponent(this.search)}`;
        }

        const response = await axios.get(url);
        this.loading = false;
        this.customers = response.data.data;
        this.pagination = response.data.meta;
      } catch (errors) {
        const toast = useToast();
        toast.error(errors.message);
        console.error(errors);
      } finally {
        this.loading = false;
      }
    },
  }
};
</script>
