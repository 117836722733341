<template>
  <settings-layout>
    <in-head :title="$t('parking_system.form.index')" />
    <sm-card
      :form="form"
      :header-title="$t('parking_system.form.index')"
      @onSubmit="onSubmit">
      <sm-select
        v-model="form.service"
        :options="serviceOptions"
        name="service"
        :label="$t('parking_system.form.service')"
        clearable
      />
      <sm-input
        v-model="form.host"
        name="host"
        :label="$t('parking_system.form.host')"
      />
      <sm-input
        v-model="form.username"
        name="username"
        :label="$t('parking_system.form.username')"
      />
      <sm-input
        v-model="form.password"
        name="password"
        :label="$t('parking_system.form.password')"
      />
      <sm-input
        v-model="form.customer_override"
        name="customer_override"
        :label="$t('parking_system.form.customer_override.label')"
        :help="$t('parking_system.form.customer_override.help')"
      />
      <template #footer>
        <sm-button
          preset="save"
          :loading="form.processing"
          @click="onSubmit" />
      </template>
    </sm-card>
  </settings-layout>
</template>

<script setup>
import { useFormatSelect } from "@/inertia/composables/global";
import { useForm, usePage } from "@inertiajs/vue3";
import { provide } from "vue";

const props = defineProps({
  settings: { type: Object, required: true, default:  null },
  serviceOptions: { type: Array, required: true, default: () => [] },
});

const serviceOptions = ref(useFormatSelect(props.serviceOptions));

// default variables
const page = usePage();

const form = useForm({
  host: props.settings.host,
  service: props.settings.service,
  username: props.settings.username,
  password: props.settings.password,
  customer_override: props.settings.customer_override,
});

const onSubmit = () => {
  form
    .transform((data) => {
      return {
        ...data,
        service: data.service?.id
      };
    })
    .post(page.url);
};

provide("form", form);
</script>
