<template>
  <svg
    class="max-w-[100vw]"
    width="454"
    height="398"
    viewBox="0 0 454 398"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3205_26097)">
      <path
        d="M106.83 379.732C102.245 376.873 100.845 370.839 103.703 366.254C106.561 361.669 112.595 360.269 117.18 363.127C121.765 365.986 123.165 372.02 120.307 376.605C117.449 381.19 111.415 382.59 106.83 379.732Z"
        stroke="#E6F1FF"
        stroke-width="4.89149"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M81.9256 364.2C77.3405 361.342 75.9406 355.308 78.7988 350.723C81.6571 346.138 87.691 344.738 92.2761 347.596C96.8612 350.454 98.2611 356.488 95.4029 361.073C92.5447 365.659 86.5107 367.058 81.9256 364.2Z"
        stroke="#E6F1FF"
        stroke-width="4.89149"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M112.006 371.433L116.911 354.317L101.345 344.613"
        stroke="#E6F1FF"
        stroke-width="4.89149"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M124.943 350.676L118.717 346.794"
        stroke="#E6F1FF"
        stroke-width="4.89149"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M87.099 355.907L106.264 339.033L97.9617 333.858"
        stroke="#E6F1FF"
        stroke-width="4.89149"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M97.9609 333.854L96.9232 333.207C96.0975 332.692 95.1012 332.526 94.1534 332.746C93.2056 332.966 92.3839 333.553 91.8692 334.379C91.3545 335.205 91.1889 336.201 91.4088 337.149C91.6287 338.097 92.2161 338.918 93.0417 339.433L94.0795 340.08"
        stroke="#E6F1FF"
        stroke-width="4.89149"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </g>
    <circle
      cx="207.5"
      cy="209.5"
      r="134.5"
      fill="#0173FF"
      fill-opacity="0.1" />
    <circle
      cx="371.802"
      cy="209.802"
      r="10.164"
      transform="rotate(28.7881 371.802 209.802)"
      fill="#E6F1FF" />
    <circle
      cx="344.78"
      cy="73.7834"
      r="6.468"
      transform="rotate(28.7881 344.78 73.7834)"
      fill="#E6F1FF" />
    <circle
      cx="14.4508"
      cy="184.448"
      r="10.6395"
      transform="rotate(28.7881 14.4508 184.448)"
      fill="#E6F1FF" />
    <circle
      cx="282.403"
      cy="369.402"
      r="9.13236"
      transform="rotate(28.7881 282.403 369.402)"
      fill="#E6F1FF" />
    <path
      d="M396.43 116.288C389.807 121.982 390.194 133.339 397.323 141.632C401.216 146.16 407.008 149.032 411.188 149.4C415.342 149.767 421.477 149.489 425.394 154.045L435.711 166.046L439.73 162.591L429.413 150.59C425.496 146.034 426.692 140.01 426.953 135.848C427.214 131.686 425.245 125.503 421.352 120.974C414.221 112.708 403.053 110.595 396.43 116.288ZM418.789 142.325C414.458 146.048 406.448 144.173 401.314 138.201C396.18 132.229 395.527 124.054 399.859 120.305C404.189 116.583 412.199 118.458 417.333 124.429C422.467 130.401 423.121 138.576 418.789 142.325ZM434.314 123.17C434.42 121.775 435.073 120.475 436.147 119.576C438.355 117.679 441.697 117.931 443.595 120.138C444.495 121.186 444.946 122.569 444.841 123.964C444.736 125.359 444.082 126.659 443.008 127.558C440.801 129.456 437.459 129.203 435.561 126.996C434.66 125.949 434.209 124.565 434.314 123.17ZM429.051 122.773C428.889 124.857 429.351 126.942 430.376 128.763C431.401 130.585 432.944 132.061 434.81 133.004C436.675 133.947 438.779 134.315 440.854 134.061C442.928 133.807 444.881 132.942 446.463 131.577C448.671 129.679 449.902 127.046 450.105 124.361C450.266 122.277 449.805 120.192 448.779 118.371C447.754 116.549 446.211 115.073 444.346 114.13C442.48 113.187 440.377 112.819 438.302 113.073C436.227 113.327 434.275 114.192 432.692 115.557C431.641 116.462 430.778 117.565 430.153 118.803C429.528 120.041 429.154 121.39 429.051 122.773Z"
      fill="#E6F1FF" />
    <path
      d="M40.7901 85.4863C43.701 90.5282 48.3815 94.1281 53.9757 95.627C59.5699 97.126 65.4254 96.3492 70.4651 93.4378C80.8389 87.4485 84.4058 74.1364 78.4171 63.7606C75.5077 58.7214 70.8236 55.1183 65.2315 53.6199C59.6393 52.1215 53.7813 52.8998 48.7415 55.8113C38.3683 61.7985 34.8008 75.1126 40.7901 85.4863ZM64.7464 58.0239C68.2171 59.0891 71.2614 61.2259 73.4428 64.128L60.3983 71.6574L58.1556 67.7729C61.4234 65.3222 63.7758 61.8391 64.7464 58.0239ZM55.959 63.9682L52.8683 58.6151C55.2667 57.5925 57.8658 57.1278 60.4699 57.2559C59.7435 59.841 58.1434 62.2033 55.959 63.9682ZM52.1815 66.1137C49.6527 67.0013 46.9133 67.0965 44.329 66.3868C45.4997 64.1984 47.1299 62.2891 49.1078 60.7898L52.1815 66.1137ZM42.7574 70.439C42.9178 70.4865 43.0727 70.555 43.2342 70.5983C46.9236 71.5994 50.8393 71.3642 54.3822 69.9285L56.6362 73.8295L43.5965 81.358C42.1431 77.9049 41.8487 74.0736 42.7574 70.439ZM67.0113 83.1114C69.624 82.1067 72.4635 81.9005 75.0504 82.5532C73.8668 84.8706 72.1747 86.8906 70.1004 88.4619L67.0113 83.1114ZM66.3383 90.634C64.0498 91.6061 61.5782 92.0716 59.0929 91.9986C59.7654 89.394 61.221 87.0585 63.263 85.3074L66.3383 90.634ZM54.8062 91.3358C51.1947 90.3063 48.0192 88.1237 45.7638 85.121L58.8083 77.5916L61.0556 81.4841C57.9292 83.894 55.7381 87.4046 54.8062 91.3358ZM76.5256 78.4661C72.744 77.4124 68.5593 77.7035 64.8131 79.304L62.5704 75.4195L75.6101 67.8911C77.0152 71.2332 77.3355 74.9322 76.5256 78.4661Z"
      fill="#E6F1FF" />
    <g clip-path="url(#clip1_3205_26097)">
      <path
        d="M184.083 191.896C188.326 191.963 192.921 191.946 196.358 189.447C194.43 188.374 192.132 188.24 189.936 188.123C177.661 187.502 165.386 186.982 153.11 187.083C150.411 187.1 147.66 187.167 145.061 187.905C143.954 188.223 142.898 188.659 141.842 189.095C140.936 189.464 139.645 190.185 140.232 191.393C140.701 192.332 141.825 192.114 142.697 192.097C145.984 192.03 149.287 191.946 152.574 191.896C159.298 191.795 166.006 191.745 172.73 191.778C176.504 191.795 180.293 191.829 184.083 191.896Z"
        fill="url(#paint0_linear_3205_26097)" />
      <path
        d="M184.081 191.896C188.324 191.963 192.919 191.946 196.356 189.447C194.428 188.374 192.131 188.24 189.934 188.123C177.659 187.502 165.384 186.982 153.108 187.083C150.409 187.1 147.658 187.167 145.059 187.905C143.952 188.223 142.896 188.659 141.84 189.095C140.934 189.464 139.643 190.185 140.23 191.393C140.699 192.332 141.823 192.114 142.695 192.097C145.982 192.03 149.285 191.946 152.572 191.896C159.296 191.795 166.004 191.745 172.728 191.778C176.502 191.795 180.291 191.829 184.081 191.896Z"
        fill="url(#paint1_linear_3205_26097)" />
      <path
        d="M146.233 82.5453C147.39 86.0165 147.457 89.8902 146.4 93.3782C146.3 93.7136 146.182 94.0657 146.233 94.4011C146.317 95.0719 146.92 95.5247 147.49 95.8433C150.375 97.4028 153.745 97.5537 156.982 97.5705C162.197 97.5873 167.412 97.2854 172.594 96.665C173.181 95.9439 172.795 94.8371 172.51 93.9148C171.688 91.1479 172.309 88.163 172.594 85.2787C172.627 84.9433 172.644 84.5911 172.51 84.3061C172.376 84.0378 172.124 83.8701 171.873 83.7024C168.217 81.405 163.958 80.1641 159.698 80.1641C157.971 80.1641 156.244 80.3653 154.517 80.6336C151.716 81.0864 148.949 81.7236 146.233 82.5453Z"
        fill="#A08B75" />
      <path
        d="M137.209 36.6288C137.863 37.7859 138.5 38.943 139.003 40.1839C139.204 40.7038 139.925 40.3684 139.774 39.8653C139.405 38.5908 139.036 37.2996 138.567 36.0587C138.215 35.1531 136.739 35.8239 137.209 36.6288Z"
        fill="#666666" />
      <path
        d="M132.688 48.3473C133.258 48.23 133.761 47.9281 134.281 47.6766C134.817 47.4083 135.488 47.1735 135.924 46.7375C136.142 46.5195 136.125 46.0332 135.723 46.0164C135.136 45.9997 134.566 46.2512 133.996 46.4357C133.375 46.6369 132.721 46.7878 132.168 47.14C131.581 47.4921 132.017 48.4983 132.688 48.3473Z"
        fill="#666666" />
      <path
        d="M132.146 41.8137C132.767 42.1994 133.454 42.4342 134.125 42.6857C134.729 42.9037 135.333 43.172 135.987 43.1552C136.288 43.1552 136.439 42.7192 136.255 42.5012C135.886 42.0652 135.383 41.8305 134.88 41.5622C134.226 41.21 133.555 40.8076 132.834 40.5896C132.13 40.3883 131.425 41.3609 132.146 41.8137Z"
        fill="#666666" />
      <path
        d="M158.15 38.5437C159.139 38.7785 160.146 38.8288 161.185 38.6443C166.87 37.6717 168.748 30.6789 168.95 26.3692C169.151 22.1266 168.95 17.7498 167.038 13.8761C165.545 10.8745 162.829 9.08014 159.173 8.96276C158.418 8.94599 157.681 9.0466 156.943 9.19753C148.206 10.9583 148.34 22.6968 149.43 29.0355C150.084 32.7415 153.706 37.4872 158.15 38.5437Z"
        fill="#A08B75" />
      <path
        d="M156.395 184.3C154.064 184.116 151.716 184.217 149.402 184.602C147.625 185.927 145.679 187.017 143.617 187.856C143.013 188.09 142.393 188.325 141.957 188.795C141.521 189.264 141.336 190.052 141.755 190.555C142.108 190.991 142.745 191.042 143.315 191.042C147.625 191.092 151.934 190.723 156.177 189.952C156.429 189.901 156.697 189.851 156.881 189.667C157.032 189.516 157.099 189.314 157.15 189.113C157.586 187.487 157.301 185.692 156.395 184.3Z"
        fill="#333333" />
      <path
        d="M176.301 182.82C174.205 183.055 172.159 183.558 170.18 184.296C168.838 185.772 167.312 187.08 165.652 188.17C165.166 188.488 164.646 188.807 164.344 189.293C164.042 189.78 164.026 190.517 164.462 190.87C164.847 191.188 165.417 191.121 165.921 191.02C169.727 190.316 173.467 189.226 177.072 187.818C177.29 187.734 177.525 187.633 177.642 187.449C177.743 187.298 177.776 187.096 177.776 186.912C177.894 185.436 177.34 183.91 176.301 182.82Z"
        fill="#333333" />
      <path
        d="M169.089 15.3279C167.546 11.2362 163.27 8.51958 158.91 8.2345C157.787 8.16743 156.646 8.23451 155.573 8.55312C152.605 9.42512 150.408 12.0914 149.318 14.9925C148.228 17.8936 148.061 21.0462 147.91 24.1485C147.893 24.5845 147.876 25.0205 148.077 25.4062C148.279 25.7919 148.782 26.0434 149.151 25.8422C149.637 22.9747 150.14 20.0568 151.498 17.4911C152.857 14.9254 155.255 12.7287 158.139 12.3597C160.788 12.0244 163.455 13.3324 165.266 15.3111C167.077 17.2899 167.848 19.4028 168.066 21.9182C168.2 23.4945 168.938 25.49 169.659 22.7399C170.23 20.4928 169.894 17.4576 169.089 15.3279Z"
        fill="#FBAE17" />
      <path
        d="M164.543 8.22119C163.655 8.72427 162.598 8.77458 161.575 8.75781C159.663 8.72427 157.769 8.55658 155.89 8.22119C154.901 8.0535 153.878 7.81873 153.04 7.28211C152.184 6.7455 151.514 5.82319 151.514 4.81704C151.514 3.87796 152.084 3.02273 152.771 2.36873C154.549 0.658266 157.115 -0.0628108 159.58 0.00426613C161.022 0.0378046 162.498 0.339653 163.738 1.09427C164.979 1.84888 165.969 3.07304 166.204 4.49842C166.422 5.9238 165.801 7.50011 164.543 8.22119Z"
        fill="#FBAE17" />
      <path
        d="M174.425 142.443C173.854 129.715 175.062 116.736 174.944 103.941C175.33 99.4967 174.71 94.9858 173.15 90.8103C173.049 90.5587 172.949 90.2904 172.748 90.0892C172.144 89.4687 171.121 89.8041 170.333 90.1227C162.72 93.2586 153.731 92.5208 146.789 88.0938C145.414 90.173 145.028 92.7387 144.693 95.2206C144.139 99.2284 143.552 103.22 143.401 107.227V107.244C143.368 108.099 143.351 108.971 143.368 109.843C143.468 114.857 144.424 119.788 145.179 124.718C145.665 127.937 146.101 131.174 146.152 134.444C146.185 137.11 145.816 139.559 146.403 142.175C147.879 148.614 147.879 155.623 148.499 162.197C149.17 169.24 149.824 176.283 150.361 183.343C150.377 183.628 150.411 183.913 150.428 184.181C150.78 184.282 156.213 185.523 156.213 184.114C156.649 170.431 157.085 156.73 157.521 143.047C157.706 137.278 157.907 131.442 159.366 125.959C159.969 126.026 160.573 126.076 161.177 126.143C164.48 144.254 167.801 162.381 171.104 180.492C171.389 182.085 171.741 183.813 172.798 184.835C173.854 185.858 175.816 185.573 176.118 183.98C175.162 170.129 175.045 156.294 174.425 142.443Z"
        fill="#4D4D4D" />
      <path
        d="M151.228 38.4066C152.15 38.9097 153.123 39.3457 154.179 39.6811C158.305 40.9891 163.319 40.3854 166.74 38.1719C169.506 38.893 171.468 40.8046 172.475 42.9008C173.481 45.0137 173.665 47.2943 173.85 49.5414C174.655 60.0557 175.46 70.5868 176.265 81.1011C176.298 81.621 176.332 82.1743 176.047 82.6439C175.443 83.5997 173.799 83.801 172.424 83.8513C163.101 84.2537 153.727 84.086 144.42 83.3649C144.034 83.3314 143.632 83.2979 143.313 83.147C142.592 82.7948 142.491 82.0402 142.474 81.3862C142.256 71.3917 142.911 61.3805 144.453 51.4699C145.023 47.831 145.728 44.1417 147.723 40.8382C148.126 40.1674 148.629 39.4799 149.434 39.0606C150.222 38.6414 150.44 37.9706 151.228 38.4066Z"
        fill="#AFD9C4" />
      <path
        d="M160.907 45.6135C154.333 45.2446 147.709 44.8756 141.203 45.8483C140.834 52.8746 140.884 59.9344 141.371 66.9607C151.231 66.0216 161.594 66.0384 171.488 66.0552C171.488 59.75 171.002 52.4889 171.002 46.2004C167.631 45.9824 164.277 45.798 160.907 45.6135Z"
        fill="#B3B3B3" />
      <path
        d="M142.441 70.4199C141.854 71.3757 141.267 72.1974 140.697 72.5999C139.959 73.103 138.869 72.9688 138.282 72.2981C136.722 70.5205 139.54 64.9196 140.244 63.1085C141.535 59.7715 143.078 56.5518 144.621 53.3321C145.174 52.1918 146.063 50.8502 147.321 51.0514C147.639 51.1018 147.924 51.2527 148.176 51.4539C149.618 52.5942 149.45 54.8245 148.981 56.6021C148.126 59.8888 146.75 63.0414 144.923 65.909C144.285 66.9319 143.38 68.8604 142.441 70.4199Z"
        fill="#A08B75" />
      <path
        d="M185.804 56.5856C185.788 56.1664 185.637 55.7639 185.469 55.3783C183.624 51.035 180.807 47.1278 177.286 43.9752C175.911 42.751 174.016 41.5772 172.339 42.3318C172.054 42.4659 171.785 42.6504 171.584 42.9019C170.83 43.9249 171.601 45.3335 172.372 46.3396C174.955 49.7606 177.571 53.1815 180.505 56.2838C180.204 56.5353 179.986 56.9043 179.784 57.2396C177.47 61.2139 174.502 64.8026 172.674 69.0116C172.372 69.7159 172.104 70.4538 172.205 71.2084C172.322 71.963 172.943 72.6841 173.697 72.6506C174.318 72.617 174.787 72.1139 175.206 71.6444C177.051 69.5482 178.879 67.4521 180.723 65.3727C182.451 63.4107 184.211 61.3313 184.882 58.8159C185.318 58.1116 185.838 57.4241 185.804 56.5856Z"
        fill="#A08B75" />
      <path
        d="M174.233 21.2804C173.797 20.4923 173.311 19.6874 172.573 19.1507C172.372 18.9998 172.137 18.8824 171.902 18.7818C172.154 15.646 170.527 12.5604 168.951 9.75997C168.364 8.72028 167.76 7.64705 166.905 6.80859C165.027 4.93043 162.193 4.36028 159.543 4.22612C156.089 4.05843 152.483 4.54474 149.599 6.43966C148.157 7.39551 146.899 8.75382 146.43 10.414C146.161 11.3866 146.161 12.4095 146.161 13.3989C146.161 15.0926 146.178 16.7695 146.178 18.4632C145.507 18.9998 145.038 19.7377 144.619 20.509C143.847 21.9512 143.193 23.5107 143.126 25.1374C143.059 26.764 143.663 28.508 144.971 29.4638C145.507 29.8495 146.128 30.101 146.748 30.319C147.654 30.6209 148.71 30.8054 149.498 30.2687C150.471 29.598 150.505 28.2061 150.421 27.0323C150.303 25.3386 150.136 23.6449 150.136 21.9344C150.136 21.2134 150.169 20.4755 150.002 19.7712C149.834 19.0669 149.448 18.3794 148.811 18.044C148.358 17.8092 147.855 17.7757 147.352 17.8763C147.251 16.3503 147.369 14.8243 147.889 13.3989C148.979 10.414 151.846 8.23397 154.965 7.61351C158.084 7.00982 161.405 7.88182 164.037 9.67612C167.123 11.7723 169.32 15.1429 170.041 18.7986C170.041 18.7986 170.024 18.7986 170.007 18.8154C169.37 19.1507 168.984 19.8383 168.817 20.5426C168.649 21.2469 168.682 21.9847 168.682 22.7058C168.682 24.3995 168.515 26.11 168.397 27.8037C168.314 28.9775 168.347 30.3694 169.32 31.0401C170.108 31.5767 171.164 31.3923 172.07 31.0904C172.69 30.8892 173.328 30.6377 173.847 30.2352C175.172 29.2626 175.759 27.5354 175.692 25.9087C175.658 24.2654 175.004 22.7058 174.233 21.2804Z"
        fill="#1A5E73" />
    </g>
    <path
      d="M244.089 209.085C248.031 209.199 251.973 209.065 255.915 208.912C266.919 208.509 277.903 208.105 288.907 207.702C290.61 207.645 292.83 207.146 292.964 205.495C293.079 204.018 291.356 203.096 289.883 202.674C284.639 201.177 279.109 200.928 273.636 200.755C265.599 200.466 257.542 200.256 249.505 200.121C244.472 200.026 239.324 199.987 234.616 201.696C232.052 202.616 228.244 206.454 232.301 208.24C233.43 208.739 235.707 208.47 236.913 208.586C239.305 208.816 241.697 209.008 244.089 209.085Z"
      fill="url(#paint2_linear_3205_26097)" />
    <path
      d="M244.081 209.085C248.023 209.199 251.965 209.065 255.908 208.912C266.911 208.509 277.896 208.105 288.899 207.702C290.602 207.645 292.822 207.146 292.956 205.495C293.071 204.018 291.349 203.096 289.875 202.674C284.632 201.177 279.101 200.928 273.628 200.755C265.591 200.466 257.534 200.256 249.497 200.121C244.464 200.026 239.316 199.987 234.608 201.696C232.044 202.616 228.236 206.454 232.293 208.24C233.422 208.739 235.699 208.47 236.905 208.586C239.297 208.816 241.689 209.008 244.081 209.085Z"
      fill="url(#paint3_linear_3205_26097)" />
    <path
      d="M206.423 62.471C206.041 61.4922 205.658 60.5326 205.294 59.5538C205.141 59.1508 204.95 58.7094 204.586 58.4599C204.357 58.3063 204.05 58.2295 203.878 58.0184C203.687 57.788 203.706 57.4619 203.668 57.1548C203.61 56.3103 203.189 55.5042 202.634 54.8708C201.945 54.084 200.855 53.5082 199.859 53.796C199.304 53.9688 198.845 54.3718 198.462 54.8132C197.372 56.0608 196.644 57.8265 197.18 59.4194C197.467 60.264 198.061 60.9549 198.635 61.6457C199.228 62.3751 199.783 63.1236 200.319 63.8914C200.587 64.2752 200.835 64.659 201.084 65.0429C201.237 65.2924 201.524 66.0409 201.792 66.1752C202.271 66.4247 203.629 65.6762 204.069 65.4651C204.777 65.1004 205.428 64.6399 206.021 64.0832C206.308 63.8146 206.596 63.469 206.615 63.0661C206.596 62.8934 206.5 62.6822 206.423 62.471Z"
      fill="#F9E9DE" />
    <path
      d="M276.927 70.5483C279.396 76.2292 281.673 82.0062 283.548 87.8984C284.448 90.7196 285.251 93.6561 284.945 96.6118C284.639 99.5482 282.936 102.542 280.161 103.54C279.032 103.963 277.807 104.02 276.583 104.001C274.21 103.982 271.741 103.617 269.77 102.293C266.708 100.258 265.445 96.3815 264.833 92.7541C263.684 85.9983 263.971 79.089 263.818 72.2181C263.684 66.0381 263.149 59.8581 262.268 53.7165C262.02 51.9508 262.268 47.6516 264.909 49.4173C266.708 50.6264 268.354 54.1195 269.502 55.9428C272.392 60.6066 274.745 65.5583 276.927 70.5483Z"
      fill="#65627D" />
    <path
      d="M273.619 57.5004C275.399 60.8208 276.605 64.4865 279.073 67.3271C279.8 68.1524 280.662 68.9776 280.834 70.0716C281.083 71.6453 279.399 73.2 277.848 72.8352C276.107 72.413 275.418 70.3787 274.901 68.6705C273.237 63.239 270.423 58.153 266.692 53.8731C264.395 51.2437 265.735 49.9194 268.625 51.7043C270.806 53.0669 272.414 55.2741 273.619 57.5004Z"
      fill="#5B5870" />
    <path
      d="M282.768 76.207C281.639 74.6908 280.682 73.0595 279.859 71.3513C279.648 70.8907 278.883 70.8907 278.653 71.3513C278.213 72.2534 278.577 72.8483 278.998 73.5392C279.227 74.614 279.648 75.6313 280.222 76.5908C280.758 77.4929 281.677 79.0668 282.959 78.6061C284.413 78.0879 283.284 76.9171 282.768 76.207Z"
      fill="#5B5870" />
    <path
      d="M232.294 138.605C231.701 142.041 231.051 145.514 231.338 148.969C231.548 151.484 232.237 153.921 232.964 156.319C236.428 167.855 240.734 179.12 245.671 190.079C245.958 190.713 246.283 191.385 246.877 191.768C248.101 192.536 249.805 191.462 250.245 190.079C250.685 188.698 250.264 187.201 249.862 185.8C246.991 175.857 244.963 165.686 242.934 155.533C242.513 153.384 242.073 151.195 242.112 149.007C242.15 146.455 242.8 143.94 243.489 141.484C245.767 133.192 248.331 124.998 251.144 116.879C252.024 114.365 252.905 111.467 251.622 109.125C250.34 106.784 247.393 105.978 244.733 105.709C243.068 105.536 241.404 105.498 239.739 105.575C236.696 105.728 235.854 106.803 235.777 109.548C235.682 112.83 235.261 116.131 234.916 119.393C234.265 125.823 233.385 132.233 232.294 138.605Z"
      fill="#F9E9DE" />
    <path
      d="M255.759 168.762C256.084 176.765 256.41 184.768 257.118 192.752C258.515 192.925 259.912 193.098 261.289 193.27C261.787 176.497 262.38 159.684 264.504 143.044C265.595 134.427 267.126 125.847 267.949 117.211C268.274 113.871 268.274 109.975 265.729 107.787C264.619 106.827 263.146 106.348 261.672 106.194C258.974 105.887 253.903 106.981 253.233 110.224C252.888 111.875 253.539 114.389 253.596 116.097C253.692 118.497 253.788 120.896 253.884 123.314C254.285 132.987 254.668 142.679 255.07 152.352C255.319 157.822 255.548 163.292 255.759 168.762Z"
      fill="#F9E9DE" />
    <path
      d="M257.592 205.689C259.008 205.748 260.386 205.402 261.744 205.019C262.07 204.923 262.433 204.807 262.605 204.5C262.72 204.308 262.72 204.078 262.72 203.848C262.72 203.559 262.739 203.253 262.739 202.964C262.739 202.888 262.739 202.791 262.682 202.715C262.605 202.619 262.452 202.638 262.318 202.677C260.194 203.214 257.974 203.367 255.793 203.118C255.295 203.06 254.798 202.984 254.396 202.677C254.319 202.619 254.224 202.542 254.128 202.581C254.051 202.6 254.013 202.695 253.994 202.773C253.879 203.137 253.649 203.789 253.822 204.155C253.975 204.5 254.568 204.807 254.874 204.98C255.716 205.44 256.673 205.671 257.592 205.689Z"
      fill="#F7F7F7" />
    <path
      d="M256.103 191.118C255.931 190.485 255.797 189.717 256.276 189.295C256.563 189.045 256.965 189.008 257.347 189.008C258.553 188.988 259.739 189.141 260.907 189.448C261.079 189.507 261.27 189.564 261.385 189.698C261.519 189.852 261.538 190.082 261.557 190.293C261.634 191.713 261.557 193.133 261.309 194.534C261.27 194.727 261.232 194.937 261.117 195.11C260.888 195.456 260.428 195.552 260.026 195.609C259.05 195.744 257.902 196.051 257.252 195.206C256.563 194.285 256.41 192.212 256.103 191.118Z"
      fill="#EBEBEB" />
    <path
      d="M255.281 198.739C255.721 198.24 256.276 197.855 256.85 197.529C257.558 197.106 258.324 196.742 259.127 196.723C260.276 196.704 261.347 197.452 261.979 198.431C262.591 199.409 262.84 200.58 262.916 201.731C262.955 202.154 262.955 202.615 262.744 202.979C262.457 203.497 261.826 203.747 261.251 203.882C258.993 204.419 256.563 204.189 254.439 203.228C252.965 202.576 254.515 199.601 255.281 198.739Z"
      fill="#EBEBEB" />
    <path
      d="M261.861 193.402C262.206 193.94 262.263 194.592 262.301 195.225C262.455 197.567 262.608 199.909 262.742 202.231C262.742 202.327 262.761 202.423 262.703 202.5C262.627 202.595 262.474 202.614 262.34 202.614C260.311 202.557 258.283 202.48 256.254 202.346C255.718 202.308 255.049 202.211 254.857 201.693C254.723 201.367 254.857 201.003 254.953 200.657C255.508 198.911 255.661 197.068 256.235 195.321C256.484 194.573 256.828 193.824 257.422 193.306C258.378 192.538 261.077 192.212 261.861 193.402Z"
      fill="#EBEBEB" />
    <path
      d="M240.581 202.654C240.926 202.998 241.366 203.267 241.844 203.306C242.17 203.325 242.495 203.229 242.82 203.153C243.815 202.864 244.811 202.595 245.767 202.231C248.638 201.137 251.164 199.314 253.594 197.432C253.767 197.298 253.92 197.183 254.034 196.992C254.379 196.473 254.168 195.801 253.958 195.206C253.9 195.053 253.824 194.899 253.69 194.823C253.537 194.746 253.345 194.823 253.192 194.899C252.408 195.302 251.795 195.935 251.126 196.511C248.408 198.815 244.925 199.986 241.538 201.098C241.232 201.194 240.16 201.31 239.988 201.52C239.739 201.789 240.352 202.422 240.581 202.654Z"
      fill="#F7F7F7" />
    <path
      d="M246.632 192.482C245.866 191.407 245.139 190.295 244.469 189.162C244.412 189.047 244.335 188.932 244.374 188.816C244.393 188.72 244.488 188.643 244.565 188.586C245.388 188.01 246.249 187.415 247.263 187.338C247.589 187.319 247.933 187.358 248.201 187.568C248.45 187.761 248.565 188.087 248.66 188.375C249.024 189.373 249.368 190.39 249.445 191.446C249.464 191.772 249.445 192.156 249.215 192.386C249.119 192.482 249.005 192.54 248.89 192.597C248.526 192.77 247.895 193.23 247.493 193.25C246.976 193.25 246.957 192.924 246.632 192.482Z"
      fill="#EBEBEB" />
    <path
      d="M250.225 188.812C250.32 188.697 250.416 188.563 250.55 188.506C250.818 188.39 251.143 188.563 251.315 188.793C251.488 189.042 251.545 189.331 251.622 189.618C251.928 190.636 252.617 191.48 253.152 192.402C253.688 193.323 254.09 194.435 253.746 195.433C253.401 196.431 252.425 197.026 251.507 197.545C250.148 198.331 248.808 199.099 247.45 199.887C246.282 200.558 245.115 201.23 243.833 201.671C242.972 201.979 242.053 202.17 241.154 202.055C240.254 201.94 239.355 201.48 238.877 200.712C238.379 199.924 238.341 198.946 238.417 198.025C238.494 197.142 238.647 196.239 239.202 195.549C239.853 194.724 240.943 194.359 241.996 194.263C243.048 194.166 244.101 194.301 245.173 194.263C245.421 194.244 245.67 194.225 245.881 194.109C246.091 193.994 246.244 193.802 246.378 193.61C247.584 192.075 249.344 190.578 250.225 188.812Z"
      fill="#EBEBEB" />
    <path
      d="M260.563 197.913C259.817 197.894 259.166 197.491 258.42 197.53C257.846 197.568 257.253 197.741 256.793 198.106C256.679 198.202 256.813 198.412 256.947 198.375C258.076 197.933 259.339 197.472 260.334 198.47C260.755 198.911 261.176 197.933 260.563 197.913Z"
      fill="#B2B2B2" />
    <path
      d="M260.562 196.604C259.491 196.047 258.247 195.874 257.194 196.565C256.984 196.699 257.156 197.027 257.405 196.911C258.361 196.431 259.452 196.393 260.409 196.892C260.581 196.988 260.754 196.699 260.562 196.604Z"
      fill="#B2B2B2" />
    <path
      d="M260.886 194.631C259.737 194.132 258.494 194.19 257.46 194.939C257.307 195.053 257.441 195.265 257.594 195.188C258.57 194.689 259.699 194.573 260.733 194.976C260.943 195.035 261.077 194.708 260.886 194.631Z"
      fill="#B2B2B2" />
    <path
      d="M244.242 194.208C244.069 194.075 243.897 194.363 244.069 194.497C244.797 195.053 244.969 196.051 245.466 196.762C245.6 196.953 245.849 196.819 245.811 196.607C245.658 195.591 245.026 194.823 244.242 194.208Z"
      fill="#B2B2B2" />
    <path
      d="M245.577 193.916C245.481 193.897 245.366 194.032 245.462 194.108C245.998 194.549 246.476 195.24 246.744 195.893C246.84 196.142 247.222 196.046 247.146 195.778C246.878 194.991 246.495 194.069 245.577 193.916Z"
      fill="#B2B2B2" />
    <path
      d="M249.113 193.725C248.366 193.13 247.448 192.957 246.567 193.341C246.395 193.417 246.491 193.686 246.663 193.686C247.409 193.686 248.06 193.879 248.692 194.282C249.017 194.474 249.419 193.975 249.113 193.725Z"
      fill="#B2B2B2" />
    <path
      d="M253.844 105.632C252.351 103.175 249.519 101.755 246.687 101.314C243.854 100.872 240.946 101.295 238.113 101.813C236.678 102.062 234.63 102.485 233.98 104.001C233.463 105.191 233.846 107.071 233.731 108.338C233.31 112.656 232.143 116.841 231.128 121.044C230.535 123.462 229.98 125.88 229.636 128.337C229.062 132.271 229.023 136.282 229.023 140.255C229.023 147.817 229.253 155.551 231.875 162.653C233.884 168.085 237.195 172.883 240.563 177.604C239.357 178.295 238.994 179.005 240.123 180.407C240.486 180.847 241.041 181.232 241.73 181.289C242.228 181.327 242.725 181.212 243.204 181.077C245.5 180.464 247.701 179.639 249.787 178.621C250.055 178.487 250.342 178.333 250.418 178.103C250.476 177.949 250.418 177.796 250.38 177.643C250.131 176.914 249.71 176.222 249.155 175.627C249.079 175.531 248.983 175.435 248.849 175.397C248.696 175.339 248.524 175.358 248.351 175.358C248.103 175.378 247.873 175.417 247.624 175.454C246.572 167.451 245.519 159.429 244.467 151.425C244.275 150.005 244.103 148.585 244.122 147.164C244.199 144.229 245.194 141.426 246.17 138.662C248.543 132.021 250.897 125.362 253.27 118.721C254.035 116.591 254.781 114.441 255.049 112.196C255.279 109.931 255.011 107.551 253.844 105.632Z"
      fill="#736357" />
    <path
      d="M268.367 130.2C269.649 121.928 270.95 113.598 270.605 105.249C270.586 104.75 270.548 104.232 270.395 103.752C270.184 103.138 269.763 102.601 269.266 102.179C267.984 101.104 266.223 100.739 264.558 100.854C262.893 100.969 261.286 101.507 259.698 102.044C257.822 102.678 255.966 103.292 254.09 103.925C252.808 104.367 250.493 104.635 249.479 105.595C247.814 107.169 249.249 111.429 249.326 113.445C249.613 120.757 248.522 128.107 249.383 135.401C249.976 140.41 251.469 145.285 252.043 150.313C252.292 152.368 252.368 154.459 252.56 156.513C252.693 158.01 252.904 159.488 253.095 160.965C253.631 164.88 254.148 168.796 254.684 172.692C254.263 172.731 254.11 172.903 254.071 173.536C254.033 174.246 254.071 175.149 254.722 175.456C254.933 175.552 255.162 175.571 255.392 175.59C257.114 175.705 258.836 175.839 260.559 175.955C261.095 175.994 261.649 176.031 262.185 176.012C262.338 176.012 262.491 175.992 262.606 175.916C262.74 175.821 262.798 175.629 262.836 175.456C262.989 174.727 262.97 173.978 262.779 173.267C262.74 173.134 262.702 173 262.587 172.903C262.53 172.845 262.453 172.827 262.377 172.807C262.664 172.232 262.779 171.521 262.855 170.849C264.156 157.242 266.261 143.712 268.367 130.2Z"
      fill="#736357" />
    <path
      d="M243.761 49.9361C243.148 48.7269 241.981 47.6138 240.642 47.7289C239.302 47.8441 238.383 49.0532 237.637 50.1856C232.432 58.1121 227.877 66.6911 220.835 73.0247C220.491 73.3318 220.089 73.6581 219.63 73.7157C219.189 73.754 218.768 73.5429 218.366 73.351C212.951 70.5105 208.645 66.2689 204.818 61.49C204.99 61.7011 201.526 64.7911 201.469 65.1941C201.354 66.0387 202.521 67.2286 203 67.8427C204.416 69.762 206.023 71.5277 207.726 73.1974C211.171 76.5561 215.018 79.4734 218.864 82.3522C220.184 83.3503 221.811 84.4058 223.399 83.8877C224.069 83.6573 224.605 83.1776 225.141 82.6978C231.456 76.9208 236.929 70.2226 241.388 62.9103C242.574 60.9526 243.723 58.899 244.297 56.6727C244.833 54.4464 244.813 51.9897 243.761 49.9361Z"
      fill="#F9E9DE" />
    <path
      d="M256.316 47.0249C258.249 46.5835 260.66 45.9501 261.885 47.5047C262.267 47.9845 262.421 48.5795 262.593 49.1744C264.411 56.0837 265.368 63.185 266.305 70.2862C267.473 79.1148 268.659 87.9626 269.826 96.7911C270.152 99.2478 270.401 102.031 268.85 103.969C267.874 105.197 266.363 105.831 264.87 106.253C262.612 106.886 260.258 107.155 257.904 107.289C250.173 107.731 242.384 106.925 234.921 104.871C234.519 104.756 234.117 104.641 233.811 104.391C233.275 103.969 233.084 103.24 232.969 102.568C232.433 99.4589 232.835 96.2729 233.237 93.1445C234.405 83.9704 235.572 74.7964 236.739 65.6225C237.064 63.0122 237.409 60.3829 238.155 57.8494C238.729 55.911 239.552 54.0493 239.916 52.0533C240.26 50.23 240.203 48.2724 240.815 46.5067C241.772 43.7621 242.652 44.7794 244.719 45.7582C248.298 47.4471 252.45 47.9269 256.316 47.0249Z"
      fill="#0173FF" />
    <path
      d="M246.197 44.0277C247.441 44.5458 248.628 44.8721 249.508 44.8914C250.522 44.9105 251.479 44.4116 252.378 43.9126C254.215 42.8953 256.167 41.7822 257.105 39.9013C257.66 38.8073 257.794 37.5406 257.909 36.3122C258.177 33.2223 258.311 30.1322 258.272 27.0231C258.253 25.4301 258.177 23.7412 257.316 22.4169C255.842 20.1714 252.78 19.7875 250.12 19.634C247.479 19.4804 244.685 19.3461 242.37 20.5936C239.92 21.8987 240.494 23.818 240.303 26.217C240.073 29.211 239.92 32.2243 239.901 35.2376C239.882 36.9456 239.94 38.7497 240.82 40.2084C241.604 41.571 243.958 43.1064 246.197 44.0277Z"
      fill="#F9E9DE" />
    <path
      d="M256.941 32.36C256.96 32.2258 256.96 32.0914 256.96 31.957C256.884 32.0722 256.865 32.2065 256.941 32.36Z"
      fill="#D7C68E" />
    <path
      d="M238.115 24.6808C238.115 25.0647 238.134 25.4678 238.287 25.8132C238.708 26.6961 239.818 26.7153 240.699 26.6577C244.698 26.3507 248.736 26.4466 252.736 26.9072C253.176 26.9648 253.635 27.0224 254.018 27.2719C254.362 27.5021 254.611 27.8668 254.841 28.2315C255.702 29.6325 256.352 31.2063 256.773 32.8377C256.927 32.6073 257.099 32.3963 257.252 32.1659C257.749 32.5305 257.635 33.3751 257.443 34.0084C257.099 35.1984 256.754 36.4074 256.391 37.5974C255.97 39.0368 255.549 40.5147 255.549 42.0309C255.549 43.5471 256.027 45.1401 257.118 46.0422C258.917 44.3532 260.716 42.645 262.514 40.9561C263.931 39.6126 265.423 38.1732 266.016 36.1964C266.514 34.5458 266.304 32.7609 266.571 31.0528C266.801 29.5942 267.356 28.2123 267.318 26.7345C267.279 25.4293 266.763 24.201 266.246 23.0111C264.6 19.1726 262.687 15.0462 259.204 13.2997C257.692 12.532 256.008 12.3209 254.343 12.1289C250.018 11.6683 247.071 12.4168 243.397 14.7775C240.316 16.7735 238.134 20.6504 238.115 24.6808Z"
      fill="#D7C68E" />
    <path
      d="M259.646 52.2775C259.321 53.026 259.244 53.8513 259.187 54.6574C258.594 62.104 258.269 69.6275 259.359 77.0166C259.991 81.2966 261.139 85.5765 263.416 89.2615C265.636 92.8504 268.832 95.7293 270.937 99.3759C271.569 100.489 272.123 101.775 271.798 103.003C271.148 103.541 270.172 103.521 269.406 103.176C268.641 102.831 268.047 102.197 267.512 101.545C265.808 99.4719 264.545 97.1112 263.282 94.7505C261.885 92.1596 260.508 89.5493 259.34 86.8432C254.671 76.0186 253.791 63.5819 256.891 52.2007C257.197 51.0492 257.58 49.8592 258.441 49.0339C259.13 48.3814 260.029 48.0359 260.929 47.7672C261.675 47.5369 262.479 47.345 263.244 47.4986C264.01 47.652 264.756 48.2086 264.89 48.9764C265.196 50.7804 263.435 50.128 262.517 50.2046C261.33 50.3007 260.144 51.1835 259.646 52.2775Z"
      fill="#5B5870" />
    <path
      d="M255.933 44.9646C255.837 44.9839 255.741 45.003 255.665 45.0605C255.588 45.1181 255.55 45.2332 255.512 45.3293C255.244 46.1546 255.129 47.0375 255.186 47.9011C255.186 48.0355 255.205 48.1698 255.282 48.285C255.378 48.4384 255.55 48.496 255.703 48.5536C256.181 48.7264 256.679 48.8223 257.177 48.8799C257.502 48.9182 257.865 48.8991 258.095 48.6687C258.267 48.496 258.325 48.2657 258.401 48.0162C258.516 47.5748 258.612 47.1526 258.727 46.7112C259.128 45.0798 257.234 44.7727 255.933 44.9646Z"
      fill="#333333" />
    <path
      d="M254.036 51.9134C253.577 54.2741 253.098 56.6731 253.194 59.0723C253.271 61.0298 253.711 62.9683 254.476 64.7724C254.553 64.9451 254.629 65.1179 254.763 65.2523C255.069 65.5593 255.605 65.5209 255.969 65.2714C256.332 65.0219 256.562 64.6189 256.734 64.235C258.227 61.1259 258.265 57.5368 258.284 54.0822C258.284 53.0074 258.303 51.9326 258.303 50.8578C258.303 49.6678 257.595 48.0557 256.122 48.2668C254.668 48.4971 254.266 50.7427 254.036 51.9134Z"
      fill="#D7C68E" />
    <path
      d="M262.723 50.4777C263.794 50.6121 264.751 51.2262 265.555 51.9171C268.961 54.9112 270.071 59.6133 271.736 63.7973C273.458 68.1156 275.888 72.1076 277.84 76.3107C278.912 78.6331 279.85 81.109 279.639 83.6423C279.582 84.3909 279.352 85.2353 278.683 85.6191C277.936 86.0606 276.979 85.7344 276.252 85.2737C274.587 84.1989 273.535 82.4332 272.54 80.7251C271.296 78.5563 270.033 76.4068 268.789 74.238C268.062 73.0098 267.354 71.7621 266.626 70.5338C265.363 68.3459 264.1 66.1579 262.818 63.97C261.708 62.07 260.598 60.1315 259.852 58.0587C258.857 55.2949 257.9 49.8635 262.723 50.4777Z"
      fill="#F9E9DE" />
    <path
      d="M263.58 68.2439C261.724 67.0732 259.868 65.9025 258.012 64.7317C257.552 64.4438 257.055 64.1367 256.538 64.0408C254.625 63.7145 253.362 66.2096 254.605 67.5722C255.428 68.4743 256.691 69.2419 257.591 70.1632C258.815 71.4299 260.04 72.6966 261.246 73.9634C264.71 77.5716 268.192 81.1605 271.905 84.5768C273.474 86.0162 275.388 87.5517 277.378 87.59C279.579 87.6284 280.803 85.6323 280.459 83.6363C280.095 81.6403 278.564 79.7211 276.9 78.0513C273.053 74.2128 268.288 71.1996 263.58 68.2439Z"
      fill="#F9E9DE" />
    <path
      d="M260.199 65.103C260.122 64.3929 259.682 63.7403 259.089 63.3181C258.515 62.8959 257.807 62.6848 257.079 62.5696C256.352 62.4545 255.568 62.4736 254.974 62.915C254.305 63.3949 254.075 64.2778 253.941 65.103C253.75 66.2738 253.635 67.4636 253.635 68.6728C253.635 68.9799 253.635 69.287 253.788 69.5557C253.922 69.8052 254.19 69.9779 254.438 70.1315C255.147 70.5729 255.912 70.9567 256.716 71.0719C257.539 71.2062 258.419 71.0527 259.07 70.5154C260.486 69.3638 260.371 66.7536 260.199 65.103Z"
      fill="#F9E9DE" />
    <path
      d="M201.317 61.7958C201.987 63.3695 202.944 65.1737 204.628 65.404C205.987 65.5959 207.25 64.6747 208.589 64.3292C208.972 64.2333 209.374 64.1757 209.68 63.9262C210.024 63.6383 210.158 63.1585 210.197 62.6978C210.292 61.5271 209.967 60.3755 209.642 59.2432C208.742 56.1724 207.843 53.0825 206.35 50.2611C206.216 50.0116 206.063 49.7429 205.834 49.5702C205.604 49.3975 205.298 49.3398 205.011 49.3207C203.308 49.1287 201.624 49.6085 199.978 50.0884C197.241 50.8753 197.681 52.3147 198.504 54.4642C199.423 56.9401 200.322 59.3967 201.317 61.7958Z"
      fill="#4D4D4D" />
    <path
      d="M203.02 50.5103C203.843 50.3567 204.781 50.2416 205.47 50.7214C205.872 51.0093 206.12 51.4507 206.331 51.9113C207.077 53.5427 207.326 55.366 207.919 57.0549C208.302 58.1489 208.8 59.2045 209.029 60.3369C209.087 60.6439 209.125 60.9893 208.972 61.2581C208.819 61.5076 208.551 61.6419 208.302 61.7571C207.116 62.3137 205.91 62.8702 204.609 63.043C204.092 63.1197 203.48 63.1006 203.135 62.7168C202.982 62.544 202.886 62.3329 202.81 62.1217C201.872 59.8571 200.877 57.6116 199.825 55.3851C199.346 54.3871 198.849 53.3316 198.887 52.2376C198.906 51.6235 200.226 51.278 200.705 51.1245C201.432 50.8174 202.236 50.6446 203.02 50.5103Z"
      fill="white" />
    <path
      d="M207.648 63.0248L207.246 62.8138C207.055 62.7562 206.825 62.8521 206.691 63.0057C206.557 63.1592 206.5 63.3896 206.519 63.5815C206.519 63.6582 206.538 63.7542 206.577 63.8118C206.634 63.9077 206.73 63.9461 206.825 63.9845C207.112 64.0613 207.438 63.9653 207.61 63.7158C207.763 63.543 207.782 63.236 207.648 63.0248Z"
      fill="#DDDDDD" />
    <path
      d="M238.937 61.4502C237.406 67.1503 236.105 72.7161 235.397 78.5891C234.574 85.4216 233.062 95.3441 225.254 97.5321C221.044 98.7028 217.81 93.6552 215.035 91.1601C211.821 88.3005 209.314 86.1508 209.141 81.3912C208.931 75.6334 209.428 71.2382 205.505 66.5361C205.314 66.325 205.008 66.6321 205.199 66.8432C208.739 71.1423 208.74 75.5758 208.701 80.8346C208.682 83.4639 209.122 85.8822 210.825 87.955C213.313 90.9874 216.681 93.6552 219.513 96.3997C221.255 98.0886 222.996 98.914 225.446 98.2422C231.129 96.6877 234.019 90.1813 235.186 84.9418C236.048 80.9881 236.239 76.9385 236.89 72.9464C237.521 69.108 238.65 65.3845 239.684 61.642C239.798 61.1814 239.052 60.9703 238.937 61.4502Z"
      fill="#DDDDDD" />
    <path
      d="M256.126 34.2004C256.126 34.2004 256.241 33.9317 256.26 33.9317C256.299 33.8933 256.318 33.8357 256.356 33.7973C256.433 33.6822 256.433 33.4902 256.356 33.3751C256.28 33.26 256.203 33.1639 256.146 33.0488C256.012 32.8569 255.82 32.8185 255.648 32.8761H255.629C255.38 32.9144 255.17 33.2024 255.227 33.471C255.227 33.5095 255.246 33.5286 255.246 33.567C254.978 33.8165 255.17 34.2771 255.514 34.3731C255.859 37.1944 256.012 40.3803 255.131 43.0289C254.06 46.2532 250.653 48.9978 247.993 50.8979C244.759 53.201 240.205 54.6596 239.344 59.0163C239.324 59.093 239.458 59.1315 239.478 59.0547C240.415 55.2929 244.09 53.9687 247.037 52.1454C249.371 50.7059 251.457 48.9786 253.294 46.9251C256.662 43.1825 256.567 38.9793 256.126 34.2004Z"
      fill="#DDDDDD" />
    <path
      d="M240.352 39.7834C239.95 42.8159 238.592 46.6352 238.917 49.6676C239.242 52.6233 239.702 55.4637 239.472 58.4385C239.453 58.6305 239.74 58.6113 239.759 58.4385C240.046 55.79 239.721 53.3526 239.415 50.7424C239.013 47.3069 240.199 43.7754 240.85 40.3976C240.85 40.4167 240.429 39.2844 240.352 39.7834Z"
      fill="#DDDDDD" />
    <path
      d="M205.773 66.5008C205.792 66.4431 205.811 66.3664 205.773 66.2896C205.734 66.1745 205.696 66.0592 205.658 65.9441C205.639 65.8674 205.581 65.8099 205.524 65.7714C205.371 65.426 205.16 65.1765 204.605 65.2148C204.337 65.2341 204.108 65.5987 204.357 65.7906C204.395 65.829 204.433 65.8673 204.471 65.9058C204.625 66.3472 204.797 66.7886 204.988 67.2301C205.007 67.2684 205.084 67.3069 205.122 67.3069C205.734 67.2493 205.945 67.0574 205.773 66.5008Z"
      fill="#DDDDDD" />
    <path
      d="M240.564 58.3866C240.353 57.9452 239.645 57.9068 239.281 57.8109C239.033 57.7533 238.994 58.2907 238.937 58.7513C238.784 59.4999 238.612 60.2291 238.478 60.9776C238.363 61.5534 238.057 62.5898 238.133 63.1656C238.21 63.7605 239.09 64.0485 239.473 63.6646C239.683 63.4534 239.76 62.8585 239.836 62.5706C240.085 61.6494 240.277 60.7281 240.468 59.8069C240.544 59.3463 240.755 58.7897 240.564 58.3866Z"
      fill="#DDDDDD" />
    <defs>
      <linearGradient
        id="paint0_linear_3205_26097"
        x1="140.069"
        y1="189.608"
        x2="196.354"
        y2="189.608"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#4D4D4D" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3205_26097"
        x1="140.067"
        y1="189.608"
        x2="196.352"
        y2="189.608"
        gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3205_26097"
        x1="230.657"
        y1="203.536"
        x2="292.917"
        y2="206.545"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#4D4D4D" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_3205_26097"
        x1="230.65"
        y1="203.536"
        x2="292.91"
        y2="206.545"
        gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="1" />
      </linearGradient>
      <clipPath id="clip0_3205_26097">
        <rect
          width="58.6979"
          height="58.6979"
          fill="white"
          transform="matrix(-0.848618 -0.529006 -0.529006 0.848618 143.867 348.055)" />
      </clipPath>
      <clipPath id="clip1_3205_26097">
        <rect
          width="66"
          height="193"
          fill="white"
          transform="translate(131)" />
      </clipPath>
    </defs>
  </svg>
</template>
