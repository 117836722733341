<template>
  <div @keypress.enter.prevent.self>
    <v-select
      v-bind="Object.assign(
        { clearable, multiple, reduce, skipInitialValue, appendToBody },
        $attrs
      )"
      ref="vselectEl"
      v-model="model"
      :options="innerOptions"
      :calculate-position="appendToBody ? withPopper : undefined"
      :filterable="!searchEndpoint"
      @search="onSearch"
      @update:modelValue="$emit('update:modelValue', $event)"
      @search:focus="onFocus"
      @search:blur="onBlur"
      @close="onClose">
      <template #no-options>
        <p class="my-0">
          {{ $t("Nincs találat.") }}
        </p>
      </template>
    </v-select>

    <div v-if="hiddenInputName && multiple && Array.isArray(model)">
      <input
        v-for="(v, key) in model"
        :key="key"
        :name="hiddenInputName + '[' + key + ']'"
        type="hidden"
        :value="v">
    </div>
    <input
      v-else-if="hiddenInputName && model !== null"
      :name="hiddenInputName"
      type="hidden"
      :value="typeof model === 'object' ? model.value : model">
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";
import axios from "axios";
import debounce from "lodash/debounce";
import get from "lodash/get";
import { useToast } from "vue-toastification";

export default {
  props: {
    options: { type: [Array, Object], default: () => [] },
    modelValue: { type: [String, Number], default: "" },
    field: { type: String, default: "" },
    hiddenInput: { type: String, default: null },
    value: { type: [String, Number, Array, Object], default: null, },
    hasError: { type: Boolean, default: false },
    searchEndpoint: { type: String, default: "" },
    multiple: { type: Boolean, default: false },
    idKey: { type: String, default: "id" },
    labelKey: { type: String, default: "title" },
    clearable: { type: Boolean, default: false },
    skipInitialValue: { type: Boolean, default: null },
    appendToBody: { type: Boolean, default: false },
    refreshOnLoad: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
    includeValues: { type: Boolean, default: false },
  },
  emits: ["update:modelValue", "input", "change"],
  data() {
    return {
      innerOptions: [],
      model: null,
      reduce: (o) => o && o.value,
    };
  },
  computed: {
    hiddenInputName() {
      return this.field || this.hiddenInput;
    },
    modelOption() {
      return this.innerOptions.find((o) => "" + o?.value === "" + this.model);
    },
  },
  watch: {
    model: {
      handler(val) {
        this.$emit("input", val);
        this.$emit("change", this.modelOption);

        if (this.modelOption) {
          this.$el.dispatchEvent(
            new CustomEvent("change", { detail: this.modelOption })
          );
        }
      },
      deep: true
    }
  },
  created() {
    this.searchItems = debounce(async (search, loading, initialLoad = false) => {
      loading && loading(true);

      try {
        let url = this.searchEndpoint;
        if (search) {
          const divider = url.includes("?") ? "&" : "?";
          url += `${divider}search=${encodeURIComponent(search)}`;
        }

        if (initialLoad) {
          const divider = url.includes("?") ? "&" : "?";
          const params = new URLSearchParams();

          if (Array.isArray(this.modelValue)) {
            for(const val of this.modelValue) {
              params.append("ids[]", val);
            }
          } else {
            params.set("ids[]", [this.modelValue]);
          }
          url += `${divider}${params.toString()}`;
        }

        const response = await axios.get(url);
        const foundOptions = response.data.data.map((item) => {
          // get is used to handle dot-notation in idKey and labelKey
          return {
            value: "" + get(item, this.idKey),
            label: get(item, this.labelKey),
            item,
          };
        });

        if (initialLoad) {
          this.model = foundOptions.map(this.reduce);
        }

        const selectedValue = this.model ? [].concat(this.model) : [];

        for (const selectedId of selectedValue) {
          const idToCheck =
            typeof selectedId === "object"
              ? get(selectedId, "value")
              : selectedId;
          if (!foundOptions.find((o) => o.value === idToCheck)) {
            foundOptions.push(
              this.innerOptions.find((o) => o.value === idToCheck)
            );
          }
        }

        this.innerOptions = foundOptions;

        loading && loading(false);
      } catch (e) {
        console.error(e);
        const toast = useToast();
        toast.error(this.$t("Hiba a keresés közben."));

        loading && loading(false);
      }
    }, 300);
  },
  mounted() {
    this.innerOptions = this.loadOptionsFromProps();
    this.model = this.getInitialValue();

    if (this.refreshOnLoad && this.searchEndpoint) {
      const isNotEmptyString = typeof this.modelValue === "string" && this.modelValue.length > 0;
      if (this.modelValue && isNotEmptyString) {
        this.searchItems(undefined, undefined, true);
      }
    }

    if (this.autofocus) {
      this.$refs.vselectEl.searchEl.focus();
    }
  },
  beforeUnmount() {
    this.searchItems.cancel();
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width;

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: "bottom",
        modifiers: [{ name: "offset", options: { offset: [0, -1] } }],
      });

      return () => popper.destroy();
    },
    getFirstOption() {
      if (this.options && this.options.length) {
        return this.options[0][this.idKey];
      } else if (typeof this.options === "object") {
        return Object.keys(this.options)[0];
      } else {
        return null;
      }
    },
    onSearch(search, loading) {
      if (this.searchEndpoint && search) {
        this.searchItems(search, loading);
      }
    },
    onFocus() {
      if (this.searchEndpoint && !this.innerOptions.length) {
        this.searchItems();
      }
    },
    onBlur() {
      if (this.model && this.modelOption && this.innerOptions.length === 0) {
        this.innerOptions = [this.modelOption];
      }
    },
    loadOptionsFromProps() {
      if (!this.options) {
        return [];
      }

      const mapper = (label, id) => ({
        value: typeof label === "object" ? label[this.idKey] : id,
        label: typeof label === "object" ? label[this.labelKey] : label,
        item: {
          [this.idKey]: typeof label === "object" ? label[this.idKey] : id,
          [this.labelKey]:
            typeof label === "object" ? label[this.labelKey] : label,
        },
      });

      if (Array.isArray(this.options)) {
        return this.options.map(mapper);
      } else if (typeof this.options === "object") {
        return Object.entries(this.options).map(([id, label]) =>
          mapper(label, id)
        );
      }
    },
    getInitialValue() {
      if (this.multiple) {
        if (this.value) {
          if (typeof this.value === "string") {
            return this.value.split(",");
          } else {
            if (this.searchEndpoint) {
              this.innerOptions = this.value;
              return this.value.map(this.reduce);
            }
          }

          if (Array.isArray(this.value)) {
            return this.value.length ? this.value : null;
          }

          return null;
        }

        return null;
      } else {
        return (
          this.value || (!this.skipInitialValue ? this.getFirstOption() : null)
        );
      }
    },
    reset() {
      this.innerOptions = [];
      this.model = null;
    },
    addNewOption(option, select = false) {
      this.innerOptions = this.innerOptions.concat(option);

      if (select) {
        this.model = option;
      }
    },
    onClose() {
      this.$nextTick(() => {
        const modal = this.$el.closest(".sm-modal");
        if (modal) {
          setTimeout(() => {
            const showedElement = document.querySelector(".sm-show");

            if (showedElement) {
              showedElement.tabIndex = "-1";
            }

            modal.focus();
          }, 100);
        }
      });
    }
  },
};
</script>
