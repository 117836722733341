<template>
  <div>
    <sm-button
      :title="$t('customers.transactions.withdraw_modal.title')"
      @click="toggleWithdrawModalVisibility" />
    <sm-modal
      :modal-is-visible="withdrawModal"
      :modal-title="$t('customers.transactions.withdraw_modal.title')"
      @toggleModalVisibility="toggleWithdrawModalVisibility">
      <form
        @submit.prevent="onSubmit()">
        <sm-input
          v-model="form.amount"
          name="amount"
          :label="$t('customers.transactions.withdraw_modal.form.amount.label')"
          append="Ft"
        >
          <template #append>
            Ft
          </template>
        </sm-input>
        <sm-select
          v-model="form.payment_type"
          name="payment_type"
          :label="$t('customers.transactions.withdraw_modal.form.payment_type.label')"
          :options="paymentTypes"
        />
        <sm-input
          v-model="form.note"
          name="note"
          :label="$t('customers.transactions.withdraw_modal.form.note.label')"
          :placeholder="$t('customers.transactions.withdraw_modal.form.note.placeholder')"
          type="textarea"
        />
        <sm-select
          v-if="page.props.withdrawModal.userCanSelectCashRegister"
          v-model="form.cash_register"
          name="cash_register"
          :label="$t('customers.transactions.withdraw_modal.form.cash_register.label')"
          :options="cashRegisters"
        />
      </form>
      <template #footer>
        <sm-button
          :title="$t('generic.cancel')"
          @click="toggleWithdrawModalVisibility" />
        <sm-button
          color="primary"
          :title="$t('customers.transactions.withdraw_modal.title')"
          :disabled="!form.isDirty"
          :loading="form.processing"
          @click="onSubmit" />
      </template>
    </sm-modal>
  </div>
</template>

<script setup>
import { useFormatSelect } from "@/inertia/composables/global";
import { useForm, usePage } from "@inertiajs/vue3";
const page = usePage();

const withdrawModal = ref(false);

const toggleWithdrawModalVisibility = () => {
  withdrawModal.value = !withdrawModal.value;
};

const form = useForm({
  amount: "",
  payment_type: null,
  note: "",
  cash_register: null
});

provide("form", form);

const paymentTypes = useFormatSelect(page.props.withdrawModal.paymentTypes);
const cashRegisters = useFormatSelect(page.props.withdrawModal.openCashRegisters);

const onSubmit = () => {
  form
    .transform((data) => {
      let formData = {
        ...data,
        payment_type: form.payment_type?.id,
      };
      if (page.props.withdrawModal.userCanSelectCashRegister) {
        formData = {
          ...formData,
          cash_register_id: form.cash_register?.id,
        };
      }
      return formData;
    })
    .post(page.props.routes.withdrawMoney, {
      only: ["table", "errors", "flash"],
      onSuccess: () => {
        toggleWithdrawModalVisibility();
      },
    });
};
</script>
