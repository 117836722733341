<template>
  <div
    class="relative pr-8 cursor-pointer sm-input-text hover:border-bo-blue/75"
    @click="copyCode">
    <code>
      <pre
        class="overflow-x-scroll text-sm"
        v-html="code" />
    </code>
    <div
      v-if="isSupported"
      class="absolute transition-colors -translate-y-1/2 top-1/2 right-2 hover:text-bo-blue">
      <vue-feather
        type="copy"
        class="w-4 h-4 mt-1" />
    </div>
  </div>
</template>

<script setup>
import { useClipboard } from "@vueuse/core";
import { useToast } from "vue-toastification";

const props = defineProps({
  code: { type: String, required: true, default: "" }
});

const { copy, copied, isSupported } = useClipboard();
const toast = useToast();

const copyCode = () => {
  if (isSupported) {
    const scriptTag = props.code.replaceAll("&gt;", ">").replaceAll("&lt;", "<");
    copy(scriptTag);
    if (copied) {
      toast.success(window.trans("generic.copy_success"));
    }
  } else {
    toast.error(window.trans("generic.copy_failed"));
  }
};
</script>
