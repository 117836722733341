<template>
  <sm-card header-title="Slots">
    <div class="">
      <div class="sm-row">
        <div class="w-1/2 sm-col">
          <p class="mb-2">
            Add slot before the input<br>
            <strong class="inline-block pl-2 w-36">Slot name:</strong> #prepend<br>
            <code class="inline-block pl-2"><pre class="text-xs text-bo-green">&lt;template #prepend&gt;Prepend&lt;/template&gt;</pre></code>
          </p>
        </div>
        <div class="w-1/2 sm-col">
          <sm-select
            label="Prepend input slot">
            <template #prepend>
              Prepend
            </template>
          </sm-select>
        </div>
      </div>
      <hr>

      <div class="sm-row">
        <div class="w-1/2 sm-col">
          <p class="mb-2">
            Add slot after the input<br>
            <strong class="inline-block pl-2 w-36">Slot name:</strong> #append<br>
            <code class="inline-block pl-2"><pre class="text-xs text-bo-green">&lt;template #append&gt;Append&lt;/template&gt;</pre></code>
          </p>
        </div>
        <div class="w-1/2 sm-col">
          <sm-select
            label="Append input slot">
            <template #append>
              Append
            </template>
          </sm-select>
        </div>
      </div>
      <hr>

      <div class="sm-row">
        <div class="w-1/2 sm-col">
          <p class="mb-2">
            Add slot after the label<br>
            <strong class="inline-block pl-2 w-36">Slot name:</strong> #afterLabel<br>
            <code class="inline-block pl-2"><pre class="text-xs text-bo-green">&lt;template #afterLabel&gt;After label&lt;/template&gt;</pre></code>
          </p>
        </div>
        <div class="w-1/2 sm-col">
          <sm-select
            label="After label slot">
            <template #afterLabel>
              After label
            </template>
          </sm-select>
        </div>
      </div>
      <hr>

      <div class="sm-row">
        <div class="w-1/2 sm-col">
          <p class="mb-2">
            Add slot after the input<br>
            <strong class="inline-block pl-2 w-36">Slot name:</strong> #afterInput<br>
            <code class="inline-block pl-2"><pre class="text-xs text-bo-green">&lt;template #afterInput&gt;After input&lt;/template&gt;</pre></code>
          </p>
        </div>
        <div class="w-1/2 sm-col">
          <sm-select
            label="After input slot">
            <template #afterInput>
              <div class="block pl-2">
                After input
              </div>
            </template>
          </sm-select>
        </div>
      </div>
    </div>
  </sm-card>
</template>
