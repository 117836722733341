import axios from "axios";

export default class Appointments {
  constructor(endpoints) {
    this.endpoints = endpoints;
  }

  getUrl(endpoint, appointmentId) {
    return endpoint.replace(/{appointment}/, appointmentId);
  }

  create(data) {
    return axios.post(this.endpoints.create, data);
  }

  update(appointmentId, data) {
    return axios.patch(this.getUrl(this.endpoints.update, appointmentId), data);
  }

  delete(appointmentId, message, deleteAllRecurring = false) {
    return axios.delete(this.getUrl(this.endpoints.delete, appointmentId), {
      data: {message, delete_all_recurring: deleteAllRecurring},
    });
  }
}
