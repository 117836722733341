<template>
  <div class="text-right">
    <div class="flex flex-col items-end">
      <button
        v-for="card in pageCustomerCards"
        :key="card"
        type="button"
        class="mb-1 text-xs font-semibold transition-colors hover:text-bo-blue"
        @click="copyCustomerCardID(card)">
        {{ card }}
        <vue-feather
          v-tippy="$t('generic.copy_to_clipboard')"
          type="clipboard"
          class="w-4 h-4 translate-y-0.5 ml-1" />
      </button>
    </div>

    <div>
      <Link
        v-tippy="$t('customers.profile.tooltips.card_management')"
        :href="$page.props.routes.customerCards"
        class="text-xs text-bo-blue"
      >
        {{ $t('customers.generic.balance.to_special_cards') }}
        <vue-feather
          type="info"
          class="w-3 h-3 translate-y-0.5" />
      </Link>
    </div>
  </div>
</template>

<script setup>
import { Link } from "@inertiajs/vue3";

import { useClipboard } from "@vueuse/core";
import { useToast } from "vue-toastification";

defineProps({
  pageCustomerCards: { type: Object, required: false, default: null }
});

const { copy, copied, isSupported } = useClipboard();
const toast = useToast();

const copyCustomerCardID = (card) => {
  if (isSupported) {
    copy(card);
    if (copied) {
      toast.success(window.trans("generic.copy_success"));
    }
  } else {
    toast.error(window.trans("generic.copy_failed"));
  }
};
</script>
