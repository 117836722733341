<template>
  <div>
    <In-Head :title="$page.props.title" />
    <sub-header :title="$page.props.title">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          :href="$page.props.routes.index"
          preset="back"
          icon="arrow-left" />
        <sm-button
          v-if="$page.props.permissions.edit"
          :href="$page.props.routes.edit"
          preset="edit" />
        <sm-button
          v-if="$page.props.permissions.destroy"
          preset="delete"
          @click="confirmDelete"
        />
      </template>
    </sub-header>

    <div class="space-y-4">
      <div>
        <h4>{{ $t('franchises.sections.clubs') }}</h4>
        <sm-card
          v-if="availableClubs?.length > 0"
          no-padding>
          <table class="sm-table">
            <tbody>
              <tr
                v-for="club in availableClubs"
                :key="club.id">
                <td>{{ club.name }}</td>
                <td>
                  <sm-checkbox
                    class="mb-0"
                    sm-switch
                    :value="addedClubIds.includes(club.id)"
                    @update:modelValue="toggleClub(club.id)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </sm-card>
        <template v-else>
          <sm-alert color="warning">
            {{ $t('Nincs rögzítve klub ehhez a franchisehoz.') }}
          </sm-alert>
        </template>
      </div>
      <div>
        <div class="flex justify-between items-center mb-2">
          <h4>Bérletek</h4>
          <div>
            <sm-button
              v-if="$page.props.permissions.createTicket"
              :href="$page.props.routes.createTicket"
              preset="add" />
          </div>
        </div>
        <sm-card no-padding>
          <sm-datatable
            :data="$page.props.ticketTable"
            :sorts="$page.props.ticketTableSorts"
            table-prop-name="ticketTable"
            paginator>
            <template #columns>
              <Column
                v-for="(col, index) of ticketTableFields"
                :key="col + '_' + index"
                :field="col"
                :header="$t(`tickets.season_tickets.fields.${col}`)"
                :sortable="$page.props.ticketTableSorts.allowed.find(data => data === col)">
                <template #body="slotProps">
                  {{ slotProps.data?.[col] }}
                </template>
              </Column>
              <Column field="actions">
                <template #body="slotProps">
                  <div
                    v-if="slotProps.data"
                    class="flex justify-end space-x-1">
                    <sm-button
                      v-if="slotProps.data.permissions.edit"
                      preset="edit-icon"
                      :href="slotProps.data.routes.edit"
                    />
                    <sm-button
                      v-if="slotProps.data.permissions.destroy"
                      preset="delete-icon"
                      @click="confirmDeleteTicket(slotProps.data)"
                    />
                  </div>
                </template>
              </Column>
            </template>
          </sm-datatable>
        </sm-card>
      </div>
    </div>
    <SmConfirmDialog group="confirmDelete" />
  </div>
</template>
<script setup>
import { useConfirm } from "primevue/useconfirm";
import axios from "axios";
import { router } from "@inertiajs/vue3";
import { ref } from "vue";

const { franchise, routes } = defineProps({
  routes: { type: Object, required: true },
  permissions: { type: Object, required: true },
  franchise: { type: Object, required: true },
  availableClubs: { type: Array, required: true },
});

const confirm = useConfirm();

const addedClubIds = ref(franchise.clubs.map(club => club.id));

const toggleClub = async (club) => {
  if (addedClubIds.value.includes(club)) {
    addedClubIds.value = addedClubIds.value.filter(c => c !== club);
  } else {
    addedClubIds.value = addedClubIds.value.concat(club);
  }

  try {
    await axios.post(routes.updateClubs, { club: club });
  } catch (e) {
    // ...
  }
};

const confirmDelete = async () => {
  confirm.require({
    group: "confirmDelete",
    header: window.trans("franchises.dev_tools.delete_modal_title"),
    message: `${window.trans("generic.are_you_sure")}?`,
    accept: async () => {
      await axios.delete(routes.destroy);
      router.visit(routes.index);
    }
  });
};

const confirmDeleteTicket = async (ticket) => {
  confirm.require({
    group: "confirmDelete",
    header: window.trans("franchises.dev_tools.delete_modal_title"),
    message: `${window.trans("generic.are_you_sure")}?`,
    accept: async () => {
      await axios.delete(ticket.routes.destroy);
      router.reload({ only: "ticketsTable" }, { preserveState: true });
    }
  });
};

const ticketTableFields = ref([
  "name",
  "availability",
  "price",
  "validity",
]);
</script>
