<template>
  <div>
    <In-Head :title="$t('customers.usage_extensions.title', {name: $page.props.customer.name})" />

    <sub-header :title="$t('customers.usage_extensions.title', {name: $page.props.customer.name})">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        :data="table"
        :sorts="sorts"
        paginator>
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`customers.usage_extensions.table.${useFormatToSnakeCase(col)}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template v-if="col === 'createdAt'">
                {{ slotProps.data?.createdAt ? useDateFormat(slotProps.data?.createdAt) : null }}
              </template>
              <template v-else-if="col === 'ticketCreatedAt'">
                {{ slotProps.data?.ticketCreatedAt ? useDateFormat(slotProps.data?.ticketCreatedAt) : null }}
              </template>
              <template v-else-if="col === 'extensionDays'">
                {{ slotProps.data?.extensionDays ? slotProps.data?.extensionDays : '-' }}
              </template>
              <template v-else-if="col === 'usages'">
                {{ slotProps.data?.usages ? slotProps.data?.usages : '-' }}
              </template>
              <template v-else-if="col === 'price'">
                {{ slotProps.data?.price ? usePriceFormat(slotProps.data?.price) : null }}
              </template>
              <template v-else-if="col === 'extendedBy'">
                <Link
                  :href="slotProps.data?.routes?.extenderProfile"
                  class="text-bo-blue">
                  {{ slotProps.data?.extendedBy }}
                </Link>
              </template>
              <template v-else-if="col === 'ticketCreatedByName'">
                <Link
                  :href="slotProps.data?.routes?.ticketCreatedByProfile"
                  class="text-bo-blue">
                  {{ slotProps.data?.ticketCreatedByName }}
                </Link>
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat, useFormatToSnakeCase, usePriceFormat } from "@/inertia/composables/global";
import { Link } from "@inertiajs/vue3";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableFields = ref(["createdAt", "extendedBy", "extensionDays", "usages", "price", "ticketCreatedByName", "ticketCreatedAt"]);
</script>
