<template>
  <div>
    <transition
      name="fade"
      mode="out-in">
      <onboarding-step-1-a
        v-if="activeSubStep === 1"
        @increaseSubStep="increaseSubStep" />
      <onboarding-step-1-b
        v-else-if="activeSubStep === 2"
        @increaseSubStep="increaseSubStep"
        @updateStepB="updateStepB"
        @decreaseSubStep="decreaseSubStep" />
      <onboarding-step-1-c
        v-else-if="activeSubStep === 3"
        :step-b-finished="stepBFinished"
        @increaseStep="$emit('increaseStep')" />
    </transition>
  </div>
</template>

<script>
import OnboardingStep1A from "./Step1/OnboardingStep1A.vue";
import OnboardingStep1B from "./Step1/OnboardingStep1B.vue";
import OnboardingStep1C from "./Step1/OnboardingStep1C.vue";

export default {
  components: {
    OnboardingStep1A,
    OnboardingStep1B,
    OnboardingStep1C
  },
  emits: ["increaseStep"],
  data() {
    return {
      subSteps: 3,
      activeSubStep: 1,
      stepBFinished: false
    };
  },
  methods: {
    increaseSubStep(number) {
      if (number) {
        this.activeSubStep = number;
      } else {
        this.activeSubStep++;
      }
    },
    decreaseSubStep() {
      this.activeSubStep--;
    },
    updateStepB() {
      this.stepBFinished = !this.stepBFinished;
    }
  }
};
</script>
