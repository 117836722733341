<template>
  <div>
    <div>
      <div
        v-if="stepAFinished"
        class="flex items-center justify-center h-12 mb-8 text-center text-white uppercase bg-brand-green-500 rounded-xl font-jost-semibold">
        Bérlet rögzítve
        <check-circle-icon class="inline-block w-5 ml-4" />
      </div>
      <div class="flex justify-center relative max-w-[28.25rem] mx-auto">
        <step-2-finish-svg class="" />
        <div class="absolute text-center -bottom-14 lg:-bottom-6">
          <p class="mb-16 text-2xl text-brand-blue-400">
            Bérleteidet később a <strong>“Bérletek”</strong>
            menüben tudod módosítani, valamint új bérleteket is fel tudsz vinni.
          </p>
          <button
            type="button"
            class="w-full sm-btn-app sm-btn-app-primary lg:w-auto"
            @click="$emit('increaseStep')">
            <span>Következő lépés</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckCircleIcon } from "vue-feather-icons";
import Step2FinishSvg from "./Step2FinishSvg.vue";

export default {
  components: {
    CheckCircleIcon,
    Step2FinishSvg
  },
  props: {
    stepAFinished: { type: Boolean, required: true, default: false }
  },
  emits: ["increaseStep"],
  mounted() {
    const selectedNodeElem = [...document.querySelectorAll(".nav-aside .nav-item")].filter(a => a.textContent.includes("Bérletek és jegyek"))[0];
    if (selectedNodeElem) {
      selectedNodeElem.classList.add("selected");
    }
  },
  unmounted() {
    const selectedNodeElem = [...document.querySelectorAll(".nav-aside .nav-item")].filter(a => a.textContent.includes("Bérletek és jegyek"))[0];
    if (selectedNodeElem) {
      selectedNodeElem.classList.remove("selected");
    }
  },
};
</script>
