<template>
  <tr>
    <td>
      {{
        product.product ? product.product.title || product.product.name : "-"
      }}
      <input
        type="hidden"
        :value="product.product.id"
        :name="`items[${index}][product]`"
      >
    </td>
    <td>{{ tax }}%</td>
    <td>
      <template v-if="isEditing">
        <div class="sm-input-group">
          <input
            v-model="acquisitionPrice"
            type="number"
            class="sm-input-text"
          >
          <div class="sm-input-group-append">
            Ft
          </div>
        </div>
      </template>
      <template v-else>
        {{ acquisitionPrice }} Ft
      </template>
      <input
        type="hidden"
        :value="acquisitionPrice"
        :name="`items[${index}][acquisition_price]`"
      >
    </td>
    <td>
      <template v-if="isEditing">
        <div class="sm-input-group">
          <input
            v-model="amount"
            type="number"
            class="sm-input-text"
          >
          <div class="sm-input-group-append">
            {{ product.product.data.unit }}
          </div>
        </div>
      </template>
      <template v-else>
        {{ amount }} {{ product.product.data.unit }}
      </template>
      <input
        type="hidden"
        :value="amount"
        :name="`items[${index}][amount]`"
      >
    </td>
    <td>{{ netTotal.toLocaleString() }} Ft</td>
    <td>{{ total.toLocaleString() }} Ft</td>
    <td>
      <div class="flex items-center space-x-1">
        <button
          class="sm-btn sm-btn-xs sm-btn-white sm-btn-icon"
          type="button"
          @click="toggleEdit"
        >
          <i class="fas fa-edit" />
        </button>
        <button
          class="sm-btn sm-btn-xs sm-btn-danger sm-btn-icon"
          type="button"
          @click="$emit('delete')"
        >
          <i class="fas fa-trash-alt" />
        </button>
      </div>
    </td>
  </tr>
</template>
<script>
export default {
  props: {
    product: { type: Object, default: () => {} },
    index: { type: Number, default: null },
  },
  emits: ["delete"],
  data() {
    return {
      isEditing: false,
    };
  },
  computed: {
    tax() {
      return this.product.product && this.product.product.data.tax
        ? this.product.product.data.tax
        : 27;
    },
    netTotal() {
      return Math.round(this.acquisitionPrice * this.amount);
    },
    total() {
      const taxValue = 1 + this.tax / 100;
      return Math.round(this.acquisitionPrice * taxValue * this.amount);
    },
    amount: {
      get() {
        return this.product.amount;
      },
      set(value) {
        this.$store.commit("updateProduct", {
          product: this.product,
          data: { amount: value },
        });
      },
    },
    acquisitionPrice: {
      get() {
        return this.product.acquisitionPrice;
      },
      set(value) {
        this.$store.commit("updateProduct", {
          product: this.product,
          data: { acquisitionPrice: value },
        });
      },
    },
  },
  methods: {
    toggleEdit() {
      this.isEditing = !this.isEditing;
    },
  },
};
</script>
