<template>
  <sm-card
    :form="form"
    :header-title="$t('customers.invoice_settings.create.title')"
    @onSubmit="onSubmit">
    <h5 class="pt-4 font-bold">
      {{ $t('invoicing.settings.invoicing-data.type') }}
    </h5>
    <div class="pl-4 sm-row">
      <sm-select
        v-model="form.type"
        name="type"
        :options="invoicingDataTypes"
        half
      />
    </div>

    <hr>

    <h5 class="pt-4 font-bold">
      {{ $t('invoicing.settings.invoicing-data.partner') }}
    </h5>
    <div class="pl-4 sm-row">
      <sm-input
        v-model="form.name"
        name="name"
        :label="$t('invoicing.settings.invoicing-data.name.label')"
        half
      />
      <div class="sm-col sm-col-half" />
      <template v-if="form.type?.id === 'company'">
        <sm-input
          v-model="form.tax_number"
          name="tax_number"
          :label="$t('invoicing.settings.invoicing-data.tax_number.label')"
          half
        />
        <sm-input
          v-model="form.community_tax_number"
          name="community_tax_number"
          :label="$t('invoicing.settings.invoicing-data.community_tax_number.label')"
          :help="$t('invoicing.settings.invoicing-data.community_tax_number.help')"
          half
        />
      </template>
    </div>

    <hr>

    <h5 class="pt-4 font-bold">
      {{ $t('invoicing.settings.invoicing-data.billing_address') }}
    </h5>
    <div class="pl-4 sm-row">
      <div class="w-4/12 px-2 lg:w-2/12">
        <sm-input
          v-model="form.postal_code"
          name="postal_code"
          :label="$t('invoicing.settings.invoicing-data.postal_code.label')"
        />
      </div>
      <div class="w-8/12 px-2 lg:w-4/12">
        <sm-input
          v-model="form.city"
          name="city"
          :label="$t('invoicing.settings.invoicing-data.city.label')"
        />
      </div>
      <div class="w-full px-2 lg:w-6/12">
        <sm-input
          v-model="form.street"
          name="street"
          :label="$t('invoicing.settings.invoicing-data.street.label')"
        />
      </div>
    </div>

    <hr>

    <h5 class="pt-4 font-bold">
      {{ $t('invoicing.settings.invoicing-data.contact') }}
    </h5>
    <div class="pl-4 sm-row">
      <sm-input
        v-model="form.email"
        name="email"
        :label="$t('invoicing.settings.invoicing-data.email.label')"
        half
      />
    </div>

    <hr>

    <h5 class="pt-4 font-bold">
      {{ $t('invoicing.settings.invoicing-data.other') }}
    </h5>
    <div class="pl-4 sm-row">
      <sm-input
        v-model="form.note"
        type="textarea"
        name="note"
        :label="$t('invoicing.settings.invoicing-data.note.label')"
        half
      />
      <sm-checkbox
        v-model="form.is_default"
        name="is_default"
        :label="$t('invoicing.settings.invoicing-data.is_default.label')"
        :text="$t('invoicing.settings.invoicing-data.is_default.help')"
        half
      />
    </div>
  </sm-card>
</template>


<script setup>
import { useFormatSelect } from "@/inertia/composables/global";
import { useForm } from "@inertiajs/vue3";
import { provide } from "vue";


const props = defineProps({
  invoiceSetting: { type: Object, required: false, default: null },
  formRoute: { type: String, required: false, default: "" },
  invoiceTypes: { type: Object, required: false, default: null },
  method: { type: String, required: false, default: "" },
});

const invoicingDataTypes = useFormatSelect(props.invoiceTypes);

const form = useForm({
  type: props.invoiceSetting.type ? invoicingDataTypes.find(data => data.id === props.invoiceSetting.type) : invoicingDataTypes[1],
  name: props.invoiceSetting.name,
  tax_number: props.invoiceSetting.tax_number,
  community_tax_number: props.invoiceSetting.community_tax_number,
  postal_code: props.invoiceSetting.postal_code,
  city: props.invoiceSetting.city,
  street: props.invoiceSetting.street,
  email: props.invoiceSetting.email,
  note: props.invoiceSetting.note,
  is_default: props.invoiceSetting.is_default,
});

provide("form", form);

const onSubmit = () => {
  if (props.method === "put") {
    form
      .transform((data) => ({
        ...data,
        type: data.type?.id,
        _method: "put",
      }))
      .put(props.formRoute);
  } else {
    form
      .transform((data) => ({
        ...data,
        type: data.type?.id
      }))
      .post(props.formRoute);
  }
};
</script>
