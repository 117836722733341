const paginator = {
  root: {
    class: [
      "sm-pagination px-2 py-4 w-full justify-between",
    ]
  },
  firstpagebutton: ({ context }) => ({
    class: [
      "sm-page-link",
      {
        "disabled": context.disabled,
      }
    ]
  }),
  previouspagebutton: ({ context }) => ({
    class: [
      "sm-page-link",
      {
        "disabled": context.disabled,
      }
    ]
  }),
  nextpagebutton: ({ context }) => ({
    class: [
      "sm-page-link",
      {
        "disabled": context.disabled,
      }
    ]
  }),
  lastpagebutton: ({ context }) => ({
    class: [
      "sm-page-link",
      {
        "disabled": context.disabled,
      }
    ]
  }),
  pagebutton: ({ context }) => ({
    class: [
      "sm-page-link",
      {
        "active": context.active
      }
    ]
  }),
  rowperpagedropdown: {
    root: ({ props }) => ({
      class: [
        "!ml-auto inline-flex relative cursor-pointer user-none",
        "bg-white border rounded-md",
        "transition duration-200",
        "h-9 mx-2",
        {
          // "outline-none outline-offset-0 shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] border-blue-500": state.focused && !props.disabled, //Focus
          "hover:border-blue-500": !props.disabled //Hover
        }
      ]
    }),
    input: {
      class: [
        "font-sans text-base text-gray-600 pl-3 py-1.5 m-0 rounded-md apperance-none",
        "block whitespace-nowrap overflow-hidden flex-auto w-[1%] cursor-pointer text-ellipsis border-0 pr-0",
        "dark:text-white" //Dark Mode
      ]
    },
    trigger: {
      class: ["flex items-center justify-center shrink-0", "text-gray-500 dark:text-white w-12 rounded-r-md"]
    },
    panel: {
      class: [
        "bg-white text-gray-600 border-0 rounded-md shadow-[0_2px_12px_rgba(0,0,0,0.1)]",
        "dark:bg-gray-900 dark:text-white/80 dark:border-blue-900/40" //Dark Mode
      ]
    },
    wrapper: "overflow-auto",
    list: "m-0 p-0 py-3 list-none",
    item: ({ context }) => ({
      class: [
        "relative font-normal cursor-pointer space-nowrap overflow-hidden",
        "m-0 py-3 px-5 border-none text-gray-600 rounded-none",
        "transition duration-200",
        "dark:text-white/80", // Dark Mode
        "hover:bg-bo-blue/10",
        {
          // "text-blue-700 bg-blue-50 dark:text-white/80 dark:bg-blue-300": !context.focused && context.selected,
          "text-gray-600 bg-gray-300 dark:text-white/80 dark:bg-blue-900/40": context.focused && !context.selected
        }
      ]
    })
  },
  jumptopageinput: {
    root: "inline-flex mx-2",
    input: {
      class: [
        "font-sans text-base text-gray-600 p-3 m-0 rounded-md apperance-none",
        "block whitespace-nowrap overflow-hidden flex-auto w-[1%] cursor-pointer text-ellipsis border border-gray-300 pr-0",
        "dark:text-white dark:bg-gray-950 dark:border-blue-900/40", //Dark Mode
        "m-0 flex-auto max-w-[3rem]"
      ]
    }
  },
  jumptopagedropdown: {
    root: ({ props, state }) => ({
      class: [
        "inline-flex relative cursor-pointer user-none",
        "bg-white border rounded-md",
        "transition duration-200",
        "h-9 mx-2",
        "dark:bg-gray-950 dark:border-blue-900/40", //DarkMode
        {
          // "outline-none outline-offset-0 shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] border-blue-500": state.focused && !props.disabled, //Focus
          "border-gray-300": !state.focused,
          "hover:border-blue-500": !props.disabled //Hover
        }
      ]
    }),
    input: {
      class: [
        "font-sans text-base text-gray-600 p-0 m-0 rounded-md apperance-none",
        "block whitespace-nowrap overflow-hidden flex-auto w-[1%] cursor-pointer text-ellipsis border-0",
        "dark:text-white" //Dark Mode
      ]
    },
    trigger: {
      class: ["flex items-center justify-center shrink-0", "text-gray-500 dark:text-white w-12 rounded-r-md"]
    },
    panel: {
      class: [
        "bg-white text-gray-600 border-0 rounded-md shadow-[0_2px_12px_rgba(0,0,0,0.1)]",
        "dark:bg-gray-900 dark:text-white/80 dark:border-blue-900/40" //Dark Mode
      ]
    },
    wrapper: "overflow-auto",
    list: "m-0 p-0 py-3 list-none",
    item: ({ context }) => ({
      class: [
        "relative font-normal cursor-pointer space-nowrap overflow-hidden",
        "m-0 py-3 px-5 border-none text-gray-600 rounded-none",
        "transition duration-200",
        "dark:text-white/80", // Dark Mode
        {
          // "text-blue-700 bg-blue-50 dark:text-white/80 dark:bg-blue-300": !context.focused && context.selected,
          "bg-blue-300/40": context.focused && context.selected,
          "text-gray-600 bg-gray-300 dark:text-white/80 dark:bg-blue-900/40": context.focused && !context.selected
        }
      ]
    })
  }
};

export default paginator;
