<template>
  <div
    :id="modalId"
    class="sm-modal"
    tabindex="-1"
    role="dialog"
    :data-backdrop="staticBackdrop ? 'static' : null">
    <div class="sm-modal-backdrop" />
    <div
      class="sm-modal-dialog sm-modal-md"
      role="document">
      <div class="sm-modal-content">
        <div class="sm-modal-header">
          <h5
            v-if="title"
            class="sm-modal-title">
            {{ title }}
          </h5>

          <button
            type="button"
            class="sm-close"
            data-dismiss="sm-modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="sm-modal-body">
          <slot />
        </div>

        <div
          v-if="!!$slots.footer"
          class="sm-modal-footer">
          <slot
            name="footer"
            :toggle="toggle" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: "" },
    id: {
      type: [String, Number],
      default: null,
    },
    staticBackdrop: {
      type: Boolean,
      default: false,
    }
  },
  emits: ["show", "shown", "hide", "hidden"],
  computed: {
    modalId() {
      const id = this.id || this._uid;
      return `modal_${id}`;
    },
  },
  mounted() {
    this.$nextTick(() => {
      // window.loadSmModal();
      document.querySelectorAll(`#${this.modalId}`).forEach(modal => {

        modal.addEventListener("bs-modal-open", () => {
          this.$emit("show");
        });
        modal.addEventListener("bs-modal-opened", () => {
          this.$emit("shown");
        });
        modal.addEventListener("bs-modal-close", () => {
          this.$emit("hide");
        });
        modal.addEventListener("bs-modal-closed", () => {
          this.$emit("hidden");
        });
      });
    });
  },
  methods: {
    toggle() {
      this.$nextTick(() => window.toggleSmModal("#" + this.modalId));
    },
    hide() {
      this.$nextTick(() => window.toggleSmModal("#" + this.modalId, "close"));
    },
  },
};
</script>
