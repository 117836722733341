<script>
import moment from "moment";
import AbstractCrudFilterType from "../utility/AbstractCrudFilterType";

export default {
  mixins: [AbstractCrudFilterType],
  data() {
    return {
      defaults: {
        operator: "=",
        term: moment().format("YYYY-MM-DD"),
      },

      availableOperators: ["=", "<>", "<", "<=", ">", ">="],
    };
  },
  computed: {
    filterData() {
      return {
        term: this.data.data.term,
        operator: this.data.data.operator,
      };
    },
  },
  render() {
    return this.renderAbstract([
      this.createDiv("sm-col w-2/12", [
        this.createSelect(
          [this.data.data, "operator"],
          this.availableOperators.map(t => ({ label: "crud_filter." + t, value: t }))
        ),
      ]),
      this.createDiv("sm-col w-6/12", [
        this.createInput([this.data.data, "term"], "text", "", "input", {
          directives: [{ name: "datepicker" }],
        }),
      ]),
    ]);
  },
};
</script>
