<template>
  <div
    class="sm-form-group"
    :class="{'has-error': inputError, 'sm-row sm-form-inline': props.inline, 'sm-col sm-col-half': props.half}">
    <div :class="{'sm-col lg:w-4/12 w-full': props.inline}">
      <sm-label
        :computed-id="computedId"
        :label="props.label"
        :tooltip="props.tooltip"
        :optional="props.optional"
        :required="props.required">
        <template
          v-if="$slots.afterLabel"
          #afterLabel>
          <slot name="afterLabel" />
        </template>
      </sm-label>
    </div>
    <div :class="props.inline ? `sm-col lg:w-8/12 w-full` : ''">
      <div :class="{'sm-input-group': $slots.prepend || $slots.append, 'flex ': $slots.afterInput}">
        <div
          v-if="$slots.prepend"
          class="sm-input-group-prepend">
          <slot name="prepend" />
        </div>
        <slot />
        <div
          v-if="$slots.afterInput"
          class="self-center sm-input-group-after-input">
          <slot name="afterInput" />
        </div>
        <div
          v-if="$slots.append"
          class="sm-input-group-append">
          <slot name="append" />
        </div>
      </div>
      <div
        v-if="props.help !== ''"
        class="sm-feedback">
        {{ props.help }}
      </div>
      <slot
        v-if="$slots.error"
        name="error" />
      <div v-auto-animate>
        <div
          v-if="props.inputError"
          class="sm-feedback error">
          {{ props.inputError }}
        </div>
        <div v-auto-animate>
          <div
            v-if="inputError"
            class="sm-feedback error">
            {{ typeof inputError !== 'boolean' ? inputError : '' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
  computedId: { type: [Number, String], required: false, default: 0 }
});

const form = inject("form", null);
const props = inject("props");

const inputError = computed(() => {
  return form?.errors[props.name] ? form?.errors[props.name] : props.error;
});
</script>
