<template>
  <label
    class="sm-toggleable sm-switch"
    :for="id">
    <input
      :id="id"
      v-model="checked"
      type="checkbox"
      @change="onChange">
    <span />
  </label>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
  name: "Checkbox",
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: [Boolean, String],
      default: false
    },
    asyncUrl: {
      type: String,
      required: false,
      default: "",
    }
  },
  data() {
    return {
      checked: this.value == "1",
      id: null
    };
  },
  mounted() {
    this.id = this._uid;
  },
  methods: {
    onChange() {
      const toast = useToast();
      if (this.asyncUrl) {
        axios.post(this.asyncUrl)
          .then(function (res) {
            toast.success("Sikeres módosítás");
            console.log(res);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  }
};
</script>
