<template>
  <sm-nav-tabs :data="navTabs" />
</template>

<script setup>
import { usePage } from "@inertiajs/vue3";
const page = usePage();

const navTabs = ref([
  {
    to: page.props.routes.subpages.default,
    label: window.trans("customers.navs.defaults"),
    active: page.component === "customers/show/index",
    nonInertia: page.props.customer.isGroup,
  },
  {
    to: page.props.routes.subpages.transactions,
    label: window.trans("customers.navs.transactions"),
    active: page.component === "customers/show/transactions/index",
  },
  {
    to: page.props.routes.subpages.balanceChanges,
    label: window.trans("customers.navs.balance_changes"),
    active: page.component === "customers/show/balance-changes/index",
  },
  {
    to: page.props.routes.subpages.checkins,
    label: window.trans("customers.navs.checkins"),
    active: page.component === "customers/show/checkins/index",
  },
  {
    to: page.props.routes.subpages.cardUsages,
    label: window.trans("customers.navs.card_usages"),
    active: page.component === "customers/show/card-usages/index",
  },
  {
    to: page.props.routes.subpages.ticketUsages,
    label: window.trans("customers.navs.ticket_usages"),
    active: page.component === "customers/show/ticket-usages/index",
  },
  {
    to: page.props.routes.subpages.lockerAssignments,
    label: window.trans("customers.navs.locker_assignments"),
    active: page.component === "customers/show/lockers/index",
    enabled: page.props.routes.subpages.permissions?.canListLockerAssignments,
  },
]);

</script>
