<template>
  <sm-card
    :form="form"
    @onSubmit="$emit('onSubmit')">
    <div class="grid grid-cols-2 gap-x-4">
      <div>
        <sm-input
          v-model="form.name"
          name="name"
          :label="$t('franchises.fields.name')"
          :placeholder="$t('franchises.fields.name')"
          required
        />
      </div>
      <div />
      <div>
        <sm-input
          v-model="form.owner_id"
          name="owner_id"
          :label="$t('franchises.fields.owner')"
          required
        />
      </div>
    </div>
  </sm-card>
</template>

<script setup>
const form = inject("form");

defineEmits(["onSubmit"]);
</script>
