<template>
  <ul class="sm-nav-tabs-material">
    <li
      v-for="(navtab, index) in filteredLinks"
      :key="navtab?.id || index">
      <template v-if="isLinkEnabled(navtab)">
        <a
          v-if="isInertiaLink(navtab)"
          :href="navtab.to"
          class="transition-all"
          :class="{'active': navtab.active}">
          {{ navtab.label }}
        </a>
        <Link
          v-else
          :href="navtab.to"
          class="transition-all"
          :class="{'active': navtab.active}">
          {{ navtab.label }}
        </Link>
      </template>
    </li>
  </ul>
</template>

<script setup>
import { Link } from "@inertiajs/vue3";

const props = defineProps({
  data: { type: Array, required: true, default: () => [] }
});

const filteredLinks = props.data.filter(link => !!link.to);

const isLinkEnabled = (navtab) => {
  if (navtab.enabled === false) {
    return false;
  }

  return true;
};

const isInertiaLink = (navtab) => navtab.nonInertia;

</script>
