import Cleave from "cleave.js";

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".time-input").forEach(node => {
    new Cleave(node, {
      time: true,
      timePattern: ["h", "m"],
    });
  });
});
