<template>
  <styleguide-layout>
    <sm-anchor-point id="defaults" />
    <select-defaults />

    <sm-anchor-point id="props" />
    <select-props />

    <sm-anchor-point id="slots" />
    <select-slots />

    <sm-anchor-point id="emits" />
    <select-emits />

    <sm-anchor-point id="async-defaults" />
    <async-select-defaults />

    <sm-anchor-point id="async-props" />
    <async-select-props />

    <sm-anchor-point id="async-slots" />
    <async-select-slots />
  </styleguide-layout>
</template>

<script setup>
import AsyncSelectDefaults from "@/inertia/components/styleguide/Selects/AsyncSelectDefaults.vue";
import AsyncSelectProps from "@/inertia/components/styleguide/Selects/AsyncSelectProps.vue";
import AsyncSelectSlots from "@/inertia/components/styleguide/Selects/AsyncSelectSlots.vue";
import SelectDefaults from "@/inertia/components/styleguide/Selects/SelectDefaults.vue";
import SelectEmits from "@/inertia/components/styleguide/Selects/SelectEmits.vue";
import SelectProps from "@/inertia/components/styleguide/Selects/SelectProps.vue";
import SelectSlots from "@/inertia/components/styleguide/Selects/SelectSlots.vue";
import StyleguideLayout from "@/inertia/components/styleguide/StyleguideLayout.vue";
import { useForm } from "@inertiajs/vue3";

const form = useForm({});
provide("form", form);
</script>
