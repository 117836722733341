<template>
  <div class="mx-auto sm-table-responsive lg:w-fit">
    <table class="sm-table sm-table-borderless">
      <thead>
        <tr class="text-center whitespace-nowrap">
          <th>{{ $t('tickets.season_tickets.columns.days') }}</th>
          <th>{{ $t('tickets.season_tickets.columns.all_day') }}</th>
          <th :colspan="dailyValidityMaxShifts * 2 - 1">
            {{ $t('tickets.season_tickets.columns.validity_periods') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <time-table-row
          v-for="day in range(7)"
          :key="day"
          :day="day"
          :model-value="modelValue[day]"
          @update:modelValue="updateValidity(day, $event)"
        />
      </tbody>
    </table>
  </div>
</template>

<script setup>
import range from "lodash/range";
import TimeTableRow from "./TimeTableRow.vue";

const dailyValidityMaxShifts = 2;

const { modelValue } = defineProps({
  modelValue: { type: Array, required: true },
});

const emit = defineEmits(["update:modelValue"]);

const updateValidity = (day, validity) => {
  const newValidities = modelValue;

  newValidities[day] = validity;

  emit("update:modelValue", newValidities);
};
</script>
