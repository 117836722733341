<script>
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
  props: {
    messageId: { type: String, default: "" },
    attachmentsEndpoint: { type: String, default: "" },
  },
  data() {
    return {
      attachments: [],
      loading: false,
    };
  },
  mounted() {
    this.$el.closest(".sm-modal")?.addEventListener("bs-modal-open", () => {
      this.loadAttachments();
    });
  },
  methods: {
    async loadAttachments() {
      this.loading = true;
      try {
        const response = await axios.get(this.attachmentsEndpoint);
        this.attachments = response.data.data;
      } catch (e) {
        const toast = useToast();
        toast.error(e.response.data.message);
      } finally {
        this.loading = false;
      }
    },
    formatSize(size) {
      // size is in bytes;

      if (size < 1024) {
        return `${size} B`;
      }

      if (size < (1024 * 1024)) {
        return `${Math.round(size / 1024)} KB`;
      }

      if (size < (1024 * 1024 * 1024)) {
        return `${Math.round(size / (1024 * 1024))} MB`;
      }

      return size;
    }

  }
};
</script>
