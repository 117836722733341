<template>
  <div>
    <In-Head :title="$page.props.title" />
    <sub-header :title="$page.props.title">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        :data="table"
        :sorts="sorts"
        paginator>
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`export_config.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template v-if="slotProps.data?.field_types?.boolean?.includes(col)">
                <sm-boolean-flag :value="slotProps.data?.[col]" />
              </template>
              <template v-else-if="col === 'progress'">
                {{ progressString(slotProps.data?.completed_jobs, slotProps.data?.job_count) }}
              </template>
              <template v-else-if="col === 'download_url'">
                <a
                  v-if="slotProps.data?.download_url"
                  class="text-bo-blue"
                  target="_blank"
                  :href="slotProps.data?.[col]">
                  {{ $t('export_config.values.download') }}
                </a>
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>
<script setup>
defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableFields = ref([
  "name",
  "progress",
  "download_url",
  "notified",
  "notified_at",
  "created_by",
]);

function percentage(value, total) {
  if (!total || !value) {
    return 0;
  }
  return ((value / total) * 100).toFixed(2);
}

function progressString(value, total) {
  return `${percentage(value, total)}% (${value}/${total})`;
}
</script>
