<template>
  <div>
    <sm-card header-title="Default usage">
      <sm-checkbox
        label="Simple checkbox with value"
      />
      <code class="block !-mt-4"><pre class="text-xs">&lt;sm-checkbox label="..."  /&gt;</pre></code>
    </sm-card>

    <sm-card header-title="Value bindings">
      <sm-checkbox
        v-model="checkboxModel"
        :label="`Simple checkbox with v-model: ${checkboxModel}`"
      />
      <code class="block !-mt-4"><pre class="text-xs">v-model="..."</pre></code>
      <hr>
      <sm-checkbox
        :value="true"
        label="Simple checkbox with value"
      />
      <code class="block !-mt-4"><pre class="text-xs">:value="..."</pre></code>
    </sm-card>

    <sm-card header-title="Texts and helps">
      <sm-checkbox
        label="Checkbox with label"
      />
      <code class="block !-mt-4"><pre class="text-xs">label="..."</pre></code>
      <hr>
      <sm-checkbox
        label="Checkbox with custom text"
        text="This is a custom text"
      />
      <code class="block !-mt-4"><pre class="text-xs">text="..."</pre></code>
      <hr>
      <sm-checkbox
        label="Checkbox with help text"
        help="This is a help text"
      />
      <code class="block !-mt-4"><pre class="text-xs">help="..."</pre></code><br>
    </sm-card>
  </div>
</template>

<script setup>
const checkboxModel = ref(false);
</script>
