<template>
  <div>
    <h4>{{ $t('tickets.season_tickets.validity') }}</h4>
    <sm-card>
      <div class="grid grid-cols-2 gap-x-4">
        <div>
          <sm-input
            v-model="form.available_uses"
            :disabled="form.limitless_usage"
            name="available_uses"
            :label="$t('tickets.season_tickets.fields.available_uses')"
            type="number"
            :min="0"
          />
        </div>
        <div>
          <sm-label :label="$t('tickets.season_tickets.fields.validity')" />
          <div class="flex space-x-4">
            <sm-input
              v-model="form.validity"
              class="flex-1"
              name="validity"
              type="number"
              :min="0"
            />
            <sm-select
              v-model="form.validity_range"
              class="flex-1"
              name="available_uses"
              :options="validityRanges"
            />
          </div>
        </div>
        <div>
          <sm-checkbox
            v-model="form.limitless_usage"
            name="limitless_usage"
            inline
            :label="$t('tickets.season_tickets.fields.limitless_usage')"
          />
        </div>
        <div>
          <sm-checkbox
            v-model="form.last_day_excluded"
            name="last_day_excluded"
            inline
            :label="$t('tickets.season_tickets.fields.last_day_excluded.label')"
            :help="$t('tickets.season_tickets.fields.last_day_excluded.help')"
          />
        </div>
      </div>
    </sm-card>
  </div>
</template>

<script setup>
const form = inject("form");
const validityRanges = inject("validityRanges");
</script>
