
const dialog = {
  root: ({ state }) => ({
    class: [
      "max-h-90 transform scale-100",
      "m-0 w-[50vw]",
      {
        "transition-none transform-none !w-screen !h-screen !max-h-full !top-0 !left-0": state.maximized
      }
    ]
  }),
  mask: ({ props }) => ({
    class: ["transition duration-200", { "bg-black/40": props.modal }]
  }),
  transition: () => {
    return {
      enterFromClass: "opacity-0 scale-75",
      enterActiveClass: "transition-all duration-200 ease-out",
      leaveActiveClass: "transition-all duration-200 ease-out",
      leaveToClass: "opacity-0 scale-75"
    };
  }
};

export default dialog;
