<template>
  <div>
    <In-Head :title="$t('customers.invoice_settings.create.title')" />

    <sub-header :title="$t('customers.invoice_settings.create.title') ">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          :href="$page.props.routes.back"
          preset="back"
          icon="arrow-left" />
      </template>
    </sub-header>

    <invoice-settings-form
      :invoice-setting="defaultInvoiceSettings"
      :form-route="$page.props.routes.storeRoute"
      :invoice-types="$page.props.invoiceTypes"
      method="post" />
  </div>
</template>

<script setup>
import InvoiceSettingsForm from "@/inertia/forms/InvoiceSettingsForm.vue";

const defaultInvoiceSettings = ref({
  type: null,
  name: "",
  tax_number: "",
  community_tax_number: "",
  postal_code: "",
  city: "",
  street: "",
  email: "",
  note: "",
  is_default: false,
});
</script>
