<template>
  <div>
    <DataTable
      v-model:filters="computedFilters"
      :value="computedValue"
      paginator
      :rows-per-page-options="[5, 10, 15, 25, 50]"
      :refresh="refresh || null"
      :rows="pageAndFilterData.perPage"
      paginator-template="null"
      striped-rows
      class="sm-data-table"
      lazy
      :loading="isLoading"
      :total-records="computedMeta?.total || 0"
      :sort-field="sorts?.field"
      :sort-order="sorts?.order === 'asc' ? 1 : -1"
      @sort="sortChanged"
      @page="pageChanged">
      <template
        v-if="$slots.header"
        #header>
        <slot name="header" />
      </template>

      <slot name="columns" />

      <template
        v-if="$slots.empty"
        #empty>
        <slot name="empty" />
      </template>
      <template
        v-else
        #empty>
        <sm-alert
          class="z-10"
          color="warning"
          no-margin>
          {{ $t('generic.empty_table') }}
        </sm-alert>
      </template>

      <template
        v-if="paginator"
        #paginatorstart>
        <div class="flex items-center space-x-4">
          <vue-paginator
            v-if="computedMeta"
            :pagination="computedMeta"
            @page-selected="$event => pageChanged({ page: $event })" />
          <span class="leading-9 ">({{ computedMeta?.current_page }} / {{ computedMeta?.last_page }})</span>
        </div>
      </template>

      <template
        v-if="paginator"
        #paginatorend>
        <sm-select
          v-model="pageAndFilterData.perPage"
          no-form
          :option-width="100"
          :options="[5, 10, 15, 25, 50]"
          append-to-body
          class="mb-0"
          @input="perPageChanged" />
      </template>
    </DataTable>
  </div>
</template>

<script setup>
import { router } from "@inertiajs/vue3";
import { watchOnce, watchPausable } from "@vueuse/core";
import { nextTick } from "vue";

const props = defineProps({
  data: { type: Object, required: false, default: () => {} },
  loading: { type: Boolean, required: false, default: false },
  filters: { type: Object, required: false, default: null },
  rows: { type: Number, required: false, default: 15 },
  refresh: { type: Boolean, required: false, default: false },
  sorts: { type: [Object, String], required: false, default: () => {} },
  loaded: { type: Boolean, required: false, default: false },
  paginator: { type: Boolean, required: false, default: true },
  tablePropName: { type: String, required: false, default: "table" },
});

const isLoading = ref(true);

const computedValue = computed(() => {
  let data = [];

  if (props.data) {
    data = props.data.data;
  }

  return data;
});

const computedMeta = computed(() => {
  return props.data?.meta;
});

const computedFilters = computed(() => {
  return props.filters;
});

const pageAndFilterData = reactive({
  page: 1,
  perPage: 15,
});

// reload table when changed
const { pause, resume } = watchPausable(pageAndFilterData, () => {
  router.reload({ data: pageAndFilterData, only: [props.tablePropName] }, { preserveState: true });
});

// refresh table on mounted
onMounted(() => {
  pause();
  router.reload({ only: [props.tablePropName] }, { preserveState: true });
  if (props.loaded) {
    isLoading.value = false;
  }
});

// to load
watchOnce(props, (obj) => {
  if (obj.data.meta) {
    isLoading.value = false;
    pageAndFilterData.page = props.data?.meta?.current_page;
    pageAndFilterData.perPage = props.data?.meta?.per_page;
    nextTick(() => {
      resume();
    });
  }
});

/* Page change */
const pageChanged = (pageData) => {
  if (pageData.page) {
    pageAndFilterData.page = parseInt(pageData.page);
  }
};

/* Per page changed */
const perPageChanged = () => {
  pageAndFilterData.page = 1;
};

/* Sorting changed */
const sortChanged = async (sortData) => {
  if (sortData.sortField) {
    pageAndFilterData.sortField = sortData.sortField;
    pageAndFilterData.sortOrder = sortData.sortOrder === -1 ? "desc" : "asc";
  } else {
    pageAndFilterData.sortField = null;
    pageAndFilterData.sortOrder = null;
  }
};
</script>
