<template>
  <div>
    <In-Head :title="$t('customers.merge_requests.index.title')" />

    <sub-header :title="$t('customers.merge_requests.index.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        :data="$page.props.table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`customers.merge_requests.table.${col}`)">
            <template #body="slotProps">
              <users
                v-if="col === 'users'"
                :data="slotProps.data?.[col]" />
              <template v-else-if="col === 'merged'">
                <sm-boolean-flag :value="slotProps.data?.[col]" />
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div
                class="flex justify-end space-x-1">
                <sm-form-button
                  v-if="slotProps.data?.permissions.approve"
                  v-tippy="$t('generic.accept')"
                  icon="check"
                  :route="slotProps.data?.routes.approve"
                  name="approveMergeRequest"
                  method="post"
                  @onSuccess="onSuccess"
                />
                <sm-button
                  v-if="slotProps.data?.permissions.reject"
                  v-tippy="$t('generic.reject')"
                  size="icon"
                  icon="x"
                  @click="deleteMerge(slotProps.data?.routes.reject)"
                />
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
    <sm-confirm-dialog
      has-fake-accept-button
      :group="`delete_${uid}`"
      :fake-accept-label="$t('customers.merge_requests.delete_modal.accept')"
      @onFakeAccept="onFakeAccept">
      <sm-input
        v-model="form.note"
        name="note"
        :error="form.errors.note"
        type="textarea" />
    </sm-confirm-dialog>
  </div>
</template>

<script setup>
import { router, useForm } from "@inertiajs/vue3";
import uniqueId from "lodash.uniqueid";
import { useConfirm } from "primevue/useconfirm";
import Users from "./components/Users.vue";

const tableFields = ref([
  "merged", "created_at", "users", "created_by", "actioned_by", "merge_reason", "reject_reason"
]);

const onSuccess = () => {
  router.reload({ only: ["table"] }, { preserveScroll: true });
};

const form = useForm({
  note: ""
});

provide("form", form);

const confirm = useConfirm();
const uid = uniqueId();

const onFakeAccept = () => {
  form.post(deleteRoute.value, {
    only: ["table", "flash", "errors"],
    onSuccess: () => {
      deleteRoute.value = "";
      confirm.close();
    }
  });

};

const deleteRoute = ref("");

const deleteMerge = (route) => {
  deleteRoute.value = route;
  confirm.require({
    group: `delete_${uid}`,
    header: window.trans("customers.merge_requests.delete_modal.header"),
    message: window.trans("customers.merge_requests.delete_modal.message"),
  });
};
</script>
