<template>
  <sm-card header-title="Emits">
    <div class="sm-row">
      <div class="w-1/2 sm-col">
        <p class="mb-2">
          Emits the <code>update:modelValue</code> event when the value of the input has changed<br>
          <strong class="inline-block pl-2 w-36">Emit name:</strong> @update:modelValue<br>
          <code class="inline-block pl-2"><pre class="text-xs text-bo-yellow-600">&lt;sm-input @update:modelValue="customEvent" /&gt;</pre></code>
        </p>
      </div>
      <div class="w-1/2 sm-col">
        <sm-select
          label="Emit the update:modelValue event (check the console)"
          :options="options"
          @update:modelValue="console.log('update:modelValue evnet emitted')" />
      </div>
    </div>
    <hr>

    <div class="sm-row">
      <div class="w-1/2 sm-col">
        <p class="mb-2">
          Emits the <code>input</code> event when the value of the input has changed<br>
          <strong class="inline-block pl-2 w-36">Emit name:</strong> @input<br>
          <code class="inline-block pl-2"><pre class="text-xs text-bo-yellow-600">&lt;sm-input @input="customEvent" /&gt;</pre></code>
        </p>
      </div>
      <div class="w-1/2 sm-col">
        <sm-select
          label="Emit the update:modelValue event (check the console)"
          :options="options"
          @input="console.log('input event emitted')" />
      </div>
    </div>
    <hr>

    <div class="sm-row">
      <div class="w-1/2 sm-col">
        <p class="mb-2">
          Emits the <code>onSelected</code> event when the value of the input has changed<br>
          <strong class="inline-block pl-2 w-36">Emit name:</strong> @onSelected<br>
          <code class="inline-block pl-2"><pre class="text-xs text-bo-yellow-600">&lt;sm-input @onSelected="customEvent" /&gt;</pre></code>
        </p>
      </div>
      <div class="w-1/2 sm-col">
        <sm-select
          label="Emit the onSelected event (check the console)"
          :options="options"
          @onSelected="console.log('onSelected event emitted')" />
      </div>
    </div>
    <hr>
  </sm-card>
</template>

<script setup>
const options = ref([
  {
    id: 1,
    label: "First something"
  },
  {
    id: 2,
    label: "Second something"
  },
  {
    id: 3,
    label: "Third and extremly long something"
  }
]);
</script>
