<template>
  <div>
    <div
      class="tags-input-root"
      :class="{
        [wrapperClass + ' sm-input-text flex items-center']: true,
        active: isInputActive && !error,
        'tags-input-read-only': readOnly,
        disabled: disabled,
        'tags-input-invalid': error,
      }"
      style="position: relative"
    >
      <fieldset style="height: inherit">
        <span
          v-for="(tag, index) in innerTags"
          :key="index"
          class="tags-input-badge tags-input-badge-pill tags-input-badge-selected-default"
        >
          <span>{{ tag }}</span>
          <a
            v-if="!readOnly"
            class="tags-input-remove"
            @click.prevent.stop="remove(index)"
          ><slot name="remove-icon" /></a>
        </span>

        <input
          v-if="!readOnly && !isLimit"
          ref="inputtag"
          v-model="newTag"
          :placeholder="placeholder"
          type="text"
          class="new-tag"
          @keydown.delete.stop="removeLastTag"
          @keydown="addNew"
          @blur="handleInputBlur"
          @focus="handleInputFocus"
        >

        <input
          type="hidden"
          :value="JSON.stringify(innerTags)"
          :name="hiddenInputName"
        >
      </fieldset>
      <small
        v-if="error"
        class="text-bo-red"
      >{{ error }}</small>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
const validators = {
  email: new RegExp(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ),
  url: new RegExp(
    /^(https?|ftp|rmtp|mms):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)(:(\d+))?\/?/i
  ),
  text: new RegExp(/^[a-zA-Z]+$/),
  digits: new RegExp(/^[\d() \.\:\-\+#]+$/),
  isodate: new RegExp(
    /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/
  ),
};
/* eslint-enable */
export default {
  name: "TagInput",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "",
    },
    hiddenInputName: {
      type: String,
      default: "",
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: [String, Function, Object],
      default: "",
    },
    addTagOnComma: {
      type: Boolean,
      default: false,
    },
    addTagOnSpace: {
      type: Boolean,
      default: false,
    },
    addTagOnTab: {
      type: Boolean,
      default: false,
    },
    addTagOnBlur: {
      type: Boolean,
      default: false,
    },
    deleteOnBackspace: {
      type: Boolean,
      default: false,
    },
    limit: {
      default: -1,
      type: Number,
    },
    allowDuplicates: {
      type: Boolean,
      default: false,
    },
    beforeAdding: {
      type: Function,
      default: null,
    },
    wrapperClass: {
      type: String,
      default: "tags-input-wrapper-default",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
  },
  emits: ["update:tags", "input"],
  data() {
    return {
      newTag: "",
      innerTags: [...this.value],
      isInputActive: false,
    };
  },
  computed: {
    isLimit: function () {
      return this.limit > 0 && Number(this.limit) === this.innerTags.length;
    },
    addTagOnKeys() {
      let keys = [13]; // keyCode of Return

      if (this.addTagOnComma) {
        keys = keys.concat(188);
      }
      if (this.addTagOnSpace) {
        keys = keys.concat(32);
      }
      if (this.addTagOnTab) {
        keys = keys.concat(9);
      }

      return keys;
    },
  },
  watch: {
    value() {
      this.innerTags = [...this.value];
    },
  },
  methods: {
    focusNewTag() {
      if (this.readOnly || !this.$el.querySelector(".new-tag")) {
        return;
      }
      this.$el.querySelector(".new-tag").focus();
    },
    handleInputFocus() {
      this.isInputActive = true;
    },
    handleInputBlur(e) {
      this.isInputActive = false;
      this.addNew(e);
    },
    async addNew(e) {
      const keyShouldAddTag = e
        ? this.addTagOnKeys.indexOf(e.keyCode) !== -1
        : true;
      const typeIsNotBlur = e && e.type !== "blur";

      if (
        (!keyShouldAddTag && (typeIsNotBlur || !this.addTagOnBlur)) ||
        this.isLimit
      ) {
        return;
      }

      const tag = this.beforeAdding
        ? await this.beforeAdding(this.newTag)
        : this.newTag;
      const isValid = await this.validateIfNeeded(tag);
      e && e.preventDefault();

      if (
        tag &&
        isValid &&
        (this.allowDuplicates || this.innerTags.indexOf(tag) === -1)
      ) {
        this.innerTags = this.innerTags.concat(tag);
        this.tagChange();
      }

      this.newTag = "";
    },
    validateIfNeeded(tagValue) {
      if (this.validate === "" || this.validate === undefined) {
        return true;
      }
      if (typeof this.validate === "function") {
        return this.validate(tagValue);
      }
      if (
        typeof this.validate === "string" &&
        Object.keys(validators).indexOf(this.validate) > -1
      ) {
        return validators[this.validate].test(tagValue);
      }
      if (
        typeof this.validate === "object" &&
        this.validate.test !== undefined
      ) {
        return this.validate.test(tagValue);
      }
      return true;
    },
    remove(index) {
      this.innerTags.splice(index, 1);
      this.tagChange();
    },
    removeLastTag() {
      if (this.deleteOnBackspace && !this.newTag) {
        this.innerTags.pop();
        this.tagChange();
      }
    },
    tagChange() {
      this.$emit("update:tags", this.innerTags);
      this.$emit("input", this.innerTags);
    },
  },
};
</script>

<!--TODO: move styles-->
<style lang="postcss">
.tags-input {
  @apply flex flex-wrap items-center;

  &-wrapper-default {
    @apply w-full;

    &.active {
      @apply border-bo-blue;
    }

    &.read-only {
      @apply cursor-default;
    }

    fieldset {
      @apply flex items-center flex-wrap w-full;

      input {
        @apply flex-1 bg-transparent border-0 focus:outline-0;
        @apply outline-0 text-bo-gray-500 w-full;
      }
    }
  }

  /* The tag badges & the remove icon */
  span {
    @apply inline-block mr-1;
  }

  &-remove {
    @apply inline-block cursor-pointer overflow-hidden;
    @apply absolute right-1 top-1 p-1 mt-[1px];
    @apply focus:outline-0;

    &:before,
    &:after {
      content: "";
      @apply absolute w-[75%] left-0 bg-bo-blue;
      @apply h-0.5;
    }

    &:before {
      @apply rotate-45;
    }

    &:after {
      @apply -rotate-45;
    }
  }

  /* Tag badge styles */
  &-badge {
    @apply relative inline-block;
    @apply py-1 px-2 mr-1;
    @apply text-xs font-bold leading-none text-center whitespace-nowrap;
    @apply rounded-md text-ellipsis overflow-hidden;

    &-pill {
      @apply pr-4 pl-2;
      @apply rounded-2xl;

      &.disabled {
        @apply pr-2;
      }
    }

    &-selected-default {
      @apply bg-bo-gray-100 text-bo-gray-800;
    }
  }
}
</style>
