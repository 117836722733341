<template>
  <sm-input-wrapper>
    <template
      v-if="$slots.afterLabel"
      #afterLabel>
      <slot name="afterLabel" />
    </template>
    <template
      v-if="$slots.prepend"
      #prepend>
      <slot name="prepend" />
    </template>
    <template
      v-if="$slots.afterInput"
      #afterInput>
      <slot name="afterInput" />
    </template>
    <template
      v-if="$slots.append"
      #append>
      <slot name="append" />
    </template>
    <label
      :for="id"
      class="block sm-toggleable sm-radio"
      :class="{'disabled': disabled}">
      <input
        :id="id"
        :value="id"
        type="radio"
        :name="name"
        :checked="modelValue === id || checked"
        class="sm-input-text"
        :disabled="disabled"
        @input="updateInput($event)">
      <span>
        {{ text }}
      </span>
    </label>
  </sm-input-wrapper>
</template>

<script setup>
import SmInputWrapper from "@/inertia/components/forms/_layouts/SmInputWrapper.vue";

const props = defineProps({
  name: { type: String, required: false, default: "" },
  id: { type: String, required: false, default: "" },
  label: { type: String, required: false, default: "" },
  text: { type: String, required: false, default: "" },
  value: { type: [Boolean, String, Number], required: false, default: false },
  modelValue: { type: [Boolean, String, Number], required: false, default: false },
  tooltip: { type: String, required: false, default: "" },
  help: { type: String, required: false, default: "" },
  error: { type: [Boolean, String], required: false, default: false },
  errors: { type: Array, required: false, default: () => [] },
  inline: { type: Boolean, required: false, default: false },
  half: { type: Boolean, required: false, default: false },
  checked: { type: Boolean, required: false, default: false },
  disabled: { type: Boolean, required: false, default: false },
  required: { type: Boolean, required: false, default: false },
  optional: { type: Boolean, required: false, default: false },
});
provide("props", props);

const emit = defineEmits(["update:modelValue"]);

function updateInput(e) {
  emit("update:modelValue", e.target.id);
}
</script>
