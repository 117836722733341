<script>
export default {
  props: {
    initBankNotes: {
      type: Object,
      default: () => ({})
    },
    cashRegisterCash: {
      type: Object,
      default: () => ({})
    },
    initCashRegister: { type: Number, default: 0 }
  },
  data() {
    return {
      bankNotes: this.initBankNotes || {},
      selectedCashRegister: this.initCashRegister,
    };
  },
  computed: {
    sum() {
      return Object.keys(this.bankNotes).reduce((sum, index) => {
        if (this.bankNotes[index]) {
          sum += parseInt(this.bankNotes[index]) * index;
        }

        return sum;
      }, 0);
    },
    formattedSum() {
      return this.formatAsHUF(this.sum);
    },
    sameAsPreviousValue() {
      const previousValue = this.previousValueForCashRegister;

      if (previousValue !== null) {
        return this.sum === parseInt(previousValue);
      }

      return true;
    },
    previousValueForCashRegister() {
      return this.cashRegisterCash[this.selectedCashRegister];
    }
  },
  methods: {
    formatAsHUF(amount) {
      let options = { style: "currency", currency: "HUF", minimumFractionDigits: 0, maximumFractionDigits: 0 };
      let numberFormat = new Intl.NumberFormat("hu-HU", options);

      return numberFormat.format(amount);
    }
  },
};
</script>
