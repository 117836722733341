<script>
import CrudTextFilterType from "./filter_types/CrudTextFilterType.vue";
import CrudDateFilterType from "./filter_types/CrudDateFilterType.vue";
import CrudNumberFilterType from "./filter_types/CrudNumberFilterType.vue";
import CrudSelectFilterType from "./filter_types/CrudSelectFilterType.vue";
import CrudBoolFilterType from "./filter_types/CrudBoolFilterType.vue";
import CrudScoutSearchFilterType from "./filter_types/CrudScoutSearchFilterType.vue";

export default {
  props: {
    availableFilters: {
      type: Array,
      default: () => [],
    },
    appliedFilters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const data = {
      id: 1,
      filterComponents: {
        CrudTextFilterType,
        CrudDateFilterType,
        CrudNumberFilterType,
        CrudSelectFilterType,
        CrudBoolFilterType,
        CrudScoutSearchFilterType
      },
      addedFilters: [],

      newFilter: "",
      visible: false,
    };

    if (this.appliedFilters) {
      data.addedFilters = this.appliedFilters.map(fs => {
        const filter = JSON.parse(fs);
        if (!filter) {
          return null;
        }

        const filterType = this.availableFilters.find(af => af.field === filter.field);
        if (!filterType) {
          return null;
        }

        data.id = filter.id + 1;

        return {
          id: filter.id,
          component: this.componentFromFilterType(filterType),
          filter: filterType,
          data: filter,
        };
      }).filter(f => !!f);
    }

    return data;
  },
  mounted() {
    document.addEventListener("click", e => {
      if (!(e.target === this.$el || this.$el.contains(e.target))) {
        this.visible = false;
      }
    });
  },
  methods: {
    addFilter() {
      if (!this.newFilter) {
        return;
      }

      this.addedFilters.push({
        id: this.id++,
        component: this.componentFromFilterType(this.newFilter),
        filter: this.newFilter,
        data: {},
      });

      this.newFilter = "";
    },
    componentFromFilterType(filterType) {
      return "Crud" + filterType.component + "FilterType";
    },
    removeFilter(event, filter) {
      event.stopPropagation();
      this.addedFilters = this.addedFilters.filter(f => f !== filter);
    },
    reset() {
      this.addedFilters = [];
    },
    submitByEnter() {
      this.$refs.submitButton?.dispatchEvent(new MouseEvent("click"));
    },
    collectAndSubmitFilters(event) {
      const filters = Array.from(this.$el.querySelectorAll("input[type=hidden]"))
        .map(node => node.value);

      event.target.dispatchEvent(new CustomEvent("input", {detail: filters}));
      window.Livewire.emit("crud:changed", filters);

      this.visible = false;
    },
    clearFilters(event) {
      event.target.dispatchEvent(new CustomEvent("input", {detail: []}));
      window.Livewire.emit("crud:changed", []);
      this.reset();

      this.visible = false;
    },
    toggleControl() {
      this.visible = !this.visible;
    },
  }
};
</script>
