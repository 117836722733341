export default (params) => ({
  requiresExtenderTicket: false,
  extenderTicket: null,
  ticketSelected(event) {
    const selectedOption = event.target.options[event.target.selectedIndex];
    this.loadPropsFromOption(selectedOption);
  },
  init() {
    if (params && params.onlyOne) {
      this.$el.addEventListener("submit", e => {
        // Check if the only ticket requires extender.
        if (!params.requiresExtender) {
          return true;
        }

        let message = `'${params.extender.name}' kiegészítő jegy szükséges a beléptetéshez!`;

        if (params.extender.owns) {
          message += " A vendég rendelkezik ilyen kiegészítő jeggyel, ami fel lesz használva a beléptetéshez.";
        } else {
          message += ` Kiegészítő jegy lesz vásárolva ${params.extender.price} Ft-ért pluszban.`;
        }

        if (!confirm(message)) {
          e.preventDefault();
          e.stopPropagation();
        }
      });
    } else {
      // We handle multiple, make sure we properly initialize `requiresExtenderTicket`
      if (this.$refs.ticketSelector) {
        const selectedOption = this.$refs.ticketSelector.options[this.$refs.ticketSelector.selectedIndex];
        this.loadPropsFromOption(selectedOption);
      }
    }
  },
  loadPropsFromOption(option) {
    this.requiresExtenderTicket = "requiresExtender" in option.dataset;
    if (this.requiresExtenderTicket) {
      this.extenderTicket = JSON.parse(option.dataset.extender);
    }
  }
});
