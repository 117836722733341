<template>
  <div>
    <vc-date-picker
      v-model="date"
      mode="time"
      is24hr
    >
      <template
        v-for="(index, name) in $slots"
        #[name]="props"
      >
        <slot
          :name="name"
          v-bind="props"
        />
      </template>
    </vc-date-picker>
  </div>
</template>

<script>
import parse from "date-fns/parse";

export default {
  props: {
    value: { type: String, default: "" },
  },
  data() {
    return {
      date: (this.value && this.value !== "") ? parse(this.value, "HH:mm", new Date()) : null,
    };
  },
};
</script>

<style>
.vc-date {
  display: none !important;
}
</style>
