<template>
  <div>
    <sm-confirm-dialog
      :group="`${name}_${uid}`"
      :accept-label="$t('generic.ok')"
      :has-cancel-button="false" />
  </div>
</template>

<script setup>
import uniqueId from "lodash.uniqueid";
import { useConfirm } from "primevue/useconfirm";

const confirm = useConfirm();

const uid = uniqueId();

const props = defineProps({
  name: { type: String, required: true, default: "delete" },
  visible: { type: Boolean, required: true, default: false },
  message: { type: String, required: false, default: "!" },
});

const emit = defineEmits(["onAccept"]);

watch(() => props.visible, (e) => {
  if (e === true) {
    confirm.require({
      group: `${props.name}_${uid}`,
      message: props.message,
      accept: () => {
        emit("onAccept");
      }
    });
  }
});
</script>
