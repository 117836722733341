<template>
  <div>
    <In-Head title="Inertia demo" />

    <sm-card
      :form="form"
      @onSubmit="onSubmit">
      <template #header>
        Demo form
      </template>
      <div class="grid grid-cols-2 gap-x-4 ">
        <div>
          <sm-input
            v-model="form.first_name"
            name="first_name"
            label="Vezetéknév"
            placeholder="Vezetéknév"
          />
        </div>
        <div>
          <sm-input
            v-model="form.last_name"
            name="last_name"
            label="Keresztnév"
            placeholder="Keresztnév"
          />
        </div>
        <div>
          <sm-input
            v-model="form.email"
            name="email"
            label="E-mail cím"
            placeholder="pl. pelda@email.hu"
          />
        </div>
        <div>
          <sm-input
            v-model="form.password"
            name="password"
            type="password"
            label="Jelszó"
            placeholder="Jelszó"
          />
        </div>
        <div>
          <sm-input
            v-model="form.password_confirmation"
            type="password"
            name="password_confirmation"
            label="Jelszó megerősítése"
            placeholder="Jelszó megerősítése"
          />
        </div>
        <div>
          <sm-date-picker
            v-model="form.birth_date"
            name="birth_date"
            label="Születési idő"
            :placeholder="`pl: ${format(new Date(), 'yyyy. MM. dd')}`"
          />
        </div>
        <div>
          <sm-checkbox
            v-model="form.terms_accepted"
            name="terms_accepted"
            label="Felhasználási feltételek"
            text="Elfogadva"
            inline
          />
        </div>
      </div>

      <template #footer>
        <sm-button
          preset="save"
          :disabled="!form.isDirty"
          :loading="form.processing"
          type="submit" />
      </template>
    </sm-card>
  </div>
</template>

<script setup>
import { useForm, usePage } from "@inertiajs/vue3";
import { format } from "date-fns";

const page = usePage();

const form = useForm({
  first_name: "Ez már ki van töltve",
  last_name: null,
  email: null,
  password: null,
  password_confirmation: null,
  birth_date: null,
  terms_accepted: false,
});

const onSubmit = () => {
  form
    .transform((data) => ({
      ...data,
      birth_date: data.birth_date ? format(new Date(data.birth_date), "yyyy-MM-dd") : ""
    }))
    .post(page.url);
};

provide("form", form);
</script>
