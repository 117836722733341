<template>
  <sm-input-wrapper>
    <template
      v-if="$slots.afterLabel"
      #afterLabel>
      <slot name="afterLabel" />
    </template>
    <template
      v-if="$slots.prepend"
      #prepend>
      <slot name="prepend" />
    </template>
    <template
      v-if="$slots.afterInput"
      #afterInput>
      <slot name="afterInput" />
    </template>
    <template
      v-if="$slots.append"
      #append>
      <slot name="append" />
    </template>
    <label
      :for="computedId"
      class="block sm-toggleable"
      :class="[smSwitch ? 'sm-switch' : 'sm-checkbox', {'disabled': disabled}]">
      <input
        :id="computedId"
        type="checkbox"
        :name="computedName"
        :checked="!!(value || modelValue)"
        class="sm-input-text"
        :disabled="disabled"
        @input="updateInput($event)">
      <span>
        <vue-feather
          v-if="!smSwitch"
          type="check"
          class="w-2.5 text-white" />
        {{ text }}
      </span>
    </label>
  </sm-input-wrapper>
</template>

<script setup>
import SmInputWrapper from "@/inertia/components/forms/_layouts/SmInputWrapper.vue";

import uniqueId from "lodash.uniqueid";

const props = defineProps({
  name: { type: [String, Number], required: false, default: "" },
  id: { type: String, required: false, default: "" },
  label: { type: String, required: false, default: "" },
  text: { type: [String, Number], required: false, default: window.trans("generic.yes") },
  value: { type: Boolean, required: false, default: false },
  modelValue: { type: [Boolean, Number, String], required: false, default: false },
  tooltip: { type: String, required: false, default: "" },
  help: { type: String, required: false, default: "" },
  error: { type: [Boolean, String], required: false, default: false },
  errors: { type: Array, required: false, default: () => [] },
  inline: { type: Boolean, required: false, default: false },
  half: { type: Boolean, required: false, default: false },
  disabled: { type: Boolean, required: false, default: false },
  required: { type: Boolean, required: false, default: false },
  optional: { type: Boolean, required: false, default: false },
  smSwitch: { type: Boolean, required: false, default: false },
});
provide("props", props);

const emit = defineEmits(["update:modelValue"]);

const computedId = computed(() => {
  if (props.id) {
    return props.id;
  } else {
    return `id_${uniqueId()}`;
  }
});

const computedName = computed(() => {
  if (props.name) {
    return props.name;
  } else {
    return `name_${uniqueId()}`;
  }
});

function updateInput(e) {
  emit("update:modelValue", e.target.checked);
}
</script>
