<script>
export default {
  props: {
    creditLimit: Boolean,
  },
  data() {
    return {
      useWebCamPhoto: false,
      image: null,
      open: false,
      useCustomCreditLimit: this.creditLimit,
    };
  },
  watch: {
    open() {
      if (!this.open) {
        this.stopCamera();
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      const checkbox = this.$el.querySelector("input[type=checkbox][name=custom_credit_limit]");
      this.useCustomCreditLimit = checkbox.checked;
    });
  },
  methods: {
    showModal(selector) {
      this.open = true;
      this.$nextTick(() => {
        window.toggleSmModal(selector);
        document.querySelector(selector).addEventListener("bs-modal-closed", () => {
          this.open = false;
        });
      });
    },
    stopCamera() {
      this.$refs.webCam?.onStop();
    },
  }
};
</script>
