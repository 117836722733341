<template>
  <div
    v-show="visible"
    class="fixed sm-card sa-container">
    <input
      ref="inputRef"
      v-model="terms"
      type="text"
      title="Search"
      placeholder="Keresés menük között..."
      @keydown.enter.prevent="openSelectedResult"
      @keydown.down.prevent="selectNext"
      @keydown.up.prevent="selectPrev"
      @keydown.esc.prevent="close"
      @keydown.page-down.prevent="close">

    <div
      v-if="matches.length"
      class="matches">
      <a
        v-for="(match, index) in matches"
        :key="index"
        :class="{ 'active': selectedIndex === index }"
        :href="match.item.url"
        @mouseenter="selectedIndex = index">
        {{ match.item.label }}
      </a>
    </div>
  </div>
</template>

<script>
import Fuse from "fuse.js";
import hotkeys from "hotkeys-js";

export default {
  data() {
    return {
      visible: false,
      options: [],
      matches: [],
      fuse: null,
      terms: "",
      selectedIndex: null,
    };
  },
  watch: {
    terms() {
      if (!this.fuse || this.terms === "") {
        this.matches = this.options.map(o => ({ item: o })).slice(0, 10);
      } else {
        this.matches = this.fuse.search(this.terms).slice(0, 10);
      }

      if (this.matches.length) {
        this.selectedIndex = 0;
      }
    },
  },
  mounted() {
    // Check context
    if (!document.querySelector("aside.sidebar")) {
      return;
    }

    this.options = [...document.querySelectorAll(".sidebar-body a")]
      .filter(link => link.getAttribute("href"))
      .map(link => ({
        label: link.text.trim(),
        url: link.getAttribute("href"),
      }));

    this.fuse = new Fuse(this.options, {
      keys: [
        {
          name: "label",
          weight: 0.7,
        },
        {
          name: "url",
          weight: 0.3,
        },
      ],
    });

    hotkeys("command+k,ctrl+k", "global", e => {
      e.preventDefault();
      if (e.repeat) {
        return;
      } else {
        this.visible = true;
        this.$nextTick(() => this.$refs.inputRef.focus());
      }
    });
  },
  methods: {
    openSelectedResult() {
      if (!this.matches.length) {
        return;
      }

      window.location.href = this.matches[this.selectedIndex].item.url;
    },
    selectNext() {
      this.selectedIndex = Math.min(this.matches.length - 1, this.selectedIndex + 1);
    },
    selectPrev() {
      this.selectedIndex = Math.max(0, this.selectedIndex - 1);
    },
    close() {
      this.terms = "";
      this.visible = false;
    },
  },
};
</script>

<style lang="scss">
.sa-container {
  left: 25%;
  top: 10%;
  width: 50%;
  z-index: 1000;

  input {
    height: 50px;
    font-size: 1.4em;
    padding: 0 15px;
    outline: none;
    border: none;
    background: transparent;
  }

  .matches {
    margin: 0 0 5px;
    display: flex;
    flex-direction: column;

    a {
      padding: 5px 20px;
      font-size: 1.1em;

      &.active {
        background-color: #e6e6e6;
      }
    }
  }
}
</style>
