<template>
  <div>
    <button
      id="change-email-btn"
      class="text-bo-blue"
      type="button"
      @click="toggleChangeEmailModalVisibility">
      {{ $t('customers.generic.info.data.change_email') }}
    </button>
    <sm-modal
      close-button
      :modal-is-visible="changeEmailModal"
      :modal-title="$t('customers.generic.info.change_email_modal.title')"
      @toggleModalVisibility="toggleChangeEmailModalVisibility">
      <div v-auto-animate>
        <template v-if="!changeEmailFormVisiblity">
          <p class="mb-6">
            {{ $t('customers.generic.info.change_email_modal.info') }}
          </p>
          <div class="px-4">
            <div class="flex justify-between mb-6">
              <p class="pr-3">
                <strong>{{ $t('customers.generic.info.change_email_modal.send_link[0]') }}</strong>
                {{ $t('customers.generic.info.change_email_modal.send_link[1]') }}
              </p>
              <div class="whitespace-nowrap">
                <sm-button
                  color="secondary"
                  icon="link"
                  :loading="sendEmailForm.processing"
                  :title="$t('customers.generic.info.change_email_modal.buttons.send_link')"
                  @click="sendChangeLink" />
              </div>
            </div>
            <div>
              <sm-alert
                v-if="sendEmailFormSuccess"
                color="success">
                {{ $t('customers.generic.info.change_email_modal.success.link') }}
              </sm-alert>
            </div>
            <div class="flex items-center justify-center mb-6">
              <span class="block w-16 h-px bg-bo-gray-200" />
              <span class="inline-block px-2.5 font-bold">{{ $t('generic.or') }}</span>
              <span class="block w-16 h-px bg-bo-gray-200" />
            </div>
            <div class="flex items-center justify-between">
              <p class="pr-3">
                <strong>{{ $t('customers.generic.info.change_email_modal.change_email[0]') }}</strong>
                {{ $t('customers.generic.info.change_email_modal.change_email[1]') }}
              </p>
              <div class="whitespace-nowrap">
                <sm-button
                  color="primary"
                  icon="edit"
                  :title="$t('customers.generic.info.change_email_modal.buttons.change_email')"
                  @click="openChangeEmailForm" />
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <form>
            <sm-input
              v-model="changeEmailForm.old_email"
              name="old_email"
              :label="$t('customers.generic.info.change_email_modal.form.old_email.label')"
              disabled
            />
            <sm-input
              v-model="changeEmailForm.new_email"
              name="new_email"
              :label="$t('customers.generic.info.change_email_modal.form.new_email.label')"
            />
            <sm-checkbox
              v-model="changeEmailForm.update_contact_mail"
              name="update_contact_mail"
              :text="$t('customers.generic.info.change_email_modal.form.update_contact_mail.text')"
              :help="$t('customers.generic.info.change_email_modal.form.update_contact_mail.help', {email: $page.props.customer.email})"
            />
            <div class="flex items-center justify-center mb-6">
              <span class="block w-16 h-px bg-bo-gray-200" />
              <span class="inline-block px-2.5 font-bold">kiegészítő információk</span>
              <span class="block w-16 h-px bg-bo-gray-200" />
            </div>
            <sm-input
              v-model="changeEmailForm.note"
              type="textarea"
              name="note"
              rows="4"
              :label="$t('customers.generic.info.change_email_modal.form.note.label')"
              :placeholder="$t('customers.generic.info.change_email_modal.form.note.placeholder')"
            />
          </form>
          <div>
            <sm-alert
              v-if="changeEmailFormSuccess"
              color="success">
              {{ $t('customers.generic.info.change_email_modal.success.form') }}
            </sm-alert>
            <sm-alert
              v-if="shouldShowNewEmailAvailabilityError"
              color="danger">
              <div class="space-y-2">
                <p>{{ changeEmailForm.errors.new_email_availability }}</p>
                <template v-if="page.props.custom">
                  <p
                    v-if="page.props.custom.new_user"
                    class="underline cursor-pointer"
                    @click="openRegistration"
                  >
                    {{ $t('customers.generic.info.change_email_modal.errors.new_email.add_as_new_cta') }}
                  </p>
                  <p
                    v-else
                    class="underline cursor-pointer"
                    @click="openProfileMerge"
                  >
                    {{ $t('customers.generic.info.change_email_modal.errors.new_email.merge_cta') }}
                  </p>
                </template>
              </div>
            </sm-alert>
          </div>
          <footer
            class="flex flex-wrap items-center justify-between p-4 mt-4 -mx-4 -mb-4 space-x-2 border-t border-brand-gray-200 rounded-b-md">
            <sm-button
              color="secondary"
              :title="$t('generic.buttons.back')"
              @click="openChangeEmailForm" />
            <sm-button
              color="primary"
              :title="$t('customers.generic.info.change_email_modal.buttons.change_email')"
              @click="onSubmitForm" />
          </footer>
        </template>
      </div>
    </sm-modal>
  </div>
</template>

<script setup>
import { useForm, usePage, router } from "@inertiajs/vue3";
import { computed, provide } from "vue";

const page = usePage();

const emit = defineEmits(["openProfileMerge"]);

const changeEmailModal = ref(false);

const toggleChangeEmailModalVisibility = () => {
  sendEmailFormSuccess.value = false;
  changeEmailFormSuccess.value = false;
  changeEmailModal.value = !changeEmailModal.value;
};

const changeEmailFormVisiblity = ref(false);

const openChangeEmailForm = () => {
  sendEmailFormSuccess.value = false;
  changeEmailFormVisiblity.value = !changeEmailFormVisiblity.value;
};

const sendEmailForm = useForm({});
const sendEmailFormSuccess = ref(false);

const sendChangeLink = () => {
  sendEmailFormSuccess.value = false;
  sendEmailForm.post(page.props.routes.changeEmail, {
    onSuccess: () => {
      sendEmailFormSuccess.value = true;
    }
  });
};

const changeEmailForm = useForm({
  old_email: page.props.customer.sportmateEmail,
  new_email: "",
  update_contact_mail: false,
  note: ""
});
const changeEmailFormSuccess = ref(false);

const onSubmitForm = () => {
  changeEmailFormSuccess.value = false;
  changeEmailForm.post(page.props.routes.changeEmailForm, {
    onSuccess: () => {
      changeEmailFormSuccess.value = true;
    }
  });
};

const shouldShowNewEmailAvailabilityError = computed(() => {
  return changeEmailForm.errors.new_email_availability;
});

const openProfileMerge = () => {
  toggleChangeEmailModalVisibility();
  emit("openProfileMerge", { user: page.props.custom });
};

const openRegistration = () => {
  toggleChangeEmailModalVisibility();
  router.visit(page.props.custom.registration_url);
};

provide("form", changeEmailForm);
</script>
