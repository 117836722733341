const smartenFileInput = input => {
  const inputLabel = input.parentNode.querySelector(".sm-input-file-label");
  const originalContent = inputLabel.textContent;
  input.addEventListener("change", e => {
    inputLabel.textContent = e.target.files.length ? e.target.files[0].name : originalContent;
  });

  if (input.form) {
    input.form.addEventListener("reset", () => {
      inputLabel.textContent = originalContent;
    });
  }
};

export {
  smartenFileInput
};

(function () {
  document.addEventListener("DOMContentLoaded", () => {
    document.querySelectorAll("input[type=file]:not([data-evaporate]):not([data-vue])")
      .forEach(smartenFileInput);
  });
})();
