const directives = {
  ripple: {
    root: {
      class: ["block absolute bg-black/10 rounded-full pointer-events-none z-[1] !m-0"],
      style: "transform: scale(0)"
    }
  }
};

export default directives;
