<template>
  <div>
    <sm-button
      color="primary"
      :title="$t('customers.actions.resend_email_verification')"
      :loading="form.processing"
      @click="onSubmit" />
  </div>
</template>

<script setup>
import { useForm, usePage } from "@inertiajs/vue3";

const form = useForm({});
const page = usePage();

const onSubmit = () => {
  form.post(page.props.routes.resendEmailVerification, {
    only: ["customer", "errors", "flash"],
  });
};

</script>
