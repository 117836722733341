<template>
  <styleguide-layout>
    <sm-anchor-point id="props" />
    <input-wrapper-props />

    <sm-anchor-point id="slots" />
    <input-wrapper-slots />
  </styleguide-layout>
</template>

<script setup>
import InputWrapperProps from "@/inertia/components/styleguide/InputWrapper/InputWrapperProps.vue";
import InputWrapperSlots from "@/inertia/components/styleguide/InputWrapper/InputWrapperSlots.vue";
import StyleguideLayout from "@/inertia/components/styleguide/StyleguideLayout.vue";
import { useForm } from "@inertiajs/vue3";

const form = useForm({});
provide("form", form);
</script>
