<template>
  <sm-input-wrapper>
    <template
      v-if="$slots.afterLabel"
      #afterLabel>
      <slot name="afterLabel" />
    </template>
    <template
      v-if="$slots.prepend"
      #prepend>
      <slot name="prepend" />
    </template>
    <template
      v-if="$slots.afterInput"
      #afterInput>
      <slot name="afterInput" />
    </template>
    <template
      v-if="$slots.append"
      #append>
      <slot name="append" />
    </template>
    <v-select
      ref="smSelect"
      v-model="selectModelValue"
      :input-id="id"
      :options="options.length !== 0 ? options : selectOptions"
      :value="modelValue"
      :taggable="tagSelect"
      :clearable="tagSelect ? true : clearable"
      :multiple="tagSelect ? true : multiple"
      :disabled="disabled"
      :placeholder="placeholder"
      :append-to-body="appendToBody"
      :label="customLabel"
      @update:modelValue="selected"
      @option:selected="onSelected"
    />
  </sm-input-wrapper>
</template>

<script setup>
import SmInputWrapper from "@/inertia/components/forms/_layouts/SmInputWrapper.vue";

const props = defineProps({
  name: { type: String, required: false, default: "" },
  id: { type: String, required: false, default: "" },
  label: { type: String, required: false, default: "" },
  placeholder: { type: String, required: false, default: "" },
  modelValue: { type: [Array, String, Object, Number], required: false, default: "" },
  options: { type: Array, required: false, default: () => [] },
  tooltip: { type: String, required: false, default: "" },
  help: { type: String, required: false, default: "" },
  error: { type: [Boolean, String], required: false, default: false },
  inline: { type: Boolean, required: false, default: false },
  disabled: { type: Boolean, required: false, default: false },
  required: { type: Boolean, required: false, default: false },
  clearable: { type: Boolean, required: false, default: false },
  multiple: { type: Boolean, required: false, default: false },
  tagSelect: { type: Boolean, required: false, default: false },
  preset: { type: String, required: false, default: "" },
  half: { type: Boolean, required: false, default: false },
  appendToBody: { type: Boolean, required: false, default: false },
  customLabel: { type: String, required: false, default: "label" },
  noForm: { type: Boolean, required: false, default: false },
  hasDefault: { type: Boolean, required: false, default: false },
});
provide("props", props);

const selectModelValue = ref(props.multiple ? [] : "");
const selectOptions = ref(props.options);

watch(props, () => {
  selectModelValue.value = props.modelValue;
});

onMounted(() => {
  if (props.modelValue) {
    selectModelValue.value = props.modelValue;
  }
  if (props.hasDefault) {
    selectModelValue.value = selectOptions.value[0];
  }
  if (props.preset === "gender") {
    selectOptions.value = [
      {
        id: "male",
        label: window.trans("genders.male")
      },{
        id: "female",
        label: window.trans("genders.female")
      }
    ];
  }
});

const emit = defineEmits(["update:modelValue", "input", "onSelected"]);

const smSelect = ref();

const selected = () => {
  nextTick(() => {
    if (props.tagSelect) {
      smSelect.value.$refs.search.focus();
    }
    emit("update:modelValue", selectModelValue.value);
    emit("input", selectModelValue.value);
  });
  // setTimeout(() => {
  //   if (document.activeElement.nodeName === "BODY") {
  //     smSelect.value.$refs.search.closest("form").tabIndex = "1";
  //     smSelect.value.$refs.search.closest("form").focus();
  //   }
  // });
};

const onSelected = () => {
  emit("onSelected");
};
</script>
