<template>
  <settings-layout>
    <in-head :title="$t('access_control.settings.title')" />

    <form @submit.prevent="onSubmit()">
      <sm-card
        :header-title="$t('access_control.settings.title')">
        <sm-input
          v-model="form.checkin_before_club_opens"
          name="checkin_before_club_opens"
          :label="$t('access_control.settings.checkin_before_club_opens.label')"
          :help="$t('access_control.settings.checkin_before_club_opens.help')"
          inline
          required
        >
          <template #append>
            {{ $t('generic.minute') }}
          </template>
        </sm-input>
        <sm-input
          v-model="form.reenter_time_limit"
          name="reenter_time_limit"
          :label="$t('access_control.settings.reenter_time_limit.label')"
          :help="$t('access_control.settings.reenter_time_limit.help')"
          inline
          required
        >
          <template #append>
            {{ $t('generic.minute') }}
          </template>
        </sm-input>
        <sm-radio-group
          v-model="should_limit_entry_per_time_span"
          name="should_limit_entry_per_time_span"
          :items="should_limit_entry_per_time_span_options"
          :label="$t('access_control.settings.should_limit_entry_per_time_span.label')"
          :help="$t('access_control.settings.should_limit_entry_per_time_span.help')"
          inline
        />
        <div v-auto-animate>
          <sm-input
            v-if="should_limit_entry_per_time_span"
            v-model="form.limit_entry_per_time_span"
            name="limit_entry_per_time_span"
            type="number"
            min="0"
            inline
          >
            <template #append>
              {{ $t('generic.minute') }}
            </template>
          </sm-input>
        </div>
        <sm-input
          v-model="form.cancel_checkin_time_limit"
          name="cancel_checkin_time_limit"
          :label="$t('access_control.settings.cancel_checkin_time_limit.label')"
          :help="$t('access_control.settings.cancel_checkin_time_limit.help')"
          inline
          required
        >
          <template #append>
            {{ $t('generic.minute') }}
          </template>
        </sm-input>
        <sm-radio-group
          v-model="form.overused_ticket_penalty"
          name="overused_ticket_penalty"
          :items="overused_ticket_penalty_options"
          :label="$t('access_control.settings.overused_ticket_penalty.label')"
          inline
          required
        />
        <div v-auto-animate>
          <div
            v-if="form.overused_ticket_penalty"
            class="mb-4 sm-row">
            <div class="w-full sm-col lg:w-4/12">
              <sm-label :label="$t('access_control.settings.ticket_to_add_as_penalty.label')" />
            </div>
            <div class="w-full sm-col lg:w-8/12">
              <sm-async-select
                v-model="form.ticket_to_add_as_penalty"
                name="ticket_to_add_as_penalty"
                :help="$t('access_control.settings.ticket_to_add_as_penalty.help')"
                :route="ticketSearchEndpoint"
                search-on-mount
              />
            </div>
          </div>
        </div>
        <sm-input
          v-model="form.entry_card_allowance"
          name="entry_card_allowance"
          :label="$t('access_control.settings.entry_card_allowance.label')"
          :help="$t('access_control.settings.entry_card_allowance.help')"
          inline
          required
        >
          <template #append>
            {{ $t('generic.minute_before') }}
          </template>
        </sm-input>
        <sm-input
          v-model="form.appointment_kiosk_allowance"
          name="appointment_kiosk_allowance"
          :label="$t('access_control.settings.appointment_kiosk_allowance.label')"
          :help="$t('access_control.settings.appointment_kiosk_allowance.help')"
          inline
          required
        >
          <template #append>
            {{ $t('generic.minute_before') }}
          </template>
        </sm-input>
        <sm-radio-group
          v-model="form.can_checkin_with_negative_balance"
          name="can_checkin_with_negative_balance"
          :items="can_checkin_with_negative_balance_options"
          :label="$t('access_control.settings.can_checkin_with_negative_balance.label')"
          inline
          required
        />
        <sm-radio-group
          v-model="form.can_checkout_with_negative_balance"
          name="can_checkout_with_negative_balance"
          :items="can_checkout_with_negative_balance_options"
          :label="$t('access_control.settings.can_checkout_with_negative_balance.label')"
          inline
          required
        />
        <sm-radio-group
          v-model="form.coach_can_checkin_checkedout_guests"
          name="coach_can_checkin_checkedout_guests"
          :items="coach_can_checkin_checkedout_guests_options"
          :label="$t('access_control.settings.coach_can_checkin_checkedout_guests.label')"
          :help="$t('access_control.settings.coach_can_checkin_checkedout_guests.help')"
          inline
          required
        />
        <sm-radio-group
          v-model="form.mark_customer_as_attended"
          name="mark_customer_as_attended"
          :items="mark_customer_as_attended_options"
          :label="$t('access_control.settings.mark_customer_as_attended.label')"
          :help="$t('access_control.settings.mark_customer_as_attended.help')"
          inline
          required
        />
        <sm-radio-group
          v-model="form.automatic_checkin_enabled"
          name="automatic_checkin_enabled"
          :items="automatic_checkin_enabled_options"
          :label="$t('access_control.settings.automatic_checkin_enabled.label')"
          :help="$t('access_control.settings.automatic_checkin_enabled.help')"
          inline
          required
        />
        <sm-radio-group
          v-model="form.automatic_checkout_enabled"
          name="automatic_checkout_enabled"
          :items="automatic_checkout_enabled_options"
          :label="$t('access_control.settings.automatic_checkout_enabled.label')"
          :help="$t('access_control.settings.automatic_checkout_enabled.help')"
          inline
          required
        />
        <div v-auto-animate>
          <sm-input
            v-if="form.automatic_checkout_enabled"
            v-model="form.automatic_checkout_time"
            name="automatic_checkout_time"
            class="-mt-4"
            inline />
        </div>
        <sm-radio-group
          v-model="form.unassign_accessory_on_checkout"
          name="unassign_accessory_on_checkout"
          :items="unassign_accessory_on_checkout_options"
          :label="$t('access_control.settings.unassign_accessory_on_checkout.label')"
          :help="$t('access_control.settings.unassign_accessory_on_checkout.help')"
          inline
          required
        />
        <sm-radio-group
          v-model="form.allow_reentry_with_valid_checkin"
          name="allow_reentry_with_valid_checkin"
          :items="allow_reentry_with_valid_checkin_options"
          :label="$t('access_control.settings.allow_reentry_with_valid_checkin.label')"
          :help="$t('access_control.settings.allow_reentry_with_valid_checkin.help')"
          inline
          required
        />
        <sm-radio-group
          v-model="form.can_checkout_with_closed_locker"
          name="can_checkout_with_closed_locker"
          :items="can_checkout_with_closed_locker_options"
          :label="$t('access_control.settings.can_checkout_with_closed_locker.label')"
          :help="$t('access_control.settings.can_checkout_with_closed_locker.help')"
          inline
          required
        />
      </sm-card>
      <sm-card
        :header-title="$t('access_control.settings.acym_entrance')">
        <sm-radio-group
          v-model="form.aycm_quick_checkin"
          name="aycm_quick_checkin"
          :items="aycm_quick_checkin_options"
          :label="$t('access_control.settings.aycm_quick_checkin.label')"
          :help="$t('access_control.settings.aycm_quick_checkin.help')"
          inline
          required
        />
        <div v-auto-animate>
          <div
            v-if="form.aycm_quick_checkin"
            class="sm-row">
            <div class="w-full sm-col lg:w-4/12">
              <sm-label :label="$t('access_control.settings.aycm_quick_checkin_ticket.label')" />
            </div>
            <div class="w-full sm-col lg:w-8/12">
              <sm-async-select
                v-model="form.aycm_quick_checkin_ticket"
                name="aycm_quick_checkin_ticket"
                :route="ticketSearchEndpoint"
                search-on-mount
              />
            </div>
          </div>
        </div>
      </sm-card>
      <sm-card>
        <sm-button
          preset="save"
          :loading="form.processing"
          @click="onSubmit" />
      </sm-card>
    </form>
  </settings-layout>
</template>

<script setup>
import { useForm, usePage } from "@inertiajs/vue3";
import { useFormatArrayToTrueFalseObjects } from "@/inertia/composables/global";

// default variables
const page = usePage();

const props = defineProps({
  settings: { type: Object, required: true, default: null },
  ticketSearchEndpoint: { type: String, required: true, default: "" },
  penaltyTicketOptions: { type: Object, required: true, default: () => {} },
  aycmTicket: { type: Object, required: true, default: () => {} },
});

const should_limit_entry_per_time_span_options = useFormatArrayToTrueFalseObjects(["should_limit_entry_per_time_span_yes", "should_limit_entry_per_time_span_no"]);
const overused_ticket_penalty_options = useFormatArrayToTrueFalseObjects(["overused_ticket_penalty_action_enabled", "overused_ticket_penalty_action_disabled"]);
const can_checkin_with_negative_balance_options = useFormatArrayToTrueFalseObjects(["can_checkin_with_negative_balance", "cannot_checkin_with_negative_balance"]);
const can_checkout_with_negative_balance_options = useFormatArrayToTrueFalseObjects(["can_checkout_with_negative_balance", "cannot_checkout_with_negative_balance"]);
const coach_can_checkin_checkedout_guests_options = useFormatArrayToTrueFalseObjects(["coach_can_check_in_checked_out_guests", "coach_cannot_check_in_checked_out_guests"]);
const mark_customer_as_attended_options = useFormatArrayToTrueFalseObjects(["mark_customer_as_attended", "mark_customer_as_attended_no"]);
const automatic_checkin_enabled_options = useFormatArrayToTrueFalseObjects(["automatic_checkin_enabled", "automatic_checkin_enabled_no"]);
const automatic_checkout_enabled_options = useFormatArrayToTrueFalseObjects(["automatic_checkout_enabled", "automatic_checkout_enabled_no"]);
const unassign_accessory_on_checkout_options = useFormatArrayToTrueFalseObjects(["unassign_accessory_on_checkout_on", "unassign_accessory_on_checkout_off"]);
const allow_reentry_with_valid_checkin_options = useFormatArrayToTrueFalseObjects(["allow_reentry_with_valid_checkin_on", "allow_reentry_with_valid_checkin_off"]);
const aycm_quick_checkin_options = useFormatArrayToTrueFalseObjects(["aycm_quick_checkin_on", "aycm_quick_checkin_off"]);
const can_checkout_with_closed_locker_options = useFormatArrayToTrueFalseObjects(["can_checkout_with_closed_locker_on", "can_checkout_with_closed_locker_on"]);

const form = useForm({
  checkin_before_club_opens: props.settings.checkin_before_club_opens,
  reenter_time_limit: props.settings.reenter_time_limit,
  limit_entry_per_time_span: props.settings.limit_entry_per_time_span,
  cancel_checkin_time_limit: props.settings.cancel_checkin_time_limit,
  overused_ticket_penalty: props.settings.overused_ticket_penalty,
  ticket_to_add_as_penalty: props.settings.ticket_to_add_as_penalty,
  entry_card_allowance: props.settings.entry_card_allowance,
  appointment_kiosk_allowance: props.settings.appointment_kiosk_allowance,
  can_checkin_with_negative_balance: props.settings.can_checkin_with_negative_balance,
  can_checkout_with_negative_balance: props.settings.can_checkout_with_negative_balance,
  coach_can_checkin_checkedout_guests: props.settings.coach_can_checkin_checkedout_guests,
  mark_customer_as_attended: props.settings.mark_customer_as_attended,
  automatic_checkin_enabled: props.settings.automatic_checkin_enabled,
  automatic_checkout_enabled: props.settings.automatic_checkout_enabled,
  automatic_checkout_time: props.settings.automatic_checkout_time,
  unassign_accessory_on_checkout: props.settings.unassign_accessory_on_checkout,
  allow_reentry_with_valid_checkin: props.settings.allow_reentry_with_valid_checkin,
  can_checkout_with_closed_locker: props.settings.can_checkout_with_closed_locker,
  aycm_quick_checkin: props.settings.aycm_quick_checkin,
  aycm_quick_checkin_ticket: props.aycmTicket,
});

const should_limit_entry_per_time_span = ref(form.limit_entry_per_time_span !== "" ? true : false);

const onSubmit = () => {
  form
    .transform((data) => {
      const form = {
        ...data,
        ticket_to_add_as_penalty: data.overused_ticket_penalty ? data.ticket_to_add_as_penalty.id ? data.ticket_to_add_as_penalty.id : data.ticket_to_add_as_penalty : 0,
        limit_entry_per_time_span: data.limit_entry_per_time_span ? data.limit_entry_per_time_span : 0,
        aycm_quick_checkin_ticket: data.aycm_quick_checkin ? data.aycm_quick_checkin_ticket?.id : null,
      };
      return form;
    })
    .post(page.url);
};

provide("form", form);
</script>
