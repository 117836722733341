<template>
  <div>
    <sm-card header-title="Default usage">
      <sm-input
        label="Default input" />
      <code class="block !-mt-4"><pre class="text-xs">&lt;sm-input label="..." /&gt;</pre></code>
    </sm-card>

    <sm-card header-title="Value bindings">
      <sm-input
        label="Value input"
        placeholder="But here is the placeholder"
        value="This is a value" />
      <code class="block !-mt-4"><pre class="text-xs">value="..."</pre></code>
      <hr>
      <sm-input
        v-model="inputModel"
        :label="`V-model input: ${inputModel}`"
        placeholder="But here is the placeholder" />
      <code class="block !-mt-4"><pre class="text-xs">v-model="..."</pre></code>
    </sm-card>

    <sm-card header-title="Texts and helps">
      <sm-input
        label="Label input" />
      <code class="block !-mt-4"><pre class="text-xs">label="..."</pre></code>
      <hr>
      <sm-input
        label="Placeholder input"
        placeholder="This is a the placeholder" />
      <code class="block !-mt-4"><pre class="text-xs">placeholder="..."</pre></code>
      <hr>
      <sm-input
        label="Help text input"
        help="This is a help text" />
      <code class="block !-mt-4"><pre class="text-xs">help="..."</pre></code>
    </sm-card>
  </div>
</template>

<script setup>
const inputModel = ref("This is v-model binded");
</script>
