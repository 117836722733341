<template>
  <tr class="whitespace-nowrap">
    <td>
      <sm-button
        class="justify-center w-full"
        :title="$t(`days.${day + 1}`)"
        :class="modelValue.enabled ? 'sm-btn-primary' : 'sm-btn-light'"
        size="xl"
        @click="toggleDay"
      />
    </td>
    <td class="text-center">
      <sm-checkbox
        :model-value="modelValue.allDay"
        :name="`all_day[${day}]`"
        class="mb-0"
        :disabled="!modelValue.enabled"
        inline
        @update:modelValue="toggleAllDay"
      />
    </td>
    <template
      v-for="shift in range(dailyValidityMaxShifts)"
      :key="`${day}-${shift}`"
    >
      <td>
        <vue-timepicker
          :model-value="modelValue.shifts[shift].from"
          :disabled="isTimePickerDisabled"
          auto-scroll
          manual-input
          :minute-interval="5"
          :hour-label="$t('generic.time.hour')"
          :minute-label="$t('generic.time.minute')"
          append-to-body
          :input-class="(isTimePickerDisabled ? 'disabled bg-gray' : 'bg-white') + ' sm-input-text skip-error-style'"
          :placeholder="$t('generic.time.time_picker_format')"
          @change="setTime(shift, 'from', $event)"
        />

        <span class="mx-2 my-auto">-</span>

        <vue-timepicker
          :model-value="modelValue.shifts[shift].to"
          :disabled="isTimePickerDisabled"
          auto-scroll
          manual-input
          :minute-interval="5"
          :hour-label="$t('generic.time.hour')"
          :minute-label="$t('generic.time.hour')"
          append-to-body
          :input-class="(isTimePickerDisabled ? 'disabled bg-gray' : 'bg-white') + ' sm-input-text skip-error-style'"
          :placeholder="$t('generic.time.time_picker_format')"
          @change="setTime(shift, 'to', $event)"
        />
      </td>
      <td v-if="shift !== dailyValidityMaxShifts - 1">
        <div />
      </td>
    </template>
  </tr>
</template>

<script setup>
import range from "lodash/range";

const dailyValidityMaxShifts = 2;

const { day, modelValue } = defineProps({
  day: { type: Number, required: true },
  modelValue: { type: Object, required: true },
});

const emit = defineEmits(["update:modelValue"]);

const isTimePickerDisabled = computed(() => {
  return !modelValue.enabled || modelValue.allDay;
});

const toggleDay = () => {
  const newValidity = modelValue;
  newValidity.enabled = !newValidity.enabled;

  emit("update:modelValue", newValidity);
};

const setTime = (shift, prop, e) => {
  const { data: { HH: hour, mm: minute }, displayTime } = e;

  if ((!hour && minute) || (hour && !minute)) {
    return;
  }

  const timeString = (!hour && !minute) ? null : displayTime;

  const newShifts = modelValue.shifts;
  newShifts[shift][prop] = timeString;

  const newValidity = modelValue;
  newValidity.shifts = newShifts;
  emit("update:modelValue", newValidity);
};

const toggleAllDay = () => {
  const newValidity = modelValue;
  newValidity.allDay = !newValidity.allDay;

  emit("update:modelValue", newValidity);
};
</script>
