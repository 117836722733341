<template>
  <div>
    <In-Head :title="$t('customers.checkins.show.title', {name: $page.props.customer.name})" />

    <sub-header
      :title="$t('customers.checkins.show.title', {name: $page.props.customer.name})"
      :export-route="$page.props.routes.export">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          :href="$page.props.routes.back"
          preset="back"
          icon="arrow-left" />
      </template>
      <template #crufFilterEnd>
        <sm-button
          icon="arrow-left" />
      </template>
    </sub-header>

    <customers-nav-tabs />

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`customers.checkins.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template v-if="col === 'created_at'">
                {{ slotProps.data?.created_at }}
                <template v-if="slotProps.data?.trashed">
                  <span class="text-bo-gray-400 text-xxs">
                    {{ $t('Visszavont') }}
                  </span>
                </template>
              </template>
              <template v-else-if="col === 'ticket'">
                {{ slotProps.data?.ticket ? slotProps.data?.ticket : '-' }}
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div
                v-if="!slotProps.data?.trashed && slotProps.data?.permissions.cancel"
                class="flex items-center justify-end space-x-1">
                <sm-button
                  size="icon"
                  icon="rotate-ccw"
                  @click="onConfirm(slotProps.data?.routes?.cancel)" />
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
    <sm-confirm-dialog
      title="Confirm title"
      group="confirmGroup" />
  </div>
</template>

<script setup>
import CustomersNavTabs from "@/inertia/pages/customers/show/_partials/CustomersNavTabs.vue";
import { useForm } from "@inertiajs/vue3";
import { useConfirm } from "primevue/useconfirm";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableFields = ref(["created_at","status","created_by","ticket"]);

const confirm = useConfirm();

const form = useForm({});

const onConfirm = (e) => {
  console.log(e);
  confirm.require({
    group: "confirmGroup",
    header: window.trans("generic.are_you_sure"),
    accept: () => form.post(e, {
      only: ["table", "flash"]
    }),
  });
};

</script>
