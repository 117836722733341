(function () {
  document.addEventListener("DOMContentLoaded", () => {
    const addModalName = (form, modal) => {
      const modalNameInput = document.createElement("input");
      modalNameInput.setAttribute("type", "hidden");
      modalNameInput.setAttribute("name", "_modal_name_");
      modalNameInput.setAttribute("value", modal.id);
      form.appendChild(modalNameInput);
    };

    const modalsInForms = document.querySelectorAll("form .sm-modal:not(.no-reopen)");
    modalsInForms.forEach(modal => {
      modal.closest("form").addEventListener("submit", e => addModalName(e.target, modal));
    });

    const formsInModals = document.querySelectorAll(".sm-modal:not(.no-reopen) form");
    formsInModals.forEach(form => {
      const modal = form.closest(".sm-modal");
      form.addEventListener("submit", e => addModalName(e.target, modal));
    });
  });
})();
