<template>
  <div>
    <sm-landing-modal
      :modal-is-visible="modal"
      modal-title="Üdvözlünk a SportMate világában! :)"
      @toggleModalVisibility="toggleModalVisibility">
      <div class="text-base font-font-open-sans-regular">
        <div class="absolute top-0 left-0 flex w-full space-x-2">
          <div
            v-for="step in steps"
            :key="step"
            class=" h-1.5 flex-1 transition-colors"
            :class="step <= activeStep ? 'bg-brand-blue-400' : 'bg-brand-blue-50'" />
        </div>
        <transition
          name="fade"
          mode="out-in">
          <onboarding-step-1
            v-if="activeStep === 1"
            @increaseStep="increaseStep" />
          <onboarding-step-2
            v-else-if="activeStep === 2"
            @increaseStep="increaseStep" />
          <onboarding-step-3
            v-else-if="activeStep === 3"
            @increaseStep="increaseStep" />
        </transition>
      </div>
    </sm-landing-modal>
  </div>
</template>

<script>
import OnboardingStep1 from "./OnboardingStep1.vue";
import OnboardingStep2 from "./OnboardingStep2.vue";
import OnboardingStep3 from "./OnboardingStep3.vue";

export default {
  components: {
    OnboardingStep1,
    OnboardingStep2,
    OnboardingStep3,
  },
  provide() {
    return {
      customerApi: this.customerApi,
      ticketApi: this.ticketApi,
      finishApi: this.finishApi,
      inviteLink: this.inviteLink,
    };
  },
  props: {
    customerApi: { type: String, required: false, default: "" },
    ticketApi: { type: String, required: false, default: "" },
    finishApi: { type: String, required: false, default: "" },
    onboardingFinished: { type: Boolean, default: false },
    inviteLink: { type: String, required: true },
  },
  data() {
    return {
      modal: false,
      steps: 3,
      activeStep: 1,
    };
  },
  mounted() {
    if (! this.onboardingFinished) {
      this.toggleModalVisibility();
    }
  },
  methods: {
    toggleModalVisibility() {
      this.modal = !this.modal;
    },
    increaseStep() {
      this.activeStep++;
    },
    decreaseStep() {
      this.activeStep--;
    }
  },
};
</script>
