<template>
  <div class="text-left">
    <sm-button
      v-if="$page.props.permissions.create"
      preset="add"
      @click="toggleModalVisibility"
    />

    <sm-modal
      :modal-is-visible="visible"
      :modal-title="$t('sales.vouchers.create_modal.title')"
      @toggleModalVisibility="toggleModalVisibility">
      <sm-input
        v-model="form.amount"
        type="number"
        name="amount"
        :label="$t('sales.vouchers.create_modal.fields.amount.label')"
      />
      <template #footer>
        <sm-button
          :title="$t('generic.cancel')"
          @click="toggleModalVisibility" />
        <sm-button
          color="primary"
          :loading="form.processing"
          :title="$t('generic.save')"
          @click="onSubmit" />
      </template>
    </sm-modal>
  </div>
</template>

<script setup>
import { useForm, usePage } from "@inertiajs/vue3";

const page = usePage();

const visible = ref(false);

const form = useForm({
  amount: 1,
});

function toggleModalVisibility() {
  visible.value = !visible.value;
}

async function onSubmit() {
  await form.post(page.props.routes.store);
  toggleModalVisibility();
}
</script>
