<template>
  <div class="space-y-4">
    <div>
      <h4>{{ $t('tickets.season_tickets.validity') }}</h4>
      <sm-card>
        <div>
          <sm-checkbox
            v-model="form.is_entry_ticket"
            name="is_entry_ticket"
            inline
            :label="$t('tickets.season_tickets.fields.is_entry_ticket.label')"
            :help="$t('tickets.season_tickets.fields.is_entry_ticket.help')"
          />
        </div>

        <div>
          <time-table v-model="form.dailyValidities" />
        </div>
      </sm-card>
    </div>
    <div>
      <div class="flex justify-between mb-4">
        <h4>{{ $t('tickets.season_tickets.location_validities') }}</h4>
        <sm-button :href="$page.props.routes.manageZones">
          {{ $t('tickets.season_tickets.manage_zones') }}
        </sm-button>
      </div>
      <sm-card>
        <div>
          <sm-checkbox
            v-model="form.is_valid_for_every_location"
            name="is_valid_for_every_location"
            inline
            :label="$t('tickets.season_tickets.fields.is_valid_for_every_location.label') + '*'"
          />
        </div>

        <div v-if="!form.is_valid_for_every_location">
          <div class="grid grid-cols-2 gap-x-4">
            <div
              v-for="zone in page.props.groupedLocations"
              :key="zone.zoneId">
              <div class="flex">
                <h5>{{ zone.locations[0].zone.name }}</h5>
                <sm-checkbox
                  class="mb-0"
                  inline
                  :text="$t('tickets.season_tickets.fields.valid_for_all_location_in_zone')"
                  :value="form.valid_for_zones.includes(zone.zoneId)"
                  @update:model-value="toggleZone(zone)"
                />
              </div>
              <div v-if="!form.valid_for_zones.includes(zone.zoneId)">
                <div
                  v-for="location in zone.locations"
                  :key="location.id">
                  <sm-checkbox
                    class="mb-0"
                    inline
                    :label="location.name"
                    :value="form.valid_for_locations.includes(location.id)"
                    @update:model-value="toggleLocation(location)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </sm-card>
    </div>
    <div>
      <div class="flex justify-between mb-4">
        <h4>{{ $t('tickets.season_tickets.service_validities') }}</h4>
        <sm-button :href="$page.props.routes.manageServices">
          {{ $t('tickets.season_tickets.manage_services') }}
        </sm-button>
      </div>
      <sm-card>
        <div>
          <sm-checkbox
            v-model="form.is_valid_for_every_service"
            name="is_valid_for_every_service"
            inline
            :label="$t('tickets.season_tickets.fields.is_valid_for_every_service.label')"
          />
        </div>

        <div
          v-if="!form.is_valid_for_every_service"
          class="space-y-4">
          <div>
            <sm-async-select
              v-model="selectedService"
              :label="$t('tickets.season_tickets.fields.service')"
              :route="$page.props.routes.searchService"
            />
          </div>

          <div class="flex justify-between">
            <sm-button
              icon="plus-circle"
              :title="$t('generic.buttons.add')"
            />
            <copy-tickets
              :ticket-service-constraints-endpoint="$page.props.routes.ticketServiceConstraintsEndpoint"
              @ticket-selected="copyTicketServices"
            />
          </div>

          <template v-if="form.valid_for_services.length">
            <table class="sm-table">
              <thead>
                <tr>
                  <th>{{ $t('generic.fields.id') }}</th>
                  <th>{{ $t('generic.fields.name') }}</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(service, index) in form.valid_for_services"
                  :key="index">
                  <td>{{ service.id }}</td>
                  <td>{{ service.label }}</td>
                  <td class="w-px">
                    <sm-button
                      preset="delete-icon"
                      @click="removeService(service)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
          <template v-else>
            <sm-alert color="light">
              {{ $t('tickets.season_tickets.constraints.empty_service_list') }}
            </sm-alert>
          </template>
        </div>
      </sm-card>
    </div>
    <sm-card>
      <sm-button
        preset="save"
        :loading="form.processing"
        @click="save" />
    </sm-card>
  </div>
</template>

<script setup>
import { useForm, usePage } from "@inertiajs/vue3";
import { watch } from "vue";
import TimeTable from "../_partials/TimeTable.vue";
import CopyTickets from "../_partials/CopyTickets.vue";

defineProps({
  club: { type: Object, required: true },
});

const page = usePage();

const form = useForm({
  ...page.props.clubDetails,
  dailyValidities: page.props.ticketDailyValidities,
});

const save = () => {
  form
    .transform(data => ({
      ...data,
      valid_for_zones: data.is_valid_for_every_location ? [] : data.valid_for_zones,
      valid_for_locations: data.is_valid_for_every_location ? [] : data.valid_for_locations,
      valid_for_services: data.is_valid_for_every_service ? [] : data.valid_for_services.map(service => service.id),
    }))
    .put(page.props.routes.submit);
};

const _toggle = (prop, value) => {
  if (form[prop].includes(value)) {
    form[prop] = form[prop].filter(v => v !== value);
  } else {
    form[prop] = [...form[prop], value];
  }
};

const toggleZone = zone => {
  _toggle("valid_for_zones", zone.zoneId);

  zone.locations.forEach(location => {
    form.valid_for_locations = form.valid_for_locations.filter(l => l !== location.id);
  });
};

const toggleLocation = location => {
  _toggle("valid_for_locations", location.id);
};

const selectedService = ref(null);
watch(selectedService, () => {
  if (!selectedService.value?.id) {
    return;
  }

  const exists = form.valid_for_services.find(_service => _service.id === selectedService.value.id);

  if (exists) {
    return;
  }

  form.valid_for_services = [...form.valid_for_services, selectedService.value];
  selectedService.value = null;
});

const removeService = service => {
  form.valid_for_services = form.valid_for_services.filter(_service => _service.id !== service.id);
};

const copyTicketServices = newServices => {
  form.valid_for_services = newServices.map(({ id, name: label }) => ({ id, label }));
};

provide("form", form);
</script>
