<script>
export default {
  data: function () {
    return {
      enabledGroups: window.selectedUserGroups || [],
    };
  },
  computed: {
    enabledGroupsJson: function () {
      return JSON.stringify(this.enabledGroups.map(v => v.value));
    }
  }
};
</script>
