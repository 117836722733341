<template>
  <div>
    <sm-card header-title="Props">
      <div class="">
        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Render the checkbox as a switch<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> smSwitch<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:sm-switch="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-checkbox
              label="Checkbox with sm-switch prop"
              sm-switch />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add the name attribute to the checkbox<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> name<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">name="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-checkbox
              label="Checkbox with name prop"
              name="name" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add the id attribute to the checkbox<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> id<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">id="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-checkbox
              id="id"
              label="Checkbox with id prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add error text and state to the checkbox<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> error<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean, String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">error="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-checkbox
              error="Some error text"
              label="Checkbox with error prop" />
            <hr>
            <sm-checkbox
              sm-switch
              error="Some error text"
              label="Switch Checkbox with disabled prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add disabled state to checkbox<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> disabled<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:disabled="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-checkbox
              disabled
              label="Checkbox with disabled prop" />
            <hr>
            <sm-checkbox
              disabled
              sm-switch
              label="Switch Checkbox with disabled prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add required state to checkbox<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> required<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:required="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-checkbox
              required
              label="Checkbox with required prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add optional text to checkbox<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> optional<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:optional="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-checkbox
              optional
              label="Checkbox with optional prop ->" />
          </div>
        </div>
        <hr>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Render the label inline before the checkbox<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> inline<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:inline="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-checkbox
              inline
              label="Checkbox with inline prop" />
            <hr>
            <sm-checkbox
              inline
              sm-switch
              label="Checkbox with inline prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Render the checkbox with half width<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> half<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:half="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-checkbox
              half
              label="Input with half prop" />
            <hr>
            <sm-checkbox
              half
              sm-switch
              label="Input with half prop" />
          </div>
        </div>
      </div>
    </sm-card>
  </div>
</template>
