<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <div>
    <sub-header title="Beállítások" />
    <div class="mb-6 sm-row">
      <div class="w-full mb-4 sm-col lg:w-3/12 lg:mb-0">
        <div class="sm-list-group sm-list-group-bordered">
          <template
            v-for="item in page.props.settingsLayout.menu"
            :key="item.icon">
            <Link
              :href="item.url"
              class="sm-list-group-item sm-list-group-item-action"
              :class="{'active': page.url.startsWith(item.url)}">
              <vue-feather
                :type="item.icon"
                class="inline w-4 h-4 mr-2 translate-y-1"
              />
              {{ item.label }}
            </Link>
          </template>
        </div>
      </div>
      <div class="w-full sm-col lg:w-9/12">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
import { usePage, Link } from "@inertiajs/vue3";
const page = usePage();
</script>
