import TRANSITIONS from "./transitions";

const datatable = {
  root: ({ props }) => ({
    class: [
      "relative",
      {
        "flex flex-col h-full": props.scrollable && props.scrollHeight === "flex"
      }
    ]
  }),
  loadingoverlay: {
    class: [
      "fixed w-full h-full t-0 l-0 bg-gray-100/40 z-50",
      "transition duration-200",
      "absolute flex items-center justify-center z-2",
    ]
  },
  loadingicon: "w-8 h-8",
  wrapper: ({ props }) => ({
    class: [
      {
        relative: props.scrollable,
        "flex flex-col grow h-full": props.scrollable && props.scrollHeight === "flex"
      }
    ]
  }),
  header: ({ props }) => ({
    class: [
      "border-t-0 border-b-bo-gray-200/50 px-5 py-4",
      props.showGridlines ? "border-x border-t border-b-0" : "border-y border-x-0"
    ]
  }),
  table: "w-full border-spacing-0",
  tbody: ({ instance, context }) => ({
    class: [
      {
        "sticky z-[1]": instance.frozenRow && context.scrollable
      }
    ]
  }),
  tfoot: ({ context }) => ({
    class: [
      {
        "bg-bo-gray-100/50 bottom-0 z-[1]": context.scrollable
      }
    ]
  }),
  footer: {
    class: [
      "bg-bo-gray-100/50 border-t-0 border-b border-x-0 border-bo-gray-200/50 font-bold p-4",
    ]
  },
  column: {
    headercell: ({ context, props }) => ({
      class: [
        "text-left border-0 border-b border-solid border-bo-gray-200/50 rounded-md",
        "transition duration-200",
        context?.size === "small" ? "px-1 py-2" : context?.size === "large" ? "p-3" : "px-2 py-2.5", // Size
        context.sorted ? "bg-bo-blue/10" : "bg-white", // Sort
        {
          "sticky z-[1]": props.frozen || props.frozen === "", // Frozen Columns
          "border-x border-y": context?.showGridlines,
          "overflow-hidden space-nowrap border-y relative bg-clip-padding": context.resizable // Resizable
        }
      ]
    }),
    headercontent: "flex items-center",
    bodycell: ({ props, context }) => ({
      class: [
        "text-left border-0 border-b border-solid border-bo-gray-200/50",
        context?.size === "small" ? "px-1 py-2" : context?.size === "large" ? "p-3" : "px-2 py-2.5", // Size
        {
          "sticky bg-inherit": props.frozen || props.frozen === "", // Frozen Columns
          "border-x border-y": context?.showGridlines
        }
      ]
    }),
    footercell: ({ context }) => ({
      class: [
        "text-left border-0 border-b border-solid border-bo-gray-200/50 font-bold",
        "bg-bo-gray-100/50 text-slate-700",
        "transition duration-200",
        context?.size === "small" ? "p-2" : context?.size === "large" ? "p-5" : "p-4", // Size
        {
          "border-x border-y": context?.showGridlines
        }
      ]
    }),
    sorticon: ({ context }) => ({
      class: ["ml-2 cursor-pointer", context.sorted ? "text-bo-blue" : ""]
    }),
    sortbadge: {
      class: [
      ]
    },
    transition: TRANSITIONS.overlay
  },
  bodyrow: ({ context }) => ({
    class: [
      context.selected ? "bg-blue-50 text-blue-700 dark:bg-blue-300" : "bg-white",
      context.stripedRows ? (context.index % 2 === 0 ? "!bg-bo-gray-100/50" : "!bg-white") : "",
      "hover:!bg-bo-gray-100",
      "transition duration-200",
      "focus:outline focus:outline-[0.15rem] focus:outline-bo-blue/50 focus:outline-offset-[-0.15rem]", // Focus
      {
        "cursor-pointer": context.selectable,
        "hover:bg-gray-300/20 hover:text-gray-600": context.selectable && !context.selected // Hover
      }
    ]
  }),
  rowexpansion: "bg-white ",
  rowgroupheader: {
    class: ["sticky z-[1]", "bg-white", "transition duration-200"]
  },
  rowgroupfooter: {
    class: ["sticky z-[1]", "bg-white", "transition duration-200"]
  },
  rowgrouptoggler: {
    class: [
      "text-left m-0 p-0 cursor-pointer select-none",
      "inline-flex items-center justify-center overflow-hidden relative",
      "w-8 h-8 text-gray-500 border-0 bg-transparent rounded-[50%]",
      "transition duration-200",
    ]
  },
  rowgrouptogglericon: "inline-block w-4 h-4",
  resizehelper: "absolute hidden w-px z-10 bg-blue-500"
};

export default datatable;
