<template>
  <div>
    <sm-button
      color="secondary"
      :title="$t('customers.generic.accessories.assign_button')"
      hotkey="F5"
      @click="toggleModalVisibility" />
    <sm-modal
      class="text-left"
      :modal-is-visible="modal"
      :modal-title="$t('customers.accessories.modal.title')"
      @toggleModalVisibility="toggleModalVisibility">
      <form
        @submit.prevent="onSubmit()">
        <sm-async-select
          ref="accessoryInputRef"
          v-model="form.accessory"
          name="accessory"
          :search-on-focus="false"
          :label="$t('customers.accessories.modal.form.accessory.label')"
          :route="$page.props.routes.searchAccessory" />
        <div v-auto-animate>
          <template v-if="form.accessory?.data?.locker">
            <sm-alert color="light">
              {{ $t('customers.accessories.modal.locker_help') }}
            </sm-alert>
            <sm-radio-group
              v-model="lockerValidtiy"
              :label="$t('customers.accessories.modal.form.locker_validtiy.label')"
              name="locker_validtiy"
              :items="lockerValidtiyItems"
              button-group
            />
            <sm-date-picker
              v-if="lockerValidtiy === 'until_date'"
              v-model="rental_valid_until"
              name="rental_valid_until"
              :min-date="new Date()"
            />
          </template>
        </div>
        <div class="flex flex-wrap items-center justify-end p-4 -mx-4 -mb-4 space-x-2 border-t border-brand-gray-200 rounded-b-md">
          <sm-button
            :title="$t('generic.cancel')"
            @click="toggleModalVisibility" />
          <sm-button
            id="assignAccessorySubmitButton"
            type="submit"
            color="primary"
            :title="$t('generic.save')"
            :disabled="!form.isDirty"
            :loading="form.processing" />
        </div>
      </form>
    </sm-modal>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";
import { useForm, usePage } from "@inertiajs/vue3";
import hotkeys from "hotkeys-js";
import { provide, watch } from "vue";

const lockerValidtiy = ref("until_withdrawal");
const lockerValidtiyItems = ref([
  {
    id: "until_withdrawal",
    label: window.trans("customers.accessories.modal.form.locker_validtiy.options.until_withdrawal")
  },
  {
    id: "until_next_exit",
    label: window.trans("customers.accessories.modal.form.locker_validtiy.options.until_next_exit")
  },
  {
    id: "until_date",
    label: window.trans("customers.accessories.modal.form.locker_validtiy.options.until_date")
  }
]);

const rental_valid_until = ref(null);

watch(lockerValidtiy, (e) => {
  if (e !== "until_date") {
    rental_valid_until.value = null;
  }
});

const page = usePage();
const modal = ref(false);

watch(modal, (e) => {
  if (e === false) {
    form.reset();
  }
});

const emit = defineEmits(["updateAccessoriesRefreshed"]);

const toggleModalVisibility = () => {
  modal.value = !modal.value;
};

const form = useForm({
  accessory: null,
});

provide("form", form);

onMounted(() => {
  hotkeys("f5", "global", (e) => {
    e.preventDefault();
    if (e.repeat) {
      return;
    } else {
      toggleModalVisibility();
    }
  });
});

const accessoryInputRef = ref();

watch(modal, (e) => {
  if (e === true) {
    nextTick(() => {
      accessoryInputRef.value.$el.querySelector(".vs__search").focus();
    });
  }
});

watch(() => form, (e) => {
  if (e.isDirty === true) {
    nextTick(() => {
      document.getElementById("assignAccessorySubmitButton").focus();
    });
  }
}, {deep: true});

const onSubmit = () => {
  form
    .transform(() => {
      let formData = {
        accessory: form.accessory.id,
        customer_id: page.props.customer.id
      };
      if (lockerValidtiy.value === "until_next_exit") {
        formData = {
          ...formData,
          rental_valid_until_next_checkout: true
        };
      }
      if (rental_valid_until.value) {
        formData = {
          ...formData,
          rental_valid_until: useDateFormat(rental_valid_until.value, "form")
        };
      }
      return formData;
    })
    .post(page.props.routes.assignAccessory, {
      only: ["accessories"],
      onSuccess: () => {
        form.reset();
        toggleModalVisibility();
        emit("updateAccessoriesRefreshed");
      }
    });
};
</script>
