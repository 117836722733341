<template>
  <div>
    <In-Head :title="$page.props.title" />
    <sub-header :title="$page.props.title">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          :href="$page.props.routes.showFranchise"
          preset="back"
          icon="arrow-left" />
      </template>
    </sub-header>

    <Form @onSubmit="onSubmit" />
  </div>
</template>

<script setup>
import { useForm, usePage } from "@inertiajs/vue3";
import Form from "./Form.vue";

const page = usePage();

const { routes } = defineProps({
  routes: { type: Object, required: true },
  franchise: { type: Object, required: true },
});

const validityRanges = ref(
  page.props.ticketValidityRanges.map(id => ({ id, label: window.trans(`ticket_validity_range.${id}`) }))
);

const form = useForm({
  name: null,
  price: null,
  note: null,
  max_entries_per_day: null,
  available_uses: null,
  validity: null,
  validity_range: validityRanges.value[0],
  limitless_usage: false,
  last_day_excluded: false,
  tax: null,
  purchasable: true,
  online_purchasable: true,
  one_time_purchase: false,
  cannot_be_discounted: false,
  has_continuous_discount: false,
  continuous_discount_type: null,
  continuous_discount_amount: null,
  continuous_discount_required_count: null,
  continuous_discount_valid_from: null,
});

const onSubmit = () => {
  form
    .transform(data => {
      const { limitless_usage, available_uses, ...rest } = data;
      return {
        ...rest,
        validity_range: data.validity_range?.id,
        continuous_discount_type: data.continuous_discount_type?.id,
        available_uses: limitless_usage ? -1 : Math.max(-1, available_uses),
      };
    })
    .post(routes.submit);
};

provide("form", form);
provide("validityRanges", validityRanges);
</script>
