<template>
  <div>
    <In-Head :title="$page.props.title" />
    <sub-header :title="$page.props.title">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          :href="$page.props.routes.index"
          preset="back"
          icon="arrow-left" />
      </template>
    </sub-header>

    <Form @onSubmit="onSubmit" />
  </div>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";
import Form from "./Form.vue";

const props = defineProps({
  routes: { type: Object, required: true },
});

const form = useForm({
  name: null,
  owner_id: null,
});

const onSubmit = () => {
  form.post(props.routes.submit);
};

provide("form", form);
</script>
