import get from "lodash/get";
import eachRight from "lodash/eachRight";
import replace from "lodash/replace";

window.trans = (key, args) => {
  // Base translations are under "_" key
  let translation = window.transKey(`_.${key}`, args);

  if (translation.startsWith("_")) {
    translation = translation.substr(2);
  }

  const translated =  window.transKey(translation, args);

  if (translated === translation) {
    console.warn(`missing translation for: ${key}`);
  }

  return translated;
};

window.transKey = (key, args) => {
  let value = get(window.translations, key, key);

  // Replace placeholders with value from args
  eachRight(args, (val, key) => {
    value = replace(value, `:${key}`, val);
  });

  return value || key;
};
