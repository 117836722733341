import colorpicker from "./primevue/colorpicker";
import datatable from "./primevue/datatable";
import dialog from "./primevue/dialog";
import directives from "./primevue/directives";
import dropdown from "./primevue/dropdown";
import global from "./primevue/global";
import overlaypanel from "./primevue/overlaypanel";
import paginator from "./primevue/paginator";
import progressbar from "./primevue/progressbar";

const SportmatePrimevueConfig = {
  global,
  directives,
  datatable,
  paginator,
  progressbar,
  colorpicker,
  dropdown,
  dialog,
  overlaypanel
};

export default SportmatePrimevueConfig;
