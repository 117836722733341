<template>
  <div>
    <In-Head :title="$page.props.title" />
    <sub-header :title="$page.props.title">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          :href="$page.props.routes.showFranchise"
          preset="back"
          icon="arrow-left" />
      </template>
    </sub-header>

    <navigation :clubs="clubs" />

    <club-details
      v-if="selectedClub"
      :club="selectedClubObject" />
    <ticket-details v-else />
  </div>
</template>

<script setup>
import Navigation from "./_partials/Navigation.vue";
import TicketDetails from "./edit/TicketDetails.vue";
import ClubDetails from "./edit/ClubDetails.vue";

const { clubs, selectedClub } = defineProps({
  routes: { type: Object, required: true },
  franchise: { type: Object, required: true },
  clubs: { type: Array, required: true },
  selectedClub: { type: [Number, String], required: false, default: null },
});

const selectedClubObject = computed(() => clubs.find(club => club.id == selectedClub));
</script>
