<template>
  <div class="space-y-4">
    <basics />
    <validity />
    <sales />
    <sm-card>
      <sm-button
        preset="save"
        :loading="form.processing"
        @click="$emit('onSubmit')" />
    </sm-card>
  </div>
</template>

<script setup>
import Basics from "./_partials/Basics.vue";
import Validity from "./_partials/Validity.vue";
import Sales from "./_partials/Sales.vue";
const form = inject("form");

defineEmits(["onSubmit"]);
</script>
