<template>
  <div>
    <sm-card header-title="Default usage">
      <sm-select
        label="Default select"
        :options="options" />
      <code class="block !-mt-4"><pre class="text-xs">&lt;sm-select label="..." options="..." /&gt;</pre></code>
    </sm-card>

    <sm-card header-title="Value bindings">
      <sm-alert
        no-flex
        color="info">
        The default format of the select value is: <code class="leading-normal">{ id: 'something', label: 'asdas' }</code>
        <br>
        If your object has a different id or a different label use the <code class="leading-normal">id</code> or the <code class="leading-normal">customLabel</code> props (see below).
      </sm-alert>
      <sm-select
        v-model="selectModel"
        :options="options"
        :label="`V-model select id: ${selectModel ? selectModel?.id: '-'}, label: ${selectModel ? selectModel?.label : '-'}`" />
      <code class="block !-mt-4"><pre class="text-xs">v-model="..."</pre></code>
      <hr>
      <sm-select
        :model-value="selectValue"
        :options="options"
        :label="`ModelValue select id: ${selectValue ? selectValue?.id: '-'}, label: ${selectValue ? selectValue?.label : '-'}`" />
      <code class="block !-mt-4"><pre class="text-xs">:model-value="..."</pre></code>
    </sm-card>

    <sm-card header-title="Texts and helps">
      <sm-select
        label="Label select" />
      <code class="block !-mt-4"><pre class="text-xs">label="..."</pre></code>
      <hr>
      <sm-select
        label="Placeholder select"
        placeholder="This is a the placeholder" />
      <code class="block !-mt-4"><pre class="text-xs">placeholder="..."</pre></code>
      <hr>
      <sm-select
        label="Help text select"
        help="This is a help text" />
      <code class="block !-mt-4"><pre class="text-xs">help="..."</pre></code>
    </sm-card>
  </div>
</template>

<script setup>
const selectModel = ref("");
const selectValue = ref({
  id: 1,
  label: "First something"
});
const options = ref([
  {
    id: 1,
    label: "First something"
  },
  {
    id: 2,
    label: "Second something"
  },
  {
    id: 3,
    label: "Third and extremly long something"
  }
]);
</script>
