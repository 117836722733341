<template>
  <div class="space-y-4">
    <sm-card
      :form="form"
      :header-title="$t('payment.physical.cashdro.settings.title')"
      @onSubmit="onSubmit">
      <sm-radio-group
        v-model="form.physical_payment"
        name="physical_payment"
        :items="physicalPaymentOptions"
        :label="$t('payment.physical.cashdro.settings.physical_payment')"
        inline
      />
      <template v-if="form.physical_payment">
        <sm-input
          v-model="form.config.ip"
          name="config.ip"
          :label="$t('payment.physical.cashdro.settings.address')"
        />
        <sm-input
          v-model="form.config.username"
          name="config.username"
          :label="$t('payment.physical.cashdro.settings.username')"
        />
        <sm-input
          v-model="form.config.password"
          name="config.password"
          type="password"
          :label="$t('payment.physical.cashdro.settings.password')"
        />
      </template>
      <template #footer>
        <sm-button
          preset="save"
          :loading="form.processing"
          @click="onSubmit" />
      </template>
    </sm-card>
  </div>
</template>

<script setup>
import { useFormatArrayToTrueFalseObjects } from "@/inertia/composables/global";
import { useForm, usePage } from "@inertiajs/vue3";

const page = usePage();

const physicalPaymentOptions = useFormatArrayToTrueFalseObjects(["physical_payment_enabled", "physical_payment_disabled"]);

// build the form
const form = useForm({
  physical_payment: page.props.settings.physicalPaymentEnabled,
  config: {
    ip: "",
    username: "",
    password: "",
    ...page.props.settings.config,
  },
});

const onSubmit = () => {
  form.post(page.url);
};

provide("form", form);
</script>
