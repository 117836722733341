import CreateElementHelpers from "./CreateElementHelpers";

export default {
  mixins: [CreateElementHelpers],
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
  },
  // data() {
  //   return {
  //     defaults: {},
  //   };
  // },
  computed: {
    serializedFilter() {
      return JSON.stringify({
        field: this.data.filter.field,
        id: this.data.id,
        ...this.filterData,
      });
    },
    // icon() {
    //   if (!this.data.filter.icon) {
    //     return this.defaultIcon;
    //   }

    //   return this.data.filter.icon.length > 0 ?
    //     this.data.filter.icon :
    //     this.defaultIcon;
    // },
  },
  created() {
    // eslint-disable-next-line vue/no-mutating-props
    this.data.data = {
      ...this.defaults,
      ...this.data.data,
    };
  },
  methods: {
    removeFilter(event) {
      this.$emit("remove", event);
    },
    renderAbstract(defaultSlot = null) {
      const labelColSlot = this.$slots["label-col"] || [
        this.createDiv("sm-col lg:w-3/12 w-full", [
          this.createLabel(this.$t(this.data.filter.label || this.data.filter.field)),
        ]),
      ];

      const removeColSlot = this.$slots["remove-col"] || [
        this.createDiv("sm-col lg:w-1/12 w-full", [
          this.createInput([this, "serializedFilter"], "hidden", "filters[]"),
          this.$createElement("i", {
            staticClass: "fa fa-minus-circle text-bo-red col-form-label",
            on: { click: this.removeFilter }
          }),
        ]),
      ];

      return this.createDiv("crud-filter sm-row items-center", [
        labelColSlot,
        defaultSlot || this.$slots["default"],
        removeColSlot,
      ]);
    },
  },
};
