<script>
import moment from "moment";
import axios from "axios";
import { Bar } from "vue-chartjs";
import {BarElement, CategoryScale, Chart as ChartJS, LinearScale, Title, Tooltip} from "chart.js";

ChartJS.register(
  Title, Tooltip, BarElement, CategoryScale, LinearScale,
);

export default {
  components: { "bar-chart": Bar },
  props: {
    endpoint: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loaded: false,
      day: null,
      salesCount: null,
      chartdata: null,
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
        height: 50,
        legend: false,
      }
    };
  },
  computed: {
    today() {
      return moment().format("YYYY-MM-DD");
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loaded = false;
      try {
        const url = this.makeUrl();
        const response = await axios.get(url);
        const {data, meta} = response.data;

        if (!this.day) {
          this.day = meta.day;
        }

        this.salesCount = data.sales_count;
        this.setChartData(data);
        this.loaded = true;
      } catch (e) {
        console.error(e);
      }
    },
    setChartData(data) {
      this.chartdata = {
        labels: data.labels,
        datasets: [
          {
            label: "Eladások",
            backgroundColor: "#3490dc",
            data: data.values,
          },
        ]
      };
    },
    makeUrl() {
      let url = `${this.endpoint}`;

      if (this.day) {
        url += `?day=${this.day}`;
      }

      return url;
    },
    filterToday() {
      this.day = this.today;
      this.loadData();
    }
  }
};
</script>
