<template>
  <div>
    <In-Head :title="$t('customers.appointments.title', {name: $page.props.customer.name})" />

    <sub-header :title="$t('customers.appointments.title', {name: $page.props.customer.name})">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        :data="table"
        :sorts="sorts"
        paginator>
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`customers.appointments.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template v-if="col === 'date'">
                {{ slotProps.data?.from }} - {{ slotProps.data?.to }}
              </template>
              <template v-else-if="col === 'paid'">
                <vue-feather
                  v-if="slotProps.data?.paid"
                  :type="slotProps.data?.paid ? 'check' : 'x'"
                  class="w-4"
                  :class="{'text-bo-green': slotProps.data?.paid, 'text-bo-red': !slotProps.data?.paid}" />
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableFields = ref(["date", "location", "coaches", "note", "paid", "tickets"]);
</script>
