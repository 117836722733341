<template>
  <sm-modal
    :modal-is-visible="modalVisibility"
    :modal-title="$t('customers.tickets.modals.change_validty_end.title')"
    @toggleModalVisibility="emit('toggleModalVisibility')">
    <form @submit.prevent="onSubmit">
      <sm-date-picker-date
        v-if="showExtensions"
        v-model="form.valid_to"
        name="valid_from"
        :label="$t('customers.tickets.modals.change_validty_end.fields.valid_to.label')"
        :placeholder="$t('customers.tickets.modals.change_validty_end.fields.valid_to.placeholder', {date: useDateFormat(new Date())})"
      />
      <sm-select
        v-else
        v-model="form.extension_days"
        name="extension_days"
        :options="extensionAmounts"
        :label="$t('customers.tickets.modals.change_validty_end.fields.extension_days.label')"
        has-default
      />
      <sm-checkbox
        v-model="showExtensions"
        :text="$t('customers.tickets.modals.change_validty_end.fields.change_format.text')"
      />
    </form>
    <sm-alert color="secondary">
      <small>
        {{ $t('customers.tickets.modals.change_validty_end.info') }}
      </small>
    </sm-alert>
    <template #footer>
      <sm-button
        :title="$t('generic.cancel')"
        @click="emit('toggleModalVisibility')" />
      <sm-button
        color="primary"
        :disabled="formDisabled"
        :title="$t('generic.save')"
        :loading="form.processing"
        @click="onSubmit" />
    </template>
  </sm-modal>
</template>

<script setup>
import { useDateFormat, useFormatSelect } from "@/inertia/composables/global";
import { useForm, usePage } from "@inertiajs/vue3";

const props = defineProps({
  modalVisibility: { type: Boolean, required: true, default: false },
  modalData: { type: Object, required: false, default: null },
});

const page = usePage();

const emit = defineEmits(["toggleModalVisibility"]);

const extensionAmounts = useFormatSelect(page.props.extensionAmounts);

const showExtensions = ref(false);

watch(showExtensions, () => {
  form.defaults("valid_to", null);
  form.reset("valid_to");
});

const form = useForm({
  valid_to: null,
  extension_days: extensionAmounts[0],
  use_datepicker: false
});

provide("form", form);

const formDisabled = computed(() => {
  return showExtensions.value && form.valid_to === null;
});

const onSubmit = () => {
  form
    .transform((data) => ({
      ...data,
      valid_to: data.valid_to ? useDateFormat(data.valid_to, "form") : null,
      extension_days: data.extension_days?.id,
      use_datepicker: data.valid_to ? true : false
    }))
    .post(props.modalData.routes.extendTicketValidity, {
      only: ["table", "customer", "errors", "flash"],
      onSuccess: () => {
        emit("toggleModalVisibility");
      }
    });
};
</script>
