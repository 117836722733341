<template>
  <ul class="sm-nav-tabs-material">
    <li>
      <Link
        :href="currentUrlWithoutQueryParams"
        class="transition-all"
        :class="{active: currentId === null}"
      >
        Alapadatok
      </Link>
    </li>
    <li
      v-for="club in clubs"
      :key="club.id">
      <Link
        :href="club.url"
        class="transition-all"
        :class="{active: club.id == currentId}"
      >
        {{ club.name }}
      </Link>
    </li>
  </ul>
</template>

<script setup>
import { Link, usePage } from "@inertiajs/vue3";

const page = usePage();

const { clubs } = defineProps({
  clubs: { type: Array, required: true },
});

const currentId = page.props.selectedClub;

const currentUrlWithoutQueryParams = computed(() => {
  return page.url.substring(0, page.url.indexOf("?"));
});

</script>
