export default {
  state: {
    stockRegistration: {
      availableProducts: [],
      selectedProducts: [],
    },
  },
  mutations: {
    selectProduct(state, product) {
      state.stockRegistration.selectedProducts = [
        ...state.stockRegistration.selectedProducts,
        product
      ];
    },
    removeProduct(state, product) {
      state.stockRegistration.selectedProducts = state.stockRegistration.selectedProducts.filter(p => p !== product);
    },
    updateProduct(state, {product, data}) {
      state.stockRegistration.selectedProducts = state.stockRegistration.selectedProducts.map(item => {
        if (item === product) {
          return {
            ...item,
            ...data
          };
        }

        return item;
      });
    },
    setAvailableProducts(state, products) {
      state.stockRegistration.availableProducts = products;
    },
    setSelectedProducts(state, products) {
      state.stockRegistration.selectedProducts = products.map(product => ({
        acquisitionPrice: product.acquisition_price,
        product: product.product,
        amount: product.amount
      }));
    }
  },
  getters: {
    productsNetSum(state) {
      return state.stockRegistration.selectedProducts.reduce((sum, product) => {
        return sum + Math.round(product.amount * product.acquisitionPrice);
      }, 0);
    },
    productsSum(state) {
      return state.stockRegistration.selectedProducts.reduce((sum, product) => {
        const tax = product.product && product.product.data.tax ? product.product.data.tax : 27;
        const taxValue = 1 + (tax / 100);
        return sum + Math.round(product.amount * (product.acquisitionPrice * taxValue));
      }, 0);
    }
  },
  actions: {},
};
