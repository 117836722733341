<template>
  <div class="space-y-4">
    <div>
      <h4>{{ $t('cafeteria.recipes.form.items') }}</h4>
      <sm-card>
        <div class="grid grid-cols-2 gap-x-4">
          <div class="mb-2">
            <sm-async-select
              v-model="selectedProduct"
              :label="$t('cafeteria.recipes.fields.product')"
              :route="$page.props.routes.searchProduct"
            />
            <sm-button
              preset="add"
              :disabled="!selectedProduct"
              @click="addProduct"
            />
          </div>
          <div />
          <table class="sm-table col-span-2">
            <thead>
              <tr>
                <th>{{ $t('cafeteria.recipes.items.name') }}</th>
                <th>{{ $t('cafeteria.recipes.items.amount') }}</th>
                <th class="w-px" />
              </tr>
            </thead>
            <tbody>
              <template v-if="form.items.length > 0">
                <tr
                  v-for="item in form.items"
                  :key="item.product.id"
                >
                  <td>{{ item.product.label }}</td>
                  <td>
                    <sm-input
                      v-model="item.amount"
                      type="number"
                      step="1"
                      min="1"
                      name="item-amount"
                      class="mb-0"
                    />
                  </td>
                  <td>
                    <sm-button
                      preset="delete-icon"
                      @click="removeProduct(item.product)"
                    />
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="3">
                    <sm-alert
                      color="warning"
                      no-margin
                    >
                      {{ $t('generic.empty_table') }}
                    </sm-alert>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </sm-card>
    </div>
    <sm-card>
      <sm-button
        preset="save"
        :loading="form.processing"
        @click="$emit('onSubmit')" />
    </sm-card>
  </div>
</template>

<script setup>
const form = inject("form");

const selectedProduct = ref(null);

const addProduct = () => {
  if (! selectedProduct.value) {
    return;
  }

  const existing = form.items.find(i => i.product.id === selectedProduct.value.id);

  if (! existing) {
    form.items = form.items.concat({
      product: selectedProduct.value,
      amount: 1,
    });
  } else {
    existing.amount += 1;
  }

  selectedProduct.value = null;
};

const removeProduct = (product) => {
  form.items = form.items.filter(_p => _p.product.id !== product.id);
};

defineEmits(["onSubmit"]);
</script>
