<template>
  <styleguide-layout>
    <sm-card header-title="Simple usage">
      <sm-alert color="info">
        <span>
          We use inertia.js for forms. Read more at: <a
            class="font-bold"
            href="https://inertiajs.com/forms"
            target="_blank">https://inertiajs.com/forms</a>
        </span>
      </sm-alert>
      <form @submit.prevent="onSubmit">
        <div class="sm-row">
          <div class="sm-col">
            <sm-input
              v-model="form.name"
              label="Sample form input" />
          </div>
          <div class="sm-col pt-label">
            <sm-button
              type="submit"
              size="input">
              Submit
            </sm-button>
          </div>
        </div>
      </form>
      <hr>
      <code class="block">
        <strong>HTML:</strong>
        <pre class="text-xs">
&lt;form @submit.prevent="onSubmit"&gt;
  &lt;sm-input v-model="form.name" label="Sample form input" /&gt;
  &lt;sm-button type="submit"&gt;Submit&lt;&gt;
&lt;/form>
        </pre>
        <strong class="text-bo-green">Javascript:</strong>
        <pre class="text-xs text-bo-green">
import { useForm } from "@inertiajs/vue3";

const form = useForm({
  name: "First name"
});

const onSubmit = () => {
  form.post(page.url);
};
        </pre>
      </code>
    </sm-card>

    <sm-card header-title="Transform forms">
      <sm-alert color="info">
        You can transform the data before posting.
      </sm-alert>
      <code>
        <pre class="text-xs text-bo-green">
form
  .transform((data) => ({
    ...data,
    birth_date: format(data.birth_date, "yyyy-MM-dd")
  }))
  .post(page.url);</pre>
      </code>
    </sm-card>

    <sm-card header-title="Reload selection of data">
      <sm-alert color="info">
        After posting you can reload selection of data.
      </sm-alert>
      <code>
        <pre class="text-xs text-bo-green">
form
  .post(page.url, {
    only: ["selection", "of", "props"],
  });</pre>
      </code>
    </sm-card>

    <sm-card header-title="Function after success">
      <sm-alert color="info">
        After posting you can run functions after success.
      </sm-alert>
      <code>
        <pre class="text-xs text-bo-green">
form
  .post(page.url, {
    onSuccess: () => {
      someFunction();
    },
  });</pre>
      </code>
    </sm-card>
  </styleguide-layout>
</template>

<script setup>
import StyleguideLayout from "@/inertia/components/styleguide/StyleguideLayout.vue";
import { useForm } from "@inertiajs/vue3";

const form = useForm({
  name: "First name"
});

const onSubmit = () => {
  form.post("/route");
};
</script>


