import hotkeys from "hotkeys-js";
import { throttle } from "lodash";

const closeModal = throttle(() => {
  const activeModal = document.querySelector(".sm-modal.sm-show");

  if (!activeModal || activeModal.classList.contains("vue-modal")) {
    return;
  }

  const closeEvent = new CustomEvent("bs-modal-close");
  const closedEvent = new CustomEvent("bs-modal-closed");

  activeModal.classList.toggle("sm-show");
  activeModal.focus();

  hotkeys.setScope("global");

  setTimeout(() => {
    activeModal.style.cssText = "display: hidden";
    activeModal.dispatchEvent(closeEvent);
  }, 150);

  activeModal.dispatchEvent(closedEvent);
}, 300);

const openModal = throttle((modal) => {
  const activeModal = document.querySelector(modal);

  if (!activeModal) {
    console.warn(`Missing element for modal: ${modal}`);
    return;
  }

  if (activeModal.classList.contains("vue-modal")) {
    return;
  }

  const openEvent = new CustomEvent("bs-modal-open");
  const openedEvent = new CustomEvent("bs-modal-opened");
  activeModal.style.cssText = "display: block";
  activeModal.focus();

  hotkeys.setScope("sm-modal");

  setTimeout(() => {
    activeModal.classList.toggle("sm-show");
    activeModal.dispatchEvent(openEvent);
  }, 15);

  activeModal.dispatchEvent(openedEvent);
}, 300);

function loadSmModal() {

  document.querySelectorAll("[data-toggle='sm-modal']").forEach(toggler => {
    toggler.addEventListener("click", (e) => {
      e.preventDefault();
      const modal = toggler.getAttribute("data-target") || toggler.getAttribute("href");
      openModal(modal);
    });
  });

  document.querySelectorAll(".sm-modal-backdrop").forEach(modal => {
    modal.addEventListener("click", () => {
      if (!modal.parentElement.dataset.backdrop === "static") {
        closeModal();
      }
    });
  });

  document.querySelectorAll("[data-dismiss='sm-modal']").forEach(toggler => {
    toggler.addEventListener("click", () => {
      closeModal();
    });
  });

  document.addEventListener("keydown", e => {
    const activeElement = document.activeElement;
    if (activeElement.dataset.keyboard && activeElement.dataset.keyboard === "false") {
      return;
    }
    if (e.key === "Escape" && activeElement.classList.contains("sm-show")) {
      closeModal();
    }
  });

  document.addEventListener("keydown", e => {
    if (e.key === "Enter" && document.activeElement.classList.contains("sm-show")) {
      document.querySelector(".sm-show .sm-modal-footer button[type=\"submit\"]")?.click();
    }
  });
}

window.loadSmModal = loadSmModal;

function toggleSmModal(modal, type) {

  if (type === "open") {
    openModal(modal);
  } else if (type === "close") {
    closeModal();
  } else {
    if (document.querySelector(modal).classList.contains("sm-show")) {
      closeModal();
    } else {
      openModal(modal);
    }
  }
}

window.toggleSmModal = toggleSmModal;

function removeSmModalEventListeners() {
  document.querySelectorAll("[data-toggle='sm-modal']").forEach(toggler => {
    toggler.replaceWith(toggler.cloneNode(true));
  });

  document.querySelectorAll(".sm-modal-backdrop").forEach(modal => {
    if (!modal.parentElement.classList.contains("vue-modal")) {
      modal.replaceWith(modal.cloneNode(true));
    }
  });

  document.querySelectorAll("[data-dismiss='sm-modal']").forEach(toggler => {
    toggler.replaceWith(toggler.cloneNode(true));
  });
}

window.removeSmModalEventListeners = removeSmModalEventListeners;

export { closeModal, openModal, toggleSmModal };

