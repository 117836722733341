
const global = {
  css: `
  *[data-pd-ripple="true"]{
      overflow: hidden;
      position: relative;
  }
  span[data-p-ink-active="true"]{
      animation: ripple 0.4s linear;
  }
  @keyframes ripple {
      100% {
          opacity: 0;
          transform: scale(2.5);
      }
  }
  .progressbar-value-animate::after {
      will-change: left, right;
      animation: p-progressbar-indeterminate-anim-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  }
  .progressbar-value-animate::before {
      will-change: left, right;
      animation: p-progressbar-indeterminate-anim 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  }
  @keyframes p-progressbar-indeterminate-anim {
      0% {
          left: -35%;
          right: 100%;
      }
      60% {
          left: 100%;
          right: -90%;
      }
      100% {
          left: 100%;
          right: -90%;
      }
  }
`
};

export default global;
