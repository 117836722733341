const TRANSITIONS = {
  overlay: {
    enterFromClass: "opacity-0 scale-75",
    enterActiveClass: "transition-transform transition-opacity duration-100 ease-in",
    leaveActiveClass: "transition-opacity duration-100 ease-linear",
    leaveToClass: "opacity-0",
  },
  fade: {
    enterFromClass: "opacity-0 -translate-y-4",
    enterActiveClass: "transition-transform transition-opacity duration-150 ease-in-out",
    leaveActiveClass: "transition-opacity duration-150 ease-in-out",
    leaveToClass: "opacity-0",
  }
};

export default TRANSITIONS;

