<template>
  <div class="text-right">
    <p class="mb-0 text-xs text-brand-gray-800/50 whitespace-nowrap">
      {{ $t('Bent lévő vendégek') }}
    </p>
    <div class="flex items-center space-x-2">
      <div
        class="text-xl cursor-pointer border rounded-lg leading-5 px-1 hover:border-bo-gray-400 transition-colors min-w-[22px] text-center"
        @click="openModal({ increase: false })">
        -
      </div>
      <div class="text-xs font-semibold uppercase text-brand-gray-800 flex-1 text-center">
        {{ checkedInUsers }} {{ $t('fő') }}
      </div>
      <div
        class="text-xl cursor-pointer border rounded-lg leading-5 px-1 hover:border-bo-gray-400 transition-colors min-w-[22px] text-center"
        @click="openModal({ increase: true })">
        +
      </div>
    </div>
    <portal to="modals">
      <sm-modal
        :modal-is-visible="modalOpen"
        :modal-title="actionIncrease ? $t('Belépett vendégek rögzítése') : $t('Kilépett vendégek rögzítése')"
        @toggleModalVisibility="toggleModal">
        <div
          class="sm-form-group"
          :class="{'has-error': form.errors.value}"
        >
          <label class="sm-label">{{ $t('Vendégek száma') }}</label>

          <input
            ref="valueInputEl"
            v-model="form.value"
            class="text-sm sm-input-text"
            type="number"
            min="0"
            step="1"
            @keyup.enter="submit"
          >

          <template v-if="form.errors.value">
            <div class="sm-feedback error">
              {{ form.errors.value }}
            </div>
          </template>
        </div>
        <template #footer>
          <button
            class="sm-btn sm-btn-xs"
            @click="toggleModal">
            {{ $t('generic.cancel') }}
          </button>
          <button
            class="sm-btn sm-btn-xs sm-btn-primary"
            @click="submit">
            {{ $t('generic.save') }}
          </button>
        </template>
      </sm-modal>
    </portal>
  </div>
</template>

<script setup>
import axios from "axios";
import SmModal from "../../inertia/components/base/SmModal.vue";
import { ref } from "vue";
import { useForm } from "@inertiajs/vue3";

const props = defineProps({
  initial: { type: Number, required: false, default: 0 },
  submitRoute: { type: String, required: true },
});

const checkedInUsers = ref(props.initial);
const modalOpen = ref(false);
const actionIncrease = ref(false);
const valueInputEl = ref(null);

const form = useForm({
  value: 1,
});

const toggleModal = () => {
  modalOpen.value = !modalOpen.value;
};

const openModal = ({ increase }) => {
  actionIncrease.value = increase;
  form.reset();
  toggleModal();
};

const closeModal = () => {
  toggleModal();
};

watchEffect(() => {
  if (valueInputEl.value) {
    valueInputEl.value.focus();
  }
});

const submit = async () => {
  try {
    const response = await axios.post(props.submitRoute, {
      value: actionIncrease.value ? form.value : (-1 * form.value)
    });
    checkedInUsers.value = response.data.data.value;

    closeModal();
  } catch (e) {
    console.error(e);

    if (e.response.data.errors.value) {
      form.setError("value", e.response.data.errors.value[0]);
    }
  }
};
</script>
