
const InitNativeBootstrapPlugins = {
  install(Vue) {
    Vue.mixin({
      mounted() {
        window.loadSmScripts();
      },
    });
  }
};

export default InitNativeBootstrapPlugins;
