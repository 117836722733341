<template>
  <div>
    <In-Head :title="$page.props.title" />
    <sub-header :title="$page.props.title">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <a
          :href="$page.props.routes.index"
          class="sm-btn p-ripple sm-btn-xs"
        >
          <vue-feather type="arrow-left" />
          <span class="inline-flex items-center">
            {{ $t("generic.buttons.back") }}
          </span>
        </a>
      </template>
    </sub-header>

    <Form @onSubmit="onSubmit" />
  </div>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";
import Form from "./Form.vue";

const props = defineProps({
  routes: { type: Object, required: true },
  recipe: { type: Object, required: true },
});

const form = useForm(props.recipe);

const onSubmit = () => {
  form
    .transform((data) => {
      return {
        ...data,
        items: data.items.map((item) => ({
          club_stock_item_id: item.product.id,
          amount: item.amount,
        })),
      };
    })
    .put(props.routes.submit);
};

provide("form", form);
</script>
