<template>
  <div>
    <In-Head :title="$t('customers.ticket_usages.show.title', {name: $page.props.customer.name})" />

    <sub-header
      :title="$t('customers.ticket_usages.show.title', {name: $page.props.customer.name})"
      :export-route="$page.props.routes.export"
    >
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          :href="$page.props.routes.back"
          preset="back"
          icon="arrow-left" />
      </template>
    </sub-header>

    <customers-nav-tabs />

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`customers.ticket_usages.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template v-if="col === 'used_for'">
                <template v-if="slotProps.data?.for_appointment">
                  <template v-if="slotProps.data?.service">
                    <p class="mb-0 font-bold">
                      {{ slotProps.data?.service.name }}
                    </p>
                  </template>
                  <p class="mb-0">
                    {{ slotProps.data?.appointment.from }} - {{ slotProps.data?.appointment.to }}
                  </p>
                </template>
                <template v-else>
                  -
                </template>
              </template>
              <template v-if="col === 'attended'">
                <template v-if="slotProps.data?.for_appointment">
                  <sm-boolean-flag :value="slotProps.data?.[col]" />
                </template>
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import CustomersNavTabs from "@/inertia/pages/customers/show/_partials/CustomersNavTabs.vue";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableFields = ref(["ticket", "used_for", "attended", "created_at"]);
</script>
