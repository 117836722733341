<template>
  <sm-card
    :form="form"
    :header-title="$t('payment.simplepay.settings.title')"
    @onSubmit="onSubmit">
    <sm-radio-group
      v-model="form.online_payment"
      name="online_payment"
      :items="online_payment_options"
      :label="$t('payment.simplepay.settings.online_payment.label')"
      :help="$t('payment.simplepay.settings.online_payment.help')"
      inline
    />
    <sm-radio-group
      v-model="form.escrow_payment"
      class="hidden"
      name="escrow_payment"
      :items="escrow_payment_options"
      :label="$t('payment.simplepay.settings.escrow_payment.label')"
      :help="$t('payment.simplepay.settings.escrow_payment.help')"
      inline
    />
    <sm-radio-group
      v-model="form.balance_payment"
      name="balance_payment"
      :items="balance_payment_options"
      :label="$t('payment.simplepay.settings.balance_payment.label')"
      :help="$t('payment.simplepay.settings.balance_payment.help')"
      inline
    />
    <div v-auto-animate>
      <sm-radio-group
        v-if="form.balance_payment"
        v-model="form.balance_payment_use_credit_limit"
        name="balance_payment_use_credit_limit"
        :items="balance_payment_use_credit_limit_options"
        :label="$t('payment.simplepay.settings.balance_payment_use_credit_limit.label')"
        :help="$t('payment.simplepay.settings.balance_payment_use_credit_limit.help')"
        inline
      />
    </div>
    <template #footer>
      <sm-button
        preset="save"
        :loading="form.processing"
        @click="onSubmit" />
    </template>
  </sm-card>
</template>

<script setup>
import { useForm, usePage } from "@inertiajs/vue3";
import { useFormatArrayToTrueFalseObjects } from "@/inertia/composables/global";

const page = usePage();

const online_payment_options = useFormatArrayToTrueFalseObjects(["online_payment_enabled", "online_payment_disabled"]);
const escrow_payment_options = useFormatArrayToTrueFalseObjects(["has_escrow_payment", "doesnt_have_escrow_payment"]);
const balance_payment_options = useFormatArrayToTrueFalseObjects(["has_balance_payment", "doesnt_have_balance_payment"]);
const balance_payment_use_credit_limit_options = useFormatArrayToTrueFalseObjects(["balance_payment_use_credit_limit_yes", "balance_payment_use_credit_limit_no"]);

// build the form
const form = useForm({
  online_payment: page.props.settings.online_payment,
  escrow_payment: page.props.settings.escrow_payment,
  balance_payment: page.props.settings.balance_payment,
  balance_payment_use_credit_limit: page.props.settings.balance_payment_use_credit_limit,
});

const onSubmit = () => {
  form
    .transform((data) => {
      return {
        ...data
      };
    })
    .post(page.url);
};

provide("form", form);
</script>
