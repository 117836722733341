import Vue from "vue";
// import Alpine from "alpinejs";
import axios from "axios";
import "bootstrap";
import "bootstrap-datepicker";
import jQuery from "jquery";
import _ from "lodash";
import moment from "moment";
import "moment/dist/locale/hu";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import { router } from "@inertiajs/vue3";

import LivewireModal from "../../vendor/livewire-ui/modal/js/modal";
import CampaignStatistics from "./components/Marketing/CampaignStatistics";
import EmailTemplateFormEditor from "./components/Marketing/EmailTemplateFormEditor";
import EmailTemplateFormUnlayer from "./components/Marketing/EmailTemplateFormUnlayer";
import SupportBadge from "./components/SupportBadge";
import CheckinWithTickets from "./modules/clubs/customers/CheckinWithTickets";

import "./components/ClubSelector";

import "@/shared/Axios";
import "../shared/FileInput";
import "./utils/FormTargetCsrfFix";
import "./utils/ModalForms";
import "./utils/Sidebar";
import "./utils/TimeInput";
import "./utils/BootstrapDatepickerHu";

import "./utils/Hotkeys";

window.$ = jQuery;
window.jQuery = jQuery;
window._ = _;

window.Vue = Vue;
window.moment = moment;
moment.locale("hu");
window.axios = axios;
// window.Alpine = Alpine;

document.addEventListener("alpine:init", () => {
  window.Alpine.data("LivewireUIModal", LivewireModal);
  window.Alpine.data("AppointmentCheckinTicketSelector", CheckinWithTickets);
  window.Alpine.data("SupportBadge", SupportBadge);
  window.Alpine.data("EmailTemplateFormEditor", EmailTemplateFormEditor);
  window.Alpine.data("EmailTemplateFormUnlayer", EmailTemplateFormUnlayer);
  window.Alpine.data("campaignStatisticsChart", CampaignStatistics);
});

// Alpine.start();

tippy("[data-tippy]", {
  content(reference) {
    return reference.getAttribute("title");
  },
});

window.addEventListener("modal-closed", e => {
  window.toggleSmModal(`#${e.detail.modal}`, "close");
});

document.querySelectorAll("a[data-inertia-link=true]")
  .forEach(link => {
    link.addEventListener("click", e => {
      e.preventDefault();
      router.visit(link.href);
    });
  });
