<template>
  <div>
    <sm-button
      v-if="pagePermissions?.hasBalanceManagement"
      color="success"
      :title="$t('customers.generic.balance.deposit_money')"
      hotkey="F4"
      @click="toggleModalVisibility" />
    <sm-modal
      :modal-is-visible="modal"
      :modal-title="$t('customers.generic.balance.modal_title')"
      @toggleModalVisibility="toggleModalVisibility">
      <form
        v-if="pageDeposit"
        ref="formRef"
        class=" focus:outline-0"
        @submit.prevent="onSubmit()">
        <sm-input
          id="amountInput"
          ref="amountInputRef"
          v-model="form.amount"
          name="amount"
          :label="$t('customers.generic.balance.fields.amount.label')"
          type="number"
          required
          step="1"
        >
          <template #append>
            Ft
          </template>
        </sm-input>
        <sm-select
          ref="selectRef"
          v-model="form.payment_type"
          :options="paymentTypes"
          required
          name="payment_type"
          :label="$t('customers.generic.balance.fields.payment_type.label')"
        />
        <sm-select
          v-if="pagePermissions?.canSelectCashRegister"
          v-model="form.cash_register_id"
          :options="cashRegisters"
          name="cash_register_id"
          required
          :label="$t('customers.generic.balance.fields.cash_register_id.label')"
        />
        <div class="flex flex-wrap items-center justify-end p-4 -mx-4 -mb-4 space-x-2 border-t border-brand-gray-200 rounded-b-md">
          <sm-button
            :title="$t('generic.cancel')"
            @click="toggleModalVisibility" />
          <sm-button
            id="submitButton"
            color="primary"
            type="submit"
            :disabled="v$.$invalid"
            :loading="form.processing"
            :title="$t('generic.pay')" />
        </div>
      </form>
    </sm-modal>
  </div>
</template>

<script setup>
import { useFormatSelect } from "@/inertia/composables/global";
import { useForm, usePage } from "@inertiajs/vue3";
import { useFocusTrap } from "@vueuse/integrations/useFocusTrap";
import hotkeys from "hotkeys-js";
import { nextTick, provide, watch } from "vue";

const props = defineProps({
  pageDeposit: { type: Object, required: false, default: null },
  pagePermissions: { type: Object, required: false, default: null }
});

const formRef = ref();
const { activate, deactivate } = useFocusTrap(formRef);

import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";

const paymentTypes = ref([]);
const cashRegisters = ref([]);

const page = usePage();
const modal = ref(false);
const amountInputRef = ref();
const selectRef = ref();

const toggleModalVisibility = () => {
  modal.value = !modal.value;
};

const form = useForm({
  amount: null,
  payment_type: null,
  cash_register_id: null,
});

const rules = {
  amount: { required },
  payment_type: { required },
  cash_register_id: { requiredIfFunction: requiredIf(() => props.pagePermissions?.canSelectCashRegister) },
};

const v$ = useVuelidate(rules, form);

provide("form", form);

watch(props, (e) => {
  if (e.pageDeposit) {
    paymentTypes.value = useFormatSelect(props.pageDeposit?.paymentTypes);
    cashRegisters.value = useFormatSelect(props.pageDeposit?.cashRegisters);

    if (props.pagePermissions?.canSelectCashRegister) {
      form.defaults("cash_register_id", cashRegisters.value[0]);
    }

    if (page.props.customer.balance < 0) {
      form.defaults("amount", Math.abs(page.props.customer.balance));
      form.defaults("payment_type", paymentTypes.value[0]);
    }
    form.reset();
  }
});

onMounted(() => {
  hotkeys("f4", "global", (e) => {
    e.preventDefault();
    if (e.repeat) {
      return;
    } else {
      toggleModalVisibility();
    }
  });
});

watch(modal, (e) => {
  if (e === true) {
    nextTick(() => {
      amountInputRef.value?.$el.querySelector(".sm-input-text")?.focus();
      activate();

      if (page.props.customer.balance < 0) {
        form.defaults("amount", Math.abs(page.props.customer.balance));
        form.defaults("payment_type", paymentTypes.value[0]);
        form.reset();
      }
    });
  } else {
    deactivate();
  }
});

watch(() => v$, (e) => {
  if (e.value.$invalid === false) {
    nextTick(() => {
      if (document.activeElement?.id !== "amountInput") {
        document.getElementById("submitButton")?.focus();
      }
    });
  }
}, {deep: true});

const onSubmit = () => {
  form
    .transform((data) => ({
      ...data,
      payment_type: data.payment_type?.id,
      cash_register_id: data.cash_register_id?.id
    }))
    .post(page.props.routes.depositMoney, {
      only: ["customer", "flash", "errors"],
      onSuccess: () => {
        toggleModalVisibility();
        form.reset();
        deactivate();
      }
    });
};
</script>
