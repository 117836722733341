<template>
  <div>
    <sm-card header-title="Props">
      <div class="">
        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Render the radio group as a buttons<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> buttonGroup<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Defaukt:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:button-group="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-radio-group
              :items="radioGroupItems"
              button-group
              label="Radio group with buttonGroup prop"
              name="prop_00" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add the name attribute to the radio group<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> name<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">name="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-radio-group
              :items="radioGroupItems"
              label="Radio group with name prop"
              name="prop_01" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add the id attribute to the radio group<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> id<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">id="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-radio-group
              id="id"
              :items="radioGroupItems"
              label="Radio group with id prop"
              name="prop_02" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add error text and state to the radio group<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> error<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean, String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">error="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-radio-group
              :items="radioGroupItems"
              error="Some error text"
              label="Radio group with error prop"
              name="prop_03" />
            <hr>
            <sm-radio-group
              :items="radioGroupItems"
              error="Some error text"
              button-group
              label="Radio group with error prop"
              name="prop_03_02" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add disabled state to radio group or a single id from the group<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> disabled<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> [Boolean, String]<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:disabled="true"</pre></code><br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:disabled="10"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-radio-group
              :items="radioGroupItems"
              disabled
              label="Radio group with disabled prop (boolean)"
              name="prop_04" />
            <hr>
            <sm-radio-group
              :items="radioGroupItems"
              disabled="radio_group_01"
              label="Radio group with disabled prop (string)"
              name="prop_04_01" />
            <hr>
            <sm-radio-group
              :items="radioGroupItems"
              button-group
              disabled
              label="Radio group with disabled prop (boolean)"
              name="prop_04_02" />
            <hr>
            <sm-radio-group
              :items="radioGroupItems"
              button-group
              disabled="radio_group_01"
              label="Radio group with disabled prop (string)"
              name="prop_04_02" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add required state to radio group<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> required<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:required="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-radio-group
              :items="radioGroupItems"
              required
              label="Radio group with required prop"
              name="prop_05" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add optional text to radio group<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> optional<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:optional="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-radio-group
              :items="radioGroupItems"
              optional
              label="Radio group with optional prop"
              name="prop_06" />
          </div>
        </div>
        <hr>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Render the label inline before the radio group<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> inline<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:inline="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-radio-group
              :items="radioGroupItems"
              inline
              label="Radio group with inline prop"
              name="prop_07" />
            <hr>
            <sm-radio-group
              :items="radioGroupItems"
              button-group
              inline
              label="Radio group with inline prop"
              name="prop_07_02" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Render the radio group with half width<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> half<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:half="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-radio-group
              :items="radioGroupItems"
              half
              label="Radio group with half prop"
              name="prop_08" />
            <hr>
            <sm-radio-group
              :items="radioGroupItems"
              button-group
              half
              label="Radio group with half prop"
              name="prop_08_02" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Render the radio group to multiple lines<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> breakLines<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:break-lines="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-radio-group
              :items="radioGroupItems"
              break-lines
              label="Radio group with breakLines prop"
              name="prop_09" />
            <hr>
            <sm-radio-group
              :items="radioGroupItems"
              button-group
              break-lines
              label="Radio group with breakLines prop"
              name="prop_09_02" />
          </div>
        </div>
      </div>
    </sm-card>
  </div>
</template>

<script setup>
const radioGroupItems = ref([
  {
    id: "radio_group_01",
    text: "First element"
  },{
    id: "radio_group_02",
    text: "Second element"
  },{
    id: "radio_group_03",
    text: "Third element"
  }
]);
</script>
