<script>
import get from "lodash/get";

export default {
  name: "StockRegistrationStornoForm",
  props: {
    stockProducts: { type: Array, default: () => {} },
    errors: { type: Object, default: () => {} }
  },
  data() {
    return {
      products: this.stockProducts,
    };
  },
  computed: {
    netSum() {
      return this.products.reduce((sum, product) => {
        return sum + Math.round(product.amount * product.acquisition_price);
      }, 0);
    },
    sum() {
      return this.products.reduce((sum, product) => {
        const tax = product.club_product.tax || 27;
        const taxValue = 1 + (tax / 100);
        return sum + Math.round(product.amount * (product.acquisition_price * taxValue));
      }, 0);
    },
  },
  methods: {
    removeProduct(product) {
      this.products = this.products.filter(p => p !== product);
    },
    errorMessage(index) {
      const errorsForIndex = get(this.errors, `items.${index}`);

      if (!errorsForIndex) {
        return null;
      }

      return Array.isArray(errorsForIndex) ? errorsForIndex[0] : errorsForIndex;
    }
  },
};
</script>
