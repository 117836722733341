<template>
  <div>
    <In-Head :title="$page.props.title" />
    <sub-header :title="$page.props.title">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <voucher-create-modal
          v-if="$page.props.permissions.create"
        />
        <sm-button
          icon="download"
          :title="$t('sales.vouchers.export_btn')"
          @click="submitExport"
        />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        :data="table"
        :sorts="sorts"
        paginator>
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`sales.vouchers.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template v-if="col === 'redeemed_by' || col === 'user'">
                <sm-avatar
                  v-if="slotProps.data?.[col]"
                  :user="slotProps.data?.[col]" />
              </template>
              <template v-else-if="col === 'redeemed_at' || col === 'created_at'">
                {{ slotProps.data?.[col] ? useDateFormat(slotProps.data?.[col], 'long') : '' }}
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div class="flex justify-end space-x-1">
                <sm-button
                  v-if="slotProps.data?.permissions.canDeactivate"
                  v-tippy="$t('sales.vouchers.deactivate')"
                  icon="x-circle"
                  size="icon"
                  @click="confirmDeactivate(slotProps.data)"
                />
                <sm-button
                  v-if="slotProps.data?.permissions.canDelete"
                  preset="delete-icon"
                  @click="confirmDelete(slotProps.data)"
                />
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
    <SmConfirmDialog group="confirmDelete" />
  </div>
</template>
<script setup>
import { useConfirm } from "primevue/useconfirm";
import axios from "axios";
import { router, useForm } from "@inertiajs/vue3";
import VoucherCreateModal from "./VoucherCreateModal.vue";
import { useDateFormat } from "@/inertia/composables/global";

const props = defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
  routes: { type: Object, required: true },
});

const tableFields = ref(["code", "user", "voucherable", "created_at", "redeemed_by", "redeemed_at"]);

const confirm = useConfirm();

const confirmDelete = async (voucher) => {
  confirm.require({
    group: "confirmDelete",
    header: window.trans("sales.vouchers.delete_modal_title"),
    message: `${window.trans("generic.are_you_sure")}`,
    accept: async () => {
      await axios.delete(voucher.routes.destroy);
      router.reload({ only: ["table", "flash"] }, { preserveState: true });
    }
  });
};

const confirmDeactivate = async (voucher) => {
  confirm.require({
    group: "confirmDelete",
    header: window.trans("sales.vouchers.deactivate_modal_title"),
    message: `${window.trans("generic.are_you_sure")}`,
    accept: async () => {
      await axios.post(voucher.routes.deactivate);
      router.reload({ only: ["table", "flash"] }, { preserveState: true });
    }
  });
};

const submitExport = async () => {
  const form = useForm({});
  await form.post(props.routes.export);
};
</script>
