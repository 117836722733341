import throttle from "lodash/throttle";

const loadSmTabs = throttle(() => {
  document.querySelectorAll("[data-toggle='tab']").forEach(toggler => {
    toggler.addEventListener("click", () => {
      const target = toggler.getAttribute("data-target") || toggler.getAttribute("href");
      const activeTarget = document.querySelector(target);

      // link
      document.querySelectorAll(".sm-nav-link.active").forEach(menu => {
        menu.classList.remove("active");
      });
      toggler.classList.add("active");

      // target
      document.querySelectorAll(".sm-tab-pane.block").forEach(menu => {
        menu.classList.remove("block");
      });
      activeTarget.classList.add("block");
    });
  });
}, 300);

window.loadSmTabs = loadSmTabs;

const removeSmTabsEventListeners = () => {
  document.querySelectorAll("[data-toggle='tab']").forEach(toggler => {
    toggler.replaceWith(toggler.cloneNode(true));
  });
};

window.removeSmTabsEventListeners = removeSmTabsEventListeners;
