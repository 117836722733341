<template>
  <div class="sm-media">
    <div class="block">
      <div class="flex flex-col mr-2 align-content-end">
        <i
          v-if="!isOn"
          class="mb-2 fas fa-toggle-off fa-2x"
          :title="$t('Bekapcsolás')"
          onmouseover=""
          style="cursor: pointer"
          @click="onStart"
        />
        <i
          v-if="isOn"
          class="mb-2 fas fa-toggle-on fa-2x"
          :title="$t('Kikapcsolás')"
          onmouseover=""
          style="cursor: pointer"
          @click="onStop"
        />
        <i
          v-if="isOn"
          class="mb-2 fas fa-camera-retro fa-2x"
          :title="$t('Kép készítése')"
          onmouseover=""
          style="cursor: pointer"
          @click="onCapture"
        />
      </div>
    </div>

    <div>
      <div class="flex items-center justify-center mb-2">
        <vue-web-cam
          ref="webcam"
          :hidden="!isOn"
          :device-id="deviceId"
          :height="height"
          :width="width"
          :resolution="computedResolution"
          @started="onStarted"
          @stopped="onStopped"
          @error="onError"
          @cameras="onCameras"
          @camera-change="onCameraChange"
        />

        <figure
          v-if="!isOn"
          class="m-0 figure"
        >
          <div
            v-if="img"
            class="relative"
          >
            <img
              :src="img"
              class="img-responsive"
            >
            <a
              href="#"
              class="retry-icon"
              :title="$t('Újra')"
              @click="onStart"
            >
              <i class="fa fa-redo" />
            </a>
          </div>
          <p v-else>
            Nincs kép
          </p>
        </figure>
      </div>

      <select
        v-model="camera"
        class="sm-select"
        :style="'width: ' + width + 'px;'"
      >
        <option
          v-for="item in devices"
          :key="item.deviceId"
          :value="item.deviceId"
        >
          {{ item.label }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { WebCam } from "vue-web-cam";

export default {
  name: "WebCam",
  components: {
    "vue-web-cam": WebCam,
  },
  props: {
    height: {
      type: Number,
      default: 256,
    },
    width: {
      type: Number,
      default: 256,
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
    screenshotFormat: {
      type: String,
      default: "image/jpeg",
    },
    playsinline: {
      type: Boolean,
      default: true,
    },
    resolution: {
      type: Object, // with width and height for camera resolution
      default: null,
    },
  },
  emits: ["input"],
  data() {
    return {
      img: null,
      camera: null,
      deviceId: null,
      devices: [],
      isOn: false,
      loading: false,
    };
  },
  computed: {
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
    computedResolution: function () {
      const resolution = this.$props.resolution;
      return resolution
        ? resolution
        : { height: this.$props.height, width: this.$props.width };
    },
  },
  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      // Once we have a list select the first one
      const [first] = this.devices;
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    },
  },
  methods: {
    onCapture() {
      this.img = this.$refs.webcam.capture();
      this.$emit("input", this.img);
      this.onStop();
    },
    onStarted() {
      this.isOn = true;
    },
    onStopped() {
      this.isOn = false;
    },
    onStop() {
      this.$refs.webcam.stop();
    },
    onStart() {
      this.$refs.webcam.start();
    },
    onError(error) {
      console.error(error);
    },
    onCameras(cameras) {
      this.devices = cameras;
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
    },
  },
};
</script>

<style>
.fas:hover {
  color: #3490dc;
}

.retry-icon {
  color: white;
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  opacity: 30%;
}
</style>
