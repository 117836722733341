import throttle from "lodash/throttle";

const loadSmAlert = throttle(() => {
  document.querySelectorAll("[data-dismiss='alert']").forEach(toggler => {
    toggler.addEventListener("click", () => {
      toggler.parentElement.remove();
    });
  });
}, 300);

window.loadSmAlert = loadSmAlert;
