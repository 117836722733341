<template>
  <sm-modal
    :modal-is-visible="modalVisibility"
    :modal-title="$t('customers.tickets.modals.change_usage_amount.title')"
    @toggleModalVisibility="emit('toggleModalVisibility')">
    <form @submit.prevent="onSubmit">
      <sm-input
        v-model="form.usages"
        type="number"
        name="usages"
        :label="$t('customers.tickets.modals.change_usage_amount.fields.usages.label')"
      />
    </form>
    <sm-alert color="secondary">
      <small>
        {{ $t('customers.tickets.modals.change_usage_amount.info') }}
      </small>
    </sm-alert>
    <template #footer>
      <sm-button
        :title="$t('generic.cancel')"
        @click="emit('toggleModalVisibility')" />
      <sm-button
        color="primary"
        :title="$t('generic.save')"
        :disabled="!form.isDirty"
        :loading="form.processing"
        @click="onSubmit" />
    </template>
  </sm-modal>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";

const props = defineProps({
  modalVisibility: { type: Boolean, required: true, default: false },
  modalData: { type: Object, required: false, default: null },
});

const emit = defineEmits(["toggleModalVisibility"]);

const form = useForm({
  usages: null
});

provide("form", form);

const onSubmit = () => {
  form
    .post(props.modalData.routes.extendTicketAvailableUses, {
      only: ["table", "customer", "errors", "flash"],
      onSuccess: () => {
        emit("toggleModalVisibility");
        form.reset("usages");
      }
    });
};
</script>
