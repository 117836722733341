<template>
  <settings-layout>
    <in-head :title="$t('invoicing.settings.title')" />

    <form @submit.prevent="onSubmit()">
      <sm-card
        :header-title="$t('invoicing.settings.title')">
        <sm-radio-group
          v-model="form.invoicing_type"
          name="invoicing_type"
          :label="$t('invoicing.settings.invoicing_type.label')"
          :help="$t('invoicing.settings.invoicing_type.help')"
          :items="invoicing_type_options"
          inline
          break-lines
        />
        <sm-input
          v-model="form.external_token"
          name="external_token"
          :label="$t('invoicing.settings.external_token.label')"
          :help="$t('invoicing.settings.external_token.help')"
          inline
        />
        <sm-radio-group
          v-model="form.online_invoicing_type"
          name="online_invoicing_type"
          :label="$t('invoicing.settings.online_invoicing_type.label')"
          :help="$t('invoicing.settings.online_invoicing_type.help')"
          :items="online_invoicing_type_options"
          inline
          break-lines
        />
        <sm-checkbox
          v-model="form.email_customer"
          name="email_customer"
          :label="$t('invoicing.settings.email_customer.label')"
          :help="$t('invoicing.settings.email_customer.help')"
          :text="$t('generic.yes')"
          inline
          sm-switch
        />
        <sm-input
          v-model="form.bank_name"
          name="bank_name"
          :label="$t('invoicing.settings.bank_name.label')"
          inline
        />
        <sm-input
          v-model="form.bank_account_number"
          name="bank_account_number"
          :label="$t('invoicing.settings.bank_account_number.label')"
          :help="$t('invoicing.settings.bank_account_number.help')"
          inline
        />
        <sm-input
          v-model="form.product_tax"
          name="product_tax"
          :label="$t('invoicing.settings.product_tax.label')"
          inline
        >
          <template #append>
            %
          </template>
        </sm-input>
        <sm-radio-group
          v-model="form.company_invoicing"
          name="company_invoicing"
          :label="$t('invoicing.settings.company_invoicing.label')"
          :help="$t('invoicing.settings.company_invoicing.help')"
          :items="company_invoicing_options"
          inline
          break-lines
        />
        <sm-radio-group
          v-model="form.invoice_type"
          name="invoice_type"
          :label="$t('invoicing.settings.invoice_type.label')"
          :items="invoice_type_options"
          inline
          break-lines
        />
        <sm-input
          v-model="form.invoice_prefix"
          name="invoice_prefix"
          :label="$t('invoicing.settings.invoice_prefix.label')"
          :help="$t('invoicing.settings.invoice_prefix.help')"
          inline
        />
        <sm-input
          v-model="form.online_invoice_prefix"
          name="online_invoice_prefix"
          :label="$t('invoicing.settings.online_invoice_prefix.label')"
          :help="$t('invoicing.settings.online_invoice_prefix.help')"
          inline
        />
      </sm-card>

      <sm-card
        :header-title="$t('invoicing.settings.notifications')">
        <sm-input
          v-model="form.invoice_notification_email"
          name="invoice_notification_email"
          :label="$t('notifications.settings.invoice_notification_email.label')"
          :help="$t('notifications.settings.invoice_notification_email.help')"
        />
      </sm-card>

      <sm-card>
        <sm-button
          preset="save"
          :loading="form.processing"
          @click="onSubmit" />
      </sm-card>
    </form>
  </settings-layout>
</template>

<script setup>
import { useFormatArrayToTrueFalseObjects } from "@/inertia/composables/global";
import { useForm, usePage } from "@inertiajs/vue3";

// default variables
const page = usePage();

const props = defineProps({
  settings: { type: Object, required: true, default:  null },
  notificationSettings: { type: Object, required: true, default:  null },
});

const invoicing_type_options = ref([
  {
    id: "off",
    text: window.trans("invoicing.settings.invoicing_type_options.off")
  },{
    id: "automatic",
    text: window.trans("invoicing.settings.invoicing_type_options.automatic")
  },{
    id: "manual",
    text: window.trans("invoicing.settings.invoicing_type_options.manual")
  }
]);

const online_invoicing_type_options = ref([
  {
    id: "automatic",
    text: window.trans("invoicing.settings.online_invoicing_type_options.online_automatic")
  },{
    id: "manual",
    text: window.trans("invoicing.settings.online_invoicing_type_options.online_manual")
  }
]);

const company_invoicing_options = useFormatArrayToTrueFalseObjects(["company_invoicing_on", "company_invoicing_off"], "enabled_disabled");

const invoice_type_options = ref([
  {
    id: "electronic",
    text: window.trans("invoicing.settings.invoice_type.types.electronic")
  },{
    id: "paper",
    text: window.trans("invoicing.settings.invoice_type.types.paper")
  }
]);

const form = useForm({
  invoicing_type: props.settings.invoicing_type,
  external_token: props.settings.external_token,
  online_invoicing_type: props.settings.online_invoicing_type,
  email_customer: props.settings.email_customer,
  bank_name: props.settings.bank_name,
  bank_account_number: props.settings.bank_account_number,
  product_tax: props.settings.product_tax,
  company_invoicing: props.settings.company_invoicing,
  invoice_type: props.settings.invoice_type,
  invoice_prefix: props.settings.invoice_prefix,
  online_invoice_prefix: props.settings.online_invoice_prefix,
  invoice_notification_email: props.notificationSettings.invoice_notification_email,
});

const onSubmit = () => {
  form
    .post(page.url);
};

provide("form", form);
</script>
