export default class Product {
  constructor(product, amount) {
    this.product = product;
    this.amount = amount;
    this.price = 0;
    this.isTakeout = false;
    this.priceAttributes = {
      oldPrice: 0,
      originalPrice: 0,
      discountValue: 0,
      discountPercentage: 0,
    };
    this.taxCategory = null;
    this.depositFee = 0;
    this.markAsLoading(false);
  }

  static fromOld(object) {
    let product = new Product();
    product = Object.assign(product, object);
    return product;
  }

  setWarehouse(warehouse) {
    this.warehouse = warehouse;
  }

  setValidFrom(validFrom) {
    this.validFrom = validFrom;
  }

  increaseAmount(by) {
    this.amount += by;
  }

  markAsLoading(flag = true) {
    this.priceIsLoading = flag;
  }

  setPrice(price) {
    this.price = price;
  }

  setTakeout(takeout) {
    this.isTakeout = takeout;
  }

  setPriceAttributes(price, originalPrice, discountValue, discountPercentage) {
    this.priceAttributes.oldPrice = price;
    this.priceAttributes.originalPrice = originalPrice;
    this.priceAttributes.discountValue = discountValue;
    this.priceAttributes.discountPercentage = discountPercentage;
  }

  setTaxCategory(taxCategory) {
    this.taxCategory = taxCategory;
  }

  setDepositFee(depositFee) {
    this.depositFee = depositFee;
  }

  isTicket() {
    if (!this.product) {
      return false;
    }

    return this.product.product_type === "ticket";
  }

  isStockProduct() {
    if (!this.product) {
      return false;
    }

    return [
      "stock-item",
      "stock-item-unit",
      "recipe",
    ].includes(this.product.product_type);
  }

  hasProduct() {
    return !!this.product;
  }

  hasWarehouse() {
    return !!this.warehouse;
  }

  hasValidFrom() {
    return !!this.validFrom;
  }

  hasCashRegister() {
    return !!this.cashRegister;
  }

  total() {
    return this.amount * (this.price ?? 0);
  }

  canBeTakenOut() {
    return this.isStockProduct();
  }

  hasDepositFee() {
    return this.depositFee && this.depositFee > 0;
  }

  depositFeeValue() {
    if (! this.hasDepositFee()) {
      return 0;
    }

    return this.amount * this.depositFee;
  }

  totalWithDepositFee() {
    return this.total() + this.depositFeeValue();
  }
}
