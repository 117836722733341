<template>
  <div>
    <div>
      <header class=" mb-11">
        <h2 class="font-open-sans-bold text-brand-blue-450 text-2xl mb-7">
          Rögzítsd első időpontodat a naptárban!
        </h2>
        <p class=" leading-7">
          Vidd fel első időpontodat a naptár tetszőleges időintervallumára kattintva!
        </p>
      </header>
      <div class="text-center">
        <img
          src="../../../../../img/backoffice/onboarding/Step3Finish.png"
          class="inline-block mb-4">
      </div>
      <p class="flex items-center justify-center text-black/50 italic mb-8">
        <calendar-icon class="w-5 mr-4 inline-block" />
        *órarendeted a későbbiekben az “Órarend” menüben módosíthatod
      </p>
      <div class="text-center">
        <a
          :href="finishApi"
          class="sm-btn-app sm-btn-app-primary w-full lg:w-auto">
          <span>Mutasd a naptáram</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {CalendarIcon} from "vue-feather-icons";

export default {
  components: { CalendarIcon },
  inject: ["finishApi"],
};
</script>
