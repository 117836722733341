<template>
  <modal
    ref="modal"
    :title="dialogConfig.title"
    static-backdrop
  >
    <p
      v-if="dialogConfig.message"
      class="mb-0"
      v-html="dialogConfig.message"
    />
    <template #footer>
      <div class="flex justify-between flex-1">
        <div>
          <button
            type="button"
            class="sm-btn sm-btn-xs sm-btn-white"
            @click="cancel"
          >
            {{ $t('generic.cancel') }}
          </button>
        </div>
        <div class="flex space-x-2">
          <button
            v-for="button in dialogConfig.buttons"
            :key="button.title"
            type="button"
            class="sm-btn sm-btn-xs"
            :class="`sm-btn-${button.color ?? 'white'}`"
            @click="respond(button.value)">
            {{ button.title }}
          </button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
export default {
  props: {
    dialogConfig: { type: Object, default: () => {} },
  },
  data() {
    return {
      resolvePromise: null,
    };
  },
  mounted() {
    this.toggle();
  },
  methods: {
    toggle() {
      this.$refs.modal.toggle();
    },
    hide() {
      this.$refs.modal.hide();
    },
    cancel() {
      this.hide();
      this.dialogConfig.callback({ cancelled: true, value: null});
    },
    respond(value) {
      this.hide();
      this.dialogConfig.callback({ cancelled: false, value });
    },
  },
};
</script>
