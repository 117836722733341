ta
<template>
  <div>
    <div>
      <div
        v-if="stepBFinished"
        class="flex items-center justify-center h-12 mb-8 text-center text-white uppercase bg-brand-green-500 rounded-xl font-jost-semibold">
        Vendég rögzítve <check-circle-icon class="inline-block w-5 ml-4" />
      </div>
      <div class="flex justify-center relative max-w-[90vw] lg:max-w-[28.25rem] mx-auto">
        <step-1-finish-svg />
        <div class="absolute text-center -bottom-6">
          <p class="mb-16 text-2xl text-brand-blue-400">
            További vendégeket a <strong>“Vendéglista”</strong>
            menüben tudsz majd hozzáadni.
          </p>
          <button
            type="button"
            class="w-full sm-btn-app sm-btn-app-primary lg:w-auto"
            @click="$emit('increaseStep')">
            <span>Következő lépés</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckCircleIcon } from "vue-feather-icons";
import Step1FinishSvg from "./Step1FinishSvg.vue";

export default {
  components: {
    CheckCircleIcon,
    Step1FinishSvg
  },
  props: {
    stepBFinished: { type: Boolean, required: true, default: false }
  },
  emits: ["increaseStep"],
  mounted() {
    const selectedNodeElem = [...document.querySelectorAll(".nav-aside .nav-item")].filter(a => a.textContent.includes("Vendéglista"))[0];
    if (selectedNodeElem) {
      selectedNodeElem.classList.add("selected");
    }
  },
  unmounted() {
    const selectedNodeElem = [...document.querySelectorAll(".nav-aside .nav-item")].filter(a => a.textContent.includes("Vendéglista"))[0];
    if (selectedNodeElem) {
      selectedNodeElem.classList.remove("selected");
    }
  },
};
</script>
