<script>
import AbstractCrudFilterType from "../utility/AbstractCrudFilterType";

export default {
  mixins: [AbstractCrudFilterType],
  data() {
    return {
      defaults: {},
    };
  },
  computed: {
    filterData() {
      return {
        term: this.data.data.term,
      };
    },
  },
  render() {
    return this.renderAbstract([
      this.createDiv("sm-col w-8/12", [
        this.createInput([this.data.data, "term"]),
      ]),
    ]);
  },
};
</script>
