<template>
  <div>
    <hr>
    <div class="customer-accessories-wrapper">
      <div>
        <p class="font-semibold">
          {{ $t('customers.generic.accessories.title') }}:
        </p>
        <div
          v-for="(accessory, index) in $page.props?.accessories?.accessories"
          :key="accessory.id"
          class="flex items-center justify-between px-2 py-1 mb-2 -mx-2 text-black bg-white rounded customer-accessory-item"
          :class="{'animate-pulse-from-green': index + 1 === $page.props?.accessories?.accessories?.length && accessoriesRefreshed}">
          <div class="name">
            {{ accessory.accessory.name }}
            <template v-if="accessory.rental">
              - {{ $t('sales.lockers.rental') }}
              <template v-if="accessory.rentalValidUntilNextCheckout">
                {{ $t('sales.lockers.rental_valid_until_next_checkout') }}
              </template>
              <template v-else-if="accessory.rentalValidUntil">
                {{ $t('generic.date_until', { date: accessory.rentalValidUntil }) }}
              </template>
            </template>
          </div>
          <sm-button
            v-if="pageAccessories?.permissions?.hasPermissionToAssign"
            color="danger"
            size="icon"
            icon="trash-2"
            @click="confirmDelete(accessory)" />
        </div>
        <SmConfirmDialog group="confirmDelete" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { router } from "@inertiajs/vue3";
import axios from "axios";
import { useConfirm } from "primevue/useconfirm";
import { watch } from "vue";

const props = defineProps({
  accessoriesRefreshed: { type: Boolean, required: false, default: false },
  pageAccessories: { type: Object, required: false, default: null }
});

const emit = defineEmits(["updateAccessoriesRefreshed"]);

watch(() => props.accessoriesRefreshed, (e) => {
  if (e === true) {
    setTimeout(() => {
      emit("updateAccessoriesRefreshed");
    }, 1500);
  }
});

const confirm = useConfirm();

const confirmDelete = async (accessory) => {
  confirm.require({
    group: "confirmDelete",
    header: window.trans("customers.generic.accessories.delete_modal_title"),
    message: `${window.trans("generic.are_you_sure")}?`,
    accept: async () => {
      await axios.delete(accessory.routes.deleteAccessoryRoute, {
        accessory: accessory.id
      });
      router.reload({ only: ["accessories"] }, { preserveState: true });
    }
  });
};
</script>
