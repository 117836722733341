<script>
export default {
  data() {
    return {
      plan: null,
    };
  },
  computed: {
    planSelected() {
      return this.plan !== null;
    }
  },
  methods: {
    selectPlan(plan) {
      this.plan = plan;
    }
  }
};
</script>
