<template>
  <div>
    <searchable-select
      :search-endpoint="endpoint"
      id-key="zip"
      :options="options"
      :value="value"
      skip-initial-value
      @change="selectCity"
    />
    <template v-if="selectedItem">
      <input
        type="hidden"
        :name="cityField"
        :value="selectedItem.city"
      >
      <input
        type="hidden"
        :name="zipField"
        :value="selectedItem.zip"
      >
    </template>
  </div>
</template>

<script>
export default {
  props: {
    endpoint: { type: String, default: "" },
    cityField: {
      type: String,
      default: "city"
    },
    zipField: {
      type: String,
      default: "zip"
    },
    options: {type: [Array, Object], default: () => []},
    value: {type: [String, Number, Array], default: null},
  },
  emits: ["change"],
  data() {
    return {
      selectedItem: null,
    };
  },
  methods: {
    selectCity(item) {
      if (!item || !item.item) {
        return;
      }

      this.selectedItem = item.item;
      this.$emit("change", item.item);
    }
  }
};
</script>
