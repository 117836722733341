<script>
import AbstractCrudFilterType from "../utility/AbstractCrudFilterType";

export default {
  mixins: [AbstractCrudFilterType],
  data() {
    return {
      defaults: {
        operator: "=",
      },

      availableOperators: ["=", "<>", "<", "<=", ">", ">="],
    };
  },
  computed: {
    filterData() {
      return {
        term: this.data.data.term,
        operator: this.data.data.operator,
      };
    },
  },
  render() {
    return this.renderAbstract([
      this.createDiv("sm-col w-2/12", [
        this.createSelect([this.data.data, "operator"], this.availableOperators.map(t => ({
          label: "crud_filter." + t,
          value: t
        }))),
      ]),
      this.createDiv("sm-col w-6/12", [
        this.createInput([this.data.data, "term", "number"], "number"),
      ]),
    ]);
  },
};
</script>
