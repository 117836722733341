<template>
  <div>
    <In-Head :title="$t('customers.balance_changes.show.title', {name: $page.props.customer.name})" />

    <sub-header :title="$t('customers.balance_changes.show.title', {name: $page.props.customer.name})">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          :href="$page.props.routes.back"
          preset="back"
          icon="arrow-left" />
      </template>
    </sub-header>

    <customers-nav-tabs />

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`customers.balance_changes.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template v-if="col === 'amount'">
                <span
                  class="mr-2 font-medium"
                  :class="slotProps.data?.amount > 0 ? 'text-bo-green' : 'text-bo-red'">
                  {{ usePriceFormat(slotProps.data?.amount) }}
                </span>
                <span class="text-small">{{ usePriceFormat(slotProps.data?.from) }} → {{ usePriceFormat(slotProps.data?.to) }}</span>
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div
                class="flex justify-end">
                <a
                  v-if="slotProps.data?.permissions.show"
                  class="sm-btn sm-btn-icon"
                  :href="slotProps.data?.routes.show">
                  <vue-feather
                    class="inline"
                    type="info" />
                </a>
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { usePriceFormat } from "@/inertia/composables/global";
import CustomersNavTabs from "@/inertia/pages/customers/show/_partials/CustomersNavTabs.vue";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableFields = ref([
  "amount", "created_at", "note"
]);
</script>
