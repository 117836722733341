<template>
  <div>
    <sm-button
      v-if="!$page.props.customer.pendingMerge && !$page.props.customer.pendingDelete"
      icon="users"
      :title="$t('customers.generic.info.data.profile_merge')"
      @click="toggleModalVisibility" />
    <sm-modal
      close-button
      :modal-is-visible="modal"
      :modal-title="$t('customers.generic.info.profile_merge_modal.title')"
      @toggleModalVisibility="toggleModalVisibility">
      <profile-merge-user-card
        :user="form.users[0]"
        :selected-user="selectedUser"
        :error="form?.errors['users.0.id']"
        @updateSelectedUser="updateSelectedUser"
      />
      <div v-auto-animate>
        <div
          v-for="(user, index) of form.users"
          :key="user.id"
        >
          <template
            v-if="index !== 0">
            <profile-merge-user-card
              :user="user"
              :selected-user="selectedUser"
              can-be-deleted
              :error="form?.errors[`users.${index}.id`]"
              @onUserRemoved="onUserRemoved"
              @updateSelectedUser="updateSelectedUser"
            />
          </template>
        </div>
      </div>

      <div v-auto-animate>
        <template v-if="showAddUserSelect">
          <div class="flex items-center justify-center mb-4">
            <span class="block w-16 h-px bg-bo-gray-200" />
            <span class="inline-block px-2.5 font-bold">
              <vue-feather
                type="plus"
                class="w-4 h-4" />
            </span>
            <span class="block w-16 h-px bg-bo-gray-200" />
          </div>
          <sm-async-select
            v-model="newUser"
            name="customers"
            :label="$t('customers.generic.info.profile_merge_modal.fields.user.label')"
            placeholder="Név vagy e-mail cím"
            clearable
            route="/api-int/customers"
            :params="searchUsersParams"
            :disabled-ids="disabledIds"
            @update:modelValue="onUserPicked"
            @input="input">
            <template #prepend>
              <vue-feather
                type="search"
                class="w-4" />
            </template>
            <template #option="{data}">
              <user-picker-row :option="data" />
            </template>
          </sm-async-select>
        </template>
        <template v-else>
          <div class="flex items-center justify-center mb-6">
            <span class="block w-16 h-px bg-bo-gray-200" />
            <span class="inline-block px-2.5 font-bold">kiegészítő információk</span>
            <span class="block w-16 h-px bg-bo-gray-200" />
          </div>
          <sm-input
            v-model="form.note"
            type="textarea"
            name="note"
            rows="4"
            :label="$t('customers.generic.info.change_email_modal.form.note.label')"
            :placeholder="$t('customers.generic.info.change_email_modal.form.note.placeholder')"
          />
          <sm-button
            v-if="form.users.length < 3"
            color="primary-outline"
            size="lg"
            class="justify-center w-full"
            icon="plus"
            :title="$t('További fiók hozzáadása')"
            @click="showAddUserSelect = !showAddUserSelect" />
        </template>
      </div>
      <div v-auto-animate>
        <sm-alert
          v-if="showSuccess"
          color="success"
          after-icon="check"
          class="mt-6">
          <p>{{ $t('customers.merge_requests.success.merge') }}</p>
        </sm-alert>
      </div>
      <footer
        class="flex flex-wrap items-center justify-end p-4 mt-4 -mx-4 -mb-4 space-x-2 border-t border-brand-gray-200 rounded-b-md">
        <sm-button
          color="secondary"
          :title="$t('generic.cancel')"
          @click="toggleModalVisibility" />
        <sm-button
          color="primary"
          :disabled="form.users.length === 1 || showSuccess"
          :title="$t('customers.generic.info.profile_merge_modal.submit')"
          @click="submit" />
      </footer>
    </sm-modal>
  </div>
</template>

<script setup>
import { nextTick } from "vue";
import { useForm, usePage } from "@inertiajs/vue3";
import ProfileMergeUserCard from "./ProfileMergeUserCard.vue";

const page = usePage();

const form = useForm({
  users: [
    {
      id: page.props.customer.userId,
      fullName: page.props.customer.fullName,
      email: page.props.customer.email,
      profilePicture: page.props.customer.profilePicture,
      isDefault: true,
    }
  ],
  note: ""
});

provide("form", form);

const modal = ref(false);
const showAddUserSelect = ref(true);
const showSuccess = ref(false);
const newUser = ref(null);
const selectedUser = ref(form.users[0]);
const disabledIds = ref([form.users[0].id]);

const updateSelectedUser = (user) => {
  selectedUser.value = user;
};

const toggleModalVisibility = () => {
  modal.value = !modal.value;
  form.users = form.users.slice(0, 1);
};

const onUserPicked = (e) => {
  form.users.push({
    id: e.id,
    fullName: e.label,
    email: e.data.subtitle,
    profilePicture: e.data.data.avatar,
    isDefault: false,
  });
  disabledIds.value.push(e.id);
  nextTick(() => {
    newUser.value = null;
    showAddUserSelect.value = false;
  });
};

const searchUsersParams = ref();

const input = (e) => {
  searchUsersParams.value = {
    search: e.target.value
  };
};

const onUserRemoved = (e) => {
  form.users = form.users.filter(data => data.id !== e.id);
  nextTick(() => {
    if (form.users.length === 1) {
      showAddUserSelect.value = true;
    }
  });
};

const submit = () => {
  form.post(page.props.routes.merge, {
    preserveScroll: true,
    onSuccess: () => {
      showSuccess.value = !showSuccess.value;
    },
  });
};

const openAndPreload = user => {
  toggleModalVisibility();

  form.users.push({
    id: user.id,
    fullName: user.title,
    email: user.subtitle,
    profilePicture: user.data.avatar,
    isDefault: false,
  });
};

defineExpose({ openAndPreload });

</script>
