<template>
  <div class="inline-block mb-4 crud-filter">
    <div
      class="inline-block sm-dropdown dropdown-icon"
      :class="{'show': visible}">
      <div class="flex items-center">
        <button
          class="sm-btn sm-btn-xs sm-btn-white"
          type="button"
          @click="toggleControl">
          <vue-feather
            type="filter"
            class="w-4 mr-1" />
          <span>{{ $t('filters.filter') }}</span>
          <span
            v-if="appliedFilters?.length > 0"
            class="mx-1 badge badge-light badge-number">
            {{ appliedFilters?.length }}
          </span>
        </button>
        <div
          v-if="exportRoute">
          <sm-button
            icon="download"
            size="icon"
            class="ml-2"
            @click="exportData" />
        </div>
        <slot />
      </div>
      <div
        class=" text-xs shadow-lg sm-dropdown-menu sm-dropdown-menu-left z-[100]"
        :class="{'show': visible}">
        <div
          style="width: 600px;"
          class="p-4">
          <div class="mb-4 sm-row">
            <div class="w-full sm-col lg:w-1/2">
              <select
                v-model="newFilter"
                class="sm-select"
                @change="addFilter">
                <option
                  value=""
                  disabled>
                  {{ $t('filters.filters') }}
                </option>
                <option
                  v-for="filter in availableFilters"
                  :key="filter.type"
                  :value="filter">
                  {{ $t(filter.label || filter.field) }}
                </option>
              </select>
            </div>

            <div class="w-full mt-4 sm-col crud-filter-list">
              <component
                :is="filterComponents[filter.component]"
                v-for="filter in addedFilters"
                :key="filter.id"
                :data="filter"
                class="mb-2"
                @remove="removeFilter($event, filter)" />
            </div>
          </div>

          <div class="flex space-x-2 inline-center">
            <sm-button @click="submit">
              <vue-feather
                type="filter"
                class="w-4" />
              <span>{{ $t('filters.filter') }}</span>
            </sm-button>

            <sm-button
              class="sm-btn-white"
              @click="reset">
              <span>{{ $t('filters.delete_filters') }}</span>
            </sm-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useForm, usePage } from "@inertiajs/vue3";
import CrudBoolFilterType from "./filter_types/CrudBoolFilterType.vue";
import CrudDateFilterType from "./filter_types/CrudDateFilterType.vue";
import CrudNumberFilterType from "./filter_types/CrudNumberFilterType.vue";
import CrudScoutSearchFilterType from "./filter_types/CrudScoutSearchFilterType.vue";
import CrudSearchableFilterType from "./filter_types/CrudSearchableFilterType.vue";
import CrudSelectFilterType from "./filter_types/CrudSelectFilterType.vue";
import CrudTextFilterType from "./filter_types/CrudTextFilterType.vue";

export default {
  props: {
    availableFilters: {
      type: Array,
      default: () => [],
    },
    appliedFilters: {
      type: Array,
      default: () => [],
    },
    exportRoute: {
      type: String,
      default: ""
    },
  },
  data() {
    const data = {
      id: 1,
      filterComponents: {
        CrudTextFilterType,
        CrudDateFilterType,
        CrudNumberFilterType,
        CrudSelectFilterType,
        CrudBoolFilterType,
        CrudScoutSearchFilterType,
        CrudSearchableFilterType
      },
      addedFilters: [],
      appliedFiltersLength : 0,

      newFilter: "",
      visible: false,
    };

    if (this.appliedFilters) {
      data.addedFilters = this.appliedFilters.map(fs => {
        const filter = JSON.parse(fs);
        if (!filter) {
          return null;
        }

        const filterType = this.availableFilters.find(af => af.field === filter.field);
        if (!filterType) {
          return null;
        }

        data.id = filter.id + 1;

        return {
          id: filter.id,
          component: this.componentFromFilterType(filterType),
          filter: filterType,
          data: filter,
        };
      }).filter(f => !!f);
    }

    return data;
  },
  computed: {
    appliedFiltersLength() {
      return this.appliedFilters.length;
    }
  },
  mounted() {
    document.addEventListener("click", e => {
      if (!(e.target === this.$el || this.$el.contains(e.target))) {
        this.visible = false;
      }
    });
  },
  methods: {
    addFilter() {
      if (!this.newFilter) {
        return;
      }

      this.addedFilters.push({
        id: this.id++,
        component: this.componentFromFilterType(this.newFilter),
        filter: this.newFilter,
        data: {},
      });

      this.newFilter = "";
    },
    componentFromFilterType(filterType) {
      return "Crud" + filterType.component + "FilterType";
    },
    removeFilter(event, filter) {
      event.stopPropagation();
      this.addedFilters = this.addedFilters.filter(f => f !== filter);
    },
    reset() {
      this.addedFilters = [];
      this.$nextTick(() => {
        this.submit();
      });
    },
    submitByEnter() {
      this.$refs.submitButton?.dispatchEvent(new MouseEvent("click"));
    },
    collectAndSubmitFilters(event) {
      const filters = Array.from(this.$el.querySelectorAll("input[type=hidden]"))
        .map(node => node.value);

      event.target.dispatchEvent(new CustomEvent("input", {detail: filters}));
      this.visible = false;
    },
    clearFilters(event) {
      event.target.dispatchEvent(new CustomEvent("input", {detail: []}));
      this.visible = false;
    },
    toggleControl() {
      this.visible = !this.visible;
    },
    submit() {
      const form = useForm({});
      const page = usePage();

      const filters = Array.from(this.$el.querySelectorAll("input[type=hidden]"))
        .map(node => node.value);
      form
        .transform(() => {
          return {
            filters
          };
        })
        .get(page.url.split("?")[0], {
          only: ["table", "filter"],
          preserveState: true,
          onSuccess: () => {
            this.toggleControl();
          }
        });
    },
    exportData() {
      const form = useForm({});

      const filters = Array.from(this.$el.querySelectorAll("input[type=hidden]"))
        .map(node => node.value);

      console.log(filters);

      form
        .transform(() => {
          return {
            filters
          };
        })
        .post(this.exportRoute, {
          only: ["filter", "flash"]
        });
    }
  }
};
</script>
