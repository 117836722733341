<template>
  <div>
    <In-Head :title="$t('backoffice.pages.customers.create')" />

    <sub-header :title="$t('backoffice.pages.customers.create')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card
      :form="form"
      back="/manage/customers"
      @onSubmit="onSubmit">
      <sm-alert color="secondary">
        {{ $t('customers.fields.info') }}
      </sm-alert>
      <div class="grid grid-cols-2 gap-x-4">
        <div>
          <sm-input
            v-model="form.email"
            name="email"
            :label="$t('customers.fields.email.label')"
            :placeholder="$t('customers.fields.email.placeholder')"
            required
          />
        </div>
        <div />
        <div>
          <sm-input
            v-model="form.lastname"
            name="lastname"
            :label="$t('customers.fields.lastname.label')"
            :placeholder="$t('customers.fields.lastname.label')"
            required
          />
        </div>
        <div>
          <sm-input
            v-model="form.firstname"
            name="firstname"
            :label="$t('customers.fields.firstname.label')"
            :placeholder="$t('customers.fields.firstname.label')"
            required
          />
        </div>
        <div>
          <sm-input
            v-model="form.phone"
            name="phone"
            :label="$t('customers.fields.phone.label')"
            :placeholder="$t('customers.fields.phone.label')"
            required
          />
        </div>
        <div>
          <sm-input
            v-model="form.zip_code"
            name="zip_code"
            :label="$t('customers.fields.zip_code.label')"
            type="number"
            :placeholder="$t('customers.fields.zip_code.label')"
            required
          />
        </div>
        <div>
          <sm-date-picker-date
            v-model="form.birth_date"
            name="birth_date"
            :label="$t('customers.fields.birth_date.label')"
            :placeholder="$t('customers.fields.birth_date.placeholder', {date: useDateFormat(new Date(), 'form')})"
            required
          />
        </div>
        <div>
          <sm-select
            v-model="form.gender"
            name="gender"
            preset="gender"
            :label="$t('customers.fields.gender.label')"
            required
          />
        </div>
        <div class="col-span-2">
          <sm-input
            v-model="form.note"
            name="note"
            :label="$t('customers.fields.note.label')"
            type="textarea"
          />
        </div>
        <div class="grid grid-cols-2 gap-4">
          <div>
            <sm-checkbox
              v-model="form.newsletter_enabled"
              name="newsletter_enabled"
              :label="$t('customers.fields.newsletter_enabled.label')"
              :text="$t('generic.yes')"
            />
          </div>
          <div v-if="hasRegistrationForm">
            <sm-checkbox
              v-model="form.registration_form_filled"
              :label="$t('customers.fields.registration_form_filled.label')"
              :text="$t('customers.fields.registration_form_filled.text')"
            />
          </div>
        </div>
        <div>
          <sm-select
            v-model="form.language"
            :options="languages"
            name="language"
            :label="$t('customers.fields.language.label')"
          />
        </div>
      </div>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat, useFormatSelect } from "@/inertia/composables/global";
import { useForm } from "@inertiajs/vue3";

const props = defineProps({
  hasRegistrationForm: { type: Boolean, required: false, default: false },
  languages: { type: Array, required: false, default: () => [] },
  defaultEmail: { type: String, required: false, default: null, },
});

const languages = useFormatSelect(props.languages);

const form = useForm({
  email: props.defaultEmail,
  firstname: "",
  lastname: "",
  phone: "",
  zip_code: "",
  birth_date: null,
  gender: null,
  note: "",
  newsletter_enabled: false,
  language: languages.find(data => data.id === "hu"),
  registration_form_filled: false,
});

const onSubmit = () => {
  form
    .transform((data) => ({
      ...data,
      gender: data.gender?.id,
      language: data.language?.id,
      birth_date: data.birth_date ? useDateFormat(data.birth_date, "form") : null,
    }))
    .post("/manage/customers");
};

provide("form", form);
</script>
