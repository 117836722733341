import axios from "axios";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import ImageTool from "@editorjs/image";
import Quote from "@editorjs/quote";
import Delimiter from "@editorjs/delimiter";
import RawTool from "@editorjs/raw";
import Table from "@editorjs/table";
import CodeTool from "@editorjs/code";
import InlineCode from "@editorjs/inline-code";
import AnyButton from "editorjs-button";

export default ({ uploadUrl, renderEditorUrl, ...params }) => ({
  html: params.html ?? "",
  json: params.json ?? {},

  init() {
    const editor = new EditorJS({
      holder: this.$refs.editor,
      data: this.json,
      autofocus: true,
      placeholder: "Write something awesome!",
      logLevel: "ERROR",
      tools: {
        header: {
          class: Header,
          config: {
            levels: [1, 2, 3],
          }
        },
        list: {
          class: List,
          inlineToolbar: true,
        },
        image: {
          class: ImageTool,
          config: {
            uploader: {
              uploadByFile(file) {
                return axios.post(uploadUrl, { file });
              },

              uploadByUrl(url) {
                return axios.post(uploadUrl, { url });
              }
            }
          }
        },
        quote: Quote,
        delimiter: Delimiter,
        raw: RawTool,
        table: {
          class: Table,
        },
        code: CodeTool,
        button: AnyButton,
        inlineCode: {
          class: InlineCode,
          shortcut: "CMD+SHIFT+M",
        },
      },

      onChange: () => {
        const self = this;
        this.$refs.editor.dirty = true;
        editor.save().then((outputData) => {
          self.json = outputData;

          axios.post(renderEditorUrl, outputData)
            .then(res => {
              this.html = res.data.html;
            });
        });
      }
    });
  },
});
