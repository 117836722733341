import Evaporate from "evaporate";
import sparkMD5 from "spark-md5";
import sha256 from "js-sha256";

let instance = null;

export default {
  async init() {
    if (!window.app || !window.app.assets) {
      return;
    }

    instance = await Evaporate.create({
      logging: false,
      signerUrl: window.app.assets.signingService,
      aws_key: window.app.assets.aws.key,
      awsRegion: window.app.assets.aws.region,
      bucket: window.app.assets.aws.bucket,
      cloudfront: true,
      computeContentMd5: true,
      cryptoMd5Method: data => btoa(sparkMD5.ArrayBuffer.hash(data, true)),
      cryptoHexEncodedHash256: sha256,
      xhrWithCredentials: true,
    });
  },
  instance() {
    return instance;
  }
};
