<script>
import Evaporate from "../utils/Evaporate";
import UploadableFile from "./Evaporate/UploadableFile.vue";
import { useToast } from "vue-toastification";

export default {
  components: {UploadableFile},
  props: {
    value: { type: [Array, Object], default: null},
    limit: {
      type: [Number, String],
      default: 1,
    },
    storageSpaceLeft: {
      type: [Number, String],
      default: null,
    }
  },
  data() {
    return {
      files: this.value ? this.generateListFromExistingValues() : [],
    };
  },
  computed: {
    uploadedFiles() {
      return this.files.filter(file => file.status === "completed");
    }
  },
  watch: {
    files() {
      this.files.forEach(file => this.uploadFile(file));
    }
  },
  methods: {
    filesSelected(event) {
      const newFiles = Array.from(event.target.files)
        .map(file => ({
          file,
          progress: 0,
          uploaded: 0,
          status: "preparing",
          remainingTime: "",
          speed: 0,
          fileKey: "",
          error: null,
          freshlyUploaded: true,
        }));

      if (this.hasEnoughStorageFor(newFiles)) {
        this.files = this.files.concat(newFiles);
      } else {
        const toast = useToast();
        toast.error(`${this.$t("Nincs elég tárhely a feltöltéshez")}`);
        return;
      }

      event.target.value = "";
    },
    hasEnoughStorageFor(newFiles) {
      if (!this.storageSpaceLeft) {
        return true;
      }

      const storageSpaceNeededInMegabytes = newFiles.reduce(
        (accumulator, currentFile) => accumulator + currentFile.file.size, 0
      ) / 1024 / 1024;

      return this.storageSpaceLeft > storageSpaceNeededInMegabytes;
    },
    async uploadFile(file) {
      try {
        await Evaporate.instance().add({
          file: file.file,
          name: `temp_uploads/${window.app.club.id}/${+(new Date)}_${file.file.name}`,
          progress: (percent, stats) => {
            file.progress = percent * 100;
            file.uploaded = stats.totalUploaded;
            file.speed = stats.speed;
            file.remainingTime = stats.secondsLeft;
          },
          complete: () => file.status = "completed",
          error: msg => {
            file.status = "errored";
            file.error = msg;
          },
          paused: () => file.status = "paused",
          resumed: () => file.status = "uploading",
          cancelled: () => {
            file.status = "cancelled";

            this.files = this.files.filter(_file => file !== _file);
          },
          started: fileKey => {
            file.status = "uploading";
            file.fileKey = fileKey;
          },
        });
      } catch (reason) {
        const toast = useToast();
        toast.error(`${this.$t("Hiba a fájl feltöltése közben:")} ${reason}`);
      }
    },
    generateListFromExistingValues() {
      const values = Array.isArray(this.value) ? this.value : [this.value];
      return values.map(value => ({
        file: new File([""], value.file_name),
        status: "completed",
        freshlyUploaded: false,
        // smc-sportmate-local/temp_uploads/21/1605863376828_Coach_checkin.mp4
        mediaId: value.id,
      }));
    },
    deleteFile(file) {
      this.files = this.files.filter(_file => file !== _file);
    },
    serializeFile(file) {
      return JSON.stringify({
        existing: !file.freshlyUploaded,
        fileKey: file.fileKey,
        mediaId: file.mediaId,
      });
    }
  }
};
</script>
