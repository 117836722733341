<template>
  <div>
    <In-Head :title="$t('customers.special_cards.edit.title')" />

    <sub-header :title="$t('customers.special_cards.edit.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          :href="$page.props.routes.back"
          preset="back"
          icon="arrow-left" />
      </template>
    </sub-header>

    <special-cards-form
      method="patch"
      :form-route="$page.props.routes.updateRoute"
      :form="form"
    />
  </div>
</template>

<script setup>
import { useFormatSelect } from "@/inertia/composables/global";
import SpecialCardsForm from "@/inertia/pages/customers/show/special-cards/form/SpecialCardsForm.vue";
import { useForm, usePage } from "@inertiajs/vue3";

const page = usePage();

const specialCardsChoices = useFormatSelect(page.props.specialCardChoices);

const form = useForm({
  special_card_id: specialCardsChoices[0],
  card_identifier: null,
  qr_code: null,
  expiration_date: null,
});
</script>
