<template>
  <div>
    <In-Head :title="$t('customers.invoice_settings.show.title', {name: $page.props.customer.name})" />

    <sub-header :title="$t('customers.invoice_settings.show.title', {name: $page.props.customer.name})">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          :href="$page.props.routes.customer"
          preset="back"
          icon="arrow-left" />
        <sm-button
          v-if="$page.props.permissions.create"
          :href="$page.props.routes.create"
          preset="add" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        :data="$page.props.table"
        loaded>
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`customers.invoice_settings.table.${col}`)">
            <template #body="slotProps">
              <template v-if="col === 'name'">
                {{ slotProps.data?.[col] }}
                <span v-if="slotProps.data?.is_default">({{ $t('customers.invoice_settings.is_default') }})</span>
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div
                v-if="!slotProps.data?.trashed"
                class="flex justify-end space-x-1">
                <sm-button
                  v-if="!slotProps.data?.is_default"
                  size="icon"
                  icon="tag"
                  :loading="form.processing"
                  @click="onSetAsDefault(slotProps.data?.routes.set_as_default)" />
                <sm-button
                  v-if="slotProps.data?.permissions.edit"
                  preset="edit-icon"
                  :href="slotProps.data?.routes.edit" />
                <sm-delete-button
                  v-if="slotProps.data?.permissions.destroy"
                  :route="slotProps.data?.routes.destroy"
                  name="deleteSpecialCard"
                />
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
    <sm-confirm-dialog :group="`setAsDefault_${uid}`" />
  </div>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";
import uniqueId from "lodash.uniqueid";
import { useConfirm } from "primevue/useconfirm";

const confirm = useConfirm();

const form = useForm({});
const uid = uniqueId();

const onSetAsDefault = (route) => {
  confirm.require({
    group: `setAsDefault_${uid}`,
    header: window.trans("customers.invoice_settings.set_as_default_confirm.header"),
    message: window.trans("customers.invoice_settings.set_as_default_confirm.message"),
    accept: () => {
      form.post(route, {
        only: ["table", "flash"]
      });
    }
  });
};

const tableFields = ref([
  "name",
  "postal_code",
  "city",
  "street",
]);
</script>
