<template>
  <div>
    <div>
      <header class=" mb-11">
        <h2 class="text-2xl font-open-sans-bold text-brand-blue-450 mb-7">
          Rögzítzsd első bérleted!
        </h2>
        <p class="leading-7 ">
          Vidd fel első bérletedet / jegyedet a rendszerbe, hogy később a naptár használatánál hozzá tudd rendelni az egyes
          órákhoz áraidat!
        </p>
      </header>
      <form class="mb-10 ">
        <div class="grid grid-cols-2 gap-5 mb-4">
          <div>
            <label class="sm-label-app">Bérlet neve</label>
            <input
              v-model="form.name"
              placeholder="pl.: havi bérlet"
              type="text"
              required
              class="sm-input-text-app">
          </div>
          <div>
            <label class="sm-label-app">Ár</label>
            <input
              v-model="form.price"
              placeholder="HUF"
              type="number"
              required
              class="sm-input-text-app">
          </div>
          <div>
            <label class="sm-label-app">Felhasználhatóság</label>
            <v-select
              v-model="form.usability"
              :options="usabilities"
              :clearable="false"
              class="sm-input-text-app"
            >
              <template #search="{attributes, events}">
                <input
                  v-bind="attributes"
                  class="vs__search"
                  :required="!form.gender"
                  v-on="events">
              </template>
            </v-select>
          </div>
          <div>
            <template v-if="form.usability.id === false">
              <label class="sm-label-app">Alkalmak száma</label>
              <input
                v-model="available_uses"
                placeholder="pl.: 5"
                type="number"
                required
                class="sm-input-text-app">
            </template>
          </div>
          <div class="col-span-2 -mb-5">
            <label class="sm-label-app">Érvényességi idő</label>
          </div>
          <input
            v-model="form.validity"
            placeholder="pl.: 5"
            type="number"
            required
            class="sm-input-text-app">
          <v-select
            v-model="form.validity_range"
            :options="validities"
            :clearable="false"
            placeholder="pl.: nap"
            class="sm-input-text-app">
            <template #search="{attributes, events}">
              <input
                v-bind="attributes"
                class="vs__search"
                :required="!form.gender"
                v-on="events">
            </template>
          </v-select>
        </div>
      </form>
      <p class="flex items-center mb-8 italic text-black/50">
        <credit-card-icon class="inline-block w-5 mr-4" />
        *bérleteidet később a “Bérletek” menüpontban módosíthatod
      </p>
      <div class="flex flex-wrap items-center justify-between mb-4 lg:flex-nowrap lg:space-x-5">
        <button
          type="button"
          class="order-2 w-full sm-btn-app sm-btn-app-secondary lg:order-1 lg:w-1/2"
          @click="skipStep">
          <span>Később</span>
        </button>
        <button
          type="button"
          class="order-1 w-full mb-5 sm-btn-app sm-btn-app-primary lg:order-2 lg:w-1/2 lg:mb-0"
          @click="saveTicket">
          <span>Bérlet mentése</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { CreditCardIcon } from "vue-feather-icons";
import { useToast } from "vue-toastification";

export default {
  components: { CreditCardIcon },
  inject: ["ticketApi"],
  emits: ["updateStepA", "increaseSubStep"],
  data() {
    return {
      usabilities: [
        {
          id: true,
          label: "Korlátlan"
        },
        {
          id: false,
          label: "Véges felhasználású"
        }
      ],
      validities: [
        {
          id: "day",
          label: "Nap"
        },
        {
          id: "month",
          label: "Hónap"
        },
        {
          id: "year",
          label: "Év"
        }
      ],
      form: {
        type: "normal",
        name: "",
        price: "",
        usability: "",
        validity: "",
        validity_range: "",
      },
      available_uses: null
    };
  },
  methods: {
    async saveTicket() {
      if (this.available_uses) {
        this.form.available_uses = this.available_uses;
      }
      const formData = {
        ...this.form,
        limitless_usage: this.form.usability.id,
        validity_range: this.form.validity_range.id
      };
      try {
        await axios.post(this.ticketApi, formData);
        this.$emit("updateStepA");
        this.$emit("increaseSubStep");
      } catch (e) {
        for (const value of Object.values(e.response.data.errors)) {
          for (const data of value) {
            const toast = useToast();
            toast.error(data);
          }
        }
      }
    },
    skipStep() {
      this.$emit("increaseSubStep");
    }
  }
};
</script>
