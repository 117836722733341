<template>
  <Teleport to="body">
    <transition name="fade">
      <div
        v-if="modalIsVisible"
        class="fixed top-0 left-0 z-[2000] w-screen h-screen bg-black/25" />
    </transition>
    <transition name="fade-from-top">
      <div
        v-if="modalIsVisible"
        class="
          fixed
          top-0
          lg:top-16
          left-0
          lg:left-1/2 lg:-translate-x-1/2
          bg-white
          lg:rounded-2xl
          overflow-hidden
          h-screen
          lg:h-auto
          w-screen
          lg:w-[42.75rem]
          z-[2001]
          transition-opacity
        ">
        <header
          class="relative z-10 flex items-center px-5 py-5 bg-white lg:py-0 lg:bg-brand-blue-50 lg:px-0 lg:justify-center lg:h-32">
          <!-- <button
            type="button"
            class="p-2 bg-white rounded lg:absolute lg:top-10 lg:right-10 lg:mt-0"
            @click="toggleModalVisibility">
            <x-icon />
          </button> -->
          <h2
            class="mb-0 text-2xl leading-relaxed font-jost-bold lg:text-4xl">
            {{ modalTitle }}
          </h2>
        </header>
        <div
          class="
            static
            lg:relative
            h-[calc(100vh-9.25rem)]
            lg:h-auto lg:max-h-[calc(100vh-16rem)]
            invisible-scrollbar px-5 lg:px-20 py-5 lg:py-14
          ">
          <slot />
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script>
// import { XIcon } from "vue-feather-icons";
export default {
  components: {
    // XIcon,
  },
  props: {
    modalIsVisible: { type: Boolean, required: true, default: false },
    modalTitle: { type: String, required: true, default: "Sportmate Club" },
  },
  emits: ["toggleModalVisibility"],
  methods: {
    toggleModalVisibility() {
      this.$emit("toggleModalVisibility");
    },
  },
};
</script>

<style lang="postcss">
.invisible-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}
</style>
