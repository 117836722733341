<template>
  <div>
    <sm-modal
      :modal-is-visible="modal"
      size="lg"
      :modal-title="$t('customers.customer_tags.modal_title', {name: user?.name || user?.full_name})"
      body-classes="!p-0"
      @toggleModalVisibility="emits('toggleModalVisibility')">
      <div class="p-4">
        <div class="flex items-center justify-between md:justify-start flex-grow-1 flex-md-grow-0">
          <sm-input
            v-model="search"
            class="mb-0 mr-2"
            :placeholder="$t('generic.search')">
            <template #prepend>
              <vue-feather
                v-if="searchIsloading"
                type="loader"
                class="w-4 h-4 animate-spin" />
              <vue-feather
                v-else
                type="search"
                class="w-4 h-4" />
            </template>
          </sm-input>

          <form
            method="post"
            :action="user.routes.export">
            <input
              type="hidden"
              name="_token"
              :value="$page.props.csrfToken">
            <sm-button
              type="submit"
              icon="download"
              size="input"
            />
          </form>
        </div>
      </div>

      <table
        class="border-t sm-table">
        <thead>
          <tr>
            <th>{{ $t('customers.fields.name.label') }}</th>
            <th>{{ $t('customers.fields.email.label') }}</th>
            <th>{{ $t('customers.fields.birth_date.label') }}</th>
          </tr>
        </thead>
        <tbody v-if="axiosData.data">
          <tr v-if="axiosData.data.length === 0">
            <td colspan="3">
              <sm-alert
                color="warning"
                no-margin>
                {{ $t('generic.empty_table') }}
              </sm-alert>
            </td>
          </tr>
          <tr
            v-for="thead of axiosData.data"
            v-else
            :key="thead.id">
            <td>
              <sm-avatar
                :user="thead"
                :href="thead.routes.show" />
            </td>
            <td>
              {{ thead.email }}
            </td>
            <td>
              {{ thead.birth_date ? useDateFormat(thead.birth_date) : '-' }}
            </td>
          </tr>
        </tbody>
        <tbody
          v-else>
          <tr>
            <td colspan="3">
              <div class="flex justify-center w-full item-center">
                <vue-feather
                  type="loader"
                  class="w-4 h-4 animate-spin" />
              </div>
            </td>
          </tr>
        </tbody>
        <div
          v-if="axiosData?.meta"
          class="p-4">
          <vue-paginator
            :pagination="axiosData?.meta"
            @page-selected="$event => pageChanged($event)" />
        </div>
      </table>
    </sm-modal>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";
import axios from "axios";
import debounce from "lodash/debounce";

const props = defineProps({
  modal: { type: Boolean, required: true, default: false },
  user: { type: Object, required: false, default: null }
});

const emits = defineEmits(["toggleModalVisibility"]);

const axiosData = ref([]);

const search = ref("");
const activePage = ref(1);
const searchIsloading = ref(false);

const searchContacts = debounce(() => {
  searchIsloading.value = true;
  axios.get(props.user?.routes?.details, { params: { search: search.value || null, page: activePage.value } })
    .then((res) => {
      searchIsloading.value = false;
      axiosData.value = res.data;
    });
}, 250);

watch(() => props.modal, (e) => {
  if (e === true) {
    searchContacts();
  } else {
    axiosData.value = [];
  }
});

watch(search, () => {
  searchContacts();
});

const pageChanged = (e) => {
  activePage.value = e;
  searchContacts();
};
</script>
