<template>
  <div
    class="relative "
    :class="{'pointer-events-none': pageIsLoading}">
    <div
      v-if="pageIsLoading"
      v-auto-animate
      class="absolute z-10 flex items-center justify-center w-full h-full">
      <vue-feather
        type="loader"
        class="ml-1 text-black animate-spin" />
    </div>
    <sm-card
      :header-title="$t('tickets.customer_tickets.title')"
      class="transition-opacity"
      :class="{'opacity-50': pageIsLoading}"
      no-padding
      :header-info="$t('customers.profile.tooltips.discounts')">
      <template #headerActions>
        <sm-button
          :href="$page.props.routes.customerTickets">
          {{ $t('tickets.customer_tickets.manage_button') }}
        </sm-button>
      </template>

      <table class="mb-0 sm-table sm-table-borderless-top">
        <template v-if="pageTickets?.length !== 0">
          <thead>
            <tr>
              <th>{{ $t('tickets.customer_tickets.columns.title') }}</th>
              <th>{{ $t('tickets.customer_tickets.columns.occasions') }}</th>
              <th class="text-right">
                {{ $t('tickets.customer_tickets.columns.valid_from') }}
              </th>
              <th class="text-right">
                {{ $t('tickets.customer_tickets.columns.valid_to') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(ticket, i) in pageTickets"
              :key="i"
              :class="{'!bg-bo-orange': ticket.isExpiringSoon, '!bg-bo-green-100': !ticket.isExpiringSoon && !ticket.isFranchiseTicket, '!bg-bo-purple/25': ticket.isFranchiseTicket}">
              <td :class="{'text-bo-yellow-800': ticket.notActiveYet}">
                <vue-feather
                  v-if="ticket.isExpiringSoon"
                  v-tippy="$t('tickets.customer_tickets.tooltips.expiring_soon')"
                  type="info"
                  class="w-2.5 mr-2 text-bo-red" />
                <vue-feather
                  v-if="ticket.isFranchiseTicket"
                  v-tippy="$t('tickets.customer_tickets.tooltips.franchise')"
                  type="briefcase"
                  class="w-2.5 mr-2 text-bo-black" />
                {{ ticket.name }}
              </td>
              <td :class="{'text-bo-red': ticket.isExpiringSoon && ticket.expiresByUsageSoon}">
                <vue-feather
                  v-if="!ticket.isLimitless"
                  v-tippy="$t('tickets.customer_tickets.tooltips.remaining_uses')"
                  type="info"
                  class="w-2.5 mr-2" />
                {{ ticket.availableUsagesLabel }}
              </td>
              <td class="text-right">
                {{ useDateFormat(ticket.validFrom) }}
              </td>
              <td
                class="text-right"
                :class="{'text-bo-red': ticket.isExpiringSoon && ticket.expiresByDateSoon}">
                {{ useDateFormat(ticket.validTo) }}
              </td>
            </tr>
          </tbody>
        </template>
        <template v-else>
          <tbody
            class="p-2 bg-bo-gray-50">
            <tr>
              <td colspan="4">
                <button
                  type="button"
                  class="flex items-center justify-center w-full p-4 rounded-md bg-bo-gray-100 text-bo-gray-400 group"
                  @click="emit('openSellProductModal')">
                  <div class="text-center">
                    <vue-feather
                      type="plus"
                      class="w-10 h-10 mb-2 transition-colors group-hover:text-bo-blue" />
                    <br>
                    <p class="mb-0 transition-colors group-hover:text-bo-blue">
                      {{ $t('tickets.customer_tickets.empty_table') }}
                    </p>
                  </div>
                </button>
              </td>
            </tr>
          </tbody>
        </template>
      </table>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";

defineProps({
  pageTickets: { type: Object, required: false, default: null }
});

const pageIsLoading = inject("pageIsLoading");

const emit = defineEmits(["openSellProductModal"]);
</script>
