<template>
  <div>
    <In-Head :title="$t('customers.tickets.title', {name: $page.props.customer.name})" />

    <sub-header :title="$t('customers.tickets.title', {name: $page.props.customer.name})">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          :href="$page.props.routes.customer"
          preset="back"
          icon="arrow-left" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        :data="table"
        :sorts="sorts"
        paginator>
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="col !== 'actions' ? $t(`customers.tickets.table.${col}`) : ''"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <div :class="{'text-bo-gray-400': !slotProps.data?.usable}">
                <template v-if="col === 'ticket_type'">
                  <template v-if="slotProps.data?.routes?.editTicket">
                    <a
                      :href="slotProps.data?.routes?.editTicket"
                      class="text-bo-blue">
                      {{ slotProps.data?.ticketType }}
                    </a>
                  </template>
                  <span v-else>
                    {{ slotProps.data?.ticketType }}
                  </span>
                </template>
                <template v-else-if="col === 'properties'">
                  <template v-if="slotProps.data?.trashed">
                    <ticket-property-icon :legend="findLegend('trashed')" />
                  </template>
                  <template v-else>
                    <template v-if="slotProps.data?.isExtended">
                      <ticket-property-icon :legend="findLegend('extended')" />
                    </template>
                    <template v-if="slotProps.data?.addedLater">
                      <ticket-property-icon :legend="findLegend('addedLater')" />
                    </template>
                    <template v-if="slotProps.data?.notActiveYet">
                      <ticket-property-icon :legend="findLegend('notActiveYet')" />
                    </template>
                    <template v-if="slotProps.data?.isExpired || !slotProps.data?.usable">
                      <ticket-property-icon :legend="findLegend('expiredOrNotUsable')" />
                    </template>
                    <template v-if="slotProps.data?.usable && !slotProps.data?.isExpired">
                      <ticket-property-icon :legend="findLegend('usableAndNotExpired')" />
                    </template>
                    <template v-if="slotProps.data?.boughtWithInstallments">
                      <ticket-property-icon :legend="findLegend('boughtWithInstallments')" />
                    </template>
                    <template v-if="slotProps.data?.hasVoucher">
                      <ticket-property-icon
                        :legend="findLegend('hasVoucher')"
                        :custom-title="slotProps.data?.voucherCode"
                        class="cursor-pointer"
                        @click="copyVoucherCode(slotProps.data?.voucherCode)" />
                    </template>
                  </template>
                </template>
                <template v-else-if="col === 'max_usage_count'">
                  <div class="inline-flex items-center space-x-2">
                    <vue-feather
                      v-if="!slotProps.data?.isLimitless"
                      v-tippy="$t('tickets.customer_tickets.tooltips.remaining_uses')"
                      type="info"
                      class="w-4 min-w-[1rem]" />
                    <span>
                      {{ slotProps.data?.availableUsages }}
                    </span>
                  </div>
                </template>
                <template v-else-if="col === 'validity'">
                  <template v-if="!slotProps.data?.validFrom && !slotProps.data?.validTo">
                    -
                  </template>
                  <template v-else-if="slotProps.data?.validFrom && slotProps.data?.validTo">
                    {{ $t('customers.tickets.table.validities.between', { from: useDateFormat(slotProps.data?.validFrom), to: useDateFormat(slotProps.data?.validTo) }) }}
                  </template>
                  <template v-else-if="slotProps.data?.validFrom">
                    {{ $t('customers.tickets.table.validities.from', { from: useDateFormat(slotProps.data?.validFrom) }) }}
                  </template>
                  <template v-else-if="slotProps.data?.validTo">
                    {{ $t('customers.tickets.table.validities.to', { to: useDateFormat(slotProps.data?.validTo) }) }}
                  </template>
                </template>
                <template v-else-if="col === 'activation_date'">
                  {{ slotProps.data?.createdAt ? useDateFormat(slotProps.data?.createdAt) : '' }}
                </template>
                <template v-else-if="col === 'stored_by'">
                  {{ slotProps.data?.createdBy }}
                </template>
                <template v-else-if="col === 'checkins_count'">
                  {{ slotProps.data?.checkinsCount }}
                </template>
                <template v-else-if="col === 'actions'">
                  <div
                    v-if="slotProps.data"
                    class="text-right"
                  >
                    <template v-if="slotProps.data.group && !slotProps.data.isCustomersTickets">
                      <a
                        :href="slotProps.data.routes.viewCustomerGroup"
                        class="text-bo-blue"
                      >
                        {{ slotProps.data.group }}
                      </a>
                    </template>
                    <template v-else-if="!slotProps.data.trashed">
                      <sm-toggle-button
                        v-if="!slotProps.data.trashed"
                        :title="$t('customers.tickets.table.actions.button')"
                        align="right"
                        :action-data="slotProps.data"
                        :options="toggleButtonOptions" />
                    </template>
                  </div>
                </template>
                <template v-else>
                  {{ slotProps.data?.[col] }}
                </template>
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
      <div class="px-4 pt-4 pb-4 border-t border-bo-gray-100">
        <div class="space-y-2 sm-row lg:space-y-0 lg:justify-between">
          <div
            v-for="(legend, index) of legends"
            :key="index"
            class="inline-flex items-center w-full sm-col lg:w-auto">
            <ticket-property-icon
              :legend="findLegend(legend.id)"
              class="mr-2" />
            {{ legend.title }}
          </div>
        </div>
      </div>
    </sm-card>
    <sm-confirm-dialog :group="`invalidate_${uid}`" />
    <sm-confirm-dialog
      has-fake-accept-button
      :group="`use_action_${uid}`"
      :fake-accept-label="$t('generic.save')"
      @onFakeAccept="onFakeAccept">
      <sm-input
        v-model="useTicketForm.usage_count"
        class="mt-4"
        type="number"
        min="1"
        :label="$t('customers.tickets.actions.modals.use_action.label')"
        :error="useTicketForm?.errors?.usage_count"
        name="usage_count"
      />
    </sm-confirm-dialog>
    <season-ticket-change-validity-start-modal
      :modal-visibility="showSeasonTicketValidtyStartModal"
      :modal-data="seasonTicketValidtyStartModalData"
      @toggleModalVisibility="updateSeasonTicketValidtyStartModal"
    />
    <season-ticket-change-validity-end-modal
      :modal-visibility="showSeasonTicketValidtyEndModal"
      :modal-data="seasonTicketValidtyEndModalData"
      @toggleModalVisibility="updateSeasonTicketValidtyEndModal"
    />
    <season-ticket-change-ticket-amount-modal
      :modal-visibility="showChangeTicketAmountModal"
      :modal-data="changeTicketAmountModalData"
      @toggleModalVisibility="changeTicketAmountModal"
    />
    <season-ticket-delete-modal
      :modal-visibility="showDeleteTicketModal"
      :modal-data="deleteTicketModalData"
      @toggleModalVisibility="updateDeleteTicketModal"
    />
    <ticket-move-modal
      :modal-visibility="showMoveModal"
      :modal-data="moveModalData"
      @toggleModalVisibility="updateMoveModal"
    />
    <ticket-voucher-modal
      :modal-visibility="showVoucherModal"
      :modal-data="voucherModalData"
      @toggleModalVisibility="updateGenerateVoucherModal"
    />
  </div>
</template>

<script setup>
import { useToast } from "vue-toastification";
import { useDateFormat } from "@/inertia/composables/global";
import SeasonTicketChangeTicketAmountModal from "@/inertia/pages/customers/show/tickets/_modals/SeasonTicketChangeTicketAmountModal.vue";
import SeasonTicketChangeValidityEndModal from "@/inertia/pages/customers/show/tickets/_modals/SeasonTicketChangeValidityEndModal.vue";
import SeasonTicketChangeValidityStartModal from "@/inertia/pages/customers/show/tickets/_modals/SeasonTicketChangeValidityStartModal.vue";
import SeasonTicketDeleteModal from "@/inertia/pages/customers/show/tickets/_modals/SeasonTicketDeleteModal.vue";
import TicketMoveModal from "@/inertia/pages/customers/show/tickets/_modals/TicketMoveModal.vue";
import TicketPropertyIcon from "@/inertia/pages/customers/show/tickets/_partials/TicketPropertyIcon.vue";
import TicketVoucherModal from "@/inertia/pages/customers/show/tickets/_modals/TicketVoucherModal.vue";
import { useForm } from "@inertiajs/vue3";
import uniqueId from "lodash.uniqueid";
import { useConfirm } from "primevue/useconfirm";

const toast = useToast();

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const confirm = useConfirm();
const uid = uniqueId();

const tableFields = ref(["ticket_type", "properties", "max_usage_count", "validity", "activation_date", "stored_by", "checkins_count", "actions"]);

// legends helper
const legends = ref([
  {
    id: "usableAndNotExpired",
    icon: "check",
    title: window.trans("customers.tickets.table.legends.usable_and_not_expired"),
    classes: "text-bo-green"
  },
  {
    id: "expiredOrNotUsable",
    icon: "clock",
    title: window.trans("customers.tickets.table.legends.expired_or_not_usable"),
    classes: "text-bo-red"
  },
  {
    id: "trashed",
    icon: "trash-2",
    title: window.trans("customers.tickets.table.legends.trashed"),
    classes: "text-bo-red"
  },
  {
    id: "notActiveYet",
    icon: "skip-forward",
    title: window.trans("customers.tickets.table.legends.not_active_yet"),
    classes: "text-bo-blue"
  },
  {
    id: "addedLater",
    icon: "skip-back",
    title: window.trans("customers.tickets.table.legends.added_later"),
    classes: ""
  },
  {
    id: "extended",
    icon: "plus-circle",
    title: window.trans("customers.tickets.table.legends.extended"),
    classes: ""
  },
  {
    id: "boughtWithInstallments",
    icon: "layers",
    title: window.trans("customers.tickets.table.legends.bought_with_installments"),
    classes: ""
  },
  {
    id: "hasVoucher",
    icon: "gift",
    title: window.trans("customers.tickets.table.legends.has_voucher"),
    classes: ""
  },
]);

const findLegend = (id) => {
  return legends.value.find(data => data.id === id);
};

// action: invalidate ticket
const invalidateTicket = (ticket) => {
  const form = useForm({});

  confirm.require({
    group: `invalidate_${uid}`,
    header: window.trans("customers.tickets.actions.modals.invalidate.header"),
    message: window.trans("generic.are_you_sure"),
    accept: () => {
      form
        .post(ticket.routes.invalidateTicket, {
          only: ["table", "customer", "errors", "flash"],
        });
    }
  });
};

// action: season ticket extension
const showSeasonTicketValidtyStartModal = ref(false);
const seasonTicketValidtyStartModalData = ref(null);

const updateSeasonTicketValidtyStartModal = (data) => {
  showSeasonTicketValidtyStartModal.value = !showSeasonTicketValidtyStartModal.value;
  seasonTicketValidtyStartModalData.value = data;
};

// action: season ticket extension
const showSeasonTicketValidtyEndModal = ref(false);
const seasonTicketValidtyEndModalData = ref(null);

const updateSeasonTicketValidtyEndModal = (data) => {
  showSeasonTicketValidtyEndModal.value = !showSeasonTicketValidtyEndModal.value;
  seasonTicketValidtyEndModalData.value = data;
};

// action: useTicket
const useTicketForm = useForm({
  usage_count: 1
});

const activeTicket = ref(null);

const useTicket = (ticket) => {
  confirm.require({
    group: `use_action_${uid}`,
    header: window.trans("customers.tickets.actions.modals.use_action.header"),
    onShow : () => {
      activeTicket.value = ticket;
    },
    onHide : () => {
      activeTicket.value = null;
    },
  });
};

const onFakeAccept = () => {
  useTicketForm
    .post(activeTicket.value.routes.useTicket, {
      only: ["table", "customer", "errors", "flash"],
      onSuccess: () => {
        useTicketForm.reset("usage_count");
        confirm.close();
      }
    });
};

// action: useTicketAmount
const showChangeTicketAmountModal = ref(false);
const changeTicketAmountModalData = ref(null);

const changeTicketAmountModal = (data) => {
  showChangeTicketAmountModal.value = !showChangeTicketAmountModal.value;
  changeTicketAmountModalData.value = data;
};

// action: deleteTicketModal
const showDeleteTicketModal = ref(false);
const deleteTicketModalData = ref(null);

const updateDeleteTicketModal = (data) => {
  showDeleteTicketModal.value = !showDeleteTicketModal.value;
  deleteTicketModalData.value = data;
};

// action: move ticket
const showMoveModal = ref(false);
const moveModalData = ref(null);

const updateMoveModal = (data) => {
  showMoveModal.value = !showMoveModal.value;
  moveModalData.value = data;
};

// action: voucher
const showVoucherModal = ref(false);
const voucherModalData = ref(null);
const updateGenerateVoucherModal = (data) => {
  showVoucherModal.value = !showVoucherModal.value;
  voucherModalData.value = data;
};

// toggle button helper
const toggleButtonOptions = ref([
  {
    id: "cancel",
    label: window.trans("customers.tickets.actions.cancel"),
    action: invalidateTicket,
    icon: "x-octagon",
    visible: ticket => ticket.permissions.canInvalidate && ticket.canBeInvalidated,
  },{
    id: "updateStart",
    label: window.trans("customers.tickets.actions.update_start_date"),
    action: updateSeasonTicketValidtyStartModal,
    icon: "sunrise",
    visible: ticket => ticket.permissions.canChangeValidityStart,
  },{
    id: "updateEnd",
    label: window.trans("customers.tickets.actions.update_end_date"),
    action: updateSeasonTicketValidtyEndModal,
    icon: "sunset",
    visible: ticket => ticket.permissions.canExtend && ticket.canExtendValidity,
  },{
    id: "endDateRequestes",
    label: window.trans("customers.tickets.actions.end_date_requests"),
    to: "viewExtensionHistory",
    icon: "clock",
    visible: ticket => ticket.permissions.canExtend && !ticket.trashed,
  },{
    id: "useTicket",
    label: window.trans("customers.tickets.actions.use"),
    action: useTicket,
    icon: "hash",
    visible: ticket => ticket.permissions.canUse && ticket.usable,
  },{
    id: "changeTicketAmount",
    label: window.trans("customers.tickets.actions.use_amount"),
    action: changeTicketAmountModal,
    icon: "hash",
    visible: ticket => ticket.permissions.canExtendUsages,
  },{
    id: "installments",
    label: window.trans("customers.tickets.actions.installment_details"),
    toLegacyRoute: "viewInstallmentPayment",
    icon: "layers",
    visible: ticket => ticket.permissions.canViewInstallmentPayment && ticket.boughtWithInstallments,
  },{
    id: "move",
    label: window.trans("customers.tickets.actions.move"),
    action: updateMoveModal,
    icon: "log-in",
    visible: ticket => ticket.permissions.canMove,
  },{
    id: "voucher",
    label: window.trans("customers.tickets.actions.generate_voucher"),
    action: updateGenerateVoucherModal,
    icon: "tag",
    visible: ticket => ticket.permissions.canGenerateVoucher,
  },{
    id: "delete",
    label: window.trans("customers.tickets.actions.delete"),
    action: updateDeleteTicketModal,
    class: "danger",
    icon: "trash-2",
    visible: ticket => ticket.permissions.canDelete && !ticket.isExpired && ticket.usable,
  }
]);

const copyVoucherCode = async (code) => {
  if (! window.isSecureContext) {
    toast.error("A másolás csak HTTPS módban működik.");
    return;
  }

  try {
    await navigator.clipboard.writeText(code);
    toast.success("Link a vágólapra másolva", {
      position: "bottom-center"
    });
  } catch ($e) {
    console.log($e);
  }
};
</script>

