<script>
import AbstractCrudFilterType from "../utility/AbstractCrudFilterType";

export default {
  mixins: [AbstractCrudFilterType],
  data() {
    return {
      defaults: {
        term: false,
      },
    };
  },
  computed: {
    filterData() {
      return {
        term: this.data.data.term,
      };
    },
  },
  render() {
    return this.renderAbstract([
      this.createDiv("sm-col lg:w-8/12 flex items-center", [
        this.createCheckbox(
          [this.data.data, "term"],
          this.data.filter.field.replace(/\./g, "_")
        ),
      ]),
    ]);
  },
};
</script>
