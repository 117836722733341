<template>
  <settings-layout>
    <in-head :title="$t('branding.settings.page_title')" />
    <sm-card
      :form="form"
      :header-title="$t('branding.settings.page_title')"
      @onSubmit="onSubmit">
      <sm-select
        v-model="form.appearance"
        :options="appearanceOptions"
        name="appearance"
        :label="$t('branding.settings.appearance.label')"
        :help="$t('branding.settings.appearance.help')"
      />

      <sm-file
        v-model="form.image.logo"
        name="image[logo]"
        label="Logo"
        :help="$t('branding.settings.logo.help')"
        :error="form?.errors['image.logo']"
      />
      <div
        v-if="previewImage"
        class="relative border border-black w-[200px] h-[200px] mb-4">
        <img :src="previewImage">

        <form
          @submit.prevent="onDeleteImage">
          <button
            v-tippy="$t('generic.delete')"
            type="submit"
            class="absolute flex items-center justify-center w-4 h-4 top-1 right-1 sm-close">
            <span
              aria-hidden="true"
              class="text-2xl">&times;</span>
          </button>
        </form>
      </div>

      <div class="grid grid-cols-2 gap-4">
        <div
          v-for="(value, key) in form.color"
          :key="key">
          <div v-if="(typeof value === 'object')">
            <div
              v-for="(vvalue, index) in value"
              :key="index"
              class="mb-4">
              <ColorPicker
                v-model="form.color[key][index]"
                class="mr-2" />
              <span>
                {{ $t(`branding.color.${key}.${index}`) }} <span class="text-bo-gray-400">({{ vvalue.startsWith('#') ? vvalue : `#${vvalue}` }})</span>
              </span>
            </div>
          </div>
          <div v-else>
            <ColorPicker
              v-model="form.color[key]"
              class="mr-2" />
            {{ $t(`branding.color.${key}`) }} <span class="text-bo-gray-400">({{ value.startsWith('#') || value.startsWith('rgb') ? value : `#${value}` }})</span>
          </div>
        </div>
      </div>

      <template #footer>
        <sm-button
          preset="save"
          :loading="form.processing"
          @click="onSubmit" />
      </template>
    </sm-card>
  </settings-layout>
</template>

<script setup>
import { router, useForm, usePage } from "@inertiajs/vue3";
import { provide } from "vue";

const props = defineProps({
  settings: { type: Object, required: true, default:  null },
  previewImageMaxSize: { type: [String, Number], required: true, default:  "" },
  previewImage: { type: String, required: true, default:  "" },
  deleteImageRoute: { type: String, required: true, default:  "" },
});

// default variables
const page = usePage();

const appearanceOptions = ref([
  {
    id: "light",
    label: window.trans("generic.light")
  },{
    id: "dark",
    label: window.trans("generic.dark")
  }
]);

const form = useForm({
  appearance: appearanceOptions.value.find(data => data.id === props.settings.appearance),
  color: props.settings.color,
  image: {
    logo: null
  }
});

const onSubmit = () => {
  form
    .transform((data) => {
      return {
        ...data,
        appearance: data.appearance.id,
      };
    })
    .post(page.url);
};

const onDeleteImage = () => {
  router.delete(props.deleteImageRoute);
};

provide("form", form);
</script>
