<script>
import AbstractCrudFilterType from "../utility/AbstractCrudFilterType";

export default {
  mixins: [AbstractCrudFilterType],
  data() {
    return {
      defaults: {
        type: "like",
      },

      availableTypes: ["like", "not like", "=", "<>"],
    };
  },
  computed: {
    filterData() {
      return {
        term: this.data.data.term,
        type: this.data.data.type,
      };
    },
  },
  render() {
    return this.renderAbstract([
      this.createDiv("sm-col w-full lg:w-3/12", [
        this.createSelect([this.data.data, "type"], this.availableTypes.map(t => ({
          label: "crud_filter." + t,
          value: t
        }))),
      ]),
      this.createDiv("sm-col w-full lg:w-5/12", [
        this.createInput([this.data.data, "term"]),
      ]),
    ]);
  },
};
</script>
