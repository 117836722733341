<script>
export default {
  props: {
    baseUnit: { type: Object, default: () => {} },
    baseAmount: { type: Number, default: null },
    basePrice: { type: Number, default: null },
    units: { type: Array, default: () => [] },
    conversions: { type: Object, default: () => {} },
  },
  data() {
    return {
      amount: null,
      unit: this.units[0].id,
      price: null,

      shouldUpdatePrice: true,
    };
  },
  computed: {
    selectedUnit() {
      return this.units.find(u => parseInt(u.id) === parseInt(this.unit));
    },
  },
  watch: {
    unit() {
      this.$nextTick(() => {
        this.updatePrice();
      });
    },
    amount() {
      this.$nextTick(() => {
        this.updatePrice();
      });
    },
  },
  methods: {
    updatePrice() {
      if (!this.amount) {
        return;
      }

      if (!this.shouldUpdatePrice) {
        return;
      }

      if (this.selectedUnit.abbreviation === this.baseUnit.abbreviation) {
        this.price = Math.round(this.basePrice * (this.amount / this.baseAmount));
      } else {
        let conversionRate = 1;
        const availableConversions = this.conversions[this.baseUnit.measure][this.selectedUnit.abbreviation];
        if (availableConversions) {
          conversionRate = availableConversions.conversions[this.baseUnit.abbreviation];
          this.price = Math.round(this.basePrice * conversionRate * ((this.amount || 1) / this.baseAmount));
        }
      }
    },
    onPriceInput(e) {
      this.shouldUpdatePrice = e.target.value === "";
    },
  },
};
</script>
