<template>
  <div>
    <sm-card header-title="Default usage">
      <sm-radio
        label="Simple radio"
        text="Radio text"
      />
      <code class="block !-mt-4"><pre class="text-xs">&lt;sm-radio label="..." text="..."  /&gt;</pre></code><br>
      <sm-radio-group
        label="Simple radio group"
        name="defaults_01"
        :items="radioGroupItems"
      />
      <code class="block !-mt-4"><pre class="text-xs">&lt;sm-radio-group label="..." :items=[{id: "...", text: "..."}] name="..."  /&gt;</pre></code><br>
    </sm-card>

    <sm-card header-title="Value bindings">
      <sm-alert
        no-flex
        color="info">
        The default format of the radio group value is: <code class="leading-normal">[{ id: '...', text: '...' }]</code>
      </sm-alert>
      <sm-radio-group
        v-model="radioGroupModel"
        name="defaults_02"
        :items="radioGroupItems"
        :label="`Simple radio group with v-model: ${radioGroupModel}`"
      />
      <code class="block !-mt-4"><pre class="text-xs">v-model="..."</pre></code>
      <hr>
      <sm-radio-group
        :model-value="radioGroupValue"
        :items="radioGroupItems"
        name="defaults_03"
        :label="`Simple radio group with value: ${radioGroupValue}`"
      />
      <code class="block !-mt-4"><pre class="text-xs">:model-value="..."</pre></code>
    </sm-card>

    <sm-card header-title="Texts and helps">
      <sm-radio-group
        :model-value="radioGroupValue"
        name="defaults_04"
        :items="radioGroupItems"
        label="Radio group with label"
      />
      <code class="block !-mt-4"><pre class="text-xs">label="..."</pre></code>
      <hr>
      <sm-radio-group
        :model-value="radioGroupValue"
        name="defaults_05"
        :items="radioGroupItems"
        label="Radio group with help text"
        help="This is a help text"
      />
      <code class="block !-mt-4"><pre class="text-xs">help="..."</pre></code><br>
    </sm-card>
  </div>
</template>

<script setup>
const radioGroupModel = ref("radio_group_01");
const radioGroupValue = ref("radio_group_02");
const radioGroupItems = ref([
  {
    id: "radio_group_01",
    text: "First element"
  },{
    id: "radio_group_02",
    text: "Second element"
  }
]);
</script>
